import React, {Component} from 'react';
class paymentOptions extends Component{
    constructor(props) {
        super(props);
        this.state={
            toggle:true
        }
        this.handleClick=this.handleClick.bind(this);
    }

    handleClick(event){
        event.persist();
        this.props.onChange(event.target.id);
    }

    componentDidMount() {
        const initialCheckState = this.props.currentMethod === this.props.item.itemId
        this.setState({toggle: initialCheckState})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({toggle: (nextProps.currentMethod === nextProps.item.itemId)})
    }



    render(){
        return(
            <div className="col-sm-4">
                <div className="custom-control custom-radio rdoCheck">
                    <input type="radio" className="custom-control-input" id={this.props.item.itemId} name="example"
                           value="customEx" onClick={this.handleClick} checked={this.state.toggle}/>
                    <label className="custom-control-label" htmlFor={this.props.item.itemId} style={{margin: '5px'}}/>
                </div>
                <div className={this.props.item.cssClass}>
                    <div className="head_sec2">{this.props.item.name}</div>
                    <div className="img_sec">
                        <img alt="" src={this.props.item.imgSrc}/>
                    </div>
                </div>
            </div>

        )
    }
}
export default paymentOptions

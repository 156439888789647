import * as types from '../constants/configuration';
const init = {
 vendors: [],
 customers: [],
}

function configurationReducer(state = init, action) {
 switch (action.type) {
  case types.GET_VENDOR_SUCCESS:
   return { ...state, vendors: action.payload };
  case types.GET_CUSTOM_VENDOR_SUCCESS:
   return { ...state, customers: action.payload };
  default:
   return state;
 }
}

export default configurationReducer;

import { put, select, takeLatest, call } from 'redux-saga/effects';
import { selectAuthToken, selectTokenAndId, selectCompanyId } from '../selectors/auth';
import * as service from '../services/fabric';

import * as types from '../constants/fabric';


function* getFabricSaga() {
 try {
  const token = yield select(selectAuthToken);
  const companyId = yield select(selectCompanyId);
  const params = {filter: {where: {companyId}}}
  const vendors = yield call(service.getFabric, params, token);
  if (typeof vendors !== 'string') {
   yield put({ type: types.GET_FABRICS_SUCCESS, payload: vendors })
   return;
  }
  yield put({ type: types.GET_FABRICS_FAILURE, payload: "no data found" })
 } catch (error) {
  yield put({ type: types.GET_FABRICS_FAILURE, payload: error.message })
  console.log("ERR ", error.message);
 }
}

export default function* configurationWatcher() {
 yield takeLatest(types.GET_FABRICS_REQUEST, getFabricSaga);
}

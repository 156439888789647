import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CreditCardInput from 'react-credit-card-input';
import Swal from 'sweetalert2';
import axios from 'axios'
import UpgradeSubscriptionPopup from './upgradeSubscriptionPopup';
import RenewalSubscription from './subscriptionRenewal';
import PaymentMethod from './paymentMethod/paymentMethod';

import { deleteSubscription, getSubscription } from '../../store/actions/subscription';

import deleteIcon from '../../img/delete.png'
import './style.css';
import { ApiUrl, CalculateDays, CalculateMonth } from '../../constants';
import { addCompany } from '../../store/actions/actions';

const UpdateCard = (props) => {

  const cid = useSelector(state => state.auth.companyId);
  const company = useSelector(state => state.auth.company)

  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  }
  const handleCardExpiryChange = (event) => {
    setExpiry(event.target.value);
  }
  const handleCardCVCChange = (event) => {
    setCvc(event.target.value);
  }

  const handleUpdate = () => {
    if (!cardNumber || !expiry) return Swal.fire({ icon: 'error', title: 'Please provide a valid card data!', timer: 2500 });
    if (props.renewal) {
      return props.setCard({ ccnumber: cardNumber, ccexp: expiry.split(" ").join("") })
    }
    if (company.isAnnual) return Swal.fire({ icon: "error", title: "Not Allowed", text: "Annual Company can't update Card" })
    const url = ApiUrl + "/Companies/" + cid + "/updateCardInfo";
    axios.post(url, { ccnumber: cardNumber, ccexp: expiry.split(" ").join("") })
      .then((result) => {
        const { data } = result.data;
        if (data.status) {
          return Swal.fire({ icon: "success", text: "Updated successfully!" })
        }
        Swal.fire({ icon: "error", text: "Something went wrong!" });
      })
      .catch((err) => {
        console.log("ERR: updating card Info Err: ", err.message)
        Swal.fire({ icon: "error", text: "Something went wrong!" });
      })
  }

  return (
    <div id="page-content-wrapper">
      <div className="container-fluid">
        <div
          className="row setup_top2 justify-content-md-center"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-12 col-sm-8">
            <div className="box_payment">
              <div className="heding1">CREDIT CARD INFO</div>
              <div className="inside">
                <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <CreditCardInput
                    cardNumberInputProps={{ value: cardNumber, onChange: handleCardNumberChange }}
                    cardExpiryInputProps={{ value: expiry, onChange: handleCardExpiryChange }}
                    cardCVCInputProps={{ value: cvc, onChange: handleCardCVCChange }}
                    fieldClassName="input"
                  />
                  <button
                    type="btn" className="btn btn-primary" data-target="#exampleModal"
                    onClick={handleUpdate}>
                    Update Card Info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateCard
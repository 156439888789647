import React, { useEffect, useState } from 'react';

import QouteStatusModal from './quote.modal'

import './order.style.css';
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer } from '../../store/actions/configuration';
import Swal from 'sweetalert2';
import { getOrderGroup, createOrderGroup, createOrder, updateOrderGroup, } from '../../store/services/order';


const orderGroupTemp = { manufacturerId: "", vendorId: "", name: "", threadColor: "", notes: "", checks: { header: false, header_drilled: false, corner_label: false, fabric_drilled: false, overlock: false, grommet: false, collate: false }, vendorNotes: { header: "", header_drilled: "", corner_label: "", fabric_drilled: "", overlock: "", grommet: "", collate: "", threadColor: "", notes: "" } }
const orderTemp = { manufacturerId: "", requireLevel: "none", vendorId: "", groupId: "", po_number: "", job_number: "", notes: '', vendorNotes: '' }

const CreateOrder = (props) => {
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.auth.companyId);
  const token = useSelector(state => state.auth.token);
  const customVendors = useSelector(state => state.configuration.customers);
  // const orderGroups = [];
  const [orderGroups, setOrderGroup] = useState([]);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState('');
  const [stateConfigs, setStateConfigs] = useState({ header: false, header_drilled: false, corner_label: false, fabric_drilled: false, overlock: false, grommet: false, collate: false });
  const [state, setState] = useState({ configs: { header: false, header_drilled: false, corner_label: false, fabric_drilled: false, overlock: false, grommet: false, collate: false } })

  useEffect(() => {
    dispatch(getCustomer('manufacturer'))
    getGroups()
  }, [])


  const getGroups = async () => {
    let groups = await getOrderGroup(token, { where: { manufacturerId: companyId } })
    if (Array.isArray(groups) && groups.length) setOrderGroup(groups)
  }

  const handleChange = ({ target }) => {
    let newState = { ...state }
    newState[target.name] = target.value;
    setState(newState);
  }

  const handleChangeGroup = ({ target }) => {
    if (target.value === 'addNew') { setStateConfigs(orderGroupTemp.checks); return setModal(true) }
    let group = orderGroups.filter(group => group.id === target.value)[0];
    console.log("GRUP: ", group)
    let newState = { ...state }
    newState.groupId = target.value;
    newState.configs = group.checks;
    newState.notes = group.notes;
    newState.vendorId = group.vendorId;
    newState.threadColor = group.threadColor;
    newState.job_number = group.job_number;
    setStateConfigs(group.checks)
    setState(newState);
  }

  const handleCheck = ({ target }) => {
    let newState = { ...state }
    let checks = { ...newState.configs }
    checks[target.name] = target.checked
    newState.configs = checks;
    setStateConfigs(checks)
    setState(newState)
  }

  const handleAddGroup = async (e) => {
    e.preventDefault();
    let group = { ...orderGroupTemp, job_number: 0, manufacturerId: companyId, vendorId: state.vendorId, name, checks: state.configs, threadColor: state.threadColor ? state.threadColor : '', notes: state.notes ? state.notes : '' };
    if (!name) return Swal.fire({ icon: 'error', title: 'Name is required', timer: 1500 })
    let query = { where: { name: name, manufacturerId: companyId } }
    const filter = await getOrderGroup(token, query)
    if ((Array.isArray(filter) && filter.length > 0) || (typeof filter === 'object' && filter.id)) return Swal.fire({ icon: 'error', title: 'Duplicate Name', timer: 1500 })
    let response = await createOrderGroup(group);
    if (response.id) {
      getGroups();
      Swal.fire({ icon: 'success', title: 'Successfully Updated', timer: 1500 })
      return setModal(false);
    }
    console.log("group: ", group)
  }

  const saveOrder = (order) => {
    let group = orderGroups.filter(group => group.id === order.groupId)[0];
    group.checks = stateConfigs;
    group.notes = state.notes;
    group.vendorNotes = state.vendorNotes;
    group.vendorId = state.vendorId;
    group.threadColor = state.threadColor;
    group.job_number = group.job_number + 1;
    order.group = group;
    createOrder(order, token)
      .then(response => {
        if (typeof response === 'string') {
          return Swal.fire({ icon: 'error', title: 'Something went wrong!', timer: 1500 })
        }
        updateOrderGroup(order.groupId, group, token)
        props.history.push('/orders/' + response.id)
      })
  }

  const { header_drilled, corner_label, header, fabric_drilled, overlock, grommet, collate } = stateConfigs;
  return (
    <div className="container CreateOderContainer" style={{ textAlign: 'center' }}>
      <div className="in_hdr">
        <div className="row">
          <div className="col-auto mr-auto"> Swatch Group Information </div>
        </div>
      </div>

      {/* SwatchGroup */}
      <div className="grp_frm">
        <form className="col-12" onSubmit={() => { }}>
          <div className="form-row">

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Vendor:</label>
                <div className="col-6">
                  <select className="browser-default custom-select" name="vendorId" value={state.vendorId} onChange={handleChange}>
                    <option key={-1 + "vendor"} value={""}>
                      Chose a vendor vendor first
                    </option>
                    {Array.isArray(customVendors) && customVendors.map(item => {
                      return (
                        <option key={item.id} value={item.vendorId}>
                          {item.companyName || item.name}
                        </option>
                      )
                    })
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <label className="col-6">Select a Swatch Type:</label>
                <div className="col-6">
                  {Array.isArray(orderGroups) && orderGroups.length > 0 ?
                    <select className="browser-default custom-select" name="orderId" onChange={handleChangeGroup}>
                      <option key={"item.id"} value="addNew">
                        [Create New Group]
                      </option>
                      {orderGroups.map(item => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })
                      }
                    </select>
                    :
                    <button className="btn_grn browser-default custom-select" onClick={(e) => { e.preventDefault(); setModal(true) }}>Create new Group</button>
                  }
                </div>
              </div>
            </div>


            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Header:</label>
                <div className="col-2">
                  <input
                    type="checkbox"
                    checked={header}
                    name="header"
                    onChange={handleCheck}
                    className="form-control"
                  />
                </div>

                {false && <div className="col-6">
                  <input
                    type="text"
                    name="header"
                    className="form-control"
                  />
                </div>}

              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Header Drilled:</label>
                <div className="col-2">
                  <input
                    type="checkbox"
                    checked={header_drilled}
                    name="header_drilled"
                    onChange={handleCheck}
                    className="form-control"
                  />
                </div>
                {false && <div className="col-6">
                  <input
                    type="text"
                    name="header_drilled"
                    className="form-control"
                  />
                </div>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Corner Label:</label>
                <div className="col-2">
                  <input
                    type="checkbox"
                    checked={corner_label}
                    name="corner_label"
                    onChange={handleCheck}
                    className="form-control"
                  />
                </div>
                {false && <div className="col-6">
                  <input
                    type="text"
                    name="corner_label"
                    className="form-control"
                  />
                </div>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Fabric Drilled:</label>
                <div className="col-2">
                  <input
                    type="checkbox"
                    checked={fabric_drilled}
                    name="fabric_drilled"
                    onChange={handleCheck}
                    className="form-control"
                  />
                </div>
                {false && <div className="col-6">
                  <input
                    type="text"
                    name="fabric_drilled"
                    className="form-control"
                  />
                </div>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Overlock:</label>
                <div className="col-2">
                  <input
                    type="checkbox"
                    checked={overlock}
                    name="overlock"
                    onChange={handleCheck}
                    className="form-control"
                  />
                </div>
                {false && <div className="col-6">
                  <input
                    type="text"
                    name="overlock"
                    className="form-control"
                  />
                </div>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Grommet:</label>
                <div className="col-2">
                  <input
                    type="checkbox"
                    checked={grommet}
                    name="grommet"
                    onChange={handleCheck}
                    className="form-control"
                  />
                </div>
                {false && <div className="col-6">
                  <input
                    type="text"
                    name="grommet"
                    className="form-control"
                  />
                </div>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Collate:</label>
                <div className="col-2">
                  <input
                    type="checkbox"
                    checked={collate}
                    name="collate"
                    onChange={handleCheck}
                    className="form-control"
                  />
                </div>
                {false && <div className="col-6">
                  <input
                    type="text"
                    name="collate"
                    className="form-control"
                  />
                </div>}
              </div>
            </div>


            <div className="col-sm-7">
              <div className="row">
                <label className="col-4">Thread Color:</label>
                <div className="col-6">
                  <input
                    type="text"
                    name="threadColor"
                    value={state.threadColor || ""}
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            {false &&
              <div className="col-sm-5">
                <div className="row">
                  <div className="col-8">
                    <input
                      type="text"
                      name="threadColor"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            }

            <div className="col-sm-6">
              <div className="row">
                <label className="col-4">Order notes:</label>
                <div className="col-8">
                  <textarea
                    type="text"
                    name="notes"
                    rows="6"
                    value={state.notes}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            {false &&
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-8">
                    <textarea
                      type="text"
                      name="notes"
                      rows="6"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            }
          </div>

        </form>
        <Modal
          size="sm"
          show={modal}
          onHide={() => setModal(false)}
          centered
        >
          <Modal.Header>Enter New Group Name</Modal.Header>
          <Modal.Body>
            <input
              name="name"
              className="form-control"
              value={name}
              onChange={({ target }) => { setName(target.value) }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn_grn" onClick={handleAddGroup}>OK</button>
            <button className="btn_red" onClick={() => setModal(false)}>Cancel</button>
          </Modal.Footer>
        </Modal>
      </div>

      <hr />

      <div className="in_hdr">
        <div className="row">
          <div className="col-auto mr-auto">Order Details  </div>
        </div>
      </div>

      <OrderDetails {...state} handleSave={saveOrder} />

    </div>
  )
}

export default CreateOrder;

const OrderDetails = (props) => {
  const companyId = useSelector(state => state.auth.companyId);
  const userId = useSelector(state => state.auth.userId);
  const [quoteModal, setQuoteModal] = useState(false);

  const [order, setOrder] = useState(orderTemp);

  const handleChange = ({ target }) => {
    let newOrder = { ...order }
    newOrder[target.name] = target.value
    setOrder(newOrder)
  }


  const handleSave = (e) => {
    e.preventDefault();
    if (!props.vendorId) return Swal.fire({ icon: 'warning', title: 'Select a vendor first', timer: 1500 })
    if (!props.groupId) return Swal.fire({ icon: 'warning', title: 'Select an order group first', timer: 1500 })
    if (!order.po_number) return Swal.fire({ icon: 'warning', title: 'PO number is required', timer: 1500 })

    let newOrder = { ...order }
    newOrder.groupId = props.groupId;
    newOrder.vendorId = props.vendorId;
    newOrder.manufacturerId = companyId;
    newOrder.job_number = props.job_number;
    newOrder.userId = userId;
    newOrder.status = "draft";
    setOrder(newOrder)
    props.handleSave(newOrder)
  }

  return (
    <div className="grp_frm">
      <form className="col-12" onSubmit={() => { }}>
        <div className="form-row">

          <div className="col-sm-4">
            <div className="row">
              <label className="col-4">Order Date:</label>
              <div className="col-8">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  readOnly
                  value={(new Date()).toLocaleDateString()}
                />
              </div>
            </div>
          </div>


          <div className="col-sm-4">
            <div className="row">
              <label className="col-4">PO Number:</label>
              <div className="col-8">
                <input
                  type="text"
                  name="po_number"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="row">
              <label className="col-4">Job Number:</label>
              <div className="col-8">
                <input
                  disabled
                  type="text"
                  name="job_number"
                  value={props.job_number || 0}
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="row">
              <label className="col-4">Scan Level:</label>
              <div className="col-8">
                <select className="browser-default custom-select" value={order.requireLevel} name="requireLevel" onChange={handleChange} >
                  <option key={"none"} value={"none"}>None</option>
                  <option key={"single"} value={"single"}>Single</option>
                  <option key={"full"} value={"full"}>Full</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="row">
              <label className="col-4">Order notes:</label>
              <div className="col-8">
                <textarea
                  type="text"
                  name="notes"
                  onChange={handleChange}
                  rows="6"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          {false && <div className="col-sm-4">
            <div className="row">
              <label className="col-4">Vendor notes:</label>
              <div className="col-8">
                <textarea
                  type="text"
                  name="vendorNotes"
                  rows="6"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>}

        </div>


      </form>
      <hr />
      {/* BUTTONS */}
      <div className="btn_arte" style={{ display: 'block' }}>
        <div className="row">

          {false &&
            <>
              <div className="col-auto mr-auto">
                <button className="btn_grn" disabled={false} onClick={() => setQuoteModal(true)} >
                  View Quote Status
                </button>
              </div>
              <div className="col-auto mr-auto">
                <button className="btn_grn" disabled={false}>
                  Submit Payment
                </button>
              </div>
            </>
          }

          <div className="col-auto mr-auto">
            <button className="btn_grn" onClick={handleSave} >
              Import/Copy Fabric
            </button>
          </div>
          <div className="col-auto mr-auto">
            <button className="btn_grn" onClick={handleSave} >
              Add Fabric Manually
            </button>
          </div>

          <div className="col-auto mr-auto">
            <button className="btn_grn" onClick={handleSave} disabled={false}>
              Save as draft
            </button>
          </div>
          <div className="col-auto mr-auto">
            <button className="btn_red" disabled={false}>
              Cancel
            </button>
          </div>

        </div>
      </div>
    </div>
  )
}
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../img/logo.png';

const PrivacyPolicy = () => {
    return (
        <div>
            <Nav />
            <section id="privacy-policy" class="privacy">
                <div class="container">
                    <h2>PRIVACY NOTICE</h2>
                    <p>Last updated July 23, 2020</p>
                    <p>Thank you for choosing to be part of our community at Milestone Computer Solutions, LLC. (“<b>Company</b>”, “<b>we</b>”, “<b>us</b>”, or “<b>our</b>”).
                        We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about
                        this privacy notice, or our practices with regards to your personal information, please contact us at <a href="mailto:sales@swatchmasters.com">sales@swatchmasters.com</a>.
                    </p>
                    <p>When you use our mobile application, as the case may be (the "<b>App</b>") and more generally, use any of our services (the "<b>Services</b>", which
                        include the App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this
                        privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you
                        have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this
                        privacy notice that you do not agree with, please discontinue use of our Services immediately.
                    </p>
                    <p>
                        This privacy notice applies to all information collected through our Services (which, as described above, includes our App), as well as
                        any related services, sales, marketing, or events.
                    </p>
                    <p class="font-weight-bold">Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.
                    </p>
                    <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
                    <h4>Information automatically collected</h4>
                    <p><b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when
                        you visit our App.</p>
                    <p>We automatically collect certain information when you visit, use, or navigate the App. This information does not reveal your specific identity (like
                        your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating
                        system, language preferences, referring URLs, device name, country, location, information about who and when you use our App and other technical
                        information. This information is primarily needed to maintain the security and operation of our App, and for our internal analytics and reporting purposes.</p>
                    <h4>The information we collect includes:</h4>
                    <ul>
                        <li>Log and Usage Data. Log and usage data is service-related, diagnostic usage and performance information our servers automatically collect when you
                            access or use our App and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device
                            information, browser type and settings and information about your activity in the App (such as the date/time stamps associated with your usage,
                            pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity,
                            error reports (sometimes called 'crash dumps') and hardware settings).</li>
                        <li>Device Data. We collect device data such as information about your computer, phone, tablet or other device you use to access the App. Depending
                            on the device used, this device data may include information such as your IP address (or proxy server), device application identification
                            numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system configuration information.
                        </li>
                        <li>
                            Location Data. We collect information data such as information about your device's location, which can be either precise or imprecise.
                            How much information we collect depends on the type of settings of the device you use to access the App. For example, we may use GPS and other
                            technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to
                            collect this information either by refusing access to the information or by disabling your Locations settings on your device. Note however,
                            if you choose to opt out, you may not be able to use certain aspects of the Services.
                        </li>
                    </ul>
                    <h4>Information collected through our App</h4>
                    <p><b>In Short:</b> We collect information regarding your geo-location, mobile device, push notifications, when you use our App.</p>
                    <p>If you use our App, we also collect the following information:</p>
                    <ul>
                        <li>Geo-Location Information. We may request access or permission to and track location-based information from your mobile device, either continuously
                            or while you are using our App, to provide certain location-based services. If you wish to change our access or permissions, you may do so in your
                            device's settings.</li>
                        <li>Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera,
                            sensors, sms messages, storage, reminders, contacts, and other features. If you wish to change our access or permissions, you may do so in your
                            device's settings.</li>
                        <li>Push Notifications. We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out
                            from receiving these types of communications, you may turn them off in your device's settings.</li>
                    </ul>
                    <p>The information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.</p>
                    <h3>2. HOW DO WE USE YOUR INFORMATION?</h3>
                    <p>In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>
                    <p>We use personal information collected via our App for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business
                        interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on
                        next to each purpose listed below.</p>
                    <p>We use the information we collect or receive:</p>
                    <ul>
                        <li><b>To facilitate account creation and logon process.</b> If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information
                            you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.</li>
                        <li><b>To post testimonials.</b> We post testimonials on our App that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the consent of
                            the testimonial. If you wish to update, or delete your testimonial, please contact us at <a href="mailto:sales@swatchmasters.com">sales@swatchmasters.com</a> and be sure to include your name, testimonial location, and contact information.
                        </li>
                        <li><b>Request feedback.</b> We may use your information to request feedback and to contact you about your use of our App.</li>
                        <li><b>To enable user-to-user communications.</b> We may use your information in order to enable user-to-user communications with each user's consent.</li>
                    </ul>
                    <h3>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
                    <p><b>In Short:</b> We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
                    <p>We may process or share your data that we hold based on the following legal basis:</p>
                    <ul>
                        <li><b>Consent:</b> We may process your data if you have given us specific consent to use your personal information in a specific purpose.</li>
                        <li><b>Legitimate Interests:</b> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
                        <li><b>Performance of a Contract:</b> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
                        <li><b>Legal Obligations:</b> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal
                            process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
                        <li><b>Vital Interests:</b> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving
                            potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
                    </ul>
                    <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>
                    <ul>
                        <li><b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company</li>
                    </ul>
                    <h3>4. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h3>
                    <p><b>In Short:</b> We may transfer, store, and process your information in countries other than your own.</p>
                    <p>Our servers are located in. If you are accessing our App from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal
                        information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in and other countries.</p>
                    <p>If you are a resident in the European Economic Area, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will however take all necessary measures to protect
                        your personal information in accordance with this privacy notice and applicable law.</p>
                    <h3>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
                    <p><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
                    <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us to keep your personal information for longer than the period of time in which users have an account with us.</p>
                    <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
                    <h3>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
                    <p><b>In Short:</b> We aim to protect your personal information through a system of organizational and technical security measures.</p>
                    <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cyber criminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our App is at your own risk. You should only access the App within a secure environment.</p>
                    <h3>7. DO WE COLLECT INFORMATION FROM MINORS?</h3>
                    <p><b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.</p>
                    <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the App, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the App. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:sales@swatchmasters.com">sales@swatchmasters.com</a>.</p>
                    <h3>8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
                    <p><b>In Short:</b> You may review, change, or terminate your account at any time.</p>
                    <p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html" target="_blank">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html</a>.</p>
                    <p>If you are resident in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</p>
                    <p>If you have questions or comments about your privacy rights, you may email us at <a href="mailto:sales@swatchmasters.com">sales@swatchmasters.com</a>.</p>
                    <h4>Account Information</h4>
                    <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                    <ul>
                        <li>Contact your employer in order to change/remove your email account if necessary.</li>
                    </ul>
                    <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.</p>
                    <p>Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:</p>
                    <ul>
                        <li>Contact your employer in order to change account settings or preferences.</li>
                    </ul>
                    <h3>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
                    <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
                    <h3>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
                    <p><b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</p>
                    <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
                    <p>If you are under 18 years of age, reside in California, and have a registered account with the App, you have the right to request removal of unwanted data that you publicly post
                        on the App. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the App, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</p>
                    <h3>11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
                    <p><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
                    <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
                    <h3>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
                    <p>If you have questions or comments about this notice, you may email us at <a href="mailto:sales@swatchmasters.com">sales@swatchmasters.com</a> or by post to:</p>
                    <p>Milestone Computer Solutions, LLC. <br/>
                        535 South Cox Street <br/>
                        Suite A <br/>
                        Asheboro, NC 27203 <br/>
                        United States</p>
                    <p><b>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</b></p>
                    <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please email us at <a href="mailto:sales@swatchmasters.com">sales@swatchmasters.com</a>. We will respond to your request within 30 days.</p>
                </div>
            </section>
        </div>
    )
}

export default PrivacyPolicy;

const Nav = (props) => {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top hdr_bg_col" id="mainNav">
        <div className="row" style={{ alignItems: "center", width: "100%" }}>
          <div className="col-6 col-sm-6">
            <Link className="navbar-brand js-scroll-trigger" to="/">
              <img src={Logo} style={{ maxWidth: "414px", width: "50%" }} alt="" />
            </Link>
          </div>
          <div className="col-sm-6 toggle-view-div" style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
              aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse col-6" id="navbarResponsive">
            <ul className="navbar-nav ml-auto ">
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="/">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="/sign-up/">Sign Up</a>
              </li>
              <li className="nav-item">
                <Link className="nav-link js-scroll-trigger" to="/login">Login</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
import axios from "axios";
import { ApiUrl } from "../../constants";
const subsUrl = ApiUrl + "/subscriptionHistories/";
const planUrl = ApiUrl + "/Plans/";
const couponUrl = ApiUrl + "/Coupons/";
const usrUrl = ApiUrl + "/users/";
const compUrl = ApiUrl + "/Companies/";

export const getNonSAASCoupons = (token) => {
  try {
    const filter = { where: { couponType: { $ne: "saasOnly" } } };
    return axios
      .get(couponUrl, { params: { filter }, headers: { Authorization: token } })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message;
  }
};
export const filterUser = (email, token = undefined, username = undefined) => {
  try {
    const filter = username
      ? { where: { or: [{ email }, { username }] } }
      : { where: { email } };
    return axios
      .get(usrUrl, { params: { filter }, headers: { Authorization: token } })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message;
  }
};
export const verifyRefCode = (referralCode, token = undefined) => {
  try {
    const filter = { where: { referralCode } };
    return axios
      .get(compUrl, { params: { filter }, headers: { Authorization: token } })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message;
  }
};

export const registerOwner = (owner) => {
  try {
    return axios
      .post(usrUrl, owner)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message;
  }
};
export const registerCompany = (company) => {
  try {
    return axios
      .post(compUrl, company)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message;
  }
};
export const addSubscription = (
  subscription,
  token = "signUp!_Process_r=S"
) => {
  try {
    return axios
      .post(subsUrl, subscription, { headers: { Authorization: token } })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message;
  }
};
export const updateCardInfo = (cid, subscription) => {
  try {
    return axios
      .post(compUrl + cid + "/updateCardInfo", subscription)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message;
  }
};
export const registerCardInfo = (cid, subscription) => {
  try {
    return axios
      .post(compUrl + cid + "/registerCardInfo", subscription)
      .then((response) => {
        if (response.data) {
          return response.data.data;
        }
        return false;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message;
  }
};

export const sendUserCredentials = (user, token = "signUp!_Process_r=S") => {
  try {
    return axios
      .post(
        compUrl + "emailCredentials",
        { user },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message;
  }
};

export const deleteCoupon = async (cid, token = "signUp!_Process_r=S") => {
  try {
    axios
      .get(couponUrl, { params: { filter: { where: { companyId: cid } } } })
      .then((coupons) => {
        if (coupons.data.length < 1) return;
        if (coupons.data.length > 0)
          return axios
            .delete(couponUrl + coupons.data[0].id, {
              headers: { Authorization: token },
            })
            .then((response) => {
              console.log("COUPON DELETE");
              if (response.data) {
                return response.data;
              }
              return false;
            })
            .catch((error) => {
              console.error(error);
              throw error;
            });
      });
  } catch (error) {
    console.log("Err in coupon delete: ", error.message)
    return error.message;
  }
};

export const deleteSubscription = async (
  cid,
  token = "signUp!_Process_r=S"
) => {
  try {
    let subscriptions = await axios.get(subsUrl, {
      params: { filter: { where: { companyId: cid } } },
    });
    if (Array.isArray(subscriptions.data)) {
      subscriptions.data.map((sub) => {
        axios.delete(subsUrl + sub.id, { headers: { Authorization: token } });
      });
    }
    return;
  } catch (error) {
    return error.message;
  }
};
export const deleteOwner = async (ownerId, token = "signUp!_Process_r=S") => {
  try {
    return axios
      .delete(usrUrl + ownerId + "/deleteUser", {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  } catch (error) {
    return error.message;
  }
};

export const deleteCompany = async (ID, token = "signUp!_Process_r=S") => {
  try {
    return axios
      .delete(compUrl + ID, { headers: { Authorization: token } })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  } catch (error) {
    return error.message;
  }
};

export const signUp = (payload) => {
  try {
    return axios.post(compUrl + "signUp", payload, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        console.log("RESPONSE:: ", response)
        if (response.data && Boolean(response.data.data.isSuccess) === Boolean(true)) {
          return true
        }
        return response.data.error
      })
  } catch (error) {
    console.log("err in sign up : ", error.message)
    return error.message
  }
}

import axios from 'axios';
import { ApiUrl } from '../../constants';
const Url = ApiUrl + '/subscriptionHistories/';
const planUrl = ApiUrl + '/Plans/';
const companyUrl = ApiUrl + '/Companies/';

export const addSubscription = (payload, token) => {
  try {
    return axios.post(Url, payload, { headers: { 'Authorization': token } })
      .then((response) => {
        if (response.data) {
          return response.data
        }
        return undefined
      })
      .catch((error) => {
        console.log(error)
        throw error;
      })
  } catch (error) {
    return error.message;
  }
}
export const getAllSubscriptions = (companyId, token) => {
  try {
    const filter = { "where": { "companyId": companyId } };
    return axios.get(Url, { params: { filter: filter }, headers: { 'Authorization': token } })
      .then((response) => {
        if (response.data) {
          return response.data
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
        throw error;
      })
  } catch (error) {
    return error.message
  }
}
export const updateSubscription = (id, payload, token) => {
  try {
    return axios.patch(Url + id, payload, { headers: { 'Authorization': token } })
      .then((response) => {
        if (response.data) {
          return response.data
        }
        return []
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message
  }
}
export const deleteSubscription = (id, token) => {
  try {
    return axios.delete(Url + id, { headers: { 'Authorization': token } })
      .then((response) => {
        if (response.data) {
          return response.data
        }
        return []
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    return error.message
  }
}
export const getAllPlans = (token) => {
  try {
    return axios.get(planUrl, { headers: { 'Authorization': token } })
      .then((response) => {
        if (response.data) {
          return response.data
        }
        return [];
      })
      .catch((error) => {
        console.error(error);
        throw error;
      })
  } catch (error) {
    return error.message
  }
}
export const getBill = (cid, token) => {
  try {
    return axios.get(companyUrl + cid + "/getBill", { headers: { 'Authorization': token } })
      .then((response) => {
        if (response.data) {
          return response.data
        }
        throw { message: "Not found" }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      })
  } catch (error) {
    return error.message
  }
}
export const updateRenewal = (id, payload, token) => {
  try {
    return axios.patch(companyUrl + id + '/subscriptionRenewal', payload, { headers: { 'Authorization': token } })
      .then((response) => {
        if (response.data) {
          return response.data
        }
        throw { message: "Not found" }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      })
  } catch (error) {
    return error.message
  }
}
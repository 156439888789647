import React, { Component } from 'react';
import axios from 'axios';
import closeIcon from "../../img/closeIcon.png";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import PlanRow from './planPopupRow'
import Swal from 'sweetalert2';
import { ApiUrl } from '../../constants';
import { getCoupons } from '../../store/actions/signUp';
class UpgradeSubscriptionPopup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			planList: null,
			loading: false,
			error: false,
			settingPlan: false
		}
	}

	componentDidMount() {

		this.props.getCoupons()

		this.refresh()
			.then(() => {
				this.setState({
					loading: false,
					error: false
				})
			})
			.catch(() => {
				this.setState({
					loading: false,
					error: true
				})
			})

	}

	refreshCompanyDetails = () => {
		this.props.refreshComp()
			.then(() => {
				console.log("Yes")
			})
			.catch(() => {
				console.log("No")
			})
	}

	refresh = () => {
		const token = this.props.token;
		const apiUrlPlan = ApiUrl + '/Plans'
		return new Promise((resolve, reject) => {
			axios.get(apiUrlPlan,
				{
					headers: {
						'Authorization': token
					}
				})
				.then(response => {
					this.setState({
						planList: response.data.filter(item => item.type === "user")
					}, () => resolve(true))
				})
				.catch(err => {
					reject(false)
				})
		})
	}

	togglePlan = () => {
		this.setState((prev) =>
		({
			settingPlan: !prev.settingPlan
		}))
	}


	render() {
		return (
			<Modal
				size={'lg'}
				show={this.props.displayStatus}
				centered>
				<Modal.Header>

					<h3>
						Upgrade Subscription
					</h3>
					<button
						type="button"
						className="close_icon2 btn-link btn"
						onClick={this.props.closePopup}
						style={{ float: "right" }}
						disabled={this.state.settingPlan}
					>
						<img alt="" src={closeIcon} />
					</button>
				</Modal.Header>
				<Modal.Body>

					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="add_comTable">
									<div className="table-responsive">
										<table className="table table-striped">
											<thead style={{ borderBottom: 1 }}>
												<tr>
													<th scope="col">Subscription Name</th>
													{/* <th scope="col">No of Inventory Groups</th> */}
													<th scope="col">Users</th>
													<th scope="col">Price Per User</th>
													<th scope="col">Actions</th>
												</tr>
											</thead>
											{/*Rows of pricing data*/}
											<tbody>
												{
													(this.state.loading || this.state.planList === null) ?
														null :
														(
															this.state.planList.map(item =>
																<PlanRow
																	key={item.id}
																	item={item}
																	selected={this.props.subscriptions.filter(sub => sub.planId === item.id).length}
																	refreshPlan={this.props.refreshComp}
																	settingPlan={this.state.settingPlan}
																	togglePlan={this.togglePlan}
																	numberOfUsers={this.props.numberOfUsers}
																/>
															)
														)
												}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		)
	}
}
const mapStateToProps = state => {
	return {
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		subscriptions: state.subscription.subscriptions,
		userId: state.auth.userId,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user,
		company: state.auth.company
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getCoupons: () => dispatch(getCoupons())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeSubscriptionPopup);

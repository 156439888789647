import React, { Component } from 'react';
import loginLogo from './../../img/logo.png';
import { Alert } from 'react-bootstrap'
import axios from 'axios'
import { connect } from "react-redux";
import { addCompanyId, addUserId, addURL, addToken, addCompany, addUser, addPermissions, addUserEmail } from "../../store/actions/actions";
import { Link } from "react-router-dom";
import Spinner from "../../components/loaders/spinner";
import Swal from "sweetalert2";
import { ApiUrl, CalculateDays, CalculateMonth } from '../../constants';

class Login extends Component {
	constructor() {
		super()
		this.state = {
			email: "",
			password: "",
			wrongCreds: false,
			noCompany: false,
			suspendedServices: false,
			loading: false
		}
	}

	componentDidMount() {
		//Set the URL to local storage
		this.props.addURL();
		if (this.props.toggleState) {
			this.props.toggler()
		}
	}

	handleChange = (event) => {
		event.persist();
		const { name, value } = event.target
		this.setState({
			[name]: value,
		});
	}

	deactivatedServices = () => {
		this.setState({
			suspendedServices: true,
			loading: false
		})
		setTimeout(() => { this.setState({ suspendedServices: false }) }, 2000)
	}

	handleSubmit = (event) => {

		//to prevent default state parameters to be passed
		event.preventDefault()
		// this.setState({ loading: true })
		//set parameters to the state
		const params = {
			email: this.state.email.toLowerCase(),
			password: this.state.password,
			source: "web"
		};

		//Filter query for getting the company of the
		const filter = {
			include: ['company']
		};

		let tok = null;
		let uid = null

		const apiUrlLogin = ApiUrl + '/users/login';
		//Axios post request to post userCredentials
		axios.post(apiUrlLogin, params,
			{
				//withCredentials: true,
				headers: {
					'content-type': 'application/json',
				}
			}
		)
			.then(response => {
				tok = response.data.id;
				uid = response.data.userId
				const apiUrlUser = ApiUrl + '/users/' + response.data.userId + '?filter=' + JSON.stringify(filter);
				//get request to get company id and information
				axios.get(apiUrlUser,
					{
						headers: {
							'Authorization': response.data.id,
						}
					})
					.then(response => {
						//saved the company id to the browser storage
						//localStorage.setItem('company_id', response.data.company.id)
						const { company, companyId, user_permissions } = response.data;
						if (companyId) {
							let isPermited = false;
							let permissions = {}
							if (!user_permissions) { Swal.fire({ icon: "error", text: "You are restricted to login!" }); throw ("Not Authorised") }
							user_permissions.map(permission => {
								if (permission.title === "Admin App Access" && permission.checked) isPermited = true;
								permissions[permission.title] = permission;
							});
							if (!isPermited) {
								Swal.fire({ icon: "error", text: "You are restricted to login!" })
								throw ("Not Authorised");
							}
							axios.get(ApiUrl + '/Companies/' + companyId, { headers: { 'Authorization': tok } })
								.then((companyData) => {
									this.props.addUser(response.data);
									this.props.addPermissions(permissions)
									this.doLogin({ data: { company: companyData.data } }, tok, uid);
								})
								.catch((err) => {
									console.log("SOMETHING Went wrong !", err);
									Swal.fire({ icon: "warning", text: 'Something went wrong while processing!' });
									throw (err)
								})
							return;
						}

						// const company = response.data.company ? response.data.company : null;
						if (company) {
							if (company.remainingDays == 0) {
								this.paymentExpireNotice();
								this.deactivatedServices();
								return;
							} else {
								this.proceedTowardsLogin(response, tok, uid);
							}
						} else {
							this.doLogin(response, tok, uid);
						}


					})
					.catch(error => {
						console.log("ERR +> ", error.message)
						this.setState({
							noCompany: true,
							loading: false
						})
					})
			})
			.catch(error => {
				//sets the state of component to wrong credentials
				this.setState({
					wrongCreds: true,
					loading: false
				}, () => {
					setTimeout(() => { this.setState({ wrongCreds: false }) }, 4000)
				})
			})
	}

	paymentExpireNotice = () => {
		Swal.fire({
			title: 'LOGIN BLOCKED',
			text: 'Because of pending payment your account is blocked. Please contact support@swatchmasters.com for more details.',
			icon: 'warning',
			confirmButtonText: 'Okay'
		});
	}

	proceedTowardsLogin = async (response, tok, uid) => {
		const { company } = response.data;
		let month = await CalculateMonth(new Date(), new Date(company.subscribeOn ? company.subscribeOn : company.createdAt))
		let days = await CalculateDays(new Date(), new Date(company.subscribeOn ? company.subscribeOn : company.createdAt))
		// if((365 - days) < 0) return Swal.fire({icon: 'info', title: ''})
		if (month >= 12 && (365 - days) < 25) {
			Swal.fire({
				title: 'Payment Notice',
				text: `Your remaining days for renewal of subscription are ${(365 - days)}. Please renew it as soon as possible to avoid any blocking of services`,
				icon: 'info',
				confirmButtonText: 'Okay'
			}).then((result) => {
				this.doLogin(response, tok, uid);
			});
		} else {
			this.doLogin(response, tok, uid);
		}
	}

	doLogin = (response, tok, uid) => {
		const cid = response.data.company ? response.data.company.id : response.data.companyId;
		try {
			this.props.addUserId(uid);
			this.props.addUserEmail(this.state.email.toLowerCase());
			this.props.addCompanyId(cid);
			this.props.addToken(tok);
			this.props.addCompany(response.data.company);
			if (response.data.company.type === 'vendor') {
				this.props.history.push('/vendor')
			} else {
				this.props.history.push("/")
			}
			this.setState({ loading: false })
		}
		catch (e) {
			console.log(e)
			this.setState({ loading: false })
		}
	}

	render() {
		return (
			<div>
				<div className="container">
					{
						/*Checks if state is in wrong credentials, renders error if true*/
						this.state.wrongCreds ?
							<Alert variant="danger" style={{ textAlign: 'center' }}>
								Invalid email and password combination
							</Alert> :
							null
					}
					{
						/*Checks if state is in no company, renders error if true*/
						this.state.noCompany ?
							<Alert variant="danger" style={{ textAlign: 'center' }}>
								You are not the admin of a company
							</Alert> : null
					}
					{
						/*Checks if state is in wrong credentials, renders error if true*/
						this.state.suspendedServices ?
							<Alert variant="danger" style={{ textAlign: 'center' }}>
								Your services have been suspended temporarily.
								Please contact the administration to resume the services.
							</Alert> :
							null
					}
					<div className="row">
						<div className="col-md-6 offset-md-3">
							<div className="login_box">
								<div className="logo_bx">
									<img src={loginLogo} style={{ maxWidth: "414px" }} alt="" />
								</div>
								<div className="frmBx">
									<form onSubmit={this.handleSubmit}>
										<div className="input-group form-group fild">
											<div className="input-group-prepend">
												<span className="input-group-text">USER EMAIL</span>
											</div>
											<input
												type="text"
												className="form-control"
												placeholder="Entry your email"
												name="email"
												value={this.state.email}
												onChange={this.handleChange}
											/>
										</div>
										<div className="input-group form-group fild">
											<div className="input-group-prepend">
												<span className="input-group-text">PASSWORD</span>
											</div>
											<input
												type="password"
												className="form-control"
												placeholder="Entry your password"
												name="password"
												value={this.state.password}
												onChange={this.handleChange}
											/>
										</div>
										<div className="form-group">
											<button type="submit" disabled={this.state.loading} className="login_btn">
												{
													(this.state.loading) ?
														<Spinner styleClass="text-light" /> :
														<text>Login</text>
												}
											</button>
										</div>
										<div className="form-group text-center fp">
											<Link to="/forgot-password">Forgot Password</Link>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		apiUrl: state.auth.apiUrl,
		userId: state.auth.userId,
		companyId: state.auth.companyId

	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addUserId: id => dispatch(addUserId(id)),
		addUserEmail: email => dispatch(addUserEmail(email)),
		addCompanyId: id => dispatch(addCompanyId(id)),
		addCompany: company => dispatch(addCompany(company)),
		addUser: user => dispatch(addUser(user)),
		addPermissions: permissions => dispatch(addPermissions(permissions)),
		addURL: () => dispatch(addURL()),
		addToken: (id) => dispatch(addToken(id))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

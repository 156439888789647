import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux";
import downloadImage from './../../../img/download.png';
import printImage from './../../../img/print.png';
import Spinner from './../../../components/loaders/spinner.js';

import { getScanSwatchDetails } from "../../../store/services/inventory";
import { ApiUrl } from '../../../constants';
import Swal from 'sweetalert2';

class ScannedSwatchRow extends Component {
	constructor(props) {
		super(props)
		this.state = {
			user: {
				username: '',
				email: '',
				open: false,
			},
			fetchingData: true,
			creatingPDF: false,
			error: false,
			loading: true,
			emailLoading: true,
			creatingCSV: false,
			missing: 0,
			inactive: 0,
			gradeChange: 0,
			duplicate: 0,
		}
	}
	componentDidMount() {
		this.getUserName();
		try {
			getScanSwatchDetails(this.props.details.id, this.props.token)
				.then(async (response) => {
					if (Array.isArray(response) && response.length > 0) {
						var duplicate = 0;
						var missing = 0;
						var inactive = 0;
						var gradeChange = 0;
						await new Promise((resolve) => {
							response.map((swatch, ind) => {
								if (swatch.scanned) {
									if (swatch.duplicate && Boolean(swatch.duplicate) == true) duplicate += 1;
									if (swatch.active && swatch.grade_notice && swatch.grade_notice.toString().toLowerCase() === "true") gradeChange += 1;
									if (!swatch.active) inactive += 1;
								} else {
									missing += 1;
								}
								if (ind + 1 === response.length) resolve();
							})
						})
						this.setState({ duplicate, missing, inactive, gradeChange, loading: false })
					}
				})
				.catch((err) => {
					this.setState({ loading: false })
				})
			// setTimeout(() => {
			// 	this.state.loading && this.setState({ loading: false })
			// }, 60000)
		} catch (error) {
			this.setState({ loading: false })
			console.log("ERR IN GETTING SwATCH DETAILS", error)
		}
	}
	//sets variable this.state.scannedGroups
	getUserName = () => {
		if (!this.props.details.userId || this.props.details.userId === undefined) return this.setState({ emailLoading: false});
		const url = ApiUrl + "/users/" + this.props.details.userId
		axios
			.get(url,
				{
					headers: {
						'Authorization': this.props.token
					}
				})
			.then(response => {
				this.setState({
					user: response.data,
					fetchingData: false,
					emailLoading: false
				})
			})
			.catch(err => {
				console.log(err);
				this.setState({
					fetchingData: false,
					emailLoading: false
				})
				return null;
			})
	}
	handleDownloadGroup = () => {
		this.setState({
			creatingPDF: true,
		}, () => {
			const url = ApiUrl + "/ScannedGroups/" + this.props.details.id + "/createPDF";
			axios
				.get(url)
				.then(response => {
					this.setState({ creatingPDF: false })
					this.getDownload(response.data.data.container, response.data.data.fileName);
				})
				.catch(err => {
					this.setState({ creatingPDF: false })
					console.log(err);
				})
		})

	}
	getDownload = (container, fileName) => {
		const url2 = ApiUrl + "/containers/" + container + "/download/" + fileName;
		axios(url2, {
			method: "GET",
			responseType: "blob",
			headers: {
				'Authorization': this.props.token
			}
			//Force to receive data in a Blob Format
		})
			.then(response => {
				//Create a Blob from the PDF Stream
				const file = new Blob([response.data], {
					type: "application/pdf"
				});
				//Build a URL from the file
				const fileURL = URL.createObjectURL(file);
				this.setState({
					fileURL: fileURL
				})
				//Open the URL on new Window
				window.open(fileURL);
				this.setState({
					creatingPDF: false,
				})
			})
			.catch(error => {
				console.log(error);
			});
	}

	handleDownloadCSV = () => {
		this.setState({
			creatingCSV: true
		})
		const url = ApiUrl + "/ScannedGroups/" + this.props.details.id + "/createCSV";
		axios
			.get(url)
			.then(response => {
				this.setState({ creatingCSV: false })
				this.getCSV(response.data.data.container, response.data.data.fileName);
			})
			.catch(err => {
				this.setState({ creatingCSV: false })
				Swal.fire({ icon: "error", title: "Something went wrong!", text: "There was a problem while creating csv. please try again later or inform support@swatchmasters.com", timer: 2000 })
				console.log(err);
			})

	}
	downloadCsv = (csvString, filename) => {
		var blob = new Blob([csvString]);
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveBlob(blob, this.props.details.name + ".csv");
		}
		else {
			var a = window.document.createElement("a");

			a.href = window.URL.createObjectURL(blob, {
				type: "text/plain"
			});
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);

		}
		this.setState({
			creatingCSV: false,
		})
	}
	getCSV = (container, fileName) => {
		const url2 = ApiUrl + "/containers/" + container + "/download/" + fileName;
		axios(url2, {
			method: "GET",
			responseType: "blob",
			headers: {
				'Authorization': this.props.token
			}
			//Force to receive data in a Blob Format
		})
			.then(response => {
				this.downloadCsv(response.data, fileName)
			})
			.catch(error => {
				console.log(error);
			});
	}

	handleReview = ({ target }) => {
		var group = { ...this.props.details };
		const url = ApiUrl + "/ScannedGroups/" + group.id
		group.isReviewed = target.checked
		const payload = group
		axios.patch(url, payload, {
			headers: {
				'Authorization': this.props.token
			}
		})
			.then((response) => {
				// console.log("RESPONSE FROM REVIEW UPDATE: ", response)
				this.props.getGroups();
			})
			.catch((err) => {
				console.log("ERR ==> ", err.message)
			})
	}

	render() {
		return (
			<tr>
				{
					this.state.loading ?
						<td colSpan={10}>
							<Spinner styleClass="text-primary" />
						</td>
						:
						<>
							<td>{this.props.details.name}</td>
							<td>
								{
									this.state.emailLoading ?
										<div style={{ width: '100%' }}>
											<Spinner styleClass="text-primary" />
										</div> :
										(this.state.user.email || "N-A")
								}
							</td>
							<td>{this.props.details.createdAt && (new Date(this.props.details.createdAt)).toLocaleDateString()}</td>
							<td>
								<input
									type="checkbox"
									checked={this.props.details.isReviewed}
									onChange={this.handleReview}
								/>
							</td>
							<td>{this.state.gradeChange}</td>
							<td>{this.state.inactive}</td>
							<td>{this.state.missing}</td>
							<td>{this.state.duplicate}</td>
							<td>
								<button className="edit_link btn btn-link" onClick={this.handleDownloadCSV}>
									{
										this.state.creatingCSV ?
											<div style={{ width: '100%' }}>
												<Spinner styleClass="text-primary" />
											</div>
											:
											<img alt="" src={downloadImage} />
									}
								</button>
							</td>
							<td>
								<button className="edit_link btn btn-link" onClick={this.handleDownloadGroup}>
									{
										this.state.creatingPDF ?
											<div style={{ width: '100%' }}>
												<Spinner styleClass="text-primary" />
											</div>
											:
											<img alt="" src={printImage} />
									}
								</button>
							</td>
							<td>
								<span className={this.props.details.status === 'missing' ? "red" : "green"}>{this.props.details.status}</span>
							</td>
						</>
				}
			</tr>
		)
	}
}
const mapStateToProps = state => {
	return {
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		userId: state.userId,
		token: state.auth.token
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getScanDetails: (id, token) => dispatch(getScanSwatchDetails(id, token))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ScannedSwatchRow)

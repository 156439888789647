import React, { Component } from 'react'
import { connect } from "react-redux";
import axios from 'axios'
import BallBeatLoader from "../../components/loaders/spinner";
import DangerAlert from "../../components/alerts/dangerAlert";
import Swal from "sweetalert2";

import { addSubscription, getSubscription, deleteSubscription, setBill } from '../../store/actions/subscription';
import { addSubscription as addSubscriptionService, getBill } from '../../store/services/subscription';
import { ApiUrl, CalculateMonth } from '../../constants';
/*Importing Icons*/

class planPopupRow extends Component {
	constructor(props) {
		super(props)
		this.state = {
			background: null,
			loading: false,
			patching: false,
			error: true,
			limitError: false,
			packQty: 1,
		}
		this.upgradeSubscription = this.upgradeSubscription.bind(this)
		this.handleDrop = this.handleDrop.bind(this)
	}

	componentDidMount() {
		if (this.props.selected) {
			this.setState({
				background: "bg-info"
			})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.selected !== prevProps.selected) {
			this.props.selected ?
				this.setState({ background: "bg-info" }) :
				this.setState({ background: "" })

		}
	}

	showLimitError = () => {
		this.setState({ limitError: true })
		setTimeout(() => {
			this.setState({
				limitError: false,
				patching: false

			})
			this.props.togglePlan()
		}, 3000)
	}

	upgradeSubscriptionConfirmation = () => {
		Swal.fire({
			title: 'How many pack you want to add!',
			input: 'number',
			inputAttributes: {
				autocapitalize: 'off',
				min: 1
			},
			showCancelButton: true,
			confirmButtonText: 'Confirm!',
			showLoaderOnConfirm: true,
			inputPlaceholder: 'Enter no of packs you want',
			preConfirm: async (qty) => {
				const { netBill, discount, currentDues } = await this.calculateTotal(qty);
				console.log(netBill, discount, currentDues)
				Swal.fire({
					title: 'SUBSCRIPTION UPDATE',
					text: `Subscription updated by ${this.props.item.max_users * qty} users. Your ${this.props.company.isAnnual ? "annual" : "monthly"} charge will approximately be "$${Number.parseFloat(netBill).toFixed(2)}" having an approximate discount of ${Number.parseFloat(discount).toFixed(2)}% and the amount will be charged to your card every ${this.props.company.isAnnual ? "year" : "month"} for the term of the contract!`,
					icon: 'info',
					showCancelButton: true,
					confirmButtonText: 'Okay, Update Subscription',
					cancelButtonText: 'No, Quit'
				}).then((result) => {
					if (result.value && qty > 0) {
						const promises = [];
						for (let index = 0; index < qty; index++) {
							promises.push(this.upgradeSubscription());
						}
						Promise.all(promises)
							.then((response) => {
								this.props.get()
								getBill(this.props.companyId, this.props.token)
									.then(response => {
										this.props.setBill(response);
										window.location.reload();
									})
								// Swal.fire({ icon: 'success', title: 'Successfull', text: 'Your subscription successfully updated!', timer: 2300 })
							})
					} else if (result.dismiss === Swal.DismissReason.cancel) {
					}
				})

			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
			if (result.isConfirmed) {
			}
		})
	}

	calculateTotal = async (qty) => {
		const { settings, coupons, item, company, BILL } = this.props;
		const bill = BILL.invoice;
		const nUsers = Number(company.maxUsers) + Number(item.max_users * qty);
		const newPrice = Number((item.price_per_user * item.max_users) * qty) * (company.isAnnual ? 12 : 1);
		const currentDues = (Number(bill.currentDues) + newPrice);
		const refDisc = (bill.refDiscount ? bill.refDiscount : 0);
		let termDisc = 0
		if (Number(company.term) === 3 && Array.isArray(settings)) settings.filter(set => { if (set.name === 'TermDiscount') termDisc = Number(set.value.discount) })
		let autoDisc = 0;
		Array.isArray(coupons) && coupons.filter(coupon => {
			if (coupon.couponType === 'auto' && nUsers >= coupon.minUser) {
				autoDisc = coupon.type === 'USD' ? Number(coupon.discount * 100 / currentDues) : coupon.discount;
			}
		})
		let couponDisc = 0;
		if (typeof company.coupon === 'object' && company.coupon.discount) {
			if (company.coupon.expiryDate && company.coupon.addedDate) {
				let duration = await CalculateMonth(new Date(), new Date(company.coupon.addedDate))
				if ((Number(duration) <= Number(company.coupon.expiryDate)) || (!company.coupon.expiryDate || !company.coupon.addedDate)) {
					couponDisc = company.coupon.type === 'USD' ? Number(company.coupon.discount * 100 / currentDues) : company.coupon.discount;
				}
			} else {
				couponDisc = company.coupon.type === 'USD' ? Number(company.coupon.discount * 100 / currentDues) : company.coupon.discount;
			}
		}
		const discount = couponDisc + refDisc + termDisc + autoDisc;
		const netBill = currentDues - (currentDues * (discount / 100));
		return { discount, netBill, currentDues }
	}

	upgradeSubscription = () => {
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Update Subscription"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}

		const { item } = this.props;
		const payload = { companyId: this.props.companyId, planId: item.id, plan: { ...item, dues: item.max_users * item.price_per_user } }
		return addSubscriptionService(payload, this.props.token)
		// return this.props.add(payload);
	}

	handleDrop = () => {
		Swal.fire({
			title: 'Want to cancel',
			text: 'Your payment may be different and you might face some issues',
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Cancel',
			cancelButtonText: 'No, Quit'
		}).then((result) => {
			if (result.value) {
				const { permissions, user, userId } = this.props;
				if (user && user.id === userId) {
					if (!permissions.length || !this.props.permissions[0]["Update Subscription"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
				}
				const subscription = this.props.subscriptions.filter(sub => sub.planId === this.props.item.id)[0];
				this.props.delete(subscription.id);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	render() {
		return (
			this.state.limitError ?
				<tr
					className={this.state.background}
				>
					<td colSpan={5}>
						<DangerAlert message={"You registered users exceeds plans maximum limit by " + (this.props.numberOfUsers - this.props.item.max_users)} />
					</td>
				</tr> :
				<tr
					className={this.state.background}
				>
					<td>{this.props.item.name}</td>
					{/* <td>{this.props.item.max_inventory}</td> */}
					<td>{this.props.item.max_users}</td>
					<td>${this.props.item.price_per_user}</td>
					<td>
						{
							this.props.settingPlan ?
								(this.state.patching) ?
									<BallBeatLoader /> :
									null
								:
								<button
									className="btn_grn btn-sm"
									onClick={this.upgradeSubscriptionConfirmation}
									disabled={this.state.patching}
								>
									{(this.props.selected) ? "Add More" : "Select"}
								</button>
						}
					</td>
				</tr>
		)
	}
}
const mapStateToProps = state => {
	return {
		userId: state.auth.userId,
		companyId: state.auth.companyId,
		company: state.auth.company,
		settings: state.settings.settings,
		coupons: state.signUp.coupons,
		token: state.auth.token,
		subscriptions: state.subscription.subscriptions,
		BILL: state.subscription.bill,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		get: () => dispatch(getSubscription()),
		delete: (id) => dispatch(deleteSubscription(id)),
		add: (payload) => dispatch(addSubscription(payload)),
		setBill: (payload) => dispatch(setBill(payload)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(planPopupRow);

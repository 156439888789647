import React, {Component} from 'react'
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class userSetupAdd extends Component {
	delete(){
		this.props.toggleDelete()
		this.props.close()
		this.props.deleteUser()
	}

	render() {
		return(
			<Modal
				size='sm'
				className="container-fluid"
				show={this.props.state}
				onHide={this.props.close}
				centered>
				<Modal.Header closeButton>
					<Modal.Title>Delete user</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure to delete this user? This user can't be able to log in again and you might lose all of its data.</Modal.Body>
				<Modal.Footer>
					<Button className="col-sm-6 col-md-6 col-lg-6" variant="secondary" onClick={this.props.close}>
						No
					</Button>
					<Button className="col-sm-5 col-md-5 col-lg-5" variant="danger" onClick={this.delete.bind(this)}>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}
const mapStateToProps = state => {
	return {
	userId: state.auth.userId,
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl
	};
};
export default connect(mapStateToProps)(userSetupAdd);
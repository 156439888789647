import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import CreditCardInput from 'react-credit-card-input';

import { payVendor } from '../../store/services/order';


const PaymentModal = (props) => {
  const company = useSelector(state => state.auth.company);
  const userEmail = useSelector(state => state.auth.userEmail);
  const token = useSelector(state => state.auth.token);
  const customers = useSelector(state => state.configuration.customers)

  const [vendor, setVendor] = useState(undefined);

  const [isErr, setErr] = useState('');
  const [isLoading, setLoader] = useState(false);
  const [isApproved, setApproved] = useState(false);
  const [transactionId, setID] = useState('');
  // const [cardNumber, setCardNumber] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [zip, setZIP] = useState('');

  useEffect(() => {
    getPaymentOption()
  }, [customers])

  const getPaymentOption = () => {
    const { vendorId, manufacturerId } = props.order;
    let customer = Array.isArray(customers) && customers.filter(customer => customer.vendorId === vendorId)[0] || undefined
    console.log('custoemr', customer)
    setVendor(customer);
  }

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  }
  const handleCardExpiryChange = (event) => {
    setExpiry(event.target.value);
  }
  const handleCardCVCChange = (event) => {
    setCvc(event.target.value);
  }
  const handleCardZIPChange = (event) => {
    setZIP(event.target.value);
  }

  const handleUpdate = () => {
    if (isLoading) return;
    if (vendor.paymentOption === 'card' && (!cardNumber || !expiry || !cvc || !zip)) return Swal.fire({ icon: 'error', title: 'Empty required field(s)' })
    if (vendor.paymentOption === 'card' && (!vendor.vendor.paymentApiKey || vendor.vendor.paymentApiUrl)) {
      return Swal.fire({ icon: 'error', title: 'The vendor did not setup its payments.', text: 'You can Ask to vendor to setup for payment through swatch or you can pay from outside the swatch.' })
    }
    setErr('')
    setLoader(true)
    const payload = {
      zip,
      cvv: cvc,
      type: 'sale',
      ccexp: expiry.split(' ').join(''),
      email: userEmail,
      security_key: '',
      ccnumber: cardNumber,
      company: company.name,
      orderid: props.order.id,
      amount: props.order.total,
      paymentOption: vendor.paymentOption,
      key: vendor.vendor.paymentApiKey,
      url: vendor.vendor.paymentApiUrl
    }
    payVendor(props.order.id, payload, token)
      .then((response) => {
        setLoader(true)
        if (typeof response === 'string') {
          setID(response)
          setApproved(true)
          return;
        }

        if (typeof response === 'object' && response.error) {
          console.log(response.error)
          return setErr(response.error.message)
        }
        setErr('Something went wrong!')
      })
  }

  return (
    <Modal
      className="container-fluid"
      show={props.modal}
      onHide={props.close}
      centered
      size="lg"
    // dialogClassName="modal_width"
    >
      <div className="box_payment">

        <div className="heding1">Payment to {props.order.vendor.name} for Order #{props.order.id}</div>
        <Modal.Body>
          <form className="container QuoteStatusModal" onSubmit={(e) => e.preventDefault()}>


            <div className="inside">
              {isErr && <div className="row" style={{ justifyContent: 'center', marginBottom: '3%', background: 'crimson', color: 'white' }}>
                <span> Payment Failed: {isErr} </span>
              </div>}
              {isApproved ?
                <>
                  <div className="row" style={{ justifyContent: 'center', marginBottom: '3%', color: 'green' }}>
                    <span> Transaction Approved </span>
                  </div>
                  <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <h4> Please note your Transaction Id: {transactionId} </h4>
                  </div>
                </>
                :
                <>
                  <div className="row" style={{ justifyContent: 'center', marginBottom: '3%' }}>
                    <h3 className="grn_amount"> Amount: ${Number.parseFloat(props.order.total).toFixed(2)} </h3>
                  </div>
                  {vendor && vendor.paymentOption === 'card' &&
                    <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                      <CreditCardInput
                        cardNumberInputProps={{ value: cardNumber, onChange: handleCardNumberChange }}
                        cardExpiryInputProps={{ value: expiry, onChange: handleCardExpiryChange }}
                        cardCVCInputProps={{ value: cvc, onChange: handleCardCVCChange }}
                        cardZipInputProps={{ value: zip, onChange: handleCardZIPChange }}
                        enableZipInput={true}
                        fieldClassName="input"
                        customTextLabels={{
                          cvcPlaceholder: 'CVV',
                        }}
                      />
                    </div>
                  }
                </>
              }
            </div>


          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="modalBtnWrapper">
            {isApproved ? <a href="/orders_history"><button className="btn btn-primary">Back to Orders</button></a> :
              <>
                <button
                  type="btn" className="btn btn-primary"
                  onClick={handleUpdate}>
                  Submit Payment
                </button>
                <button className="btn btn-primary" onClick={props.close}>close</button>
              </>
            }
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export default PaymentModal;
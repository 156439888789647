import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux";
import userImg from './../index.png'
import UploadIcon from "../../../img/upload.png";
import Spinner from "../../../components/loaders/spinner";
import { ApiUrl, user_permissions } from '../../../constants';

import { Button } from 'react-bootstrap';
import PermissionsModal from "./permissions";
import SuccessAlert from './../../../components/alerts/sucessfulAlert'
import DangerAlert from './../../../components/alerts/dangerAlert'
import DanderAlert from "../../../components/alerts/dangerAlert";
import closeButton from "../../../img/closeIcon.png"
import reloadButton from "../../../img/reload.png"
import Swal from 'sweetalert2';

import "../userSetupAddUser/style.css";
import { updateUserByCompanyId } from '../../../store/services/users';
class userSetupEdit extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userId: null,
			firstName: "",
			lastName: "",
			csr_email: "",
			email: "",
			phone: "",
			password: "",
			passwordConfirmation: "",
			active: true,
			image: null,
			imageToUpload: null,
			username: null,

			updatePassword: false,

			uploading: false,

			success: false,

			passMisMatch: false,
			failedPicture: false,
			failedUser: false,
			redirect: false,
			emailExistsError: false,
			usernameExistsError: false,
			error: false,
			imageName: null,
			serveImage: null,

			old_activation: null,
			last_activation: null,
			hoursDiff: 48,

			incorrectPass: false,
			incorrectPhone: false,
			permissions: false,
			user_permissions: user_permissions
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.loadFile = this.loadFile.bind(this);
		this.toggleUploading = this.toggleUploading.bind(this)
		this.showFailedToUploadPicture = this.showFailedToUploadPicture.bind(this)
		this.showFailedToCreateUser = this.showFailedToCreateUser.bind(this)
		this.showMismatchError = this.showMismatchError.bind(this)
		this.showSuccessMessage = this.showSuccessMessage.bind(this)
		this.showEmailExistsError = this.showEmailExistsError.bind(this)
		this.showUsernameExistsError = this.showUsernameExistsError.bind(this)
		this.setError = this.setError.bind(this);
	}

	toggleUploading() {
		this.setState(prevState => ({
			uploading: !prevState.uploading
		}));
	}

	setError() {
		this.setState({
			error: true,
			loading: false
		})
	}

	noImageFound() {
		this.setState({ imageName: null })
	}

	componentDidMount() {
		this.toggleUploading()
		this.setState({
			serveImage: userImg
		})
		const { match: { params } } = this.props;
		const cid = this.props.companyId;
		const token = this.props.token
		this.setState({ userId: params.id }, () => {
		})

		//Fetch Picture
		const apiPictureUser = ApiUrl + '/containers/pictures/' + params.id + '/exists';
		// axios.get("HERER", {headers: {'at'}})
		axios.get(apiPictureUser,
		)
			.then((response) => {
				(response.data.filename === "null") ?
					this.noImageFound() :
					this.setState({ imageName: response.data.filename })
			})
			.catch(
				() => {
					this.noImageFound()
				}
			)

		const apiUrlUser = ApiUrl + '/companies/' + cid + '/users/' + params.id
		axios.get(apiUrlUser,
			{
				headers: {
					'Authorization': token
				}
			})
			.then(response => {
				this.setState({
					firstName: response.data.firstName,
					lastName: response.data.lastName,
					phone: response.data.mobile,
					email: response.data.email,
					password: response.data.password,
					passwordConfirmation: response.data.password,
					active: response.data.active,
					username: response.data.username,
					csr_email: response.data.csr_email,
					old_activation: response.data.old_activation,
					last_activation: response.data.last_activation,
					user_permissions: response.data.user_permissions ? response.data.user_permissions : this.state.user_permissions
				})
				this.toggleUploading()
			})
			.catch(() => {
				this.setError()
			})
	}

	handleChange(event) {
		event.persist();
		const { name, value, type, checked } = event.target
		type === "checkbox" ?
			this.setState({ [name]: checked }) :
			this.setState({ [name]: value })
		switch (name) {
			case 'phone':
				if (/(\d{3}[\-]\d{3}[\-]\d{4})|(\d{3}\d{3}\d{4})/.test(value) === false || value.length !== 10) {
					this.showPhonePatternError()
				}
				break;
			case 'password':
				if (/(?=^.{8,}$)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value) === false) {
					this.showPasswordPatternError()
				}
				break;
			default:
				break;

		}
	}

	showSuccessMessage() {
		this.setState({ success: true })
		setTimeout(() => {
			this.setState({ success: false }, () => {
				this.props.history.push('/user-setup')
			})
		}, 2000)
	}

	showMismatchError() {
		this.setState({ passMisMatch: true })
		setTimeout(() => { this.setState({ passMisMatch: false }) }, 2000)
	}

	showFailedToCreateUser() {
		this.setState({ failedPicture: true })
		setTimeout(() => { this.setState({ failedPicture: false }) }, 2000)
	}

	showEmailExistsError() {
		this.setState({ emailExistsError: true })
		setTimeout(() => { this.setState({ emailExistsError: false }) }, 2000)
	}

	showUsernameExistsError() {
		this.setState({ usernameExistsError: true })
		setTimeout(() => { this.setState({ usernameExistsError: false }) }, 2000)
	}

	showFailedToUploadPicture() {
		this.setState({ failedUser: true })
		setTimeout(() => { this.setState({ failedUser: false }) }, 2000)
	}

	showPhonePatternError() {
		this.setState({ incorrectPhone: true })
		setTimeout(() => { this.setState({ incorrectPhone: false }) }, 3000)
	}
	showPasswordPatternError() {
		this.setState({ incorrectPass: true })
		setTimeout(() => { this.setState({ failedUser: false }) }, 2000)
	}

	calculateHours = (currentDate, pastDate) => {
		const hours = Math.abs(currentDate - pastDate) / 36e5
		return hours
	}


	//handlessubmit for the user add form. Gets the user from database using the user_id
	//if user exists, then it patches the user params on the origial user.
	// otherwise it makes a post request and creates a new user. Api requests are done using axios.

	handleSubmit(event) {
		event.preventDefault()
		const token = this.props.token
		if (!this.state.email) return Swal.fire({ icon: "error", text: "Email is Required!" })
		if (this.state.password && this.state.passwordConfirmation && this.state.password !== this.state.passwordConfirmation) return Swal.fire({ icon: "error", text: "Password does not matchyarn run!" })
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Edit User"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		if (this.state.passwordConfirmation !== this.state.password) {
			this.showMismatchError()
		} else {


			let { old_activation, last_activation } = this.state;
			const hoursDiff = old_activation ? this.calculateHours(new Date(), new Date(old_activation)) : 0;
			if ((old_activation && Math.round(hoursDiff) <= 48) === true) {
				this.setState({ hoursDiff })
			}

			this.toggleUploading()
			const filterUrl = ApiUrl + '/Companies/' + this.props.companyId + '/users'
			const filterNext = {
				"where": { "or": [{ "email": this.state.email }, { "username": this.state.username }] }
			};
			axios.get(filterUrl, {
				params: {
					filter: filterNext
				},
				headers: {
					'content-type': 'application/json',
					'Authorization': this.props.token
				}
			}).then(async (filter) => {
				if (filter.data.length > 0 && filter.data[0].id !== this.state.userId) {
					(filter.data[0].username === this.state.username) ?
						this.showUsernameExistsError() :
						this.showEmailExistsError();
					return this.toggleUploading();
				}
				const { firstName, lastName, email, phone, active, username, csr_email, user_permissions, password, passwordConfirmation } = this.state
				var params = {
					firstName,
					lastName,
					email: email && email.toLowerCase(),
					phone,
					active,
					username,
					csr_email,
					user_permissions
				};
				if (password && password === passwordConfirmation) {
					params.password = password;
				}
				updateUserByCompanyId(this.state.userId, this.props.companyId, params, token)
					.then(response => {
						if (typeof response === 'string' && response === 'Request failed with status code 402') {
							Swal.fire({ icon: 'error', title: 'Timeout!', text: 'You can not update user within 48 hours!' })
						}
						//Upload Picture
						if (this.state.imageToUpload !== null) {
							const profilePictureUploadUrl = ApiUrl + "/uploads/ProfilePicture"
							const data = new FormData()
							data.append('file', this.state.imageToUpload)
							data.append('UserId', this.state.userId)
							axios.post(profilePictureUploadUrl,
								data,
								{
									headers: {
										'Authorization': token
									}
								})
								.then(() => { // then print response status
									this.setState({
										firstName: "",
										lastName: "",
										email: "",
										phone: "",
										password: "",
										passwordConfirmation: "",
										active: true,
										image: null,
										imageToUpload: null
									}, () => {
										this.toggleUploading()
										this.showSuccessMessage()
									}

									)
								})
								.catch(res => {
									this.showFailedToUploadPicture()
									this.toggleUploading()
								})
						} else {
							this.setState({
								firstName: "",
								lastName: "",
								email: "",
								phone: "",
								password: "",
								passwordConfirmation: "",
								active: true,
							}, () => {
								this.toggleUploading()
								this.showSuccessMessage()
							})
						}
					})
					.catch(
						(err) => {
							console.log(err)
							this.setState({ failedUser: true })

							// this.showFailedToCreateUser()
							// this.toggleUploading()
						}
					)

			}).catch((err) => {
				console.log("HERE In ERR: ", err.message)
				this.setState({ failedUser: true })
				// this.showFailedToCreateUser()
				this.toggleUploading()
				// this.handlePermission()
			})
		}
	}


	loadFile(event) {
		event.persist();
		this.setState({
			image: URL.createObjectURL(event.target.files[0]),
			imageToUpload: event.target.files[0]
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.image !== this.state.image)
			if (this.state.image !== null)
				this.setState({ serveImage: this.state.image })

		if (prevState.imageName !== this.state.imageName)
			if (this.state.imageName !== null && this.state.image === null) {
				this.setState({ serveImage: ApiUrl + '/containers/pictures/download/' + this.state.imageName })
			}
	}

	refreshPage() {
		window.location.reload(false);
	}

	toggleUserPermissions = (permission) => {
		permission.checked = !permission.checked;
		this.setState({ userPermissionsList: this.state.userPermissionsList }, async () => {
			if (permission.title === "Admin App Access") {
				const { user_permissions } = this.state;
				const newList = await user_permissions && user_permissions.map((permit, ind) => {
					if (ind > 1 && permit.key != "title" && (permit.key.split(" ")[0] != "DELETE" || (!permission.checked))) {
						return permit.checked = permission.checked;
					}
					return permit;
				})
				this.setState({ userPermissionsList: newList })
			}
		})
	}

	handlePermission = () => {
		this.setState({ permissions: !this.state.permissions });
	}



	render() {
		const { permissions, user_permissions } = this.state;
		return (
			<div id="page-content-wrapper">
				{(this.state.success) ?
					<SuccessAlert message="User updated successfully" /> :
					null
				}
				{(this.state.emailExistsError) ?
					<DangerAlert message="Email already exists" /> :
					null
				}
				{(this.state.usernameExistsError) ?
					<DangerAlert message="Username already exists" /> :
					null
				}
				{(this.state.passMisMatch) ?
					<DanderAlert message="Passwords do not match" /> :
					null
				}
				{
					(this.state.failedPicture) ?
						<DanderAlert message="Failed to upload the avatar for the user" /> :
						null
				}
				{
					(this.state.failedUser) ?
						<DanderAlert message="Something went wrong! Try again later or with different email/username!" /> :
						null
				}
				{
					(this.state.usernameExistsError) ?
						<DanderAlert message="Username already exists" /> :
						null
				}

				<div className="container-fluid">
					<div className="row setup_top2 justify-content-md-center">

						<div className="col-12 col-sm-8">
							<div className="box_payment">
								<div className="heding1">
									<div className="d-inline">
										Edit User
									</div>
								</div>
								<div className="inside">
									<form className="nw_frm" onSubmit={this.handleSubmit}>
										<div className="act_inact text-center row ">
											<div className="d-inline col-2" onClick={() => this.props.history.goBack()}>
												<img alt="close button" style={{ cursor: "pointer", float: 'left' }} src={closeButton} />
											</div>

											<div className="col-8">
												<div className="row justify-content-md-center ">
													<div className="col col-lg-2">
														Inactive
													</div>
													<div className="row col-4 col-lg-4 justify-content-center">
														<label className="switch ">
															<input
																type="checkbox"
																className="default"
																name="active"
																checked={this.state.active}
																onChange={this.handleChange}
																disabled={this.state.uploading}
															/>
															<span className="slider round" />
														</label>
													</div>
													<div className="col col-lg-2">
														Active
													</div>
												</div>
											</div>
											<div className="d-inline col-2" onClick={this.refreshPage}>
												<img alt="reload button" style={{ cursor: "pointer", float: 'right' }} src={reloadButton} />
											</div>
										</div>

										<div className="user_in text-center">
											<img alt="" className="rounded-circle"
												src={this.state.serveImage}
												style={{ width: '100px', height: '100px', maxWidth: '100px', maxHeight: '100px' }}
											/>
											<h5>Upload</h5>
											<div className="text-center" >
												<input
													id="uploadProfileButton"
													type="file"
													accept="image/*"
													onChange={this.loadFile}
													disabled={this.state.uploading}
													style={{
														width: '0.1px',
														height: '0.1px',
														opacity: 0,
														overflow: 'hidden',
														position: 'absolute',
														zIndex: '-1'
													}} />
												<label className="btn_grn" htmlFor="uploadProfileButton">
													<img
														alt=""
														src={UploadIcon}
														style={{ display: 'inLine' }} />
													<h6
														style={{ display: 'inLine', color: 'white' }}>
														{' '} Upload
													</h6>
												</label>
											</div>
										</div>

										<div className="text-center" style={{ margin: "15px" }}>
											<Button onClick={this.handlePermission} >Edit Permissions</Button>
											{permissions &&
												<PermissionsModal
													user_permissions={user_permissions}
													onHide={this.handlePermission}
													toggleUserPermissions={this.toggleUserPermissions}
													onSubmit={(e) => { this.handleSubmit(e); this.handlePermission() }}
												/>
											}
										</div>

										<div className="form-row m_btm_row">
											<div className="col">
												<input
													type="text"
													className="form-control"
													placeholder="Username"
													name="username"
													value={this.state.username}
													onChange={this.handleChange}
													readOnly={this.state.uploading}
													required={true}
												/>
											</div>
											<div className="col">
												<input
													type="text"
													className="form-control"
													placeholder="CSR email"
													name="csr_email"
													value={this.state.csr_email}
													required={true}
													readOnly={this.state.uploading}
													onChange={this.handleChange}
												/>
											</div>
										</div>

										<div className="form-row m_btm_row">

											<div className="col">
												<input
													type="text"
													className="form-control"
													placeholder="First name"
													name="firstName"
													value={this.state.firstName}
													onChange={this.handleChange}
													required={true}
													readOnly={this.state.uploading}
												/>
											</div>
											<div className="col">
												<input
													type="text"
													className="form-control"
													placeholder="Last name"
													name="lastName"
													value={this.state.lastName}
													onChange={this.handleChange}
													required={true}
													readOnly={this.state.uploading}
												/>
											</div>
										</div>

										<div className="form-row m_btm_row">
											<div className="col">
												<input
													type="text"
													className="form-control"
													placeholder="Email"
													name="email"
													value={this.state.email}
													onChange={this.handleChange}
													required={true}
													readOnly={this.state.uploading}
												// pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$"
												/>
											</div>
											<div className="col">
												<input
													className="form-control"
													placeholder="Phone"
													name="phone"
													value={this.state.phone}
													onChange={this.handleChange}
													readOnly={this.state.uploading}
													// pattern="(\d{3}[\-]\d{3}[\-]\d{4})|(\d{3}\d{3}\d{4})"
													onInvalid={() => console.log("InValid Phone Number")}
												/>
											</div>
										</div>
										<div className="form-row m_btm_row">
											<div className="col">
												<input
													type="password"
													className="form-control"
													placeholder="Password"
													name="password"
													pattern="(?=^.{8,}$)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
													value={this.state.password}
													onChange={this.handleChange}
													readOnly={this.state.uploading}
												/>
											</div>
											<div className="col">
												<input
													type="password"
													className="form-control"
													placeholder="Confirm Password"
													name="passwordConfirmation"
													value={this.state.passwordConfirmation}
													onChange={this.handleChange}
													readOnly={this.state.uploading}
												/>
											</div>
										</div>
										<div className="text-center">
											<button type="submit" className="user_subBtn" disabled={this.state.uploading}>
												{(this.state.uploading) ?
													<Spinner styleClass='text-light' /> :
													'Submit'
												}
											</button>
										</div>
									</form>
									{
										(this.state.incorrectPass) ?
											<DangerAlert message="Passwords should be a minimum of 8 characters long and have at least lower case, upper case, and special character" /> :
											null
									}
									{
										(this.state.incorrectPhone) ?
											<DangerAlert message="Phone should be 10 digits long" /> :
											null
									}
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		userId: state.auth.userId,
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user,
	};
};
export default connect(mapStateToProps)(userSetupEdit);

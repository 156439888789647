import axios from 'axios';
import { ApiUrl } from '../../constants';
const Url = ApiUrl + '/InventoryGroups/';
const scannedUrl = ApiUrl + '/ScannedSwatches/';

export function getScanSwatchDetails(inventoryGroupId, token) {
  const filter = { "where": { "scannedGroupId": inventoryGroupId } };
  // return [];
  return axios.get(scannedUrl, {
    params: { filter }, headers: {
      "Authorization": token
    }
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("ERR  __ ", err)
      return err
    })
}


export const getScannedGroups = (id, token) => {
  const url = Url + id + "/scannedGroups"
  const filter = { order: ['createdAt DESC'] }
  return axios.get(url, { params: { filter }, headers: { 'Authorization': token } })
    .then(response => {
      if (response.data) {
        return response.data
      }
      return []
    })
    .catch(err => {
      console.log(err);
      return err.message;
    })
}

export const AddSwatchToGroup = (groupId, swatch, token) => {
  try {
    return axios.post(Url + groupId + '/swatches', swatch,
      {
        headers: {
          'Authorization': token
        }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => { throw (error) })
  } catch (error) {
    console.log("ERR: Adding Swatch", error.message)
    return error.message
  }
}
import React, { useEffect } from "react";
import { useDispatch, useSelector, } from "react-redux";
import {
 BrowserRouter as Router,
 Switch,
 Route,
 Link,
 useParams,
 useRouteMatch,
 Redirect,
 useHistory
} from "react-router-dom";
import SubscriptionDetails from "../../pages/companySetup";
import ScanAlerts from "../../pages/companySetup/scanAlertSound/scanAlertSound";

import UserSetup from "../../pages/userPages/userSetupMainPage/userSetupMain"
import AddUser from "../../pages/userPages/userSetupAddUser/userSetupAdd"
import EditUser from "../../pages/userPages/userSetupEditUser/userSetupEdit"
import ViewUser from "../../pages/userPages/userSetupViewUser/userSetupView"

import Dashboard from "../../pages/dashboard/dashboard";

import OrdersTable from "../vendor/order/order.table";
import OrdersDetail from "../vendor/order/ordersHistory.table";
import PrivateRoute from "./privateRoute";

import Configurations from '../vendor/customer'


const VendorRoutes = (props) => {
 // The `path` lets us build <Route> paths that are
 // relative to the parent route, while the `url` lets
 // us build relative links.
 let { path, url } = useRouteMatch();
 const history = useHistory();
 const isAuthenticated = useSelector(state => state.auth.token);
 const isVendor = useSelector(state => state.auth.company.type || false);

 useEffect(() => {
  if (!isAuthenticated) history.push('/login')
  if (!isVendor) history.push('/login')
 }, [isAuthenticated])
 return (
  <>
   <Switch>
    {/* <Route exact path={path}>
     <h3> | VENDOR DASHBOARD | </h3>
    </Route> */}
    {/*HomePage*/}
    <PrivateRoute exact path={path} isAuthenticated={true}  {...props} component={Dashboard} />
    {/*Dashboard Routing*/}
    <Route exact path={`${path}/dashboard`} isAuthenticated={true}  {...props} component={Dashboard} />

    {/*Company Setup Routing*/}
    <PrivateRoute exact path={`${path}/subscription-details`} isAuthenticated={true} component={SubscriptionDetails} />
    <PrivateRoute exact path={`${path}/scan-sounds`} isAuthenticated={true} component={ScanAlerts} />

    {/*User Setup Routing*/}
    <PrivateRoute exact path={`${path}/user-setup`} isAuthenticated={true} component={UserSetup} />
    <PrivateRoute exact path={`${path}/user-setup/add-user`} isAuthenticated={true} component={AddUser} />
    <PrivateRoute exact path={`${path}/user-setup/edit-user/:id`} isAuthenticated={true} component={EditUser} />
    <PrivateRoute exact path={`${path}/user-setup/view-user/:id`} isAuthenticated={true} component={ViewUser} />

    <Route path={`${path}/orders/:id`} component={() => <OrdersTable {...props} />} />
    <Route path={`${path}/orders_history`} component={() => <OrdersDetail {...props} />} />
    <Route path={`${path}/configurations`} component={() => <Configurations {...props} />} />
   </Switch>
  </>
 );
}
export default VendorRoutes;

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import DashboardItem from './dashboardItem'
import dashboardData from './dashboardData'

import { addCompany } from "../../store/actions/actions";

import { updateUserTimeZone } from "../../store/services/users";
import { TimeZones } from "../../constants";
import { getCompanyDataLimit } from '../../store/services/fabric';
import { getCompany } from '../../store/services/company';

const date = new Date();
const Dashboard = (props) => {
	const dispatch = useDispatch();
	const company = useSelector((state) => state.auth.company);
	const companyId = useSelector((state) => state.auth.companyId);
	const token = useSelector((state) => state.auth.token);
	const [timeZone, setZone] = useState(company && company.timeZone || "America/New_York");
	const [storage, setStorage] = useState(undefined);

	useEffect(() => {
		refreshCompanyDetails();
		getCompanyDataLimit(companyId, token)
			.then(response => {
				if (response && typeof response.data === 'object') {
					setStorage(response.data)
				}
			})
	}, [])

	const handleZone = ({ target }) => {
		setZone(target.value)
	}
	const refreshCompanyDetails = () => {
		getCompany(companyId, token)
			.then(response => {
				dispatch(addCompany(response))
			})
	}

	const handleUpdate = () => {
		if (!timeZone) return alert("Select a timeZone");
		if (company && timeZone === company.timeZone) return alert("No change in timeZone");
		const payload = {
			...company,
			timeZone: timeZone
		}
		updateUserTimeZone(payload, token)
			.then((response) => {
				if (response.data) {
					dispatch(addCompany(response.data))
				}
			})

	}

	return (
		<div id="page-content-wrapper">
			<div className="container-fluid ">
				<div className="row">
					{dashboardData && Array.isArray(dashboardData[company && company.type || 'manufacturer']) && dashboardData[company && company.type || 'manufacturer'].map(item =>
						<DashboardItem key={item.id} item={item} />)
					}
				</div>
				<hr></hr>
				<div className="row">
					<h4>Your Company's Referral Code:   <b> {company && company.referralCode || "N/A"}</b> </h4>
				</div>
				<hr></hr>
				<div className="row">
					<h4 className="col-12">Total storage: <b> {company && company.isLimited === true && company.mbpu > 0 ? Number.parseFloat(Number(company.mbpu) * Number(company.maxUsers)).toFixed(2) + " MB" : "Unlimited"} </b> </h4>
					<br />
					<h4 className="col-12">Total usage: <b> {storage && (Number.parseFloat(storage.total).toFixed(2) + " MB") || "N/A"} </b> </h4>
					<br />
					<h4 className="col-12">Total remaining: <b> {company && storage && company.isLimited === true && company.mbpu > 0 ? Number.parseFloat((Number(company.mbpu) * Number(company.maxUsers)) - (storage.total)).toFixed(2) + " MB" : "Unlimited"} </b> </h4>
				</div>
				<hr></hr>
				<div className="row">
					<div style={{ width: "100%" }}>
						<h3> TIME ZONE: </h3>
						<h6> Local Time: {date.toLocaleString()} </h6>
						{company && company.timeZone && <h6> Company Time: {date.toLocaleString("en-US", {
							timeZone: `${company && company.timeZone || ""}`,
						})} </h6>}
					</div>
					<div className="" style={{ width: "50%", padding: "3%" }}>
						<select defaultValue={timeZone} aria-label="timeZone" className="form-control" onChange={handleZone}>
							{TimeZones.map((zone, ind) => {
								return <option key={ind} value={zone} > {zone} </option>
							})}
						</select>
						<div style={{ margin: "1%" }}>
							<button onClick={() => handleUpdate()} className="btn btn-success">Update Time Zone</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Dashboard;

import React from "react";

export default function Spinner (props){
    return(
    	<div style={{display: 'block', marginTop: '10px', marginBottom: '10px'}}>
		    <div style={{ justifyContent:'center', display:'flex',flexGrow: 1 }}>
			    <div className={"spinner-border " + props.styleClass} role="status"/>
		    </div>
	    </div>
    )
}
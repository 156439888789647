export const selectAuthToken = (state) => {
 return state.auth.token;
}

export const selectCompanyId = (state) => {
 return state.auth.company.id;
}

export const selectTokenAndId = (state) => {
 return { token: state.auth.token, companyId: state.auth.companyId };
}
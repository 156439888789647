import React, { Component } from 'react';
import InventoryGroupRow from './inventoryGroupRow.js';
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import Alert from 'react-bootstrap/Alert';
import Popup from "reactjs-popup";
import Spinner from './../../../components/loaders/spinner.js';
import { connect } from "react-redux";
import SuccessAlert from './../../../components/alerts/sucessfulAlert'
import Swal from 'sweetalert2';
import { ApiUrl } from '../../../constants/index.js';
class InventoryGroup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			groups: [],
			checkedGroupId: [],
			toDownload: "",
			checkAll: false,
			filterType: '',
			filter: 'updatedAt',
			open: false,
			fetchingGroups: true,
			fetchingError: false,
			errorMsg: '',
			isCopying: false,
			copyErr: false,
			csvErr: false,
			copyErrMsg: '',
			csvErrMsg: '',
			isCreatingCsv: false,
			isDeleting: false,
		}
		this.handleChecks = this.handleChecks.bind(this);
		this.handleDeleteRow = this.handleDeleteRow.bind(this);
		this.handleCopyGroups = this.handleCopyGroups.bind(this);
		this.addGroup = this.addGroup.bind(this);
		this.handleUnChecks = this.handleUnChecks.bind(this);
		this.handleDownloadGroup = this.handleDownloadGroup.bind(this);
		this.getDownload = this.getDownload.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleCheckAll = this.handleCheckAll.bind(this);
		this.isChecked = this.isChecked.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);
	}
	componentDidMount() {
		this.setState({
			fetchingGroups: true,
		})
		this.getGroups().then(() => {
			this.setState({
				fetchingGroups: false,
			})
		})
			.catch((error) => {
				this.setState({
					fetchingError: true,
					errorMsg: error,
				})
			})
	}
	handleChangeFilter = (event) => {
		event.preventDefault();
		event.persist();
		this.setState({
			filterType: event.target.value
		})
	}
	//opens the popUp
	openModal() {
		this.setState({ open: true });
	}
	//closes the popUp
	closeModal() {
		this.setState({ open: false });
	}
	//Downloads all checked inventory groups from server in a file
	handleDownloadGroup = () => {
		this.setState({
			isCreatingCsv: true,
			csvErr: false,
		})
		const url = ApiUrl + "/InventoryGroups/multipleGroupcsv"
		const data = { "InventoryGroups": this.state.checkedGroupId };
		axios
			.post(url, data)
			.then(response => {
				if(typeof response === 'string') {
					throw response.data
				}
				setTimeout(() => {
					this.getDownload(response.data.csvDetails.container, response.data.csvDetails.fileName);
				}, 1995);
			})
			.catch(err => {
				console.log(err);
				this.setState({
					csvErrMsg: "Unable to make CSV file. Please check your internet connection.",
					csvErr: true,
					isCreatingCsv: false,
				}, () => {
					setTimeout(() => { this.setState({ csvErr: false }) }, 2000)
				})
				return null;
			})

	}
	getDownload = (container, fileName) => {
		const url2 = ApiUrl + "/containers/csv/download/" + fileName;
		axios
			.get(url2,
				{
					headers: {
						'Authorization': this.props.token
					}
				})
			.then(response => {
				this.setState({
					toDownload: response.data,
					isCreatingCsv: false,
				}, () => { return this.openModal() })
			})
			.catch(err => {
				this.setState({
					csvErrMsg: "Unable to make CSV file. Please check your internet connection.",
					csvErr: true,
					isCreatingCsv: false,
				}, () => {
					setTimeout(() => { this.setState({ csvErr: false }) }, 2000)
				})
				console.log(err);
				return null;
			})
	}
	//is called from child component inventoryGoupRow to re render parent when child is deleted
	handleDeleteRow = (groupId) => {
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Delete Group"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		const url = ApiUrl + "/companies/" + this.props.companyId + "/inventoryGroups/" + groupId
		return new Promise((resolve, reject) => {
			axios
				.delete(url,
					{
						headers: {
							'Authorization': this.props.token
						}
					})
				.then(response => {
					this.getGroups().then(() => { resolve(true) }).catch(() => { reject(false) });
					this.setState(prevState => ({
						reRend: (prevState.reRend + 1),
					})
					)
				})
				.catch(err => {
					console.log(err);
					reject(false);
				})
		})
	}

	//generates copy of all groups available in checkedGroupId
	handleCopyGroups = () => {
		this.setState({
			isCopying: true,
			copyErr: false,
		})
		this.state.checkedGroupId.map((item, index) => {
			const url = ApiUrl + "/InventoryGroups/" + item + "/copy"
			return axios
				.get(url,
					{
						headers: {
							'Authorization': this.props.token
						}
					})
				.then(response => {
					if (this.state.checkedGroupId.length === (index + 1)) {
						this.getGroups().then(() => {
							this.setState(prevState => ({
								isCopying: false,
							})
							)
						}).catch(() => {
							this.setState({
								copyErr: true,
								copyErrMsg: "Groups copied but unable to refresh page. Please check your internet connection.",
								isCopying: false,
							}, () => {
								setTimeout(() => { this.setState({ copyErr: false }) }, 2000)
							})
						})

					}

				})
				.catch(err => {
					console.log(err);
					this.setState({
						copyErr: true,
						copyErrMsg: "Unable to copy groups. Please check your internet connection.",
						isCopying: false,
					}, () => {
						setTimeout(() => { this.setState({ copyErr: false }) }, 2000)
					})
					return null;
				})
		}
		)
	}
	//is called from child component inventoryGoupRow to add a group in list if checked
	handleChecks = (newElement) => {
		if (this.state.checkedGroupId.indexOf(newElement) !== parseInt("-1")) {
		}
		else {
			this.setState((prevState) => ({
				checkedGroupId: [...prevState.checkedGroupId, newElement]
			}), () => {
				//checks if all groups are checkedd then set checkall value to true
				if (this.state.checkedGroupId.length === this.state.groups.length) {
					this.setState({
						checkAll: true
					})
				}
			})
		}
	}
	//is called from child component inventoryGoupRow to remove a group from list if unchecked
	handleUnChecks = (newElement) => {
		this.setState((prevState) => ({
			checkedGroupId: prevState.checkedGroupId.filter(function (item) { return item !== newElement }),
			checkAll: false
		}))
	}
	//to check if an item is already in checked items Array
	isChecked(elementId) {
		if (this.state.checkedGroupId.indexOf(elementId)) {
			return true;
		}
		return false;
	}
	//to check all inventory Groups
	handleCheckAll = () => {
		this.setState((prevState) => ({
			checkAll: !prevState.checkAll
		}), () => {
			if (this.state.checkAll === true) {
				var newArray = [];
				this.state.groups.map((item) => {
					newArray = [...newArray, item.id]
					return null
				})
				this.setState({
					checkedGroupId: newArray
				})
			}
			else {
				this.setState({
					checkedGroupId: []
				})
			}
		})
	}
	//sets variable this.state.groups to group list from /api/InventoryGroup
	getGroups = () => {
		const url = ApiUrl + "/companies/" + this.props.companyId + "/inventoryGroups"
		return new Promise((resolve, reject) => {
			axios
				.get(url,
					{
						headers: {
							'Authorization': this.props.token
						}
					})
				.then(response => {
					this.setState({
						groups: response.data,
					}, () => { resolve(true); })
				})
				.catch(err => {
					console.log(err);
					reject("Network Error");
					return null;

				})
		})
	}
	//redirection to add-inventory-group page
	addGroup() {
		return (<Redirect to="/add-inventory-group/" />)
	}

	handleFilter = ({target}) => {
		this.setState({ filter: target.value })
		var newGroup = [...this.state.groups];
		newGroup = newGroup.sort((a, b) => {
			a = new Date(a[target.value]);
			b = new Date(b[target.value]);
			return a > b ? -1 : a < b ? 1 : 0;
		})
		this.setState({groups: newGroup})
	}
	


	render() {
		return (
			<div id="page-content-wrapper">
				<div className="container-fluid">
					<div className="row setup_top setup_top">
						<div className="col-12">
							<ul>
								<li style={{ flexGrow: '1', width: '100%' }}>SWATCH INVENTORY GROUP</li>
								<li style={{ flexGrow: '2', width: '100%' }}>
									<div className="col-6">
										<select className="input-group form-group fild2" onChange={this.handleFilter}>
											<option value="updatedAt">Filter By Modified</option>
											<option value="createdAt">Filter By Created</option>
											<option value="download_available">Filter By Download</option>
										</select>
									</div>
									<div className="col-6">
										<div className="input-group form-group fild2">
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-search" />
												</span>
											</div>
											<input type="text" className="form-control" onChange={this.handleChangeFilter} placeholder="Type to filter…" />
										</div>
									</div>

								</li>
							</ul>
						</div>
						<div className="col-12">
							<div className="table-responsive my-4 scroll_sec">
								<table className="table tb_top">
									<thead>
										<tr>
											<th scope="col">
												<div className="custom-control custom-checkbox">
													<input type="checkbox"
														className="custom-control-input" id="defaultUnchecked"
														checked={this.state.checkAll}
														onChange={this.handleCheckAll} />
													<label className="custom-control-label" htmlFor="defaultUnchecked" />
												</div>
											</th>
											<th scope="col">Group Name</th>
											<th scope="col">Type</th>
											<th scope="col">Version</th>
											<th scope="col">Add Date</th>
											<th scope="col">Modified Date</th>
											<th scope="col">Available For User
											<br />To Download
										</th>
											<th scope="col">Edit</th>
											<th scope="col">Delete</th>
											<th scope="col">View Scanned</th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.fetchingGroups ?
												this.state.fetchingError ?
													<tr style={{ height: 'auto', alignContent: 'center' }}>
														<td colSpan={10}>
															<Alert variant="danger" style={{ textAlign: 'center' }}>
																{this.state.errorMsg}
															</Alert>
														</td>
													</tr>
													:
													<tr style={{ height: 'auto', alignContent: 'center' }}>
														<div style={{ position: 'absolute', width: '100%' }}>
															<Spinner styleClass="text-primary" />
														</div>
													</tr>
												:
												(this.state.groups.length === 0) ?
													<tr>
														<td colSpan={10}>
															<SuccessAlert message="No inventory added yet." />
														</td>
													</tr> :
													this.state.groups.map(item => {
														return (
															<InventoryGroupRow
																item={item}
																updateGroups={this.getGroups}
																handleChecks={this.handleChecks}
																handleUnChecks={this.handleUnChecks}
																isChecked={this.isChecked}
																isDeleting={this.isDeleting}
																filterType={this.state.filterType}
																checkedGroupId={this.state.checkedGroupId}
																handleDeleteRow={this.handleDeleteRow}
															/>)
													})
										}

									</tbody>
								</table>
							</div>
						</div>
						<Popup
							open={this.state.open}
							closeOnDocumentClick
							onClose={this.closeModal}
							className="swatch_popup_parent"
						>
							<CSVLink filename='SwatchInventoryGroups.csv' data={this.state.toDownload} separator={","}>
								<Alert key='1' variant='primary'>
									Your file has been created <b>Click to download</b>
								</Alert>
							</CSVLink>
						</Popup>
						<div className="col-12">
							<div className="float-right btn_grp">
								<button style={(this.state.checkedGroupId.length === 0) ? { display: 'none' } : {}} disabled={this.state.isCreatingCsv || (this.state.checkedGroupId.length === 0)} className="btn_red" onClick={this.handleDownloadGroup}>
									{
										this.state.isCreatingCsv ?
											<Spinner style={{ display: 'flex', overflow: 'hidden' }} styleClass='text-primary' />
											:
											"Create CSV File"
									}
								</button>
								<button style={(this.state.checkedGroupId.length === 0) ? { display: 'none' } : {}} className="btn_blue" disabled={this.state.isCopying || (this.state.checkedGroupId.length === 0)} onClick={this.handleCopyGroups}>
									{
										this.state.isCopying ?
											<Spinner style={{ display: 'flex', overflow: 'hidden' }} styleClass='text-primary' />
											:
											"Copy Group"
									}
								</button>
								<Link to="/add-inventory-group">
									<button className="btn_grn addgroup1">
										{
											//onclick it goes to add-inventory-group page
										}
										Add Group
									</button>
								</Link>
							</div>
						</div>
						{
							this.state.copyErr ?
								<Alert variant="danger" style={{ textAlign: 'center' }}>
									{this.state.copyErrMsg}
								</Alert>
								:
								null
						}
						{
							this.state.csvErr ?
								<Alert variant="danger" style={{ textAlign: 'center' }}>
									{this.state.csvErrMsg}
								</Alert>
								:
								null
						}
					</div>
					<div className="addgrp_sec">
						<div className="addgrp_sec2">
							<form className="col-12">
								<h2>Add New Group</h2>
								<div className="form-row">
									<div className="col-4">
										<div className="row">
											<label className="col-auto">Group Name:</label>
											<div className="col-8">
												<input type="text" className="form-control" placeholder="" />
											</div>
										</div>
									</div>
									<div className="col">
										<div className="row">
											<label className="col-auto">Type:</label>
											<div className="col-7">
												<input type="text" className="form-control" placeholder="" />
											</div>
										</div>
									</div>
									<div className="col">
										<div className="row">
											<label className="col-auto">Version:</label>
											<div className="col-7">
												<input type="text" className="form-control" placeholder="" />
											</div>
										</div>
									</div>
									<div className="col">
										<div className="row">
											<label className="col-auto">Add Date:</label>
											<div className="col-7">
												<input type="text" className="form-control" placeholder="7/20/2019" />
											</div>
										</div>
									</div>
								</div>
								<div className="form-row">
									<div className="col-4">
										<div className="row">
											<label className="col-auto">Modify Date:</label>
											<div className="col-5">
												<input type="text" className="form-control" placeholder="7/27/2019" />
											</div>
										</div>
									</div>
								</div>
								<div className="btn_box">
									<div className="float-right btn_grp">
										<button className="btn_grn addgroup1">Save</button>
										<button className="btn_red cancelgroup">Cancel</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user,
		userId: state.auth.userId
	};
};
export default connect(mapStateToProps)(InventoryGroup)

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import createSagaMiddleware from "redux-saga";
import { fork, all } from "redux-saga/effects";
import storage from 'redux-persist/lib/storage';


import reducer from './reducers/reducer';
import SubscriptionReducer from './reducers/subscription';
import SignUpReducer from './reducers/signUp';
import SettingReducer from './reducers/setting';
import ConfigurationReducer from './reducers/configuration';
import FabricReducer from './reducers/fabric';

import SubscriptionWatcher from './sagas/subscription';
import ConfigurationWatcher from './sagas/configuration';
import FabricWatcher from './sagas/fabric';
import SignUpWatcher from './sagas/signUp';
function* rootSaga() {
    yield all([
        fork(SignUpWatcher),
        fork(SubscriptionWatcher),
        fork(ConfigurationWatcher),
        fork(FabricWatcher)
    ])
}

const rootReducers = combineReducers({
    auth: reducer,
    signUp: SignUpReducer,
    subscription: SubscriptionReducer,
    settings: SettingReducer,
    fabric: FabricReducer,
    configuration: ConfigurationReducer
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'signUp', 'subscription', 'settings', 'fabric', 'configuration']
}
const sagaMiddleware = createSagaMiddleware();
let enhancer;
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
        applyMiddleware(sagaMiddleware)
    );
} else {
    enhancer = compose(applyMiddleware(sagaMiddleware));
}

const persistedReducer = persistReducer(persistConfig, rootReducers);
const store = createStore(persistedReducer, enhancer);
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store)

export default store
import axios from "axios";
import Swal from "sweetalert2";
import { ApiUrl } from "../../constants";

const customerUrl = ApiUrl + '/Customers/';
const cmpUrl = ApiUrl + '/Companies/'

export const getVendors = (token) => {
  const params = {
    filter: { where: { type: 'vendor' }, include: ['owner'] }
  }
  return axios.get(cmpUrl, { params, headers: { 'authorization': token } })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log("err: getVendors : ", err.message)
      return err.message
    })
}

export const getVendorByBothIds = (manufacturerId, vendorId, token) => {
  const params = { filter: { where: { manufacturerId, vendorId } } }
  return axios.get(customerUrl, { params, headers: { 'authorization': token } })
    .then(response => {
      if (Array.isArray(response.data) && response.data.length) return true;
      return false
    })
    .catch(err => {
      console.log('err: getVendorByBothIds: ', err.message)
      return err.message
    })
}
export const getVendorByManufacturerId = (manufacturerId, token) => {
  const params = { filter: { where: { manufacturerId },  include: ['vendor'] } }
  return axios.get(customerUrl, { params, headers: { 'authorization': token } })
    .then(response => {
      if (Array.isArray(response.data) && response.data.length) return response.data;
      return []
    })
    .catch(err => {
      console.log('err: getVendorByManufacturerId: ', err.message)
      return err.message
    })
}
export const getManufacturerByVendorId = (vendorId, token) => {
  const params = { filter: { where: { vendorId }, include: ['manufacturer'] } }
  return axios.get(customerUrl, { params, headers: { 'authorization': token } })
    .then(response => {
      if (Array.isArray(response.data) && response.data.length) return response.data;
      return []
    })
    .catch(err => {
      console.log('err: getVendorByManufacturerId: ', err.message)
      return err.message
    })
}

export const updateCustomerById = (id, payload, token) => {
  return axios.patch(customerUrl + id, payload, { headers: { 'authorization': token } })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log('err: updateCustomerById: ', err.message)
      return err.message
    })
}

export const addVendor = async (payload, token) => {
  let filter = await getVendorByBothIds(payload.manufacturerId, payload.vendorId, token);
  if (filter) { Swal.fire({ text: 'Vendor already exist', timer: 2000 }); return "vendor Exist" }
  return axios.post(customerUrl, payload, { headers: { 'authorization': token } })
    .then(response => { return response.data })
    .catch(err => {
      console.log('err: AddVendor: ', err.message)
      return err.message
    })
}

export const notifyVendor = (params, token) => {
  return axios.post(customerUrl + 'emailVendor', { params }, { headers: { 'athurization': token } })
    .then(response => { return response.data })
    .catch(err => {
      console.log('err: notifyVendor: ', err.message)
      return err.message;
    })
}

export const referVendorEmail = (params, token) => {
  return axios.post(customerUrl + 'referVendorEmail', { params }, { headers: { 'athurization': token } })
    .then(response => { return response.data })
    .catch(err => {
      console.log('err: referVendorEmail: ', err.message)
      return err.message;
    })
}
export const requestPaymentApiKey = (params, token) => {
  return axios.post(customerUrl + 'paymentRequestEmail', { params }, { headers: { 'athurization': token } })
    .then(response => { return response.data })
    .catch(err => {
      console.log('err: requestPaymentApiKey: ', err.message)
      return err.message;
    })
}
import debitCardImg from './../../img/debit-card.png'
import groupImg from './../../img/group.png'
import settingImg from './../../img/setings.png'
import connectionImg from './../../img/connection.png'


const dashboardData = {
	"manufacturer": [
		{
			id: 1,
			imgSrc: debitCardImg,
			name: "Subscription Details",
			redirectPath: '/subscription-details'
		},
		{
			id: 2,
			imgSrc: groupImg,
			name: "Scanning Alert Sounds",
			redirectPath: '/scan-sounds'

		},
		{
			id: 3,
			imgSrc: settingImg,
			name: "User Setup",
			redirectPath: '/user-setup'

		},
		{
			id: 4,
			imgSrc: connectionImg,
			name: "Swatch Inventory Group",
			redirectPath: '/swatch-inventory-group'

		}
	],
	"vendor": [
		{
			id: 1,
			imgSrc: debitCardImg,
			name: "Subscription Details",
			redirectPath: 'vendor/subscription-details'
		},
		{
			id: 2,
			imgSrc: groupImg,
			name: "Scanning Alert Sounds",
			redirectPath: 'vendor/scan-sounds'

		},
		{
			id: 3,
			imgSrc: settingImg,
			name: "User Setup",
			redirectPath: 'vendor/user-setup'

		},
		// {
		// 	id: 4,
		// 	imgSrc: connectionImg,
		// 	name: "Swatch Inventory Group",
		// 	redirectPath: '/swatch-inventory-group'

		// }
	],
}

export default dashboardData;
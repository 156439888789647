import React from 'react';

import './css/all.css'
import './css/bootstrap.css'
import './css/style.css'


//Style Files
import './App.css';

//Libraries
import axios from 'axios'

//local component
import Router from './components/routes/Router'
import LoadingScreen from './../src/components/loaders/LoadingScreen'

import { connect } from "react-redux";
import { addCompanyId, addURL, addUserId, addToken, addRole, addSettings } from "./store/actions/actions";
import { ApiUrl } from './constants';

class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isAuthenticated: false,
			loading: false
		}
		this.setRole = this.setRole.bind(this)
	}

	componentWillMount() {
		//Set the URL to local storage
		this.props.addURL();
		this.setRole();
	}

	setRole() {
		if (this.props.token === null) {
			this.setState({ isAuthenticated: false, loading: false })
		}
		else {
			this.setState({ loading: true })
			const roleUrl = ApiUrl + '/users/getPrincipalId/?access_token=' + this.props.token;
			axios.get(roleUrl, null,
				{
					headers: {
						'content-type': 'application/json',
					}
				}).then(
					response => {
						if (typeof response === 'object' && typeof response.data === 'object' && Object.keys(response.data).length && response.data.role != "saasManager") {
							this.setState({ isAuthenticated: true, loading: false });
							this.props.addRole(response.data.role)
						} else {
							throw({message: "Token Expired"})
						}
						/*(response.data.role === "appManager") ?
							this.setState({isAuthenticated: true, loading: false}) :
							this.setState({isAuthenticated: false, loading: false})*/
					}
				).catch((error) => {
					const response = error.response
					if (response !== undefined) {
						if (response.status === 401) {
							this.setState({ isAuthenticated: false, loading: false })
						} else {
							this.setState({ loading: false })
						}
					}
					this.setState({ isAuthenticated: false, loading: false })

					this.props.addUserId(null)
					this.props.addCompanyId(null)
					this.props.addToken(null)
					this.props.addRole("")
				})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.token !== this.props.token) {
			this.setRole()
		}

	}

	componentDidMount() {
		this.props.addURL();
		const title = window.document.getElementById("SwatchAppTitle");
		if (title) {
			const getAppNameUrl = ApiUrl + '/SiteSettings'
			// const filter = { "where": { "name": "appName" } };
			return new Promise((resolve, reject) => {
				axios.get(getAppNameUrl, {
					params: {
						// filter: filter
					},
					headers: {
						'content-type': 'application/json',
						'authorization': this.props.saastoken
					}
				}).then(response => {
					this.props.addSettings(response.data)
					response.data.map((setting) => {
						if(setting.name === "appName") title.innerHTML = setting.value.appName
					})
					resolve(response);
				}).catch(error => {
					reject(error)
				})
			})

		}
	}

	render() {
		//Main App only redes the routes
		let companyType = '';
		if(this.props.company && this.props.company.type && this.props.company.type === 'vendor') {
			companyType = this.props.company.type;
		}
		return (
			this.state.loading ?
				<LoadingScreen />
				: <Router isAuthenticate={this.state.isAuthenticated} companyType={companyType} />
		)
	}
}



const mapStateToProps = state => {
	return {
		apiUrl: state.auth.apiUrl,
		userId: state.auth.userId,
		companyId: state.auth.companyId,
		company: state.auth.company,
		token: state.auth.token
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addUserId: id => dispatch(addUserId(id)),
		addCompanyId: id => dispatch(addCompanyId(id)),
		addToken: token => dispatch(addToken(token)),
		addRole: role => dispatch(addRole(role)),
		addURL: () => dispatch(addURL()),
		addSettings: (settings) => dispatch(addSettings(settings))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import * as types from '../constants/signUp';

const initialState = {
  step: 0,
  password: "",
  owner: {},
  company: {},
  coupons: [],
  noOfUsers: 0,
  noOfPacks: 0,
  cardInfo: {},
  loading: false,
  isSigned: false,
  subscriptions: []
};

function SignUpReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_STEP:
      if (action.payload < 0) return { ...state, step: 0 }
      return { ...state, step: action.payload }
    case types.SET_PASSWORD:
      return { ...state, password: action.payload }
    case types.SET_OWNER_INFO:
    case types.ADD_OWNER_SUCCESS:
      return { ...state, owner: action.payload }
    case types.SET_COMPANY_INFO:
    case types.ADD_COMPANY_SUCCESS:
      return { ...state, company: action.payload }
    case types.SET_NO_OF_USERS:
      return { ...state, noOfUsers: action.payload }
    case types.SET_NO_OF_PACKS:
      return { ...state, noOfPacks: action.payload }
    case types.SET_CARD_INFO:
      return { ...state, cardInfo: action.payload }
    case types.GET_COUPONS_SUCCESS:
      return { ...state, coupons: action.payload }
    // case types.PROCEED_TO_SIGN_UP:
    //   return { ...state, loading: true }
    case types.ADD_OWNER_FAILURE:
    case types.ADD_COMPANY_FAILURE:
    case types.ADD_SUBSCRIPTION_FAILURE:
      return { ...state, loading: false }
    case "SIGNED_UP":
      return { ...state, loading: true }
    case "SET_LOADER":
      return { ...state, loading: action.payload }
    case types.TRUNCATE_SIGN_UP:
      return initialState
    default:
      return state;
  }
}

export default SignUpReducer;

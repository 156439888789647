import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';

import QouteStatusModal from './quote.modal'

import './order.style.css';
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { getOrders, getOrderGroup, createOrderGroup, createOrder, updateOrderGroup, updateOrder, postOrderFabricCSV, } from '../../store/services/order';
import PaymentModal from './paymen.modal';
import template from '../../templateFile/orderFabricTemplate.csv'
import Spinner from '../../components/loaders/spinner';
import { getCustomer } from '../../store/actions/configuration';
const orderGroupTemp = { manufacturerId: "", vendorId: "", name: "", threadColor: "", notes: "", checks: { header: false, header_drilled: false, corner_label: false, fabric_drilled: false, overlock: false, grommet: false, collate: false }, vendorNotes: { header: "", header_drilled: "", corner_label: "", fabric_drilled: "", overlock: "", grommet: "", collate: "", threadColor: "", notes: "" } }
const orderTemp = { manufacturerId: "", requireLevel: "none", vendorId: "", groupId: "", po_number: "", job_number: "", notes: '', vendorNotes: '' }

const EditOrder = (props) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const history = useHistory()
  const company = useSelector(state => state.auth.company);

  const companyId = useSelector(state => state.auth.companyId);
  const token = useSelector(state => state.auth.token);
  const customVendors = useSelector(state => state.configuration.customers);
  // const orderGroups = [];
  const [loading, setLoader] = useState(false);
  const [order, setOrder] = useState({});
  const [modal, setModal] = useState(false);
  const [orderGroups, setOrderGroup] = useState([]);
  const [state, setState] = useState({ configs: { header: false, header_drilled: false, corner_label: false, fabric_drilled: false, overlock: false, grommet: false, collate: false } })
  const [stateConfigs, setStateConfigs] = useState({ header: false, header_drilled: false, corner_label: false, fabric_drilled: false, overlock: false, grommet: false, collate: false });


  useEffect(() => {
    dispatch(getCustomer('manufacturer'))
    getGroups();
    getORDERS();
  }, [])


  const getGroups = async () => {
    let groups = await getOrderGroup(token, { where: { manufacturerId: companyId } })
    if (Array.isArray(groups) && groups.length) setOrderGroup(groups)
  }

  const getORDERS = () => {
    setLoader(true)
    const filter = { where: { id: id }, include: ['order_group', 'vendor', 'manufacturer', 'creator', { 'fabrics': 'fabric' }] }
    getOrders(filter, token)
      .then(response => {
        if (Array.isArray(response)) {
          setOrder(response[0])
          setStateConfigs(response[0].group.checks)
          setState({ configs: response[0].group.checks, threadColor: response[0].group.threadColor, notes: response[0].group.notes })
          // console.log(response[0])
          return setLoader(false)
        }
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
      })
  }

  const handleChange = ({ target }) => {
    let newState = { ...state }
    newState[target.name] = target.value;
    setState(newState);
  }

  const handleCheck = ({ target }) => {
    let newState = { ...state }
    let checks = { ...newState.configs }
    checks[target.name] = target.checked
    newState.configs = checks;
    setStateConfigs(checks)
    setState(newState)
  }

  const saveOrder = (order) => {
    if (order.status === 'invoice') return props.history.push('/orders/order.id')
    order.group.checks = state.configs;
    order.group.notes = state.notes;
    order.group.threadColor = state.threadColor;
    updateOrder(order.id, order, token)
      .then(response => {
        if (typeof response === 'string') {
          return Swal.fire({ icon: 'error', title: 'Something went wrong!', timer: 1500 })
        }
        props.history.push('/orders/' + response.id)
      })
  }

  const { header_drilled, corner_label, header, fabric_drilled, overlock, grommet, collate } = stateConfigs;
  return (
    <div className="container CreateOderContainer" style={{ textAlign: 'center' }}>
      <div className="in_hdr">
        <div className="row">
          <div className="col-auto mr-auto"> Swatch Group Information </div>
        </div>
      </div>

      {/* SwatchGroup */}
      <div className="grp_frm">
        <form className="col-12" onSubmit={() => { }}>
          <div className="form-row">
            {company.type === 'manufacturer' &&
              <div className="col-sm-6">
                <div className="row">
                  <label className="col-6">Select a Swatch Type:</label>
                  <div className="col-6">
                    {Array.isArray(orderGroups) && orderGroups.length > 0 ?
                      <select className="browser-default custom-select" disabled value={order && order.groupId} name="orderId" >
                        <option key={"item.id"} value="addNew">
                          [Create New Group]
                        </option>
                        {orderGroups.map(item => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })
                        }
                      </select>
                      :
                      <button className="btn_grn browser-default custom-select" onClick={(e) => { e.preventDefault(); setModal(true) }}>Create new Group</button>
                    }
                  </div>
                </div>
              </div>
            }


            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Header:</label>


                {company.type !== 'vendor' ?
                  <div className="col-2">
                    <input
                      type="checkbox"
                      checked={header}
                      name="header"
                      onChange={handleCheck}
                      className="form-control"
                    />
                  </div>
                  :
                  <div className="col-6">
                    <input
                      type="text"
                      name="header"
                      className="form-control"
                    />
                  </div>
                }
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Header Drilled:</label>
                {company.type !== 'vendor' ?
                  <div className="col-2">
                    <input
                      type="checkbox"
                      checked={header_drilled}
                      name="header_drilled"
                      onChange={handleCheck}
                      className="form-control"
                    />
                  </div>
                  : <div className="col-6">
                    <input
                      type="text"
                      name="header_drilled"
                      className="form-control"
                    />
                  </div>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Corner Label:</label>

                {company.type !== 'vendor' ?
                  <div className="col-2">
                    <input
                      type="checkbox"
                      checked={corner_label}
                      name="corner_label"
                      onChange={handleCheck}
                      className="form-control"
                    />
                  </div>
                  : <div className="col-6">
                    <input
                      type="text"
                      name="corner_label"
                      className="form-control"
                    />
                  </div>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Fabric Drilled:</label>
                {company.type !== 'vendor' ? <div className="col-2">
                  <input
                    type="checkbox"
                    checked={fabric_drilled}
                    name="fabric_drilled"
                    onChange={handleCheck}
                    className="form-control"
                  />
                </div>
                  : <div className="col-6">
                    <input
                      type="text"
                      name="fabric_drilled"
                      className="form-control"
                    />
                  </div>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Overlock:</label>
                {company.type !== 'vendor' ?
                  <div className="col-2">
                    <input
                      type="checkbox"
                      checked={overlock}
                      name="overlock"
                      onChange={handleCheck}
                      className="form-control"
                    />
                  </div>
                  : <div className="col-6">
                    <input
                      type="text"
                      name="overlock"
                      className="form-control"
                    />
                  </div>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Grommet:</label>
                {company.type !== 'vendor' ?
                  <div className="col-2">
                    <input
                      type="checkbox"
                      checked={grommet}
                      name="grommet"
                      onChange={handleCheck}
                      className="form-control"
                    />
                  </div>
                  : <div className="col-6">
                    <input
                      type="text"
                      name="grommet"
                      className="form-control"
                    />
                  </div>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <label className="col-4">Collate:</label>

                {company.type !== 'vendor' ?
                  <div className="col-2">
                    <input
                      type="checkbox"
                      checked={collate}
                      name="collate"
                      onChange={handleCheck}
                      className="form-control"
                    />
                  </div>
                  : <div className="col-6">
                    <input
                      type="text"
                      name="collate"
                      className="form-control"
                    />
                  </div>}
              </div>
            </div>

            {company.type !== 'vendor' ?
              <div className="col-sm-12">
                <div className="row">
                  <label className="col-4">Thread Color:</label>
                  <div className="col-6">
                    <input
                      type="text"
                      name="threadColor"
                      value={state.threadColor || ""}
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              :
              <div className="col-sm-12">
                <div className="row">
                  <label className="col-4">Thread Color:</label>
                  <div className="col-6">
                    <input
                      type="text"
                      name="threadColor"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            }



            {company.type !== 'vendor' ?
              <div className="col-sm-12">
                <div className="row">
                  <label className="col-4">Order Notes:</label>
                  <div className="col-6">
                    <textarea
                      type="text"
                      name="notes"
                      rows="6"
                      value={state.notes}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              :
              <div className="col-sm-12">
                <div className="row">
                  <label className="col-4">Vendor Notes:</label>
                  <div className="col-6">
                    <textarea
                      type="text"
                      name="notes"
                      rows="6"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            }
          </div>

        </form>
      </div>


      <div className="in_hdr">
        <div className="row">
          <div className="col-auto mr-auto">Order Details  </div>
        </div>
      </div>
      {typeof order === 'object' && !loading &&
        <OrderDetails {...state} order={order} handleSave={saveOrder} />
      }

    </div>
  )
}

export default EditOrder;

const OrderDetails = (props) => {

  const { id } = useParams();

  const history = useHistory();
  const filterStatus = { 'accepted': true, 'scanned': true, 'quoted': true, "accepted": true, "scanned": true, "quoted": true, "invoiced": true }
  const company = useSelector(state => state.auth.company);
  const token = useSelector(state => state.auth.token);
  const [quoteModal, setQuoteModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const [order, setOrder] = useState(props.order);
  const [isAllowed, setAllowed] = useState(false);
  const [modal, showModal] = useState(false);
  const [isImporting, setImporting] = useState(false);
  const [file, setFile] = useState(undefined)
  const [option, setOption] = useState('');

  useEffect(() => {
    setOrder(props.order)
    setAllowed(!filterStatus[props.order.status])
  }, [props.order])

  const handleChange = ({ target }) => {
    let newOrder = { ...order }
    newOrder[target.name] = target.value
    setOrder(newOrder)
  }

  const handleSave = (e) => {
    e && e.preventDefault();
    if (filterStatus[order.status]) return Swal.fire({ icon: 'error', title: 'You can not make any change now!', timer: 1500 })
    let newOrder = { ...order }
    newOrder.status = order.status === 'invoiced' ? order.status : "draft";
    newOrder.modifierId = company.ownerId;
    setOrder(newOrder)
    props.handleSave(newOrder)
  }

  const handleImport = () => {
    if (isImporting) return;
    if (!option) return Swal.fire({ icon: 'warning', title: 'Select import type first!', timer: 2000 })
    if (option === 'existing') return handleSave();
    if (!file || typeof file !== 'object') return Swal.fire({ icon: 'error', title: 'Select a File first.', timer: 2000 });
    const formData = new FormData();
    formData.append('file', file);
    postOrderFabricCSV(id, formData, token)
      .then((response) => {
        setImporting(false)
        if (typeof response !== 'object') {
          return Swal.fire({ icon: 'error', title: 'Something went wrong while uploading', timer: 2000 });
        }
        Swal.fire({ icon: 'success', title: 'Operation successful' })
        return handleSave()
      })

  }

  const handleImportFile = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    //event.target.files ;
    if (event.target.files.length > 0) return setFile(file)
    setFile(undefined)
    return Swal.fire({ icon: 'error', title: 'No file found!', timer: 2000 })
  }

  return company.type === 'vendor' ?
    (<div className="grp_frm">
      <form className="col-12" onSubmit={() => { }}>
        <div className="form-row">
          <div className="col-sm-4">
            <div className="row">
              <label className="col-4">Vendor notes:</label>
              <div className="col-8">
                <textarea
                  type="text"
                  name="vendorNotes"
                  rows="6"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr />
      <div className="btn_arte" style={{ display: 'block' }}>
        <div className="row">
          <div className="col-auto mr-auto">
            <button className="btn_grn" onClick={() => history.push(`/vendor/orders/${order.id}`)}>
              View Fabrics
            </button>
          </div>
          <div className="col-auto mr-auto">
            <button className="btn_red">
              <a href={'/orders_history'}>
                Cancel
              </a>
            </button>
          </div>
        </div>
      </div>

    </div>)
    : (
      <div className="grp_frm">
        <form className="col-12" onSubmit={() => { }}>
          <div className="form-row">

            <div className="col-sm-4">
              <div className="row">
                <label className="col-4">Order Date:</label>
                <div className="col-8">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    readOnly
                    value={(new Date(order.createdAt)).toLocaleDateString()}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="row">
                <label className="col-4">PO Number:</label>
                <div className="col-8">
                  <input
                    type="text"
                    name="po_number"
                    value={order.po_number || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="row">
                <label className="col-4">Job Number:</label>
                <div className="col-8">
                  <input
                    type="text"
                    onChange={handleChange}
                    disabled
                    name="job_number"
                    className="form-control"
                    value={order.job_number || 0}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="row">
                <label className="col-4">Scan Level:</label>
                <div className="col-8">
                  <select className="browser-default custom-select" value={order.requireLevel} name="requireLevel" onChange={handleChange} >
                    <option key={"none"} value={"none"}>None</option>
                    <option key={"single"} value={"single"}>Single</option>
                    <option key={"full"} value={"full"}>Full</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="row">
                <label className="col-4">Order notes:</label>
                <div className="col-8">
                  <textarea
                    type="text"
                    disabled={company.type === 'vendor'}
                    name="notes"
                    rows="6"
                    value={order.notes || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

          </div>


        </form>
        <hr />
        {/* BUTTONS */}
        <div className="btn_arte" style={{ display: 'block' }}>
          {(order.status === 'quoted' || order.status === 'scanned' || order.status === 'rejected') &&
            <div className="row">
              <div className="col-auto mr-auto">
                <button className="btn_grn" onClick={() => setQuoteModal(true)} >
                  View Quote Status
                </button>
              </div>
              {
                order.status.toString() === "scanned" &&
                <div className="col-auto mr-auto">
                  <button className="btn_grn" onClick={() => setPaymentModal(true)} >
                    Submit Payment
                  </button>
                </div>
              }
              <br />
              <br />
              <br />
            </div>
          }
          <div className="row">
            {isAllowed &&
              <>
                <div className="col-auto mr-auto">
                  <button className="btn_grn" onClick={() => showModal(true)}>
                    Import/Copy Fabric
                  </button>
                </div>
                <div className="col-auto mr-auto">
                  <button className="btn_grn" onClick={handleSave}>
                    Add Fabric Manually
                  </button>
                </div>

                <div className="col-auto mr-auto">
                  <button className="btn_grn" onClick={handleSave}>
                    Save as draft
                  </button>
                </div>
              </>
            }

            <div className="col-auto mr-auto">
              <button className="btn_red">
                <a href={'/orders_history'}>
                  Cancel
                </a>
              </button>
            </div>
          </div>
        </div>

        <Modal
          show={modal}
          onClose={() => showModal(false)}
        >
          <Modal.Header>Import Fabric Group</Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <input
                  type='radio'
                  label="CSV"
                  id="csv"
                  onChange={() => setOption('csv')}
                  checked={option === 'csv'}
                />
                <label htmlFor="csv" >CSV</label>
              </div>
              <div className="col-12">
                <input
                  type='radio'
                  label="Exiting Group"
                  id="existing"
                  onChange={() => setOption('existing')}
                  checked={option == 'existing'}
                />
                <label htmlFor="existing" >Existing Group</label>
              </div>
            </div>
            <br />
            <input
              id="uploadCsvFile"
              type="file"
              name="file"
              accept=".csv"
              style={{
                width: '0.1px',
                height: '0.1px',
                opacity: 0,
                overflow: 'hidden',
                position: 'absolute',
                zIndex: '-1'
              }}
              placeholder='import Excel'
              onChange={handleImportFile}
              disabled={isImporting}
            />
            {option === 'csv' &&
              <label placeholder='import file' disabled={isImporting} style={{ height: 'auto', marginRight: '2px' }} className="btn_red" htmlFor="uploadCsvFile">
                {
                  isImporting ?
                    <Spinner style={{ display: 'flex', overflow: 'hidden' }} styleClass='text-primary' />
                    :
                    <b style={{ color: 'white' }}>Import file</b>
                }
              </label>
            }
            <br />
            <hr />
            <div className="row">
              <div className="col-6">
                <button className="btn_grn" onClick={handleImport}>Okay</button>
              </div>
              <div className="col-6 col-span-3">
                <button className="btn_red" onClick={() => showModal(false)}>Cancel</button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn_grn" style={{ height: 'auto' }}><a href={template} download><b style={{ color: 'white' }}>Download Excel Template</b></a></button>
          </Modal.Footer>
        </Modal>

        {/* BUTTONS END */}
        <QouteStatusModal order={order} {...order} close={() => setQuoteModal(false)} modal={quoteModal} />
        {order && order.vendor &&
          <PaymentModal order={order} {...order} close={() => setPaymentModal(false)} modal={paymentModal} />
        }
      </div>
    )
}
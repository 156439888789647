import React, { useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Modal from "react-bootstrap/Modal";
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import Spinner from "../../../components/loaders/spinner";
import { addFabric, getFabric, editFabric } from '../../../store/services/fabric';
import { createOrderFabric, getOrderFabrics, updateOrderFabric } from '../../../store/services/order';

// fabricId = id AND fabricID = code
const tempFabrics = { quantity: 0, fabricId: "", fabricID: "", code: "", pattern_name: "", color: "" }
const EditFabricModal = (props) => {
  const token = useSelector(state => state.auth.token)
  const companyId = useSelector(state => state.auth.companyId)

  const [loading, setLoader] = useState(false);
  const [submitClick, setSubmitClick] = useState(false);
  const [isFabricFound, setFound] = useState(true);
  const [orderFabrics, setFabrics] = useState(props.orderFabrics)

  const handleFabric = () => {
    if (orderFabrics.fabricID) {
      setLoader(true)
      setFound(true)
      let filter = { where: { companyId: companyId, code: orderFabrics.fabricID } }
      getFabric({ filter }, token)
        .then(response => {
          setLoader(false)
          let fabric = { ...orderFabrics }
          fabric.fabricId = ""
          fabric.code = ""
          fabric.pattern_name = ""
          fabric.color = ""
          if (Array.isArray(response) && response.length) {
            const orderFabricFilter = { where: { orderId: props.groupId, fabricId: response[0].id } }
            getOrderFabrics({ filter: orderFabricFilter }, token)
              .then(fabricsIn => {
                console.log("FABRIC IN ", fabricsIn)
                if (Array.isArray(fabricsIn) && fabricsIn.length) {
                  fabric.fabricID = ""
                  setFabrics(fabric)
                  Swal.fire({ icon: 'warning', title: "Duplicate fabric!", timer: 2000 })
                } else {
                  fabric.fabricId = response[0].id
                  fabric.fabricID = response[0].code
                  fabric.code = response[0].code
                  fabric.pattern_name = response[0].pattern_name
                  fabric.color = response[0].color
                  setFabrics(fabric)
                }
              })
          } else {
            setFabrics(fabric)
          }
          setFound(false)
        })
    }
  }

  const handleChange = ({ target }) => {
    let fabrics = { ...orderFabrics };
    fabrics[target.name] = target.value;
    setFabrics(fabrics)
  }

  const handleSubmit = async () => {
    if (submitClick) return;
    setSubmitClick(true)
    if (!orderFabrics.fabricId) {
      if ((!orderFabrics.code) || (!orderFabrics.fabricID) || (!orderFabrics.pattern_name) || (!orderFabrics.color)) { setSubmitClick(false); return Swal.fire({ icon: 'warning', title: 'Add a fabric', timer: 1500 }) }
      const payload = {
        code: orderFabrics.code,
        pattern_name: orderFabrics.pattern_name,
        color: orderFabrics.color,
        active: true,
        companyId
      }
      addFabric(payload, token)
        .then(response => {
          let fabric = { ...orderFabrics }
          fabric.fabricId = response.id
          delete fabric.code;
          delete fabric.pattern_name;
          delete fabric.color;
          delete fabric.fabricID;
          console.log("FABRIC TO SEND: ", fabric)
          updateOrderFabrics(fabric)
        })
    } else {
      let fabric = { ...orderFabrics }
      delete fabric.code;
      delete fabric.pattern_name;
      delete fabric.color;
      delete fabric.fabricID;
      updateOrderFabrics(fabric)
    }

  }

  const updateOrderFabrics = (fabric) => {
    updateOrderFabric(orderFabrics.id, fabric, token)
      .then((response) => {
        setSubmitClick(false)
        console.log('response', response)
        props.getOrders()
        props.close()
      })
  }

  return (
    <Modal
      className="container-fluid"
      show={props.modal}
      onHide={props.close}
      centered
      size="xl"
      dialogClassName="modal_width"
    >
      <Modal.Header>
        <h3>Edit Fabric Details</h3>
      </Modal.Header>

      <Modal.Body>
        <form className="container addFabricModal" onSubmit={(e) => e.preventDefault()}>

          <div className="row">
            <div className="col-3">
              Quantity:
            </div>
            <div className="col-9">
              <input
                name="quantity"
                value={orderFabrics.quantity || 0}
                onChange={handleChange}
                placeholder="[Whole number or 'Available Fabric']"
              />
            </div>
          </div>

          {!orderFabrics.fabricId && <div className="row">
            <div className="col-3">
              Fabric Id:
            </div>
            <div className="col-9">
              {!loading ?
                <input
                  name="fabricID"
                  value={orderFabrics.fabricID}
                  placeholder="Enter fabric Id to search"
                  onBlur={handleFabric}
                  onChange={handleChange}
                />
                :
                <Spinner styleClass="text-primary" />
              }
            </div>
          </div>}
          {!isFabricFound &&
            <>
              <div className="row">
                <div className="col-3">
                  Barcode:
                </div>
                <div className="col-9">
                  <input
                    name="code"
                    disabled={orderFabrics.fabricId ? true : false}
                    value={orderFabrics.code || ""}
                    onChange={handleChange}
                    placeholder=""
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-3">
                  Pattern Name:
                </div>
                <div className="col-9">
                  <input
                    name="pattern_name"
                    disabled={orderFabrics.fabricId ? true : false}
                    value={orderFabrics.pattern_name || ""}
                    onChange={handleChange}
                    placeholder=""
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-3">
                  Color Name:
                </div>
                <div className="col-9">
                  <input
                    name="color"
                    disabled={orderFabrics.fabricId ? true : false}
                    value={orderFabrics.color || ""}
                    onChange={handleChange}
                    placeholder=""
                  />
                </div>
              </div>
            </>
          }


          <div className="row">
            <div className="col-3">
              Rubs:
            </div>
            <div className="col-9">
              <input
                name="rubs"
                value={orderFabrics.rubs || ""}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              URL:
            </div>
            <div className="col-9">
              <input
                name="url"
                value={orderFabrics.url || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Grade:
            </div>
            <div className="col-9">
              <input
                name="grade"
                value={orderFabrics.grade || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Content:
            </div>
            <div className="col-9">
              <input
                name="content"
                value={orderFabrics.content || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Cleaning Code:
            </div>
            <div className="col-9">
              <input
                name="cleaning_code"
                value={orderFabrics.cleaning_code || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Cutting Direction:
            </div>
            <div className="col-9">
              <input
                name="cutting_direction"
                value={orderFabrics.cutting_direction || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Repeat Horizontal:
            </div>
            <div className="col-9">
              <input
                name="repeat_horizontal"
                value={orderFabrics.repeat_horizontal || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Repeat Vertical:
            </div>
            <div className="col-9">
              <input
                name="repeat_vertical"
                value={orderFabrics.repeat_vertical || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Correlate:
            </div>
            <div className="col-9">
              <input
                name="correlate"
                value={orderFabrics.correlate || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              finish:
            </div>
            <div className="col-9">
              <input
                name="finish"
                value={orderFabrics.finish || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Performance Logo:
            </div>
            <div className="col-9">
              <input
                name="performance_logo"
                value={orderFabrics.performance_logo || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Supplier Logo:
            </div>
            <div className="col-9">
              <input
                name="supplier_logo"
                value={orderFabrics.supplier_logo || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* <div className="row">
      <div className="col-3">
       Date:
      </div>
      <div className="col-9">
       <input
        name="date"
        value={orderFabrics.date || ""}
        onChange={handleChange}
       />
      </div>
     </div> */}
          <div className="row">
            <div className="col-3">
              Availability:
            </div>
            <div className="col-9">
              <input
                name="availability"
                value={orderFabrics.availability || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Mill/Vendor:
            </div>
            <div className="col-9">
              <input
                name="mill_vendor"
                value={orderFabrics.availability || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              UV Rating:
            </div>
            <div className="col-9">
              <input
                name="uv_rating"
                value={orderFabrics.uv_rating || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              PPI:
            </div>
            <div className="col-9">
              <input
                name="ppi"
                value={orderFabrics.ppi || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              QR Code:
            </div>
            <div className="col-9">
              <input
                name="qr_code"
                value={orderFabrics.qr_code || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Durability:
            </div>
            <div className="col-9">
              <input
                name="durability"
                value={orderFabrics.durability || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Special Comments:
            </div>
            <div className="col-9">
              <input
                name="special_comments"
                value={orderFabrics.special_comments || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Custom 1:
            </div>
            <div className="col-9">
              <input
                name="custom1"
                value={orderFabrics.custom1 || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Custom 2:
            </div>
            <div className="col-9">
              <input
                name="custom2"
                value={orderFabrics.custom2 || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Custom 3:
            </div>
            <div className="col-9">
              <input
                name="custom3"
                value={orderFabrics.custom3 || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Custom 4:
            </div>
            <div className="col-9">
              <input
                name="custom4"
                value={orderFabrics.custom4 || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Custom 5:
            </div>
            <div className="col-9">
              <input
                name="custom5"
                value={orderFabrics.custom5 || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              Collections:
            </div>
            <div className="col-9">
              <input
                name="collections"
                value={orderFabrics.collections || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="btn_Wrapper">
          <button className="btn_grn" onClick={handleSubmit}>
            {
              submitClick ?
                <Spinner styleClass="text-primary" />
                :
                "Submit"
            }
          </button>
          <button className="btn_red" onClick={() => props.close()}>Cancel</button>
        </div>
      </Modal.Footer>

    </Modal>
  )
}

export default EditFabricModal;
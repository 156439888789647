import React, {Component} from 'react'
import axios from "axios";
import {connect} from "react-redux";
import userImage from "../index.png";
import Spinner from "../../../components/loaders/spinner";
import DangerAlert from './../../../components/alerts/dangerAlert'
import { ApiUrl } from '../../../constants';

class userSetupView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: [],
			imageName:null,
			loading: false,
			error: false
		}
		this.toggleLoading=this.toggleLoading.bind(this)
		this.setError =this.setError.bind(this)

	}

	setError(){
		this.setState({
			error: true,
			loading: false
		})
	}

	noImageFound(){
		this.setState({imageName: null})
	}

	toggleLoading(){
		this.setState(prevState => (
			{loading: !prevState.loading}
		));
	}

	componentDidMount() {
		this.toggleLoading()
		const { match: { params } } = this.props;
		const cid = this.props.companyId;
		const token = this.props.token
		//Fetch Picture
		const apiPictureUser = ApiUrl + '/containers/pictures/'+ params.id +'/exists'
		axios.get(apiPictureUser,
			{
				headers: {
					'Authorization': token
				}
			})
			.then((response) => {
				(response.data.filename === "null")?
					this.noImageFound.bind(this):
					this.setState({imageName: response.data.filename})
			})
			.catch(()=> {
				this.noImageFound.bind(this)
			})

		//Fetch Data
		const apiUrlUser = ApiUrl + '/companies/' + cid + '/users/'+ params.id
		axios.get(apiUrlUser,
			{
				headers: {
					'Authorization': token
				}
			})
			.then(response => {
				this.setState({
					user: response.data
				})
				this.toggleLoading()

			})
			.catch(()=>{
				this.setError()
			})
	}

	render() {
		return(
			<div id="page-content-wrapper">
				<div className="container-fluid">
					<div className="row setup_top2 justify-content-md-center">
						<div className="col-12 col-sm-8">
							<div className="box_payment">
								<div className="heding1">VIEW USER DETAILS</div>
								<div className="inside">
									<div className="row justify-content-md-center select_area">
										<div className="col-12 col-sm-7">
											<div className="user_in text-center">
												<img alt=""
												     className="rounded-circle"
												     style={{width: '100px', height: '100px', maxWidth: '100px', maxHeight:'100px'}}
												     src={(this.state.imageName !== null) ?
													     ApiUrl + '/containers/pictures/download/'+ this.state.imageName:
													     userImage}/>
												<br/>
												Avatar
											</div>
											{
												(this.state.loading)?
													<Spinner styleClass="text-primary"/>:
													(
														(this.state.error)?
															<DangerAlert
																message="Data not loaded properly. Please refresh the page"/>:
															<div>
																<div>
																	<div className="row">
																		<div className="col-6 col-sm-7">
																			<label>Account Active:</label>
																		</div>
																		<div className="col-6 col-sm-5">
																			<span className="active_link">{this.state.user.active==="false"?"Inactive":"Active"}</span>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-6 col-sm-7">
																			<label>First Name:</label>
																		</div>
																		<div className="col-6 col-sm-5"><span>{this.state.user.firstName}</span></div>
																	</div>
																	<div className="row">
																		<div className="col-6 col-sm-7">
																			<label>Last Name:</label>
																		</div>
																		<div className="col-6 col-sm-5"><span>{this.state.user.lastName}</span></div>
																	</div>
																	<div className="row">
																		<div className="col-6 col-sm-7">
																			<label>Email:</label>
																		</div>
																		<div className="col-6 col-sm-5"><span>{this.state.user.email}</span></div>
																	</div>
																	<div className="row">
																		<div className="col-6 col-sm-7">
																			<label>Phone:</label>
																		</div>
																		<div className="col-6 col-sm-5"><span>{this.state.user.mobile}</span></div>
																	</div>
																</div>
															</div>


													)
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
	userId: state.auth.userId,
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token
	};
};
export default connect(mapStateToProps)(userSetupView);
import React from 'react';
import { BallBeat } from 'react-pure-loaders';

class BallBeatLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  render() {
    return (
        <BallBeat
          color="#001b91"
          loading={this.state.loading}
        />
    )
  }
}
export default BallBeatLoader

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

import EditModal from './fabric.edit';

import editIcon from '../../img/edit.png';
import deleteIcon from '../../img/delete.png';
import "./style.css"

import { getFabrics } from '../../store/actions/fabric';
import { deleteFabric, editFabric } from '../../store/services/fabric';
import Swal from 'sweetalert2';
import { deleteSwatches, inactiveSwatches } from '../../store/services/swatch';

const FabricsTable = (props) => {
  const fabrics = useSelector(state => state.fabric.fabrics);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFabrics())
  }, [])

  return (
    <div className="orderTrackerContainer">
      <h1>&nbsp;&nbsp;Fabrics</h1>
      <div className="tb_conatiner">
        <table className="table tb_top">
          <thead>
            <tr>
              <th scope="col">Fabric ID</th>
              <th scope="col">Fabric Alias</th>
              <th scope="col">Pattern Name</th>
              <th scope="col">Color</th>
              <th scope="col">Grade</th>
              <th scope="col">Fabric Photo</th>
              <th scope="col">Discontinued Status</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          {/*Rows of pricing data*/}
          <tbody>
            {
              Array.isArray(fabrics) && fabrics.map((item, index) => {
                return <FabricRow {...item} index={index} />
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FabricsTable;

const FabricRow = (props) => {
  const compnayId = useSelector(state => state.auth.compnayId);
  const token = useSelector(state => state.auth.token);
  const [editModal, setEditModal] = useState(false)
  const [discontinued, setDiscontinued] = useState(props.discontinued)
  const [fabric, setFabric] = useState({})

  const dispatch = useDispatch();
  const refresh = () => {
    dispatch(getFabrics())
    setEditModal(false);
  }

  const hanldeDiscontinue = ({ target }) => {
    editFabric(props.id, { discontinued: target.checked }, token)
      .then((response) => {
        if (typeof response === 'object') {
          Swal.fire({ icon: 'success', title: 'Sunccessfully Updated', timer: 2000 })
          setDiscontinued(response.discontinued)
          refresh()
          return (target.checked) ? Swal.fire({ icon: 'question', title: 'Inactive all of its instances?', confirmButtonText: 'inactive', confirmButtonColor: 'orange', showCancelButton: true })
            .then(({ isConfirmed }) => {
              console.log('isConfirmed: ', isConfirmed)
              if (isConfirmed) {
                inactiveSwatches(props.id, token)
              }
            })
            : ''
        }
        Swal.fire({ icon: 'error', title: 'Something went wrong', timer: 2000 })
      })

  }

  const handleDelete = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'If you delete this fabric, all of its instances will be removed along all the instances containing this fabric. Think before you proceed!',
      confirmButtonText: 'Delete!',
      confirmButtonColor: 'red',
      showCancelButton: true
    })
      .then(({ isConfirmed }) => {
        console.log('isConfirmed: ', isConfirmed)
        if (isConfirmed) {
          // deleteFabric(props.id, token)
          editFabric(props.id, { companyId: "" }, token)
            .then(response => {
              if (typeof response === 'object') {
                deleteSwatches(props.id, token)
              }
              refresh()
            })
        }
      })
  }

  return (
    <tr key={props.index}>
      <td>{props.code}</td>
      <td>{props.alias}</td>
      <td>{props.pattern_name}</td>
      <td>{props.color}</td>
      <td>{props.grade}</td>
      <td>{props.photo ? (
        <a href={props.photo}>
          <img style={{ width: "10%" }} src={props.photo} />
        </a>
      ) : ""}</td>
      <td>
        <div className="row justify-content-center">
          <label className="switch ">
            <input
              type="checkbox"
              className="default"
              name="active"
              checked={discontinued}
              onChange={hanldeDiscontinue}
            />
            <span className="slider round" />
          </label>
        </div>
      </td>
      <td>
        <Link to="#" className="edit_link"><img alt="" src={editIcon} onClick={() => { setFabric(props); setEditModal(true) }} /></Link>
      </td>
      <td>
        <Link href="#" className="delete_link" ><img alt="" src={deleteIcon} onClick={handleDelete} /></Link>
      </td>
      {editModal && <EditModal editModal={editModal} getFabrics={refresh} fabric={fabric} close={() => window.location.reload()} />}
    </tr>
  )
}
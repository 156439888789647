import React, { Component } from 'react';
import SwatchDetails from './../inventoryGroupAddGroup/swatchDetails.js';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Spinner from './../../../components/loaders/spinner.js';
import { connect } from 'react-redux';
import { ApiUrl, codeTypes } from '../../../constants'
import Swal from 'sweetalert2';
class InventoryGroupEditGroup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			inventoryTypes: [],
			name: '',
			oldName: '',
			version: '',
			addDate: '',
			modifyDate: '',
			type: 'N/A',
			groupId: null,
			groupIDBool: false,
			scan_status: false,
			swatches: [],
			fetchingData: true,
			saving: false,
			savingErr: false,
			savingErrMsg: "",
			error: false,
			errMsg: '',
			email_status: 'missingFabric',
			scanType: codeTypes,
			isEdited: false,
		}
		this.handleChange = this.handleChange.bind(this);
		this.saveGroup = this.saveGroup.bind(this);
		this.getSwatches = this.getSwatches.bind(this);
		this.handleDeleteSwatch = this.handleDeleteSwatch.bind(this);
		this.changeScanStatus = this.changeScanStatus.bind(this);
	}

	componentDidMount() {
		const { match: { params } } = this.props;
		this.setState({
			groupId: params.id,
			fetchingData: true,
			groupIDBool: true,
		}, () => {
			this.getInventoryTypes().then(() => {
				this.getInventoryGroup().then(() => {
					this.setState({
						fetchingData: false
					})
				}).catch((err) => {
					this.setState({
						fetchingData: false,
						error: true,
						errMsg: "Inventory Group Details not available"
					})
				})
			})
				.catch((err) => {
					this.setState({
						fetchingData: false,
						error: true,
						errMsg: "Inventory Group Details not available"
					})
				})
		})


	}

	handleDeleteSwatch(swatchId) {
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Delete Swatch"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		const url = ApiUrl + "/Swatches/" + swatchId
		return new Promise((resolve, reject) => {
			axios.delete(url,
				{
					headers: {
						'Authorization': this.props.token
					}
				})
				.then(response => {
					this.setState({
						groupIDBool: true,
					}, function () {
						this.saveGroup(); // To update group updatedAt for mobile sycn purpose.
						resolve("Swatch Deleted")
						this.getSwatches()
					})
				})
				.catch(err => {
					console.log(err);
					reject("Swatch Can Not be deleted")
					return null;
				})
		})
	}

	getInventoryGroup = () => {
		const url = ApiUrl + '/InventoryGroups/' + this.state.groupId;
		return new Promise((resolve, reject) => {
			axios.get(url,
				{
					headers: {
						'Authorization': this.props.token
					}
				})
				.then(response => {
					this.setState({
						oldName: response.data.name,
						name: response.data.name,
						version: response.data.version,
						addDate: response.data.createdAt.split("T")[0],
						modifyDate: response.data.updatedAt.split("T")[0],
						type: response.data.inventoryTypeId,
						scan_status: response.data.active,
						email_status: response.data.send_email,
						scanType: response.data.scanType
					}, function () {
						return this.getSwatches().then(() => {
							resolve("inventory group info fetched");
						}).catch(() => {
							reject("swatch details not fetched");
						})
					})
				})
				.catch(err => {
					console.log(err);
					reject("Inventory Group Details not fetched")
					return null;
				})
		})
	}
	//handleChange function for all inputs except inventorytypes
	handleChange(event) {
		event.persist();
		this.setState({ [event.target.name]: event.target.value, isEdited: true })
	}
	//Modifies the group according to the information in state, sets groupIdBool to true
	saveGroup = async (event) => {
		event && event.preventDefault();
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Edit Group"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		this.setState({
			saving: true,
			savingErr: false,
		})
		const group_data = {
			name: this.state.name.trim(),
			version: this.state.version,
			active: this.state.scan_status,
			inventoryTypeId: this.state.type,
			send_email: this.state.email_status,
			scanType: this.state.scanType
		};

		try {
			let filterName = await axios.get(ApiUrl + '/InventoryGroups/', { params: { filter: { where: { "name": this.state.name.trim(), "companyId": this.props.companyId } } } })
			if (this.state.oldName !== this.state.name && filterName && Array.isArray(filterName.data) && filterName.data.length > 0) {
				this.setState({
					saving: false,
					savingErr: false,
				})
				return Swal.fire({ icon: 'error', title: 'A swatch inventory group with that name already exists', timer: 2000 })
			}
		} catch (error) {
			this.setState({
				saving: false,
				savingErr: false,
			})
			return Swal.fire({ title: "Something went wrong! try again later.", timer: 1500 })
		}

		axios.patch(ApiUrl + `/InventoryGroups/` + this.state.groupId, group_data,
			{
				headers: {
					'Authorization': this.props.token
				}
			})
			.then(res => {
				this.setState({
					groupId: res.data.id,
					oldName: res.data.name.trim(),
					groupIDBool: true,
					addDate: (new Date(res.data.createdAt)).toLocaleDateString(),
					saving: false,
					savingErr: false,
					modifyDate: (new Date(res.data.updatedAt)).toLocaleDateString(),
				})
			})
			.catch(err => {
				console.log(err);
				this.setState({
					saving: false,
					savingErr: true,
					savingErrMsg: "Changes not saved please check you internet connection"
				}, () => {
					setTimeout(() => { this.setState({ savingErr: false }) }, 2000)
				})
				return null;
			})
	}
	//takes group Id as parameter and stores associated available swatches in state
	getSwatches = () => {
		//console.log(swatchGroupId);
		const url = ApiUrl + "/InventoryGroups/" + this.state.groupId + "/swatches";
		const params = { filter: { include: ['fabrics'] } }
		return new Promise((resolve, reject) => {
			axios
				.get(url,
					{
						params,
						headers: {
							'Authorization': this.props.token
						}
					})
				.then(response => {
					this.setState({
						groupIDBool: true,
						swatches: response.data
					}, () => {
						resolve("swatches fetched")
					})
				})
				.catch(err => {
					console.log(err);
					reject("swatch details not found")
					return null;
				})
		})
	}
	//this func will set this.state.inventoryTypes to available types
	getInventoryTypes = () => {
		const url = ApiUrl + "/InventoryTypes"
		return new Promise((resolve, reject) => {
			axios
				.get(url,
					{
						headers: {
							'Authorization': this.props.token
						}
					})
				.then(response => {
					this.setState({
						inventoryTypes: response.data,
						type: response.data[0].id
					}, () => {
						resolve("inventory types fetched")
					})
				})
				.catch(err => {
					console.log(err);
					reject("unable to fetch Inventory types")
					return null;
				})
		})
	}
	changeScanStatus(event) {
		event.persist();
		this.setState({ [event.target.name]: event.target.checked })
	}
	render() {
		return (
			<div id="page-content-wrapper">
				<div className="container-fluid">
					<div className="row setup_top setup_top">
						<div className="col-12">
							<ul>
								<li style={{ flexGrow: '1' }}>SWATCH INVENTORY GROUP</li>
							</ul>
						</div>

						<div className="col-12">
							<div className="in_hdr">
								<div className="row">
									<div className="col-auto mr-auto">Swatch Inventory Group Header</div>
									<div className="col-auto">
										<label className="switch float-left mr-2">
											<input type="checkbox" name='scan_status' onChange={this.changeScanStatus} checked={this.state.scan_status} className="default" />
											<span className="slider round" />
										</label>
										Active Status
									</div>
								</div>
							</div>
							{
								!this.state.fetchingData && !this.state.error ?
									<div>
										<div className="grp_frm">
											<form className="col-12" onSubmit={this.saveGroup}>
												<div className="form-row">
													<div className="col-sm-4">
														<div className="row">
															<label className="col-4">Group Name:</label>
															<div className="col-8">
																<input
																	type="text"
																	name="name"
																	className="form-control"
																	value={this.state.name}
																	onChange={this.handleChange}
																	placeholder={this.state.name}
																	required="required"
																/>
															</div>
														</div>
													</div>
													<div className="col-sm-4">
														<div className="row">
															<label className="col-2">Type:</label>
															<div className="col-10">
																<select className="browser-default custom-select" value={this.state.type} name="type" onChange={this.handleChange}>
																	{
																		//available inventory group types will be displayed
																		this.state.inventoryTypes.map(item => {
																			return (
																				<option value={item.id}>
																					{item.name}
																				</option>
																			)
																		})
																	}
																</select>
															</div>
														</div>
													</div>

													<div className="col-sm-4">
														<div className="row">
															<label className="col-2">Version:</label>
															<div className="col-10">
																<input
																	type="text"
																	name="version"
																	onChange={this.handleChange}
																	value={this.state.version}
																	className="form-control"
																	placeholder={this.state.version}
																	required="required"
																/>
															</div>
														</div>
													</div>

												</div>
												<div className="form-row">
													<div className="col-sm-4">
														<div className="row">
															<label className="col-4">Add Date:</label>
															<div className="col-8">
																<div className="input-group date" data-provide="datepicker">
																	<input
																		type="text"
																		className="form-control"
																		placeholder="Read Only"
																		disabled={true}
																		value={this.state.addDate}
																		onChange={this.handleChange}
																		name="addDate"
																		readOnly
																	/>
																	<div className="input-group-addon">

																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="col-sm-4">
														<div className="row">
															<label className="col-4">Modify Date:</label>
															<div className="col-8">
																<div className="input-group date" data-provide="datepicker">
																	<input
																		type="text"
																		className="form-control"
																		placeholder={this.state.modifyDate ? "" : "Read Only"}
																		value={this.state.modifyDate}
																		onChange={this.handleChange}
																		name="modifyDate"
																		disabled={true}
																		readOnly
																	/>
																	<div className="input-group-addon">

																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="col-sm-4">
														<div className="row">
															<label className="col-4">Send Mail:</label>
															<div className="col-8">
																{/* <label className="switch float-right mr-2">
																	<input type="checkbox" name='email_status' onChange={this.changeScanStatus} checked={this.state.email_status} className="default" />
																	<span className="slider round" />
																</label> */}

																<select defaultValue={this.state.email_status} name='email_status' aria-label="email_status" className="form-control" onChange={this.handleChange}>
																	<option value="missingFabric" > 	Send If Missing Fabrics  </option>
																	<option value="All" > Send All </option>
																	<option value="false" > Don't Send Mail </option>
																</select>

															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6">
														<div className="row">
															<label className="col-2">Scan Type:</label>
															<div className="col-4">
																<select className="browser-default custom-select" value={this.state.scanType} name="scanType" onChange={this.handleChange}>
																	{
																		codeTypes.map((type) => {
																			return <option value={type}>{type}</option>
																		})
																	}
																</select>
															</div>
														</div>
													</div>
												</div>
												<br></br>
												<div className="btn_arte" style={{ display: 'block' }}>
													<div className="row">
														<div className="col-auto mr-auto">
															{this.state.isEdited &&
																<button className="btn_grn" disabled={this.state.saving} type="Submit" onSubmit={this.saveGroup}>
																	{
																		this.state.saving ?
																			<Spinner style={{ display: 'flex', overflow: 'hidden' }} styleClass='text-primary' />
																			:
																			"Save Changes"
																	}
																</button>}
														</div>
													</div>
												</div>
												{
													this.state.savingErr ?
														<Alert key='1' variant='danger'>
															{this.state.savingErrMsg}
														</Alert>
														:
														null
												}
											</form>
										</div>
										<br></br>
										<SwatchDetails swatchList={this.state.swatches} handleDeleteSwatch={this.handleDeleteSwatch} handleUpdateGroup={this.saveGroup} group_name={this.state.name} groupId={this.state.groupId} />
									</div>
									:
									<div style={{ position: 'absolute', width: '100%' }}>
										<Spinner styleClass="text-primary" />
									</div>
							}
							{
								this.state.error ?
									<Alert key='1' variant='danger'>
										{this.state.errMsg}
									</Alert>
									:
									null
							}

						</div>

					</div>

				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user,
		userId: state.auth.userId
	};
};

export default connect(mapStateToProps)(InventoryGroupEditGroup)
import React, { Component } from 'react'
// import { Link, Redirect } from "react-router-dom"
import UserRow from './userSetupRow'
import axios from 'axios'
import { connect } from "react-redux";
import Spinner from '../../../components/loaders/spinner'
import BallBeatLoader from "../../../components/loaders/ballBeatLoader";
import UpgradeSubscriptionPopup from './upgradeSubscriptionPopup'

import SuccessAlert from './../../../components/alerts/sucessfulAlert'
import DangerAlert from './../../../components/alerts/dangerAlert'
import Swal from "sweetalert2";
import { getUserCSV } from '../../../store/services/users';
import { ApiUrl } from '../../../constants';

const compareByDate = (date1, date2, sort) => {
	if (!date1 || date1 < date2) return -sort;
	if (!date2 || date1 > date2) return sort;
	return 0
}

class userSetupMain extends Component {
	//setting data as user information in the component state
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			backup: [],
			loading: false,
			error: false,
			filter: null,
			plan: null,
			company: null,
			planError: false,
			displayPopup: false,
			planLoading: false,
			downloadLink: "",
			minUser: null,
			maxUser: null
		}
		this.refresh = this.refresh.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
		this.setError = this.setError.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.showPopup = this.showPopup.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.refreshCompanyDetails = this.refreshCompanyDetails.bind(this);
		this.compoundRefresh = this.compoundRefresh.bind(this);
		this.handleAddUser = this.handleAddUser.bind(this);
	}

	//fetching user data on component mount
	componentDidMount() {
		this.toggleLoading()
		this.setState({ planLoading: true })
		this.refresh()
			.then(() => {
				this.refreshCompanyDetails()
					.then(() => {
						this.toggleLoading()
						this.setState({ planLoading: false })
					})
					.catch(() => { this.setError() })
			})
			.catch(() => { this.setError() })
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.plan !== prevState.plan && this.state.plan !== null) {
			this.setState({
				maxUser: this.state.plan.max_users
			})
		}

	}

	showPopup() {
		this.setState({ displayPopup: true });
	}

	closePopup() {
		this.setState({ displayPopup: false });
	}

	toggleLoading() {
		this.setState(prevState => ({ loading: !prevState.loading }));
	}

	setError() {
		this.setState({ error: true, loading: false })
	}

	refresh() {
		const cid = this.props.companyId;
		const token = this.props.token
		const apiUrlUser = ApiUrl + '/Companies/' + cid + '/users'
		return new Promise((resolve, reject) => {
			axios.get(apiUrlUser,
				{
					headers: {
						'Authorization': token
					}
				})
				.then(response => {
					this.setState({
						data: response.data,
						backup: response.data
					}, () => resolve(true))
				})
				.catch(e => {
					/**
						* fetcing of user data is failed
						* */
					this.setError()
					reject(false)
				})
		})
	}

	refreshCompanyDetails() {
		const cid = this.props.companyId;
		const token = this.props.token
		const apiURLCompany = ApiUrl + '/Companies/' + cid;
		return new Promise((resolve, reject) => {
			axios.get(apiURLCompany,
				{
					headers: {
						'Authorization': token
					}
				})
				.then(response => {
					this.setState({
						company: response.data
					}, () => resolve(true))
				})
				.catch(e => {
					/**
						* fetcing of user data is failed
						* */
					this.setError()
					reject(false)
				})
		})
	}

	compoundRefresh() {
		return new Promise((resolve, reject) => {
			this.refreshCompanyDetails()
				.then(() => {
					resolve(true);
				})
				.catch(() => {
					reject(false)
				})
		})
	}

	setFilter(event) {
		event.persist();
		this.setState({ filter: event.target.value })
	}

	handleAddUser() {
		const { company } = this.state;
		if (company && company.isAnnual && company.unassignedSubscriptions === 0 && company.activeMembers >= company.maxUsers) {
			Swal.fire({
				title: 'Annual Subscription',
				text: 'Annual subscribers cannot add users this way. Please contact support@swatchmasters.com',
				icon: 'warning',
			});
		} else if (company && (company.unassignedSubscriptions === 0 && (company.maxUser === this.state.data || company.activeMembers >= company.maxUsers))) {
			Swal.fire({
				icon: 'error',
				title: 'Limit Reach',
				text: 'Please subscribe to another package to add more users.',
				timer: 1950
			})
		} else if (company) {
			Swal.fire({
				title: "Add User",
				icon: "question",
				html: `<div style="text-align: left;">
				<p>User to add: <b> 1 </b> <p> 
				<p>Monthly Subscription increase: <b> 0% </b> <p>
				<p>Current Subscription: <b> ${this.props.company?.activeMembers} user(s) </b> <p>
				<p>New Subscription: <b> ${this.props.company?.activeMembers + 1} user(s) </b> <p>
				</div>
				`,
				confirmButtonText: "Add",
				cancelButtonText: "Cancel",
				showCancelButton: true,
			})
				.then((result) => {
					if (result.isConfirmed) {
						this.props.history.push('/user-setup/add-user')
					}
				})
		}
		// console.log(this.props)

	}

	handleSort = ({ target }) => {
		const { value } = target;
		if (!value) return this.setState({ data: this.state.backup })
		var unsortArray = [...this.state.data];
		unsortArray = unsortArray.sort((a, b) => (new Date(a[value])).getTime() - (new Date(b[value])).getTime());
		this.setState({ data: unsortArray });
	}

	handleDownloadUserCsv = () => {
		getUserCSV(this.props.companyId, this.props.token)
			.then(response => {
				if (typeof response === 'object') {
					console.log("RESPONSE: ", response)
					const downloadLink = ApiUrl + "/containers/" + response.container + "/download/" + response.fileName;
					this.setState({ downloadLink })
				}
			})
	}

	//renderer renders the user information table. grabs the rows of user
	render() {
		const { company } = this.state;
		const { maxUsers, activeMembers } = company || { maxUser: 0, activeMembers: 0 };
		return (
			<div id="page-content-wrapper">
				<div className="container-fluid">
					<div className="row setup_top">
						<div className="col-12">
							<ul>
								<li style={{ flexGrow: 1 }}>
									USER SETUP
									<p>UNASSIGNED</p>
								</li>
								<li style={{ flexGrow: 2 }}>
									{(!company) ?
										<BallBeatLoader /> :
										`0-${maxUsers} Users are allowed for your subscription`
									}
									<p>{maxUsers - activeMembers || 0}</p>
								</li>
								<li style={{ flexGrow: 8 }}>
									<div className="row">
										<div className="col-sm-7">
											<div className="input-group form-group fild2">
												<div className="input-group-prepend">
													<span className="input-group-text"><i className="fa fa-search" /></span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="Type to filter"
													value={this.state.filter}
													onChange={this.setFilter}
												/>
											</div>
											{/* <div className="input-group form-group fild2"> */}
											<select className="input-group form-control form-group" onChange={this.handleSort}>
												<option value="">Unsorted</option>
												<option value="createdAt">Sort by created date</option>
												<option value="updatedAt">Sort by updated date</option>
											</select>
											{/* </div> */}
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div className="col-12">
							<div className="table-responsive my-4 scroll_sec">
								<table className="table tb_top">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Avatar</th>
											<th scope="col">Name</th>
											<th scope="col">Email</th>
											<th scope="col">Created On</th>
											<th scope="col">Status</th>
											{/*<th scope="col">View</th>*/}
											<th scope="col">Edit</th>
											<th scope="col">Delete</th>
										</tr>
									</thead>
									<tbody>
										{
											(this.state.loading) ?
												<div style={{ height: 'auto', alignContent: 'center', width: '100%' }}>
													<div style={{ position: 'absolute', width: '100%' }}>
														<Spinner styleClass="text-primary" />
													</div>
												</div>
												:
												(this.state.error) ?
													<tr>
														<td colSpan={9}>
															<DangerAlert message="Data not loaded properly. Please refresh the page" />
														</td>
													</tr> :
													(this.state.data.length === 0) ?
														<tr>
															<td colSpan={9}>
																<SuccessAlert message="No user added yet" />
															</td>
														</tr> :
														this.state.data.map((item, index) => {
															if (this.state.filter === null || this.state.filter === "") {
																return <UserRow index={index} item={item} refresh={this.refresh} />
															}
															else {
																if ((item.firstName + " " + item.lastName).toLocaleLowerCase().includes((this.state.filter).toLocaleLowerCase()) || item.email.toLocaleLowerCase().includes(this.state.filter.toLocaleLowerCase())) {
																	return <UserRow index={index} item={item} refresh={this.refresh} />
																}
																else {
																	return null;
																}
															}
														})
										}
									</tbody>
									<tfoot>
										<button className="btn btn-primary" onClick={this.handleDownloadUserCsv}>Create Users CSV</button>
										{this.state.downloadLink &&
											<a className="btn btn-secondary" href={this.state.downloadLink} > download </a>
										}
									</tfoot>
								</table>
							</div>
						</div>

						<div className="col-12">
							<div className="float-right">
								{
									!(this.state.loading) ? maxUsers - (activeMembers || 0) > 0 ?
										<button className="btn_grn" onClick={this.handleAddUser}>
											<i className="fa fa-plus" />
											Add User
										</button>
										:
										<div className="alert alert-danger" role="alert">
											User Limit Reached. Upgrade subscription to add more users.
										</div>
										: 
										""
								}
							</div>
						</div>

					</div>
				</div>
				{
					(this.state.planLoading || this.state.company === null) ?
						null :
						<UpgradeSubscriptionPopup
							displayStatus={this.state.displayPopup}
							closePopup={this.closePopup}
							plans={this.state.plan}
							current={this.state.company.planId}
							refreshComp={this.compoundRefresh}
							numberOfUsers={this.state.data.length} />
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		userId: state.auth.userId,
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		company: state.auth.company,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user,
	};
};

export default connect(mapStateToProps)(userSetupMain);

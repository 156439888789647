import React, { Component } from 'react'
//Icons
import editIcon from '../../../img/edit.png'
import deleteIcon from '../../../img/delete.png'
import { Link } from 'react-router-dom'
import axios from "axios";
import { connect } from "react-redux";


import userImage from './../index.png'
import Spinner from "../../../components/loaders/ballBeatLoader";
import DanderAlert from "../../../components/alerts/dangerAlert";
import DeletePopup from "../deletePopup/deletePopup";
import Swal from 'sweetalert2';
import { ApiUrl } from '../../../constants';

class userSetupRow extends Component {
	constructor(props) {
		super(props)
		this.state = {
			imageName: null,
			deleting: false,
			deletingFailed: false
		}
		this.deleteUser = this.deleteUser.bind(this)
		this.toggleDeleting = this.toggleDeleting.bind(this)
		this.showDeletingFailed = this.showDeletingFailed.bind(this)
	}

	componentDidMount() {
		const token = this.props.token
		const apiPictureUser = ApiUrl + '/containers/pictures/' + this.props.item.id + '/exists'
		axios.get(apiPictureUser,
			{
				headers: {
					'Authorization': token
				}
			})
			.then((response) => {
				(response.data.filename === "null") ?
					this.noImageFound.bind(this) :
					this.setState({ imageName: response.data.filename }
					)
			})
			.catch(
				this.noImageFound.bind(this)
			)
	}

	noImageFound() {
		this.setState({ imageIsAvailable: null })
	}

	showDeletingFailed() {
		this.setState({ deletingFailed: true })
		setTimeout(() => { this.setState({ deletingFailed: false }) }, 2000)
	}

	toggleDeleting() {
		this.setState(prevState => ({
			deleting: !prevState.deleting
		}));
	}
	openPopup() {
		this.setState({
			deletePopupState: true
		})
	}

	closePopup() {
		this.setState({
			deletePopupState: false
		})
	}

	deleteUser() {
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Delete User"].checked) {
				this.props.refresh().then(() => {
					this.toggleDeleting()
				})
				return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
			}
		}
		const cid = this.props.companyId;
		const token = this.props.token
		const apiUrlUser = ApiUrl + '/companies/' + cid + '/users/' + this.props.item.id
		axios.delete(apiUrlUser,
			{
				headers: {
					'Authorization': token
				}
			})
			.then(() => {
				this.setState({ deleting: true })
				this.props.refresh().then(() => {
					this.toggleDeleting()
				})
				/**
					* Show popup for the successful deleting
					* */
			})
			.catch(
				/**
					* Show popup for the failed deleting
					* */
				() => {
					this.showDeletingFailed()
					this.toggleDeleting()
				}
			)
	}

	render() {
		let name = this.props.item.firstName + " " + this.props.item.lastName;
		name = name === 'undefined undefined' ? this.props.item.username : name;
		return (
			(this.state.deletingFailed) ?
				<tr>
					<td colSpan="8">
						<DanderAlert message="Failed to delete" />
					</td>
				</tr> :
				(this.state.deleting) ?
					<tr>
						<td colSpan="8">
							<Spinner styleClass="text-primary" />
						</td>
					</tr> :
					<tr>
						<td>
							<h6>{this.props.index + 1}</h6>
						</td>
						<td>
							<img
								className="rounded-circle"
								style={{ width: '100px', height: '100px', maxWidth: '100px', maxHeight: '100px' }}
								src={(this.state.imageName !== null) ?
									ApiUrl + '/containers/pictures/download/' + this.state.imageName :
									userImage
								}
								alt="" />
						</td>
						<td><h6>{name}</h6></td>
						<td><h6>{this.props.item.email}</h6></td>
						<td><h6>{(new Date(this.props.item.createdAt)).toLocaleDateString()}</h6></td>
						{/* <td><h6>{this.props.item.createdAt.split('T')[0]}</h6></td> */}
						<td>
							<button
								className="active_link btn btn-link">
								<h6>{this.props.item.active ? "Active" : "Inactive"}</h6>
							</button>
						</td>
						{/*<td>
							<Link to={`/user-setup/view-user/${this.props.item.id}`} className = "view_link"><img alt="" src={viewIcon}/></Link>
						</td>*/}
						<td>
							<Link to={`/user-setup/edit-user/${this.props.item.id}`} className="edit_link"><img alt="" src={editIcon} /></Link>
						</td>
						<td>
							<Link href={null} className="delete_link" onClick={this.openPopup.bind(this)}><img alt="" src={deleteIcon} /></Link>
						</td>

						<DeletePopup state={this.state.deletePopupState} close={this.closePopup.bind(this)} deleteUser={this.deleteUser} toggleDelete={this.toggleDeleting} />
					</tr>

		)
	}
}
const mapStateToProps = state => {
	return {
		apiUrl: state.auth.apiUrl,
		userId: state.auth.userId,
		companyId: state.auth.companyId,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user,
	};
};
export default connect(mapStateToProps)(userSetupRow);
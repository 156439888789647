import axios from "axios";
import { ApiUrl } from "../../constants";
const url = ApiUrl + '/Fabrics/'
export const addFabric = (payload, token) => {
  try {
    return axios.post(url, payload, { headers: { 'authorization': token } })
      .then((response) => { return response.data })
      .catch(err => { return err.message })
  } catch (error) {
    console.log('err: catch-block: addFabric: ', error.message)
    return error.message
  }
}

export const editFabric = (fabricId, payload, token) => {
  try {
    return axios.patch(url + fabricId, payload, { headers: { 'authorization': token } })
      .then((response) => { return response.data })
      .catch(err => { return err.message })
  } catch (error) {
    console.log('err: catch-block: editFabric: ', error.message)
    return error.message
  }
}

export const deleteFabric = (fabricId, token) => {
  try {
    return axios.delete(url + fabricId, { headers: { 'authorization': token } })
      .then((response) => { return response.data })
      .catch(err => { return err.message })
  } catch (error) {
    console.log('err: catch-block: editFabric: ', error.message)
    return error.message
  }
}

export const uploadFabricPhoto = (fabricId, payload, token) => {
  try {
    return axios.post(url + fabricId + "/uploadFabricImg", payload, { headers: { 'authorization': token, 'Content-Type': 'multipart/form-data' } })
      .then((response) => { return response.data })
      .catch(err => { return err.message })
  } catch (error) {
    console.log('err: catch-block: uploadFabricPhoto: ', error.message)
    return error.message
  }
}

export const getFabric = (params, token) => {
  try {
    return axios.get(url, { params, headers: { 'authorization': token } })
      .then((response) => { return response.data })
      .catch(err => { return err.message })
  } catch (error) {
    console.log('err: catch-block: getFabric: ', error.message)
    return error.message
  }
}

export const getCompanyDataLimit = (id, token) => {
  return axios.get(url + id + "/getDataLimits", { headers: { 'authorization': token } })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log("err: updateCompany : ", err.messsage)
      return err.messsage
    })
}
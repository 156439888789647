import axios from 'axios';
import { ApiUrl } from '../../constants';
const Url = ApiUrl + '/Companies/';

export const updateUserTimeZone = (data, token) => {
  return axios.put(Url + data.id, data, { headers: { "Authorization": token } })
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log("ERR: ", err);
      return err.message
    })
}
export const getUserCSV = (cid, token) => {
  return axios.get(Url + cid + "/createUsersCSV", { headers: { "Authorization": token } })
    .then((response) => {
      return response.data.data
    })
    .catch((err) => {
      console.log("ERR: ", err);
      return err.message
    })
}

export const updateUserByCompanyId = (userId, companyId, payload, token) => {
  if (!userId || !companyId || !token) return false;
  const url = Url + companyId + '/users/' + userId
  return axios.put(url, payload, { headers: { "Authorization": token } })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      console.log("ERR: ", err);
      return err.message
    })
}
import React, { Component } from 'react'
import AudioIcon from '../../../img/audio.png'
import DeleteIcon from '../../../img/delete.png'
import { connect } from "react-redux";
import axios from "axios";
import Spinner from '../../../components/loaders/spinner'
import DanderAlert from "../../../components/alerts/dangerAlert";
import Swal from 'sweetalert2';
import { ApiUrl } from '../../../constants';
class scanAlertItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deleting: false,
			item: this.props.item,
			play: false,
			deletingFailed: false,
			settingDefault: false,
			settingDefaultFailed: false

		}
		this.deleteSound = this.deleteSound.bind(this)
		this.showDeletingFailed = this.showDeletingFailed.bind(this)
		this.showSettingDefaultFailed = this.showSettingDefaultFailed.bind(this)
	}

	audioSrc = ApiUrl + this.props.item.url;
	audio = new Audio(this.audioSrc);

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.item !== prevProps.item) {
			this.setState({
				item: this.props.item
			})
		}
	}

	showDeletingFailed() {
		this.setState({ deletingFailed: true })
		setTimeout(() => { this.setState({ deletingFailed: false }) }, 2000)
	}

	showSettingDefaultFailed() {
		this.setState({ deletingFailed: true })
		setTimeout(() => { this.setState({ deletingFailed: false }) }, 2000)
	}

	componentDidMount() {
		this.audio.addEventListener('ended', () => this.setState({ play: false }));
	}

	componentWillUnmount() {
		this.audio.removeEventListener('ended', () => this.setState({ play: false }));
	}

	togglePlay = () => {
		this.setState({ play: !this.state.play }, () => {
			this.state.play ? this.audio.play() : this.audio.pause();
		});
	}

	deleteSound() {
		const { permissions, user, userId } = this.props;
		if (user && user.id.toString() === userId.toString()) {
			if (!permissions.length || !this.props.permissions[0]["Delete Scan Sounds"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		
		this.setState({
			deleting: true
		})
		const apiUrlSound = ApiUrl + '/SoundFiles/' + this.state.item.id
		const token = this.props.token
		axios.delete(apiUrlSound,
			{
				headers: {
					'Authorization': token
				}
			})
			.then(response => {
				this.props.refresh().then(() => {
					this.setState({ deleting: false })
				})
			})
			.catch(e => {
				this.setState({ deleting: false })
				this.showDeletingFailed()
				/**
					* Add a popup of failed delete
					*/
			})
	}

	setDefault() {
		if (!this.state.item.default) {
			const { permissions, user, userId } = this.props;
			if (user && user.id.toString() === userId.toString()) {
				if (!permissions.length || !this.props.permissions[0]["Edit Scan Sounds"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
			}

			this.setState({
				settingDefault: true
			})
			let apiUrlSetDefault = ApiUrl + '/SoundFiles/' + this.state.item.id + '/makeDefault';
			axios.post(apiUrlSetDefault, null,
				{
					headers: {
						'Authorization': this.props.token,
					}
				})
				.then((response) => {
					this.props.refresh().then(() => {
						this.setState({ settingDefault: false })
					})
				})
				.catch((err) => {
					this.props.refresh().then(() => {
						this.setState({ settingDefault: false })
					})
					this.showSettingDefaultFailed()
				})
		}
	}

	render() {
		return (
			(this.state.deletingFailed) ?
				<div style={{ position: 'relative', minHeight: '50px', height: '50px', alignContent: 'center' }}>
					<div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
						<DanderAlert message="Failed to delete" />
					</div>
				</div> :
				(this.state.settingDefaultFailed) ?
					<div style={{ position: 'relative', minHeight: '50px', height: '50px', alignContent: 'center' }}>
						<div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
							<DanderAlert message="Failed to set default" />
						</div>
					</div> :
					(this.state.deleting || this.state.settingDefault) ?
						<Spinner styleClass="text-primary" /> :
						<div className="form-group">
							<div className="row">
								<div className="col-8 col-md-8 overflow-hidden">
									<div className="row custom-control custom-radio custom-control-inline">
										<input
											type="radio"
											className="custom-control-input"
											id={this.state.item.id}
											name="example"
											value="customEx"
											checked={this.props.default === this.state.item.id}
											onClick={this.setDefault.bind(this)}
										/>
										<label className="custom-control-label" htmlFor={this.state.item.id}>
											<h7 style={{ marginLeft: "20px" }}>{this.state.item.name.split("_")[1]}</h7>
										</label>
									</div>
								</div>
								<div className="col-2 col-md-2 text-center txt_sm" style={{ alignItems: 'center', display: 'flex' }}>
									<div className="row">
										<button className="col-12 col-lg-4" type={"button"} onClick={this.togglePlay}><img alt="" src={AudioIcon} /></button>
										<label className="hide col-12 col-lg-8">Sound</label>
									</div>
								</div>
								{
									(!this.state.item.default) ?
										<div className="col-2 col-md-2 text-center txt_sm" style={{ alignItems: 'center', display: 'flex' }}>
											<div className="row">
												<button className="col-12 col-lg-4" type={"button"} onClick={this.deleteSound} >
													< img alt="" src={DeleteIcon} />
												</button>
												<label className="hide col-12 col-lg-8">Delete</label>
											</div>
										</div> :
										null
								}
							</div>
						</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		userId: state.auth.userId,
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user,
	};
};

export default connect(mapStateToProps)(scanAlertItem);
import React,{Component} from 'react';


//import icon
import Logo from './../../img/logo.png'

export default class LoadingScreen extends Component {

	render() {
		return(
			<div className="container" style={{height: '100vh', display: 'grid', alignContent: 'center', justifyContent: 'center'}}>
					<img src={Logo} style={{ maxWidth: "414px"}} alt="logo" />
			</div>
		);
	}
}
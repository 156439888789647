import React, { Component } from 'react';
import SwatchDetailsRow from './swatchDetailsRow.js';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import image from './../../../templateFile/csvTemplateFile.csv';
import AddSwatchDetails from './addSwatchDetails.js';
import { connect } from 'react-redux';
import Spinner from './../../../components/loaders/spinner.js';
import Swal from 'sweetalert2';
import { ApiUrl } from '../../../constants/index.js';


class swatchDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			swatches: [],
			addingNew: false,
			csv: null,
			openAddPopup: false,
			fetchingData: true,
			importInProgress: false,
			importErr: false,
			importErrMsg: '',
			error: false,
			errMsg: '',
			email_status: false,
			inpKey: null,
		}
		this.handleNewSwatch = this.handleNewSwatch.bind(this);
		this.handleImport = this.handleImport.bind(this);
		this.importSwatches = this.importSwatches.bind(this);
		this.getSwatches = this.getSwatches.bind(this);
		this.handleDeleteSwatch = this.handleDeleteSwatch.bind(this);
		this.closeAddPopup = this.closeAddPopup.bind(this);

	}

	componentDidMount() {
		this.setState({
			fetchingData: true,
			error: false,
			errMsg: '',
			email_status: this.props.email
		})
		this.getSwatches().then(() => {
			this.setState({
				fetchingData: false,
				error: false,
				errMsg: ''
			})
		}).catch((err) => {
			this.setState({
				fetchingData: false,
				error: true,
				errMsg: 'Swatch details not fetched please check your internet connection'
			})
		})
	}
	closeAddPopup = () => {
		return new Promise((resolve, reject) => {
			this.setState({
				openAddPopup: false
			}, () => {
				resolve("Swatches Updated")
			})
		})
	}
	componentDidUpdate() {
		//console.log("swatchList : ",this.props.swatchList);
	}
	handleNewSwatch = () => {
		this.setState({
			openAddPopup: true
		})
	}
	//Once the file has been uploaded it's saved in this.state.csv and imported from backend
	handleImport = (event) => {
		event.preventDefault();
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Import Swatch"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		const file = event.target.files[0];

		//event.target.files ;
		if (event.target.files.length > 0)
			this.setState({
				importErr: false,
				importInProgress: true,
				csv: file
			}, () => {
				this.importSwatches()
			})
	}

	handleDeleteSwatch(swatchId) {
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Delete Swatch"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		return new Promise((resolve, reject) => {
			this.props.handleDeleteSwatch(swatchId)
				.then(() => {
					this.getSwatches().then(() => {
						resolve("Swatch Deleted");
					}).catch(() => {
						reject("Swatch Can Not be deleted");
					})
				}).catch(() => {
					reject("Swatch Can Not be deleted");
				})

		})

	}
	//uploads file in this.state.csv and refreshes the list of swatches
	importSwatches() {
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Import Swatch"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		const data = new FormData()
		data.append('file', this.state.csv)
		axios.post(ApiUrl + `/InventoryGroups/` + this.props.groupId + '/importCSV', data,
			{
				headers: {
					'Authorization': this.props.token,
					'companyId': this.props.companyId
				}
			})
			.then(res => {
				if (res.data.swatches.hasOwnProperty('error')) {
					this.setState({
						importInProgress: false,
						importErr: true,
						importErrMsg: res.data.swatches.error,
						inpKey: Math.random()
					}, () => {
						setTimeout(() => { this.setState({ importErr: false }) }, 2000)
					})
				}
				else {
					this.getSwatches().then(() => {
						this.setState({
							importInProgress: false,
							importErr: false,
						})
					}).catch(() => {
						this.setState({
							importInProgress: false,
							importErr: true,
							importErrMsg: "Swatches added but unable to refresh page. Please check your internet connection",
							inpKey: Math.random()
						}, () => {
							setTimeout(() => { this.setState({ importErr: false }) }, 2000)
						})
					})
				}
			})
			.catch(err => {

				this.setState({
					importInProgress: false,
					importErr: true,
					importErrMsg: "Unable to add swatches. Please check your internet connection",
					inpKey: Math.random()
				}, () => {
					setTimeout(() => { this.setState({ importErr: false }) }, 2000)
				})
				return null;
			})
	}

	getSwatches = () => {
		//console.log(swatchGroupId);
		const url = ApiUrl + "/InventoryGroups/" + this.props.groupId + "/swatches";
		const params = { filter: { include: ['fabrics'] } };
		return new Promise((resolve, reject) => {
			axios
				.get(url,
					{
						params,
						headers: {
							'Authorization': this.props.token
						}
					})
				.then(response => {
					this.setState({
						swatches: response.data
					}, () => {
						resolve("swatched added")
					})
				})
				.catch(err => {
					console.log(err);
					reject("swatches not available please check your internet connection");
					return null;
				})
		})
	}


	render() {
		return (
			!this.state.fetchingData && !this.state.error ?
				<div style={{ overflow: "visible" }}>
					<div className="in_hdr">
						<div className="row">
							<div className="col-auto">Swatch Details</div>
						</div>
					</div>
					{
						(this.state.swatches.length !== 0) ?
							<div className="table_febric">
								<div className="table-responsive">
									<table className="table table-borderless">
										<thead>
											<tr>
												<th scope="col">Fabric Scan ID</th>
												<th scope="col">Fabric Alias</th>
												<th scope="col">Pattern Name</th>
												<th scope="col">Color</th>
												<th scope="col">Grade</th>
												<th scope="col">Grade Notice</th>
												<th scope="col">Active Status</th>
												<th scope="col">Edit</th>
												<th scope="col">Delete</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.swatches.map(items => {
													return (<SwatchDetailsRow
														details={items}
														group_id={this.props.groupId}
														handleDeleteSwatch={this.handleDeleteSwatch}
														group_name={this.props.group_name}
														getSwatches={this.getSwatches}
														handleUpdateGroup={this.props.handleUpdateGroup}
														email={this.state.email_status}
													/>)
												})
											}
										</tbody>
									</table>
								</div>
							</div>
							:
							<Alert key='1' variant='primary'>
								No available swatches to show. Please <b>Add New Swatch</b> or <b>Import Swatches from csv file </b>
							</Alert>
					}
					<div className="btn_arte">
						<div className="row">
							<div className="col-auto mr-auto">
								<button className="btn_grn" style={{ height: 'auto' }} onClick={this.handleNewSwatch}>
									{
										this.state.addingNew ?
											<Spinner style={{ display: 'flex', overflow: 'hidden' }} styleClass='text-primary' />
											:
											"Add New Swatch"
									}
								</button>
							</div>
							<div className="col-auto">
								<input
									key={this.state.inpKey}
									id="uploadCsvFile"
									type="file"
									name="file"
									accept=".csv"
									style={{
										width: '0.1px',
										height: '0.1px',
										opacity: 0,
										overflow: 'hidden',
										position: 'absolute',
										zIndex: '-1'
									}}
									placeholder='import Excel'
									onChange={this.handleImport}
									disabled={this.state.importInProgress}
								/>
								<label placeholder='import from Excel' disabled={this.state.importInProgress} style={{ height: 'auto', marginRight: '2px' }} className="btn_black" htmlFor="uploadCsvFile">
									{
										this.state.importInProgress ?
											<Spinner style={{ display: 'flex', overflow: 'hidden' }} styleClass='text-primary' />
											:
											<b style={{ color: 'white' }}>Import from Excel</b>
									}
								</label>
								<button className="btn_black" style={{ height: 'auto' }}><a href={image} download><b style={{ color: 'white' }}>Download Excel Template</b></a></button>
								{
									this.state.importErr ?
										<Alert variant="danger" style={{ textAlign: 'center' }}>
											{this.state.importErrMsg}
										</Alert>
										:
										null
								}
								<AddSwatchDetails close={this.closeAddPopup} refresh={this.getSwatches} handleUpdateGroup={this.props.handleUpdateGroup} groupId={this.props.groupId} group_name={this.props.group_name} popupState={this.state.openAddPopup} />
							</div>
						</div>
					</div>
				</div>
				:
				this.state.error ?
					<Alert key='1' variant='danger'>
						{this.state.errMsg}
					</Alert>
					:
					<div style={{ position: 'absolute', width: '100%' }}>
						<Spinner styleClass="text-primary" />
					</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
	};
};
export default connect(mapStateToProps)(swatchDetails)

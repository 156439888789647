import React, { Component } from 'react';
import SwatchDetails from './swatchDetails';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';
import axios from 'axios';
import Spinner from './../../../components/loaders/spinner.js';
import { ApiUrl, codeTypes } from '../../../constants';
import Swal from 'sweetalert2';

class InventoryGroupAddGroup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			inventoryTypes: [],
			name: '',
			oldName: '',
			version: '',
			addDate: '',
			modifyDate: '',
			type: '',
			scan_status: 'not-started',
			groupId: '',
			groupIDBool: false,
			fetching: true,
			fetchErr: false,
			fetchErrMsg: '',
			addingGroup: false,
			swatches: [],
			addErr: false,
			addErrMsg: '',
			added: false,
			email_status: 'missingFabric',
			scanType: "Code 128",
		}
		this.handleChange = this.handleChange.bind(this);
		this.getSwatches = this.getSwatches.bind(this);
		this.handleDeleteSwatch = this.handleDeleteSwatch.bind(this);
	}
	componentDidMount() {
		this.getInventoryTypes();
	}
	handleDeleteSwatch(swatchId) {
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Delete Group"].checked) {
				this.getSwatches();
				return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
			}
		}
		const url = ApiUrl + "/Swatches/" + swatchId
		return new Promise((resolve, reject) => {
			axios
				.delete(url,
					{
						headers: {
							'Authorization': this.props.token
						}
					})
				.then(response => {
					resolve("Swatch deleted")
					this.getSwatches();
				})
				.catch(err => {
					reject("Swatch Can Not be deleted")
					console.log(err);
					return null;
				})
		})
	}
	//handleChange function for all inputs except inventory types
	handleChange(event) {
		event.persist();
		this.setState({ [event.target.name]: event.target.value })
	}
	//Creates new group according to the information in state, Stores groupId of newly created group in state and sets groupIdBool to true
	addNewGroup = async (event) => {
		event.preventDefault();
		const { permissions, user, userId } = this.props;
		if (user && user.id.toString() === userId.toString()) {
			if (!permissions.length || !this.props.permissions[0]["Add Group"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		this.setState({
			addingGroup: true,
			addErr: false,
		})

		try {
			let filterName = await axios.get(ApiUrl + '/InventoryGroups/', { params: { filter: { where: { "name": this.state.name.trim(), "companyId": this.props.companyId } } } })
			if (this.state.oldName.trim() !== this.state.name.trim() && filterName && Array.isArray(filterName.data) && filterName.data.length > 0) {
				this.setState({
					addingGroup: false,
					addErr: false,
				})
				return Swal.fire({ icon: 'error', title: 'A swatch inventory group with that name already exists', timer: 2000 })
			}
		} catch (error) {
			this.setState({
				addingGroup: false,
				addErr: false,
			})
			return Swal.fire({ title: "Something went wrong! try again later.", timer: 1500 })
		}
		
		const group_data = {
			name: this.state.name.trim(),
			version: this.state.version,
			active: this.state.scan_status,
			inventoryTypeId: this.state.type,
			send_email: this.state.email_status,
			scanType: this.state.scanType
		};

		axios.post(ApiUrl + "/companies/" + this.props.companyId + `/inventoryGroups`, group_data,
			{
				headers: {
					'Authorization': this.props.token
				}
			})
			.then(res => {
				this.setState({
					groupId: res.data.id,
					oldName: res.data.name.trim(),
					groupIDBool: true,
					addDate: res.data.createdAt.split("T")[0],
					modifyDate: res.data.updatedAt.split("T")[0],
					addingGroup: false,
					added: true,
					addErr: false,
				}, () => {
					// this.state.email_status && axios.get(ApiUrl + `/InventoryGroups/` + this.state.groupId + '/sendEmail',
					// 	{
					// 		headers: {
					// 			'Authorization': this.props.token
					// 		}
					// 	})
				})
			})
			.catch(err => {
				console.log(err);
				this.setState({
					addingGroup: false,
					addErr: true,
					addErrMsg: 'Inventory Group not added please check your internet connection',
				}, () => {
					setTimeout(() => { this.setState({ addErr: false }) }, 2000)
				})
				return null;
			})
	}
	//takes group Id as parameter and stores associated available swatches in state
	getSwatches = () => {
		const url = ApiUrl + "/InventoryGroups/" + this.state.groupId + "/swatches";
		axios
			.get(url,
				{
					headers: {
						'Authorization': this.props.token
					}
				})
			.then(response => {
				this.setState({
					swatches: response.data,
				})
			})
			.catch(err => {
				console.log(err);
				return null;
			})
	}
	//this func will set this.state.inventoryTypes to available types
	getInventoryTypes = () => {
		this.setState({
			fetching: true
		})
		const url = ApiUrl + "/InventoryTypes"
		axios
			.get(url,
				{
					headers: {
						'Authorization': this.props.token
					}
				})
			.then(response => {
				(response.data.length === 0) ?
					this.setState({
						inventoryTypes: null,
						type: null,
						fetching: false
					}) :
					this.setState({
						inventoryTypes: response.data,
						type: response.data[0].id,
						fetching: false
					})

			})
			.catch(err => {
				this.setState({
					fetching: false,
					fetchErr: true,
					fetchErrMsg: 'Please Check your internet connection'
				})
				return null;
			})
	}

	saveGroup = async (event) => {
		event && event.preventDefault();
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Add Group"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		this.setState({
			saving: true,
			savingErr: false,
		})

		try {
			let filterName = await axios.get(ApiUrl + '/InventoryGroups/', { params: { filter: { where: { "name": this.state.name.trim(), "companyId": this.props.companyId } } } })
			if (this.state.oldName.trim() !== this.state.name.trim() && filterName && Array.isArray(filterName.data) && filterName.data.length > 0) {
				this.setState({
					saving: false,
					savingErr: false,
				})
				return Swal.fire({ icon: 'error', title: 'A swatch inventory group with that name already exists', timer: 2000 })
			}
		} catch (error) {
			this.setState({
				saving: false,
				savingErr: false,
			})
			return Swal.fire({ title: "Something went wrong! try again later.", timer: 1500 })
		}

		const group_data = {
			name: this.state.name.trim(),
			version: this.state.version,
			active: this.state.scan_status,
			inventoryTypeId: this.state.type,
			send_email: this.state.email_status,
			scanType: this.state.scanType
		};

		axios.patch(ApiUrl + `/InventoryGroups/` + this.state.groupId, group_data,
			{
				headers: {
					'Authorization': this.props.token
				}
			})
			.then(res => {
				this.setState({
					groupId: res.data.id,
					oldName: res.data.name.trim(),
					groupIDBool: true,
					addDate: (new Date(res.data.createdAt)).toLocaleDateString(),
					saving: false,
					savingErr: false,
					modifyDate: (new Date(res.data.updatedAt)).toLocaleDateString(),
				})
			})
			.catch(err => {
				console.log(err);
				this.setState({
					saving: false,
					savingErr: true,
					savingErrMsg: "Changes not saved please check you internet connection"
				}, () => {
					setTimeout(() => { this.setState({ savingErr: false }) }, 2000)
				})
				return null;
			})
	}

	render() {
		return (
			<div id="page-content-wrapper">
				<div className="container-fluid">
					<div className="row setup_top setup_top">
						<div className="col-12">
							<ul>
								<li style={{ flexGrow: '1' }}>SWATCH INVENTORY GROUP</li>
							</ul>
						</div>
						{
							this.state.fetching ?
								<div className="col-12" style={{ height: 'auto', alignContent: 'center' }}>
									<div style={{ position: 'absolute', width: '100%' }}>
										<Spinner styleClass="text-primary" />
									</div>
								</div>
								:
								this.state.fetchErr ?
									<div className="col-12" style={{ height: 'auto', alignContent: 'center' }}>
										<Alert key='1' style={{ marginTop: '3%', justifyContent: 'center' }} variant='danger'>
											{this.state.fetchErrMsg}
										</Alert>
									</div>
									:
									<div className="col-12">
										<div className="in_hdr">
											<div className="row">
												<div className="col-auto mr-auto">Swatch Inventory Group Header</div>
												<div className="col-auto">
													<label className="switch float-left mr-2">
														<input type="checkbox" checked className="default" />
														<span className="slider round"></span>
													</label>
														Active Status</div>
											</div>
										</div>


										<div className="grp_frm">

											<form className="col-12" onSubmit={this.addNewGroup}>

												<div className="form-row">
													<div className="col-sm-4">
														<div className="row">
															<label className="col-4">Group Name:</label>
															<div className="col-8">
																<input
																	type="text"
																	name="name"
																	className="form-control"
																	value={this.state.name}
																	onChange={this.handleChange}
																	placeholder={this.state.name}
																	required="required"
																/>
															</div>
														</div>
													</div>
													<div className="col-sm-4">
														<div className="row">
															<label className="col-2">Type:</label>
															<div className="col-10">
																<select className="browser-default custom-select" value={this.state.type} name="type" onChange={this.handleChange}>
																	{
																		//available inventory group types will be displayed
																		(this.state.inventoryTypes === null) ?
																			null :
																			this.state.inventoryTypes.map(item => {
																				return (
																					<option value={item.id}>
																						{item.name}
																					</option>
																				)
																			})
																	}
																</select>
															</div>
														</div>
													</div>

													<div className="col-sm-4">
														<div className="row">
															<label className="col-2">Version:</label>
															<div className="col-10">
																<input
																	type="text"
																	name="version"
																	onChange={this.handleChange}
																	value={this.state.version}
																	className="form-control"
																	placeholder={this.state.version}
																	required="required"
																/>
															</div>
														</div>
													</div>

												</div>
												<div className="form-row">
													<div className="col-sm-4">
														<div className="row">
															<label className="col-4">Add Date:</label>
															<div className="col-8">
																<div className="input-group date" data-provide="datepicker">
																	<input
																		type="text"
																		className="form-control"
																		placeholder="Read Only"
																		disabled={true}
																		value={this.state.addDate}
																		onChange={this.handleChange}
																		name="addDate"
																		readOnly
																	/>
																	<div className="input-group-addon">

																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="col-sm-4">
														<div className="row">
															<label className="col-4">Modify Date:</label>
															<div className="col-8">
																<div className="input-group date" data-provide="datepicker">
																	<input
																		type="text"
																		className="form-control"
																		placeholder="Read Only"
																		disabled={true}
																		value={this.state.modifyDate}
																		onChange={this.handleChange}
																		name="modifyDate"
																		readOnly

																	/>
																	<div className="input-group-addon">

																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="col-sm-4">
														<div className="row">
															<label className="col-4">Send Mail:</label>
															<div className="col-8">
																{/* <label className="switch float-right mr-2">
																	<input type="checkbox" name='email_status' onChange={this.handleChange} checked={this.state.email_status} className="default" />
																	<span className="slider round" />
																</label> */}

																<select defaultValue={this.state.email_status} name='email_status' aria-label="email_status" className="form-control" onChange={this.handleChange}>
																	<option value="missingFabric" > 	Send If Missing Fabrics  </option>
																	<option value="All" > Send All </option>
																	<option value="false" > Don't Send Mail </option>
																</select>

															</div>
														</div>
													</div>

												</div>
												<div className="row">
													<div className="col-sm-6">
														<div className="row">
															<label className="col-2">Scan Type:</label>
															<div className="col-4">
																<select className="browser-default custom-select" value={this.state.scanType} name="scanType" onChange={this.handleChange}>
																	{
																		codeTypes.map((type) => {
																			return <option value={type}>{type}</option>
																		})
																	}
																</select>
															</div>
														</div>
													</div>
												</div>
												<div className="btn_arte">
													<div className="row">
														<div className="col-auto mr-auto">
															{
																this.state.added ?
																	null
																	:
																	<button disabled={this.state.addingGroup} className="btn_grn" type="submit" onSubmit={this.addNewGroup}>
																		{
																			this.state.addingGroup ?
																				<Spinner style={{ display: 'inline' }} styleClass='text-primary' />
																				:
																				"Add Group"
																		}
																	</button>
															}
															{
																this.state.addErr ?
																	<Alert key='1' style={{ marginTop: '3%' }} variant='danger'>
																		{this.state.addErrMsg}
																	</Alert>
																	:
																	null
															}
														</div>
													</div>
												</div>
											</form>
										</div>
										<br></br>
										{
											(this.state.groupIDBool) ?
												<SwatchDetails swatchList={this.state.swatches} handleDeleteSwatch={this.handleDeleteSwatch}  handleUpdateGroup={this.saveGroup} group_name={this.state.name} groupId={this.state.groupId} email={this.state.email_status} />
												:
												<Alert key='1' variant='primary'>
													Please <b>Add group</b> to view  <b>Swatch Details</b>
												</Alert>
										}
									</div>

						}

					</div>

				</div>
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user,
		userId: state.auth.userId
	};
};

export default connect(mapStateToProps)(InventoryGroupAddGroup);

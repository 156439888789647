import axios from "axios";
import { ApiUrl } from "../../constants";

const url = ApiUrl + "/Companies/"
export const updateCompany = (companyId, payload, token) => {
  return axios.patch(url + companyId, payload, { headers: { 'authorization': token } })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log("err: updateCompany : ", err.messsage)
      return err.messsage
    })
}

export const getCompanies = (filter, token) => {
  return axios.get(url, { params: { filter }, headers: { 'authorization': token } })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log("err: updateCompany : ", err.messsage)
      return err.messsage
    })
}

export const getCompany = (id, token) => {
  return axios.get(url + id, { headers: { 'authorization': token } })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log("err: updateCompany : ", err.messsage)
      return err.messsage
    })
}
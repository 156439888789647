import React, { Component } from 'react';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import ScannedSwatchRow from "./scannedSwatchRow"
import { connect } from "react-redux";
import Spinner from './../../../components/loaders/spinner.js';
import { getScannedGroups } from '../../../store/services/inventory';


class ScannedSwatches extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scannedGroups: [],
      filterValue: "isReviewed",
      fetchingData: true,
      filterType: '',
    }
    this.getGroups = this.getGroups.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
  }
  componentDidMount() {

    this.getGroups();
  }
  //sets variable this.state.scannedGroups
  getGroups = () => {
    const { match: { params } } = this.props;
    getScannedGroups(params.id, this.props.token)
      .then(response => {
        this.setState({
          scannedGroups: response,
          fetchingData: false,
        })
      })
      .catch(err => {
        console.log(err);
        this.setState({
          fetchingData: false,
        })
        return null;
      })
  }
  handleChangeFilter = (event) => {
    event.persist();
    this.setState({
      filterValue: event.target.value
    })

  }
  handleChangeTypeToFilter = (event) => {
    event.preventDefault();
    event.persist();
    this.setState({
      filterType: event.target.value
    })
  }
  render() {
    return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="row setup_top setup_top">
            <div className="col-12">
              <ul>
                <li style={{ flexGrow: 1 }}>VIEW SCANNED SWATCH INVENTORY GROUP</li>
                <li style={{ flexGrow: 1 }}>
                  <div className="float-right" >
                    <div className="float-left rt_setey" >
                      <select className="browser-default custom-select" name="filterValue" value={this.state.filterValue} onChange={this.handleChangeFilter}>
                        <option value="" selected>Show All</option>
                        <option value="isReviewed">Show Unreviewed</option>
                        <option value="isReviewedTrue">Show Reviewed</option>
                      </select>
                    </div>
                    <div className="float-right">
                      <div className="input-group form-group fild2">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="fa fa-search"></i></span>
                        </div>
                        <input type="text" className="form-control" value={this.state.filterType} onChange={this.handleChangeTypeToFilter} placeholder="Type to filter…" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {(this.state.scannedGroups.length !== 0) ?
              <div className="col-12">
                <div className="table-responsive my-4 scroll_sec">
                  <table className="table tb_top">
                    <thead>
                      <tr>
                        <th scope="col">
                          Inventory Name
                        </th>
                        <th scope="col">User Email</th>
                        <th scope="col">Uploaded Date</th>
                        <th scope="col">Reviewed</th>
                        <th scope="col">Grade Change</th>
                        <th scope="col">Inactive</th>
                        <th scope="col">Missing</th>
                        <th scope="col">Duplicate</th>
                        <th scope="col">Download</th>
                        <th scope="col">Print</th>
                        <th scope="col">Status Of Scan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(this.state.scannedGroups) && this.state.scannedGroups.map(item => {
                        if (this.state.filterValue === '' && this.state.filterType === '') {
                          return (<ScannedSwatchRow getGroups={this.getGroups} details={item} />)
                        }
                        else if ((this.state.filterValue.toLowerCase() === item.status.toLowerCase()) && this.state.filterValue !== '' && this.state.filterType === '') {
                          return (<ScannedSwatchRow getGroups={this.getGroups} details={item} />)
                        }
                        else if ((this.state.filterValue === "isReviewed" && !item.isReviewed) && this.state.filterValue !== '' && this.state.filterType === '') {
                          return (<ScannedSwatchRow getGroups={this.getGroups} details={item} />)
                        }
                        else if ((this.state.filterValue === "isReviewedTrue" && item.isReviewed) && this.state.filterValue !== '' && this.state.filterType === '') {
                          return (<ScannedSwatchRow getGroups={this.getGroups} details={item} />)
                        }
                        else if ((item.name.toLowerCase().includes(this.state.filterType.toLowerCase())) && this.state.filterType !== '' && this.state.filterValue === '') {
                          return (<ScannedSwatchRow getGroups={this.getGroups} details={item} />)
                        }
                        else if (this.state.filterValue !== '' && this.state.filterType !== '' && (item.name.toLowerCase().includes(this.state.filterType.toLowerCase())) && (this.state.filterValue.toLowerCase() === item.status.toLowerCase())) {
                          return (<ScannedSwatchRow getGroups={this.getGroups} details={item} />)
                        }
                        return null
                      })}
                    </tbody>
                  </table>

                </div>
              </div>
              :
              this.state.fetchingData ?
                <div style={{ position: 'absolute', marginTop: '10%', width: '100%' }}>
                  <Spinner styleClass="text-primary" />
                </div>
                :
                <Alert key='1' variant='primary' style={{ width: '100%', marginTop: '4%' }}>
                  No available scanned swatches to show.
                </Alert>
            }
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    companyId: state.auth.companyId,
    apiUrl: state.auth.apiUrl,
    token: state.auth.token
  };
};
export default connect(mapStateToProps)(ScannedSwatches)

import { put, select, takeLatest, call } from 'redux-saga/effects';
import { selectAuthToken, selectTokenAndId } from '../selectors/auth';
import * as service from '../services/subscription';
import * as types from '../constants/subscription';
import Swal from 'sweetalert2'

function* getSubscriptionsSaga() {
 try {
  const TokenAndId = yield select(selectTokenAndId);
  const subscriptions = yield call(service.getAllSubscriptions, TokenAndId.companyId, TokenAndId.token);
  if (subscriptions) {
   yield put({ type: types.GET_SUBSCRIPTION_SUCCESS, payload: subscriptions })
   return;
  }
  yield put({ type: types.GET_SUBSCRIPTION_FAILURE, payload: "no data found" })
 } catch (error) {
  yield put({ type: types.GET_SUBSCRIPTION_FAILURE, payload: error.message })
  console.log("ERR ", error.message);
 }
}

function* updateSubscriptionSaga(actions) {
 try {
  // const token = yield select(selectAuthToken);
  // const settings = yield call(service.updateCoupon, actions.id, actions.payload, token);
  const settings = false;
  if (settings) {
   Swal.fire({
    icon: 'success',
    text: 'Sucessfully updated'
   })
   yield put({ type: types.UPDATE_SUBSCRIPTION_SUCCESS })
   yield put({ type: types.GET_SUBSCRIPTION_REQUEST })
   return;
  }
  yield put({ type: types.UPDATE_SUBSCRIPTION_FAILURE })
 } catch (error) {
  yield put({ type: types.UPDATE_SUBSCRIPTION_FAILURE })
  console.log("ERR ", error.message);
 }
}

function* addSubscriptionSaga(actions) {
 try {
  const TokenAndId = yield select(selectTokenAndId);
  const payload = { ...actions.payload, companyId: TokenAndId.companyId }
  const subscription = yield call(service.addSubscription, payload, TokenAndId.token);
  if (subscription) {
   Swal.fire({
    icon: 'success',
    text: 'Sucessfully updated'
   })
   yield put({ type: types.UPDATE_SUBSCRIPTION_SUCCESS })
   yield put({ type: types.GET_SUBSCRIPTION_REQUEST })
   return;
  }
  yield put({ type: types.UPDATE_SUBSCRIPTION_FAILURE, payload: "not successfull" })
 } catch (error) {
  yield put({ type: types.UPDATE_SUBSCRIPTION_FAILURE, payload: error.message })
  console.log("ERR ", error.message);
 }
}

function* deleteSubscriptionSaga(actions) {
 try {
  const token = yield select(selectAuthToken);
  const subscription = yield call(service.deleteSubscription, actions.payload, token);
  if (subscription) {
   Swal.fire({
    icon: 'success',
    text: 'Sucessfully deleted'
   })
   yield put({ type: types.UPDATE_SUBSCRIPTION_SUCCESS })
   yield put({ type: types.GET_SUBSCRIPTION_REQUEST })
   window.location.reload()
   return;
  }
  yield put({ type: types.UPDATE_SUBSCRIPTION_FAILURE })
 } catch (error) {
  yield put({ type: types.UPDATE_SUBSCRIPTION_FAILURE })
  console.log("ERR ", error.message);
 }
}

function* getPlansSaga() {
 try {
  const token = yield select(selectAuthToken);
  const plans = yield call(service.getAllPlans, token);
  if (plans) {
   yield put({ type: types.GET_PLANS_SUCCESS, payload: plans })
   return;
  }
  yield put({ type: types.GET_PLANS_FAILURE, payload: "no data found" })
 } catch (error) {
  yield put({ type: types.GET_PLANS_FAILURE, payload: error.message })
  console.log("ERR ", error.message);
 }
}

export default function* authWatcher() {
 yield takeLatest(types.GET_SUBSCRIPTION_REQUEST, getSubscriptionsSaga);
 yield takeLatest(types.UPDATE_SUBSCRIPTION_REQUEST, updateSubscriptionSaga);
 yield takeLatest(types.ADD_SUBSCRIPTION_REQUEST, addSubscriptionSaga);
 yield takeLatest(types.DELETE_SUBSCRIPTION_REQUEST, deleteSubscriptionSaga);
 yield takeLatest(types.GET_PLANS_REQUEST, getPlansSaga);
}

import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
const PermissionsModal = (props) => {
 const [disabled, setDisabled] = useState(true);
 useEffect(() => {
  props.user_permissions && setDisabled(!props.user_permissions[1].checked)
 })
 return (
  <Modal
   show
   size="lg"
   aria-labelledby="contained-modal-title-vcenter"
   centered
  >
   <Modal.Header>
    <Modal.Title id="contained-modal-title-vcenter">Permissions</Modal.Title>
   </Modal.Header>
   <Modal.Body style={{ padding: "5%" }}>
    {props.user_permissions && props.user_permissions.slice(0,2).map((permission, index) => {
     var red = false;
     if(permission.key.split(" ")[0] === "DELETE" || permission.title ==="Update Subscription" || permission.title ==="Payment Method" || permission.title ==="Add User" || permission.title ==="Edit User" ) red = true;
     if (permission.key === "title") {
      return (
       <div style={{ fontWeight: "bold" }}>
        <label> {permission.title}</label>
       </div>
      )
     } else {
      return (
       <div key={index} className="custom-control custom-checkbox permissions-checks">
        <input type="checkbox" className="custom-control-input"
         checked={permission.checked}
         onChange={() => props.toggleUserPermissions(permission)}
         id={"permission" + permission.title}
         disabled={index > 1 && disabled}
        />
        <label className={`${red && "red"} custom-control-label permission-label`} htmlFor={"permission" + permission.title}>{permission.title}</label>
       </div>
      )

     }
    }
    )}
    <div style={{ border: "2px solid black", padding : "5%", borderRadius: "8px"}}>
     {props.user_permissions && props.user_permissions.slice(2).map((permission, index) => {
      var red = false;
      if(permission.key.split(" ")[0] === "DELETE" || permission.title ==="Update Subscription" || permission.title ==="Payment Method" || permission.title ==="Add User" || permission.title ==="Edit User" ) red = true;
      if (permission.key === "title") {
       return (
        <div style={{ fontWeight: "bold" }}>
         <label> {permission.title}</label>
        </div>
       )
      } else {
       return (
        <div key={index} className="custom-control custom-checkbox permissions-checks">
         <input type="checkbox" className="custom-control-input"
          checked={permission.checked}
          onChange={() => props.toggleUserPermissions(permission)}
          id={"permission" + permission.title}
          disabled={disabled}
         />
         <label className={`${red && "red"} custom-control-label permission-label`} htmlFor={"permission" + permission.title}>{permission.title}</label>
        </div>
       )

      }
     }
     )}
    </div>
   </Modal.Body>
   <Modal.Footer>
    <Button variant="warning" onClick={props.onSubmit}>Add</Button>
    <Button onClick={props.onHide}>Close</Button>
   </Modal.Footer>
  </Modal>
 );
}

export default PermissionsModal;
import { ADD_USER_ID, ADD_COMPANY_ID, ADD_URL, ADD_TOKEN, ADD_COMPANY, ADD_USER, ADD_PERMISSIONS, ADD_ROLE, SET_EMAIL_INFO } from '../constants'

export function addUserId(id) {
    return {
        type: ADD_USER_ID,
        payload: id
    };
}
export function addUserEmail(email) {
    return {
        type: SET_EMAIL_INFO,
        payload: email
    };
}

export function addCompanyId(id) {
    return {
        type: ADD_COMPANY_ID,
        payload: id
    };
}

export function addCompany(company) {
    return {
        type: ADD_COMPANY,
        payload: company
    };
}

export function addURL() {
    return {
        type: ADD_URL,
    };
}

export function addToken(id) {
    return {
        type: ADD_TOKEN,
        payload: id
    };
}

export function addUser(user) {
    return {
        type: ADD_USER,
        payload: user
    }
}

export function addRole(role) {
    return {
        type: ADD_ROLE,
        payload: role
    }
}

export function addPermissions(permissions) {
    return {
        type: ADD_PERMISSIONS,
        payload: permissions
    }
}

export function addSettings(settings) {
    return {
        type: "SET_SETTINGS",
        payload: settings
    }
}
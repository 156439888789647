import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Favicon from 'react-favicon';
import FavIconImg2 from './img/favIcon.png'
//Redux
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux'
import store, { persistor } from '././store/store';
import { ApiUrl } from "./constants";

const FavIconImg = ApiUrl + '/containers/app/download/favicon.png';

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor} >
			<Favicon url={ApiUrl + '/containers/app/download/favicon.png' || FavIconImg2} />
			<App />
		</PersistGate>
	</Provider>
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


import axios from "axios";
import Swal from "sweetalert2";
import { ApiUrl } from "../../constants";

const groupUrl = ApiUrl + "/orderGroups/";
const orderUrl = ApiUrl + "/orders/";
const orderFabricsUrl = ApiUrl + "/orderFabrics/";

export const getOrderGroup = (token, filter = undefined) => {
  return axios.get(groupUrl, { params: { filter }, headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('getOrder Group: ', err.message)
      return false
    })
}

export const createOrderGroup = (payload, token) => {
  return axios.post(groupUrl, payload, { headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      Swal.fire({ icon: 'error', title: 'something went wrong!', timer: 1500 })
      console.log('createOrder Group: ', err.message)
      return false
    })
}

export const updateOrderGroup = (id, payload, token) => {
  return axios.patch(groupUrl + id, payload, { headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('updateOrder Group: ', err.message)
      return false
    })
}

export const deleteOrderGroup = (id, token) => {
  return axios.delete(groupUrl + id, { headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('updateOrder Group: ', err.message)
      return false
    })
}

export const createOrder = (payload, token) => {
  return axios.post(orderUrl, payload, { headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('CreateOrder: ', err.message)
      return err.message
    })
}

export const updateOrder = (id, payload, token) => {
  return axios.patch(orderUrl + id, payload, { headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('updateOrder: ', err.message)
      return err.message
    })
}

export const deleteOrder = (id, token) => {
  return axios.delete(orderUrl + id, { headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('deleteOrder: ', err.message)
      return err.message
    })
}

export const getOrders = (filter, token) => {
  return axios.get(orderUrl, { params: { filter }, headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('getOrders: ', err.message)
      return err.message
    })
}

export const payVendor = (id, cardInfo, token) => {
  return axios.get(orderUrl + id + "/payVendor", { params: { cardInfo }, headers: { 'authorization': token } })
    .then(response => {
      return response.data.data
    })
    .catch(err => {
      // console.log('TYPEOF NEWERR==> ', err.response.data.error)
      return err.response.data
    })
}

export const createOrderFabric = (payload, token) => {
  return axios.post(orderFabricsUrl, payload, { headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('CreateOrderFabric: ', err.message)
      return err.message
    })
}

export const updateOrderFabric = (id, payload, token) => {
  return axios.patch(orderFabricsUrl + id, payload, { headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('updateOrderFabric: ', err.message)
      return err.message
    })
}

export const deleteOrderFabric = (id, token) => {
  return axios.delete(orderFabricsUrl + id, { headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('deleteOrderFabric: ', err.message)
      return err.message
    })
}

export const getOrderFabrics = (filter, token) => {
  return axios.get(orderFabricsUrl, { params: filter, headers: { 'authorization': token } })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log('getOrderFabrics: ', err.message)
      return err.message
    })
}

export const postOrderFabricCSV = (orderId, payload, token) => {
  return axios.post(orderFabricsUrl + 'importCSV/' + orderId, payload, { headers: { 'authorization': token } })
    .then(response => { return response.data })
    .catch((err) => {
      console.log('PostOrderFabricCSV', err.message)
      return err.message
    })
}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { orderDetailTableSet } from "../../../constants";
import { createOrder, createOrderFabric, getOrders, updateOrder, updateOrderFabric } from '../../../store/services/order';
import Spinner from '../../loaders/spinner';
import Swal from 'sweetalert2';

import AddFabricModal from "./add.fabric";
import EditFabricModal from './edit.fabric';


import "./style.css";

const quantityShift = { id: 'quantity', name: 'Quantity', hidden: false }
const fabricIdShift = { id: 'fabricId', name: 'Fabric Id', hidden: false }

const OrderDetails = (props) => {

  var TABLES = [...orderDetailTableSet];
  TABLES.unshift(quantityShift, fabricIdShift);

  const { id } = useParams();
  const history = useHistory();
  const token = useSelector(state => state.auth.token);
  const company = useSelector(state => state.auth.company);

  const [order, setOrder] = useState({});
  const [auto, setAuto] = useState(0);
  const [sort, setSort] = useState('+1');
  const [checks, setChecks] = useState({});
  const [modal, setModal] = useState(false);
  const [col, setCol] = useState('quantity');
  const [tableSet, setTable] = useState(TABLES);
  const [checkAll, setCheckAll] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  const [orderFabrics, setOrderFabrics] = useState([]);

  useEffect(() => {
    getORDERS();
  }, [])

  useEffect(() => {
    console.log(id)
    if (company.orderDetailtable && Array.isArray(company.orderDetailtable)) {
      let sets = [...company.orderDetailtable];
      sets.unshift(quantityShift, fabricIdShift)
      setTable(sets);
    }
  }, [company])

  const getORDERS = () => {
    const filter = { where: { id: id }, include: ['order_group', 'vendor', 'manufacturer', 'creator', { 'fabrics': 'fabric' }] }
    getOrders(filter, token)
      .then(response => {
        if (Array.isArray(response)) {
          setOrder(response[0])
          setOrderFabrics(response[0].fabrics)
          console.log(response[0])
        }
      })
  }

  const handleSubmitOrder = () => {
    if (order.status === 'invoiced') return history.push('/orders_history')
    if (isSubmiting) return;
    setSubmiting(true)
    let ORDER = { ...order };
    ORDER.status = 'placed';
    setOrder(ORDER)
    updateOrder(ORDER.id, ORDER, token)
      .then(response => {
        setSubmiting(false)
        if (typeof response === 'string') {
          return Swal.fire({ icon: 'error', title: "Something went wrong!", text: response, timer: 2000 })
        }
        Swal.fire({ icon: 'success', title: 'Successfully Placed', timer: 1500 })
        history.push("/orders_history");
      })
  }

  const handleSort = ({ target }) => {
    setSort(target.value)
    handleFilter(target.value)
  }

  const handleSetCol = ({ target }) => {
    setCol(target.value)
    handleFilter(null, target.value)
  }

  const handleFilter = (s, c) => {
    const srt = s ? s : sort;
    const colx = c ? c : col;
    let fabrics = [...orderFabrics]
    if (srt === "+1") {
      fabrics.sort((a, b) => {
        if (colx === "fabric.code") return a.fabric.code > b.fabric.code ? 1 : -1;
        if (colx === "fabric.pattern_name") return a.fabric.pattern_name > b.fabric.pattern_name ? 1 : -1;
        if (colx === "fabric.color") return a.fabric.color > b.fabric.color ? 1 : -1;
        return a[colx] > b[colx] ? 1 : -1
      })
    } else {
      fabrics.sort((a, b) => {
        if (colx === "fabric.code") return a.fabric.code < b.fabric.code ? 1 : -1;
        if (colx === "fabric.pattern_name") return a.fabric.pattern_name < b.fabric.pattern_name ? 1 : -1;
        if (colx === "fabric.color") return a.fabric.color < b.fabric.color ? 1 : -1;
        return a[colx] < b[colx] ? 1 : -1
      })
    }
    setOrderFabrics(fabrics)


  }

  const handleCheckAll = ({ target }) => {
    setCheckAll(target.checked)
    if (!target.checked) return setChecks({})
    let checkObj = {};
    orderFabrics.map((orderF) => {
      let orderPyaload = { ...orderF }
      delete orderPyaload.fabricId;
      delete orderPyaload.fabric;
      delete orderPyaload.id;
      checkObj[orderF.id] = orderPyaload
    })
    setChecks(checkObj)
  }

  const handleCheck = async ({ target }, item) => {
    let checkObj = { ...checks }
    let orderPyaload = { ...item }
    delete orderPyaload.fabricId;
    delete orderPyaload.fabric;
    delete orderPyaload.id;
    console.log(orderPyaload)
    checkObj[item.id] = (target.checked) ? orderPyaload : undefined;
    await setChecks(checkObj);
    let allChecked = true
    orderFabrics.map((orderF) => {
      if (!checkObj[orderF.id]) allChecked = false
    })
    setCheckAll(allChecked)
  }

  const handleDuplicate = () => {
    let promises = []
    Object.keys(checks).map((ids) => {
      if (checks[ids] && typeof checks[ids] === 'object') {
        promises.push(createOrderFabric(checks[ids], token))
      }
    })
    Promise.all(promises)
      .then(response => {
        setCheckAll(false)
        setChecks({})
        getORDERS();
      })
  }

  const handleAutoFill = () => {
    if (!auto) return Swal.fire({ icon: 'error', title: 'Enter Quantity first!', timer: 2000 })
    if (!orderFabrics && !orderFabrics.length) return Swal.fire({ icon: 'error', title: 'No record found!', timer: 2000 })
    setSubmiting(true)
    let isFound = false
    Array.isArray(orderFabrics) && orderFabrics.map(fabric => {
      if (!fabric.quantity || fabric.quantity < 1) {
        isFound = true
        let newFab = { ...fabric }
        newFab.quantity = auto;
        updateOrderFabric(newFab.id, newFab, token).then((response) => {
          getORDERS()
        })
      }
    })
    setSubmiting(false)
    return Swal.fire({ icon: (isFound ? 'success' : 'warning'), title: (isFound ? 'Successfully updated' : 'No record found!'), timer: 2000 })
  }

  return (
    <div className="orderTrackerContainer">
      <h1>Order Details</h1>
      <div className="order_info_header">
        <div className="row">
          <div className="col-6"> Order Number:</div>
          <div className="col-6"> <input type="text" disabled value={id || ""} /> </div>
          <br />
          <br />
          <div className="col-6"> Group Name:</div>
          <div className="col-6"> <input type="text" disabled value={(order && order.vendor && order.vendor.name) || "XYZ Group"} /> </div>
        </div>
      </div>
      <hr />
      {/* FILTERS  */}
      <div className="row">
        <div className="col-4">
          <select className="input-group form-group fild2 form-control" onChange={handleSetCol}>
            {Array.isArray(tableSet) && tableSet.map((set, ind) => {
              if (!set.hidden) return <option key={set.name + ind} value={set.id}> {set.name} </option>
            })}
          </select>
        </div>
        <div className="col-4">
          <select className="input-group form-control form-group" onChange={handleSort} >
            <option value='+1'>A-Z</option>
            <option value='-1'>Z-A</option>
          </select>
        </div>
        <div className="col-4">
          <button className="btn_grn" onClick={() => setOrderFabrics(order.fabrics || [])} > Clear filters </button>
        </div>
        {company && company.type === 'manufacturer' &&
          <>
            <div className="col-4">
              <div className="input-group form-group fild2">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Add quantity"
                  min={0}
                  value={auto}
                  onChange={({ target }) => setAuto(target.value)}
                />
              </div>
            </div>
            <div className="col-8">
              <button className="btn btn-primary" onClick={handleAutoFill} > Autofill Quantity </button>
            </div>
          </>
        }


      </div>
      {/* END FILTERS */}
      <hr />
      <div className="tb_conatiner">
        <table className="table tb_top tb_conatiner">
          <thead>
            <tr>
              {
                company && company.type === 'manufacturer' &&
                <th scope="col">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox"
                      className="custom-control-input" id="defaultUnchecked"
                      defaultChecked={checkAll}
                      checked={checkAll}
                      onChange={handleCheckAll}
                    />
                    <label className="custom-control-label" htmlFor="defaultUnchecked" />
                  </div>
                </th>
              }

              {Array.isArray(tableSet) && tableSet.map((set, ind) => {
                if (!set.hidden) return <th scope="col" key={set.name + ind}> {set.name} </th>
              })}
            </tr>
          </thead>
          {/*Rows of pricing data*/}
          <tbody>
            {Array.isArray(orderFabrics) && orderFabrics.map((item, index) => {
              return <DetailRow
                {...item}
                item={item}
                groupId={id}
                index={index}
                check={checks[item.id]}
                tableSet={tableSet}
                getOrders={getORDERS}
                handleCheck={handleCheck}
                type={company && company.type}
              />
            })
            }
          </tbody>
        </table>
      </div>

      <div className="order_info_footer">
        {company && company.type === 'manufacturer' ?
          <div className="row">
            <div className="col-6">
              <button className="btn btn-primary" onClick={handleDuplicate}> Duplicate Selected Fabric(s) </button>
            </div>
            <div className="col-6">
              <button className="btn_grn" onClick={() => setModal(true)}> Add Fabric </button>
            </div>
            <br />
            <br />
            <div className="col-6">
              <button className="btn btn-primary" onClick={handleSubmitOrder} >
                {isSubmiting ?
                  <Spinner />
                  :
                  "Submit Order"
                }
              </button>
            </div>
            <div className="col-6">
              <button className="btn_grn"> Save as Draft </button>
            </div>
          </div>
          :
          <BillDetail {...props} order={order} />
        }
      </div>

      {/* MODALS AND ETC */}
      <AddFabricModal close={() => setModal(false)} modal={modal} getOrders={getORDERS} groupId={id} />

    </div>
  )
}

export default OrderDetails;

const DetailRow = ({ index, item, type, tableSet, getOrders, groupId, check, handleCheck }) => {
  const [modal, setModal] = useState(false);

  return (
    <tr key={index} onDoubleClick={() => setModal(true)} >
      {
        type === 'manufacturer' &&
        <td>
          <div className="custom-control custom-checkbox">
            <input type="checkbox" name='checkBox' onChange={(e) => handleCheck(e, item)} defaultChecked={check} checked={check} className="custom-control-input" id={index} />
            <label className="custom-control-label" htmlFor={index} ></label>
          </div>
        </td>
      }
      {Array.isArray(tableSet) && tableSet.map((set, ind) => {
        if (!set.hidden) return <td key={set.name + item.id}> {
          (set.id === "fabric.code" && item.fabric && item.fabric.code) ||
          (set.id === "fabric.pattern_name" && item.fabric && item.fabric.pattern_name) ||
          (set.id === "fabric.color" && item.fabric && item.fabric.color) ||
          (set.id === "date" && item.date && (new Date(item.date)).toLocaleDateString()) ||
          item[set.id]
        } </td>
      })}
      {modal &&
        <EditFabricModal close={() => setModal(false)} orderFabrics={item} modal={modal} getOrders={getOrders} groupId={groupId} />
      }
    </tr>
  )
}

const BillDetail = (props) => {

  const { id } = useParams();
  const history = useHistory()
  const company = useSelector(state => state.auth.company);
  const token = useSelector(state => state.auth.token);

  const [isSubmiting, setSubmiting] = useState(false)
  const [total, setTotal] = useState(props.order && props.order.total || 0)
  const [notes, setNotes] = useState(props.order && props.order.quote || "")
  const [reject, setReject] = useState(props.order && props.order.rejectNotes || "")
  console.log(props)
  useEffect(() => {
    if (props.order) {
      setTotal(props.order.total)
      setNotes(props.order.quote)
      setReject(props.order.rejectNotes)
    }

  }, [props.order])

  const handleQuote = () => {
    if (!total) return Swal.fire({ icon: 'error', title: 'Total Amount is required!', timer: 1500 })
    if (!notes) return Swal.fire({ icon: 'error', title: 'Notes required', timer: 1500 })
    handleSubmitOrder(false)

  }
  const handleReject = () => {
    if (!reject) return Swal.fire({ icon: 'error', title: 'Reject reason is required', timer: 2000 })
    handleSubmitOrder(true)
  }

  const handleSubmitOrder = (isRejected) => {
    if (props.order && props.order.status !== 'placed') return Swal.fire({ icon: 'error', title: 'You can not quote or Reject this order now!' })
    if (isSubmiting) return;
    setSubmiting(true)
    let ORDER = { ...props.order };
    ORDER.status = isRejected ? 'rejected' : 'quoted';
    if (isRejected) ORDER.rejectNotes = reject;
    if (!isRejected) {
      ORDER.quote = notes;
      ORDER.total = total;
    }
    updateOrder(ORDER.id, ORDER, token)
      .then(response => {
        setSubmiting(false)
        if (typeof response === 'string') {
          return Swal.fire({ icon: 'error', title: "Something went wrong!", text: response, timer: 2000 })
        }
        Swal.fire({ icon: 'success', title: `Successfully ${isRejected ? 'Rejected' : "Placed"}`, timer: 1500 })
        history.push("/orders_history");
      })
  }

  return (
    <div className="bill_info_vendor">
      <h3> Bill Details:</h3>

      <div>
        <p>{props.order && props.order.manufacturer && props.order.manufacturer.name}</p>
        <p>{props.order && props.order.manufacturer && props.order.manufacturer.email}</p>
      </div>

      <div className="row">
        <div className="col-4">Total</div>
        <div className="col-8">
          <input className="input-group form-group fild2" type="number" min="0" value={total} onChange={({ target }) => setTotal(target.value)} />
        </div>
      </div>

      <div className="row">
        <div className="col-4">Quote Notes</div>
        <div className="col-8">
          <textarea className="input-group form-group fild2" rows="4" cols="50" value={notes} onChange={({ target }) => setNotes(target.value)} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <button className="btn_grn" onClick={handleQuote}> Send Quote </button>
        </div>
        <br />
        OR
        <br />
        <br />
        <div className="col-12">
          <textarea className="input-group form-group fild2" rows="4" cols="50" value={reject} onChange={({ target }) => setReject(target.value)} />
        </div>
        <div className="col-12">
          <button className="btn btn-primary" onClick={handleReject}> Reject </button>
        </div>
        <br />
        <br />
      </div>

    </div>
  )
}
import * as types from '../constants/subscription';

const initialState = {
 subscriptions: [],
 plans: [],
 bill: 0,
};

function reducer(state = initialState, action) {
 switch (action.type) {
  case types.GET_SUBSCRIPTION_SUCCESS:
   return { ...state, subscriptions: action.payload };
  case types.GET_PLANS_SUCCESS:
   return { ...state, plans: action.payload };
  case types.GET_BILL_SUCCESS:
   return { ...state, bill: action.payload };
  default:
   return state;
 }
}

export default reducer;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateRenewal } from "../../store/services/subscription";
import { addCompany } from "../../store/actions/actions";
import { getCoupons } from "../../store/actions/signUp";
import { getCompanies } from "../../store/services/company";

import { CalculateMonth } from "../../constants"
import UpdateCard from "./card.update";
import Swal from "sweetalert2";

const RenewalSubscription = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const company = useSelector((state) => state.auth.company);
  const bill = useSelector((state) => state.subscription.bill.invoice);
  const createdAt = (company && new Date(company.createdAt)) || new Date();

  const [card, setCard] = useState({});
  const [termDate, setTermDate] = useState(false);

  const [step, setStep] = useState(0);

  useEffect(() => {
    dispatch(getCoupons());
  }, []);

  useEffect(() => {
    termHandler()
  }, [company])

  const termHandler = async () => {
    if (typeof company === 'object' && company.createdAt) {
      let termYears = await CalculateMonth(new Date, new Date(company.termDate ? company.termDate : company.createdAt));
      termYears = Math.round(termYears / 12);
      setTermDate(String(termYears) === String(company.term) ? true : false)
    }
  }

  const handleStep = (stp) => {
    setStep(stp);
  };
  const renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <Summary
            company={company}
            bill={bill}
            createdAt={createdAt}
            termDate={termDate}
            handleStep={handleStep}
            handleUpdate={handleUpdate}
          />
        );
      case 1:
        return (
          <Edit
            company={company}
            bill={bill}
            termDate={termDate}
            createdAt={createdAt}
            handleCard={handleCard}
            handleStep={handleStep}
            handleUpdate={handleUpdate}
          />
        );
      case 2:
        return (
          <Renew
            company={company}
            bill={bill}
            createdAt={createdAt}
            termDate={termDate}
            handleCard={handleCard}
            handleStep={handleStep}
            handleUpdate={handleUpdate}
          />
        );
      default:
        return (
          <Summary
            company={company}
            bill={bill}
            createdAt={createdAt}
            handleStep={handleStep}
            handleUpdate={handleUpdate}
          />
        );
    }
  };

  const handleCard = (CARD) => {
    setCard(CARD);
  };

  const handleUpdate = ({ term, isAnnual }) => {
    const payload = {
      ...company,
      term,
      isAnnual,
      subscribeOn: new Date(),
      subscriptionUpdatedOn: company.subscribeOn,
      ...card,
    };
    updateRenewal(company.id, payload, token).then((response) => {
      if (typeof response === "object") {
        dispatch(addCompany(response));
        props.getInitialData();
        Swal.fire({ icon: "success", title: "Subscription Updated!" });
        return window.location.replace("/subscription-details");
      }
    });
  };

  return (
    <div id="page-content-wrapper">
      <div className="container-fluid">
        <div
          className="row setup_top2 justify-content-md-center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-12 col-sm-8">{renderSteps()}</div>
        </div>
      </div>
    </div>
  );
};

export default RenewalSubscription;

const Summary = ({ handleStep, company, createdAt, bill }) => {
  return (
    <div className="box_payment">
      <div className="heding1">Payment Renewal</div>
      <div className="inside">
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "2%",
          }}
        >
          <div>
            <p>
              <b>Term Length: </b>{" "}
              {Number(company.term) === 1 ? "annual" : "3-years"}{" "}
            </p>
            <p>
              <b>Renewal type: </b>{" "}
              {company && company.isAnnual ? "Manual" : "Automatic"}{" "}
            </p>
            <p>
              <b>
                {Number(company.term) && Number(company.term) < 3
                  ? "Subscription End Date"
                  : "Date of Upcoming Renewal Charge"}
                :{" "}
              </b>
              {Number(company.term) && Number(company.term) < 3
                ? new Date(
                  createdAt.setFullYear(createdAt.getFullYear() + 1)
                ).toLocaleDateString()
                : new Date(
                  createdAt.setFullYear(createdAt.getFullYear() + 3)
                ).toLocaleDateString()}
            </p>
            <p>
              <b>Estimated Renewal Charge: </b> $
              {(bill && Number.parseInt(bill.netPayable).toFixed(2)) || 0} / {company.isAnnual ? "Annum" : "Month"}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 center">
            <button
              type="btn"
              className="btn btn-primary"
              data-target="#exampleModal"
              onClick={() => handleStep(1)}
            >
              Edit Subscription Details
            </button>
          </div>
          {/* {company && company.isAnnual && */}
          <div className="col-md-6 center">
            <button
              type="btn"
              className="btn btn-primary"
              data-target="#exampleModal"
              onClick={() => handleStep(2)}
            >
              Renew Subscription
            </button>
          </div>
          {/* } */}
        </div>
      </div>
    </div>
  );
};
const Edit = ({
  handleStep,
  handleUpdate,
  company,
  handleCard,
  createdAt,
  termDate,
  bill,
}) => {
  const settings = useSelector((state) => state.settings.settings);
  const coupons = useSelector((state) => state.signUp.coupons);
  const token = useSelector((state) => state.auth.token);

  const [autoDiscount, setAutoDiscount] = useState(0);
  const [autoType, setAutoType] = useState("%");
  const [refCode, setRefCode] = useState("");
  const [term, setTerm] = useState(company.term || 3);
  const [isAnnual, setAnnual] = useState(company.isAnnual);
  const [refCount, setRefCount] = useState(0);

  useEffect(() => {
    Array.isArray(coupons) &&
      coupons.map((coupon) => {
        if (coupon.companyId === company.id) setRefCode(coupon);
        if (
          coupon.couponType === "auto" &&
          coupon.minUser <= company.maxUsers
        ) {
          setAutoDiscount(coupon.discount);
          setAutoType(coupon.type);
        }
      });
  }, [coupons]);

  useEffect(() => {
    if (refCode && refCode.id) {
      const filter = {
        where: {
          and: [
            { or: [{ couponId: refCode.id }, { refCode: refCode.name }] },
            { createdAt: { lte: Date() } },
          ],
        },
      };
      getCompanies(filter, token).then((response) => {
        console.log("companies: ---> ", response);
        if (Array.isArray(response) && response.length > 0) {
          let multiplier =
            (Array.isArray(settings) &&
              settings.filter(
                (set) =>
                  set.name ===
                  (company.type === "vendor"
                    ? "DefaultVendorRefDiscount"
                    : "DefaultManufacturerRefDiscount")
              )[0].value.discount) ||
            0;
          let refDiscount = 0;
          response.map((com, ind) => {
            refDiscount += Number(
              company.rfHistorical ? com.maxUsers : com.signupUsers || 0
            );
            if (ind + 1 === response.length) {
              let rfDiscount = Number(Number(refDiscount * multiplier) - Number(company.rfHistorical));
              if (Number(company.rfHistorical) > Number(refDiscount * multiplier)) rfDiscount = 0;
              setRefCount(rfDiscount);
            }
          });
        }
      });
    }
  }, [refCode]);

  const calculateTotal = () => {
    if (!bill) return { discount: 0, netBill: 0 };
    let termDiscount =
      term === 3
        ? Array.isArray(settings) &&
        settings.filter((set) => set.name === "TermDiscount")[0].value.discount
        : 0;
    let autoDisc =
      autoType === "USD"
        ? Number((autoDiscount * 100) / bill.currentDues)
        : autoDiscount;
    let discount = refCount + Number(termDiscount) + Number(autoDisc);
    discount = discount > 75 ? 75 : discount;
    const netBill = bill.currentDues - bill.currentDues * (discount / 100);
    return { discount, netBill };
  };

  const { discount, netBill } = calculateTotal();
  return (
    <div className="box_payment">
      <div className="heding1">Edit Subscription Renew Details</div>
      <div className="inside">
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "2%",
          }}
        >
          <div>
            <select
              name="term"
              className="form-control"
              style={{ width: "350px" }}
              disabled={!termDate}
              defaultValue={String(term)}
              onChange={({ target }) => setTerm(Number(target.value))}
            >
              <option value="1">1 Year</option>
              <option value="3">3 Years</option>
            </select>
          </div>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "2%",
          }}
        >
          <div>
            <select
              className="form-control"
              style={{ width: "350px" }}
              name="isAnnual"
              defaultValue={String(isAnnual)}
              onChange={({ target }) => setAnnual(target.value)}
            >
              <option value="true">Pay annually with check</option>
              <option value="false">Pay monthly with card</option>
            </select>
          </div>
        </div>
        <br />
        {/* SUMMARY */}
        {term === 3 && (
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2%",
            }}
          >
            <span>
              Term discount:{" "}
              {(Array.isArray(settings) &&
                settings.filter((set) => set.name === "TermDiscount")[0].value
                  .discount) ||
                0}
              %{" "}
            </span>
          </div>
        )}
        {autoDiscount > 0 && (
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2%",
            }}
          >
            <span>
              Bulk user discount: {autoType === "USD" && "$"}
              {autoDiscount}
              {autoType !== "USD" && "%"}{" "}
            </span>
          </div>
        )}
        {Number(refCount) > 0 && (
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2%",
            }}
          >
            <span>
              Referral discount:{" "}
              {Number(
                (refCount > 75) ? 75 : refCount
              )}
              %{" "}
            </span>
          </div>
        )}
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "2%",
          }}
        >
          <b>
            <span>Estimated Renewal Discount: {discount}% </span>
          </b>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "2%",
          }}
        >
          <b>
            <span>Estimated Renewal Charge: $
              {Number.parseFloat(netBill).toFixed(2) || 0} / {String(isAnnual) === 'true' ? "Annum" : 'Month'}
            </span>
          </b>
        </div>
        {/* END SUMMARY */}
        {isAnnual.toString() === "false" && (
          <div className="row">
            <UpdateCard setCard={handleCard} renewal={true} />
          </div>
        )}
        <div className="row">
          <div className="col-md-6 center">
            <button
              type="btn"
              className="btn btn-primary"
              data-target="#exampleModal"
              onClick={() => handleStep(0)}
            >
              Cancel Change
            </button>
          </div>
          <div className="col-md-6 center">
            <button
              type="btn"
              className="btn btn-primary"
              data-target="#exampleModal"
              onClick={() => handleUpdate({ term: term, isAnnual: isAnnual })}
            >
              Confirm Payment Change
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const Renew = ({
  handleStep,
  handleUpdate,
  company,
  handleCard,
  createdAt,
  termDate,
  bill,
}) => {
  const date = new Date();

  const settings = useSelector((state) => state.settings.settings);
  const coupons = useSelector((state) => state.signUp.coupons);
  const token = useSelector((state) => state.auth.token);

  const [autoDiscount, setAutoDiscount] = useState(0);
  const [autoType, setAutoType] = useState("%");
  const [refCode, setRefCode] = useState("");
  const [term, setTerm] = useState(company.term || 3);
  const [isAnnual, setAnnual] = useState(company.isAnnual);
  const [refCount, setRefCount] = useState(0);

  useEffect(() => {
    Array.isArray(coupons) &&
      coupons.map((coupon) => {
        if (coupon.companyId === company.id) setRefCode(coupon);
        if (
          coupon.couponType === "auto" &&
          coupon.minUser <= company.maxUsers
        ) {
          setAutoDiscount(coupon.discount);
          setAutoType(coupon.type);
        }
      });
  }, [coupons]);

  useEffect(() => {
    if (refCode && refCode.id) {
      const filter = {
        where: {
          and: [
            { or: [{ couponId: refCode.id }, { refCode: refCode.name }] },
            { createdAt: { lte: Date() } },
          ],
        },
      };
      getCompanies(filter, token).then((response) => {
        console.log("companies: ---> ", response);
        if (Array.isArray(response) && response.length > 0) {
          let multiplier =
            (Array.isArray(settings) &&
              settings.filter(
                (set) =>
                  set.name ===
                  (company.type === "vendor"
                    ? "DefaultVendorRefDiscount"
                    : "DefaultManufacturerRefDiscount")
              )[0].value.discount) ||
            0;
          let refDiscount = 0;
          response.map((com, ind) => {
            refDiscount += Number(
              company.rfHistorical ? com.maxUsers : com.signupUsers || 0
            );

            if (ind + 1 === response.length) {
              let rfDiscount = Number(Number(refDiscount * multiplier) - Number(company.rfHistorical));
              if (Number(company.rfHistorical) > Number(refDiscount * multiplier)) rfDiscount = 0;
              setRefCount(rfDiscount);
            }
          });
        }
      });
    }
  }, [refCode]);

  const calculateTotal = () => {
    if (!bill) return { discount: 0, netBill: 0 };
    let termDiscount =
      term === 3
        ? Array.isArray(settings) &&
        settings.filter((set) => set.name === "TermDiscount")[0].value
          .discount
        : 0;
    let autoDisc =
      autoType === "USD"
        ? Number((autoDiscount * 100) / bill.currentDues)
        : autoDiscount;
    let discount = refCount + Number(termDiscount) + Number(autoDisc);
    discount = discount > 75 ? 75 : discount;
    const netBill = bill.currentDues - bill.currentDues * (discount / 100);
    return { discount, netBill };
  };

  const { discount, netBill } = calculateTotal();

  return (
    <div className="box_payment">
      <div className="heding1">Renew Subscription</div>
      <div className="inside">
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "2%",
          }}
        >
          <div>
            <select
              name="term"
              disabled={!termDate}
              className="form-control"
              style={{ width: "350px" }}
              defaultValue={String(term)}
              onChange={({ target }) => setTerm(Number(target.value))}
            >
              <option value="1">1 Year</option>
              <option value="3">3 Years</option>
            </select>
          </div>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "2%",
          }}
        >
          <div>
            <select
              className="form-control"
              style={{ width: "350px" }}
              name="isAnnual"
              defaultValue={String(isAnnual)}
              onChange={({ target }) => setAnnual(target.value)}
            >
              <option value="true">Pay annually with check</option>
              <option value="false">Pay monthly with card</option>
            </select>
          </div>
        </div>
        <br />

        {term === 3 && (
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2%",
            }}
          >
            <span>
              Term discount:{" "}
              {(Array.isArray(settings) &&
                settings.filter((set) => set.name === "TermDiscount")[0].value
                  .discount) ||
                0}
              %{" "}
            </span>
          </div>
        )}
        {autoDiscount > 0 && (
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2%",
            }}
          >
            <span>
              Bulk user discount: {autoType === "USD" && "$"}
              {autoDiscount}
              {autoType !== "USD" && "%"}{" "}
            </span>
          </div>
        )}
        {(Number(refCount) > 0) && (
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "2%",
            }}
          >
            <span>
              Referral discount:{" "}
              {Number.parseFloat(
                refCount > 75
                  ? 75
                  : refCount
              ).toFixed(0)}
              %{" "}
            </span>
          </div>
        )}

        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "2%",
          }}
        >
          <p>
            <b>Estimated Renewal Discount: </b> {discount || 0}%{" "}
          </p>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "2%",
          }}
        >
          <p>
            <b>Estimated Renewal Charge: </b> $
            {Number.parseFloat(netBill).toFixed(2) || 0} / {String(isAnnual) === 'true' ? "Annum" : 'Month'}
          </p>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "2%",
          }}
        >
          <p>
            <b>New Subscription End Date: </b>{" "}
            {new Date(
              date.setFullYear(date.getFullYear() + Number(term))
            ).toLocaleDateString()}{" "}
          </p>
        </div>
        <br />
        {isAnnual.toString() === "false" && (
          <div className="row">
            <UpdateCard setCard={handleCard} renewal={true} />
          </div>
        )}
        <div className="row">
          <div className="col-md-6 center">
            <button
              type="btn"
              className="btn btn-primary"
              data-target="#exampleModal"
              onClick={() => handleStep(0)}
            >
              Cancel Payment
            </button>
          </div>
          <div className="col-md-6 center">
            <button
              type="btn"
              className="btn btn-primary"
              data-target="#exampleModal"
              onClick={() => handleUpdate({ term: term, isAnnual: isAnnual })}
            >
              Pay Renewal
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

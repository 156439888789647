import React from "react"
import {Link} from 'react-router-dom'
export default function dashboardItem(props) {
	return (
		<div className="col-12 col-sm-4">
            <Link to={props.item.redirectPath} className="method">
                <div className="lft_prt">
        	       <img alt="" src={props.item.imgSrc}/>
                </div>
                <div className="rt_prt">{props.item.name}</div>
                <div className="hvr_efect">
                    <img alt="" src={props.item.imgSrc}/>
                    <span className="spn_txt">{props.item.name}</span>
				</div>
            </Link>
        </div>
	);
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Modal from "react-bootstrap/Modal";

import { orderDetailTableSet } from '../../constants';
import { addCompany } from '../../store/actions/actions';
import { getVendors, getCustomer } from '../../store/actions/configuration';
import { updateCompany } from '../../store/services/company';

import './style.css';
import { addVendor, notifyVendor,referVendorEmail, updateCustomerById } from '../../store/services/configuration';
const Configurations = (props) => {
 const company = useSelector(state => state.auth.company);
 const userEmail = useSelector(state => state.auth.userEmail);
 const token = useSelector(state => state.auth.token)
 const dispatch = useDispatch();
 const [dataSet, setDataset] = useState(orderDetailTableSet);

 useEffect(() => {
  company.orderDetailtable && Array.isArray(company.orderDetailtable) && setDataset(company.orderDetailtable)
 }, [company])

 const handleOnChange = ({ target }) => {
  var sets = dataSet.map(set => {
   if (set.id === target.name) { set.hidden = target.checked } return set
  });
  setDataset(sets);
  update(sets);
 }
 const handleFieldName = ({ target }) => {
  var sets = dataSet.map(set => {
   if (set.id === target.name) { set.name = target.value } return set
  });
  setDataset(sets);
 }

 const update = (orderDetailtable) => {
  orderDetailtable = Array.isArray(orderDetailtable) ? orderDetailtable : dataSet;
  updateCompany(company.id, { orderDetailtable }, token)
   .then((response) => {
    if (typeof response === 'object') return dispatch(addCompany(response))
    throw (response)
   })
   .catch(err => Swal.fire('something went wrong!'))
 }

 return (
  <div className="configuration_Container container">
   <h1> Configuration </h1>
   <hr />
   <div className="row">
    {/* OrderDetailTable */}
    <div className="col-4">
     <h3> Order Detail Table fields: </h3>
     <table className="orderDataTbl">
      <tr>
       <th key={'heading1'}>Fields Name</th>
       <th key={'Data1'}>Hide</th>
      </tr>
      {Array.isArray(dataSet) && dataSet.map((th, ind) => {
       return (
        <tr key={th.id + ind}>
         <th>
          {th.id.indexOf('custom') > -1 ?
           <input type='text' onChange={handleFieldName} onBlur={update} name={th.id} value={th.name} id={th.id} />
           : th.name}
         </th>
         <td key={th.name + ind}>
          <input type="checkbox" onChange={handleOnChange} name={th.id} id={th.id} checked={th.hidden} />
         </td>
        </tr>
       )
      })}
     </table>
    </div>

    {/* Bill and Vendor */}
    <div className="col-6">
     <div className="container">
      <h3> Bill Details: </h3>
      <pre>
       {company && company.name} <br />
       {userEmail}
      </pre>
     </div>
     <CustomVendor />
    </div>

   </div>
  </div>
 )
}

export default Configurations;

const CustomVendor = (props) => {
 const customers = useSelector(state => state.configuration.customers);
 const token = useSelector(state => state.auth.token);
 const [addModal, setAddModal] = useState(false);
 const [referModal, setreferModal] = useState(false);
 const dispatch = useDispatch();
 useEffect(() => {
  dispatch(getVendors())
  dispatch(getCustomer('manufacturer'))
 }, []);

 const handleStatusChange = async ({ id }, { target }) => {
  const payload = { status: target.value };
  let response = await updateCustomerById(id, payload, token)
  if (typeof response !== 'string') {
   Swal.fire({ icon: "success", title: "Successfully Updated", timer: 2000 })
   return dispatch(getCustomer('manufacturer'))
  }
  return Swal.fire({ icon: "error", title: "something went wrong!", timer: 2000 })
 }
 return (
  <div className="container">
   <div className="vendorTableContainer">
    <table>
     <thead>
      <th>Vendor</th>
      <th>Status</th>
     </thead>
     <tbody>
      {Array.isArray(customers) && customers.map(customer => {
       return <tr key={customer.id}>
        <td> {customer.companyName || customer.name} </td>
        <td>
         <select defaultValue={customer.status} onChange={(e) => handleStatusChange(customer, e)} >
          <option value={true}>Active</option>
          <option value={false}>Inactive</option>
         </select>
        </td>
       </tr>
      })}
     </tbody>
    </table>
   </div>
   <div className="row">
    <div className="col-6">
     <button className="btn btn-primary" onClick={() => setAddModal(!addModal)}>Add Vendor</button>
    </div>
    <div className="col-6">
      Your vendor is not listed?
     <button className="btn btn-primary" onClick={() => setreferModal(!referModal)}>Refer a Vendor</button>
    </div>
   </div>
   <AddModal addModal={addModal} afterAdd={() =>  dispatch(getCustomer('manufacturer'))} onClose={() => setAddModal(false)} />
   <ReferVendorModal addModal={referModal} onClose={() => setreferModal(false)} />
  </div>
 )
}

const ReferVendorModal = (props) => {
 const token = useSelector(state => state.auth.token);
 const COMPANY = useSelector(state => state.auth.company);

 const [email, setEmail] = useState('');
 const [name, setName] = useState('');
 const [phone, setPhone] = useState('');
 const [company, setCompany] = useState('');

 const handleRefer = async () => {
  if (!email || !name || !phone || !company) return Swal.fire({ icon: "error", title: "Provide complete data", timer: 2000 })
  const paylaod = { email, name, phone, company, manufacturer: { name: COMPANY.name, referralCode: COMPANY.referralCode } }
  let response = await referVendorEmail(paylaod, token)
  if (typeof response !== 'string') {
   Swal.fire({ icon: "success", title: 'Vendor referred', timer: 2000 });
   props.onClose();
  }
 }

 return (
  <Modal
   size={'lg'}
   show={props.addModal}
   centered
  >
   <Modal.Body>
    <div>
     <h5> Enter contact information for the vendor you'd like to add: </h5>
    </div>
    <hr />
    <form onSubmit={(e) => { e.preventDefault() }}>
     <div className="row">
      <div className="col-3">
       <label className="label" htmlFor="manufacturerCompany" >
        Compnay: &nbsp;
      </label>
      </div>
      <div className="col-6">
       <input type="text"
        className="" id="manufacturerCompany"
        name="company"
        value={company}
        onChange={({ target }) => setCompany(target.value)}
       />
      </div>
     </div>
     <div className="row">
      <div className="col-3">
       <label className="label" htmlFor="manufacturerName" >
        Contact Name: &nbsp;
      </label>
      </div>
      <div className="col-6">
       <input type="text"
        className="" id="manufacturerName"
        name="name"
        value={name}
        onChange={({ target }) => setName(target.value)}
       />
      </div>
     </div>
     <div className="row">
      <div className="col-3">
       <label className="label" htmlFor="manufacturerPhone" >
        Phone: &nbsp;
      </label>
      </div>
      <div className="col-6">
       <input type="text"
        className="" id="manufacturerPhone"
        name="phone"
        value={phone}
        onChange={({ target }) => setPhone(target.value)}
       />
      </div>
     </div>
     <div className="row">
      <div className="col-3">
       <label className="label" htmlFor="manufacturerEmail" >
        Email: &nbsp;
      </label>
      </div>
      <div className="col-6">
       <input type="email"
        className="" id="manufacturerEmail"
        name="email"
        value={email}
        onChange={({ target }) => setEmail(target.value)}
       />
      </div>
     </div>
    </form>
    <br />
    <br />
    <div>
     <h5>We will connect with vendor and alert you when they are available.</h5>
    </div>
   </Modal.Body>
   <Modal.Footer>
    <div className="row">
     <div className="col-6"> <button className="btn_grn" onClick={handleRefer}> Okay </button> </div>
     <div className="col-6"> <button className="btn btn-primary" onClick={props.onClose}> Cancel </button> </div>
    </div>
   </Modal.Footer>
  </Modal>
 )
}

const AddModal = (props) => {
 const vendors = useSelector(state => state.configuration.vendors);
 const companyId = useSelector(state => state.auth.companyId);
 const COMPANY = useSelector(state => state.auth.company);
 const token = useSelector(state => state.auth.token);

 const [customVendors, setCustomer] = useState([]);
 const [email, setEmail] = useState('');
 const [name, setName] = useState('');
 const [phone, setPhone] = useState('');
 const [company, setCompany] = useState('');

 const dispatch = useDispatch();
 useEffect(() => {
  dispatch(getVendors())
 }, []);

 const handleCheck = ({ target }) => {
  const { name, checked, id } = target;
  let customers = [...customVendors];
  let check = vendors.filter(vendor => vendor.id === name)[0];
  if (!customers.filter(customer => customer.id === check.id).length) {
   customers.push({ id: check.id, email: check.owner.email, name: check.owner.username, companyName: check.name, phone: check.owner.phone });
   return setCustomer(customers)
  } else {
   customers = customers.filter(customer => customer.id !== check.id);
   return setCustomer(customers)
  }
 }

 const handleAdd = async () => {
  if (!email || !name || !phone || !company) return Swal.fire({ icon: "error", title: "Provide complete data", timer: 2000 })
  if (customVendors.length < 1) return Swal.fire({ icon: 'error', title: 'Chose a vendor first', timer: 2000 })
  customVendors.map((vendor) => {
   let params = { vendor, name, email, phone, company }
   let customer = { manufacturerId: companyId, vendorId: vendor.id, companyName: vendor.companyName, name: vendor.name, email: vendor.email, phone: vendor.phone, referralCode: COMPANY.referralCode, status: true }
   addVendor(customer, token)
    .then(async response => {
     if (typeof response !== 'string') {
      let notify = await notifyVendor(params, token)
      if (typeof notify !== 'string') {
       Swal.fire({ icon: "success", title: 'Vendor added', timer: 2000 });
       props.afterAdd();
       props.onClose();
      }
     }
    })
  })
 }

 return (
  <Modal
   size={'lg'}
   show={props.addModal}
   centered
  >
   <Modal.Header>
    <div className="container modal_vendor_table">
     <table className="modal_vendor_table">
      {Array.isArray(vendors) && vendors.map((vendor) => {
       return <th className="row" key={vendor.id}>
        <div className="custom-control custom-checkbox">
         <input type="checkbox"
          className="custom-control-input" id={vendor.id}
          name={vendor.id}
          onChange={handleCheck}
         />
         <label className="custom-control-label" htmlFor={vendor.id} >
          {vendor.name}
         </label>
        </div>
       </th>
      })}
     </table>
    </div>
   </Modal.Header>
   <Modal.Body>
    <div>
     <h5> Enter information below that the vendor may contact you at. </h5>
    </div>
    <hr />
    <form onSubmit={(e) => { e.preventDefault() }}>
     <div className="row">
      <div className="col-2">
       <label className="label" htmlFor="manufacturerName" >
        Name: &nbsp;
      </label>
      </div>
      <div className="col-6">
       <input type="text"
        className="" id="manufacturerName"
        name="name"
        value={name}
        onChange={({ target }) => setName(target.value)}
       />
      </div>
     </div>
     <div className="row">
      <div className="col-2">
       <label className="label" htmlFor="manufacturerCompany" >
        Compnay: &nbsp;
      </label>
      </div>
      <div className="col-6">
       <input type="text"
        className="" id="manufacturerCompany"
        name="company"
        value={company}
        onChange={({ target }) => setCompany(target.value)}
       />
      </div>
     </div>
     <div className="row">
      <div className="col-2">
       <label className="label" htmlFor="manufacturerPhone" >
        Phone: &nbsp;
      </label>
      </div>
      <div className="col-6">
       <input type="text"
        className="" id="manufacturerPhone"
        name="phone"
        value={phone}
        onChange={({ target }) => setPhone(target.value)}
       />
      </div>
     </div>
     <div className="row">
      <div className="col-2">
       <label className="label" htmlFor="manufacturerEmail" >
        Email: &nbsp;
      </label>
      </div>
      <div className="col-6">
       <input type="email"
        className="" id="manufacturerEmail"
        name="email"
        value={email}
        onChange={({ target }) => setEmail(target.value)}
       />
      </div>
     </div>
    </form>
   </Modal.Body>
   <Modal.Footer>
    <div className="row">
     <div className="col-6"> <button className="btn btn-primary" onClick={props.onClose}> Cancel </button> </div>
     <div className="col-6"> <button className="btn_grn" onClick={handleAdd}> Okay </button> </div>
    </div>
   </Modal.Footer>
  </Modal>
 )
}
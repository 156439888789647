import { put, select, takeLatest, call } from 'redux-saga/effects';
import { selectAuthToken, selectTokenAndId, selectCompanyId } from '../selectors/auth';
import * as service from '../services/configuration';

import * as types from '../constants/configuration';


function* getVendors() {
 try {
  const token = yield select(selectAuthToken);
  const vendors = yield call(service.getVendors, token);
  if (typeof vendors !== 'string') {
   yield put({ type: types.GET_VENDOR_SUCCESS, payload: vendors })
   return;
  }
  yield put({ type: types.GET_VENDOR_FAILURE, payload: "no data found" })
 } catch (error) {
  yield put({ type: types.GET_VENDOR_FAILURE, payload: error.message })
  console.log("ERR ", error.message);
 }
}

function* getCustomVendors(actions) {
 try {
  const token = yield select(selectAuthToken);
  const companyId = yield select(selectCompanyId);
  const vendors = yield call(actions.payload === 'vendor' ? service.getManufacturerByVendorId : service.getVendorByManufacturerId, companyId, token);
  if (typeof vendors !== 'string') {
   yield put({ type: types.GET_CUSTOM_VENDOR_SUCCESS, payload: vendors })
   return;
  }
  yield put({ type: types.GET_CUSTOM_VENDOR_FAILURE, payload: "no data found" })
 } catch (error) {
  yield put({ type: types.GET_CUSTOM_VENDOR_FAILURE, payload: error.message })
  console.log("ERR ", error.message);
 }
}


export default function* configurationWatcher() {
 yield takeLatest(types.GET_VENDOR_REQUEST, getVendors);
 yield takeLatest(types.GET_CUSTOM_VENDOR_REQUEST, getCustomVendors);
}

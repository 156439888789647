import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { getOrders, updateOrder } from '../../store/services/order';
import Spinner from "../../components/loaders/spinner";


const QuoteStatusModal = (props) => {

  const { id } = useParams();
  const history = useHistory()
  const company = useSelector(state => state.auth.company);
  const token = useSelector(state => state.auth.token);
  const [isSubmiting, setSubmiting] = useState(false);


  const handleSubmitOrder = (isRejected) => {
    if (props.order.status !== 'quoted') return Swal.fire({ icon: 'error', title: 'Already accepted/rejected' })
    if (isSubmiting) return;
    setSubmiting(true)
    let ORDER = { ...props.order };
    ORDER.status = isRejected ? 'cancelled' : 'accepted';
    updateOrder(ORDER.id, ORDER, token)
      .then(response => {
        setSubmiting(false)
        if (typeof response === 'string') {
          return Swal.fire({ icon: 'error', title: "Something went wrong!", text: response, timer: 2000 })
        }
        Swal.fire({ icon: 'success', title: `Successfully ${isRejected ? 'Cancelled' : "Accepted"}`, timer: 1500 })
        props.close();
        // history.push("/orders_history");
      })
  }


  return (
    <Modal
      className="container-fluid"
      show={props.modal}
      onHide={props.close}
      centered
      size="xl"
      dialogClassName="modal_width"
    >
      <Modal.Header>
        <div className="container">
          <h3>Quote Status</h3>
        </div>
      </Modal.Header>

      <Modal.Body>
        <form className="container QuoteStatusModal" onSubmit={(e) => e.preventDefault()}>

          {props.status === 'rejected' ?
            <div className="row">
              Quote Status: {props.status.toString()}
            </div>
            :
            <div className="row">
              Quote Amount: ${props.total || 0}
            </div>
          }

          <div className="row">
            {props.status === 'rejected' ? "Reason for Rejection: " : "Notes:"}
          </div>
          <div className="row">
            <textarea
              rows="5"
              cols="70"
              name="fabricId"
              value={props.status === 'rejected' ? props.rejectNotes : props.acceptedNotes}
              placeholder="The text the vendor submitted under the 'Vender Fabric Order Detail Review' page as Rejection Reason should display here"
            />
          </div>

        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="modalBtnWrapper">
          {props.order.status === "quoted" &&
            <>
              <button className="btn_grn" onClick={() => handleSubmitOrder(false)}>Accept</button>
              <button className="btn_red" onClick={() => handleSubmitOrder(true)}>Cancel</button>
            </>
          }
          <button className="btn btn-primary" onClick={props.close}>close</button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default QuoteStatusModal;
import React, { Component } from 'react';
import editImage from './../../../img/edit.png';
import Spinner from './../../../components/loaders/spinner.js';
import delImage from './../../../img/delete.png';
import EditSwatchDetails from './editSwatchDetails.js';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ApiUrl } from '../../../constants';
class swatchDetailsRow extends Component {
	constructor(props) {
		super(props)
		this.state = {
			id: null,
			code: null,
			fabricName: null,
			patternName: null,
			color: null,
			grade: null,
			gradeNotice: false,
			activeStatus: false,
			open: false,
			deleting: false,
			email: false,
		}
		//this.getSwatchDetails(this.props.swatchId);
		this.handleEdit = this.handleEdit.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.saveStatus = this.saveStatus.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
		this.handleDeleteRow = this.handleDeleteRow.bind(this);
		this.saveNotice = this.saveNotice.bind(this);
	}
	componentDidMount() {
		this.setState({
			id: this.props.details.id,
			code: this.props.details.code,
			fabricName: this.props.details.fabric_name,
			patternName: this.props.details.pattern_name,
			color: this.props.details.color,
			grade: this.props.details.grade,
			activeStatus: this.props.details.active,
			gradeNotice: this.props.details.grade_notice,
			email: this.props.email
		})
		this.handleFabric()
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props !== prevProps) {
			this.setState({
				id: this.props.details.id,
				code: this.props.details.code,
				fabricName: this.props.details.fabric_name,
				patternName: this.props.details.pattern_name,
				color: this.props.details.color,
				grade: this.props.details.grade,
				activeStatus: this.props.details.active,
				gradeNotice: this.props.details.grade_notice
			})
			this.handleFabric()
		}
	}

	handleFabric = () => {
		if (typeof this.props.details.fabrics === 'object') {
			this.setState({
				code: this.props.details.fabrics.code,
				fabricName: this.props.details.fabrics.alias,
				patternName: this.props.details.fabrics.pattern_name,
				color: this.props.details.fabrics.color,
				grade: this.props.details.fabrics.grade,
			})
		}
	}

	handleEdit = () => {
		this.setState({
			open: true
		})
	}

	openModal() {
		this.setState({ open: true });
	}
	closeModal = () => {
		return new Promise((resolve, reject) => {
			this.setState({ open: false }, () => {
				resolve("");
			});
		})

	}

	handleDeleteRow = () => {
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Delete Swatch"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		this.setState({
			deleting: true
		}, () => {
			this.props.handleDeleteSwatch(this.props.details.id).then(() => {
				this.setState({
					deleting: false
				})
			}).catch(() => {
				this.setState({
					deleting: false
				})
			})
		})
	}

	handleCheck(event) {
		event.persist();
		const { permissions, role } = this.props;
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Edit Swatch"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		switch (event.target.name) {
			case "gradeNotice":
				this.saveNotice(event.target.checked).then((res) => {
					this.setState({
						gradeNotice: res,
					})
				})
				return;
			case "activeStatus":
				this.saveStatus(event.target.checked).then((res) => {
					this.setState({ activeStatus: res })
				})
				return;
		}
	}

	saveNotice = (val) => {
		return new Promise((resolve, reject) => {
			const swatch_data = {
				grade_notice: val,
			};
			axios.patch(ApiUrl + "/Swatches/" + this.props.details.id, swatch_data,
				{
					headers: {
						'Authorization': this.props.token
					}
				})
				.then(res => {
					this.props.handleUpdateGroup();
					resolve(res.data.grade_notice);
				})
				.catch(err => {
					reject(false);
					return null;
				})
		})

	}

	saveStatus = (statusValue) => {
		return new Promise((resolve, reject) => {
			const swatch_data = {
				active: statusValue,
			};
			axios.patch(ApiUrl + "/Swatches/" + this.props.details.id, swatch_data,
				{
					headers: {
						'Authorization': this.props.token
					}
				})
				.then(res => {
					resolve(res.data.active);
				})
				.catch(err => {
					console.log(err);
					reject("");
					return null;
				})
		})

	}
	
	render() {
		return (
			!this.state.deleting ?
				<tr>
					<td>{this.state.code}</td>
					<td>{this.state.fabricName}</td>
					<td>{this.state.patternName}</td>
					<td>{this.state.color}</td>
					<td>{this.state.grade}</td>
					<td className="text-center">
						<label className="switch mt-2" style={{ float: 'none' }}>
							<input type="checkbox" name='gradeNotice' onChange={this.handleCheck} checked={this.state.gradeNotice === "true" || this.state.gradeNotice} className="default" />
							<span className="slider round" />
						</label>
					</td>
					<td className="text-center">
						<label className="switch mt-2" style={{ float: 'none' }}>
							<input type="checkbox" name='activeStatus' onChange={this.handleCheck} checked={this.state.activeStatus} className="default" />
							<span className="slider round" />
						</label>
					</td>
					<td>
						<button className="edit_link btn btn-link" onClick={this.handleEdit}><img alt="" src={editImage} /></button>
					</td>
					<td>
						<button className="delete_link btn btn-link" onClick={this.handleDeleteRow}><img alt="" src={delImage} /></button>
					</td>
					<EditSwatchDetails details={this.state} getSwatches={this.props.getSwatches} handleUpdateGroup={this.props.handleUpdateGroup} close={this.closeModal} popupState={this.state.open} />
				</tr>
				:
				<tr>
					<td colSpan={9}>
						<Spinner style={{ display: 'flex', overflow: 'hidden' }} styleClass='text-primary' />
					</td>
				</tr>

		)
	}
}
const mapStateToProps = state => {
	return {
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,

	};
};
export default connect(mapStateToProps)(swatchDetailsRow)

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { getCustomer } from '../../../store/actions/configuration';
import { referVendorEmail, requestPaymentApiKey, updateCustomerById } from '../../../store/services/configuration';
import { Modal } from 'react-bootstrap';

const CustomersManagement = (props) => {
 const dispatch = useDispatch();
 const token = useSelector(state => state.auth.token)
 const company = useSelector(state => state.auth.company)
 const companyId = useSelector(state => state.auth.companyId)
 const customers = useSelector(state => state.configuration.customers)

 useEffect(() => {
  dispatch(getCustomer('vendor'))
 }, [])

 useEffect(() => {

 })
 return (
  <div>
   <CustomVendor />
  </div>
 )
}

export default CustomersManagement;

const CustomVendor = (props) => {
 const customers = useSelector(state => state.configuration.customers);
 const token = useSelector(state => state.auth.token);
 const [addModal, setAddModal] = useState(false);
 const [referModal, setreferModal] = useState(false);
 const dispatch = useDispatch();
 useEffect(() => {
  // dispatch(getVendors())
  dispatch(getCustomer('manufacturer'))
 }, []);

 const handleStatusChange = async ({ id }, { target }) => {
  const payload = { paymentOption: target.value };
  let response = await updateCustomerById(id, payload, token)
  if (typeof response !== 'string') {
   Swal.fire({ icon: "success", title: "Successfully Updated", timer: 2000 })
   return dispatch(getCustomer('manufacturer'))
  }
  return Swal.fire({ icon: "error", title: "something went wrong!", timer: 2000 })
 }

 return (
  <div className="container">
   <div className="vendorTableContainer">
    <table>
     <thead>
      <th>Customer</th>
      <th>Payment Option</th>
     </thead>
     <tbody>
      {Array.isArray(customers) && customers.map(customer => {
       return <tr key={customer.id}>
        <td> {customer.manufacturer && customer.manufacturer.name || 'N/A'} </td>
        <td>
         <select defaultValue={customer.paymentOption} onChange={(e) => handleStatusChange(customer, e)} >
          <option value={'card'}>Credit Card Only</option>
          <option value={'invoice'}>Invoice Only</option>
          <option value={'any'}>Any</option>
         </select>
        </td>
       </tr>
      })}
     </tbody>
    </table>
   </div>
   <div className="row">
    <div className="col-6">
     <button className="btn btn-primary" onClick={() => setAddModal(!addModal)}>Register for credit card processing</button>
    </div>
   </div>
   {/* <AddModal addModal={addModal} afterAdd={() => dispatch(getCustomer('manufacturer'))} onClose={() => setAddModal(false)} /> */}
   <ReferVendorModal addModal={addModal} onClose={() => setAddModal(false)} />
  </div>
 )
}


const ReferVendorModal = (props) => {
 const token = useSelector(state => state.auth.token);
 const COMPANY = useSelector(state => state.auth.company);

 const [address, setAddress] = useState('');
 const [email, setEmail] = useState('');
 const [name, setName] = useState(COMPANY.name);
 const [contactName, setContactName] = useState('');
 const [phone, setPhone] = useState('');

 const handleRefer = async () => {
  if (!email || !name || !phone || !contactName || !address) return Swal.fire({ icon: "error", title: "Provide complete data", timer: 2000 })
  const paylaod = { email, name, phone, contactName, address }
  let response = await requestPaymentApiKey(paylaod, token)
  if (typeof response !== 'string') {
   Swal.fire({ icon: "success", title: 'Requested successfully', text: 'Wait while the swatchmasters get an api key and url for you.', timer: 2000 });
   props.onClose();
  }
 }

 return (
  <Modal
   size={'lg'}
   show={props.addModal}
   centered
  >
   <Modal.Body>
    <form onSubmit={(e) => { e.preventDefault() }}>
     <div className="row">
      <div className="col-3">
       <label className="label" htmlFor="manufacturerCompany" >
        Compnay Name: &nbsp;
       </label>
      </div>
      <div className="col-6">
       <input type="text"
        id="manufacturerCompany"
        className="form-control"
        name="name"
        value={name}
        onChange={({ target }) => setName(target.value)}
       />
      </div>
     </div>
     <div className="row">
      <div className="col-3">
       <label className="label" htmlFor="manufacturerAddress" >
        Address: &nbsp;
       </label>
      </div>
      <div className="col-6">
       <input type="text"
        id="manufacturerAddress"
        className="form-control"
        name="address"
        value={address}
        onChange={({ target }) => setAddress(target.value)}
       />
      </div>
     </div>
     <div className="row">
      <div className="col-3">
       <label className="label" htmlFor="manufacturerContactName" >
        Contact Name: &nbsp;
       </label>
      </div>
      <div className="col-6">
       <input type="text"
        id="manufacturerContactName"
        name="contactName"
        className="form-control"
        value={contactName}
        onChange={({ target }) => setContactName(target.value)}
       />
      </div>
     </div>
     <div className="row">
      <div className="col-3">
       <label className="label" htmlFor="manufacturerPhone" >
        Phone: &nbsp;
       </label>
      </div>
      <div className="col-6">
       <input type="text"
        className=""
        id="manufacturerPhone"
        name="phone"
        className="form-control"
        value={phone}
        onChange={({ target }) => setPhone(target.value)}
       />
      </div>
     </div>
     <div className="row">
      <div className="col-3">
       <label className="label" htmlFor="manufacturerEmail" >
        Email: &nbsp;
       </label>
      </div>
      <div className="col-6">
       <input type="email"
        className=""
        id="manufacturerEmail"
        name="email"
        value={email}
        className="form-control"
        onChange={({ target }) => setEmail(target.value)}
       />
      </div>
     </div>
    </form>
   </Modal.Body>
   <Modal.Footer>
    <div className="row">
     <div className="col-6"> <button className="btn_grn" onClick={handleRefer}> Okay </button> </div>
     <div className="col-6"> <button className="btn btn-primary" onClick={props.onClose}> Cancel </button> </div>
    </div>
   </Modal.Footer>
  </Modal>
 )
}
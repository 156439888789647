import React, {Component} from 'react'
import {connect} from "react-redux";
import axios from 'axios'
import BallBeatLoader from "../../../components/loaders/spinner";
import DangerAlert from "../../../components/alerts/dangerAlert";
import Swal from "sweetalert2";
import { ApiUrl } from '../../../constants';


/*Importing Icons*/

class planPopupRow extends Component {
	constructor(props){
		super(props)
		this.state={
			background: null,
			loading: false,
			patching: false,
			error: true,
			limitError: false
		}
		this.upgradeSubscription = this.upgradeSubscription.bind(this)
	}

	componentDidMount() {
		if(this.props.selected){
			this.setState({
				background: "bg-info"
			})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.selected !== prevProps.selected){
			this.props.selected?
				this.setState({background: "bg-info"}):
				this.setState({background: ""})

		}
	}

	showLimitError(){
		this.setState({limitError: true})
		setTimeout(()=>{
			this.setState({
				limitError: false,
				patching:false

			})
			this.props.togglePlan()
		},3000)
	}

	upgradeSubscriptionConfirmation = () => {
		Swal.fire({
			title: 'SUBSCRIPTION UPDATE',
			text: 'The amount will be charged to your card every month for the term of the contract!',
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Okay, Update Subscription',
			cancelButtonText: 'No, Quit'
		}).then((result) => {
			if (result.value) {
				this.upgradeSubscription();
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	upgradeSubscription(){
		this.props.togglePlan()
		this.setState({
			patching: true
		})
		const token = this.props.token
		const apiUrlPatchPlan = ApiUrl + '/Companies/'+this.props.companyId
		const params={
			planId : this.props.item.id
		}
		if(this.props.item.max_users < this.props.numberOfUsers){
			this.showLimitError()

		}
		else{
			axios.patch(apiUrlPatchPlan,
				params,{
					headers: {
						'Authorization': token
					}
				})
				.then(()=>{
					this.props.refreshPlan()
						.then(()=>{
							this.setState({
								patching: false
							},()=>{this.props.togglePlan()})
						})
						.catch(()=>{
							this.setState({
								patching: false
							},()=>{this.props.togglePlan()})
						})
				})
				.catch(()=>{
					this.setState({
						patching: false
					},()=>{this.props.togglePlan()})
				})
		}
	}

	render() {
		return(
			this.state.limitError?
				<tr
					className={this.state.background}
				>
					<td colSpan={5}>
					<DangerAlert message={"You registered users exceeds plans maximum limit by " + (this.props.numberOfUsers-this.props.item.max_users)}/>
					</td>
				</tr>:
				<tr
					className={this.state.background}
				>
					<td>{this.props.item.name}</td>
					{/* <td>{this.props.item.max_inventory}</td> */}
					<td>{this.props.item.max_users}</td>
					<td>${this.props.item.price_per_user}</td>
					<td>
						{
							this.props.settingPlan?
								(this.state.patching)?
									<BallBeatLoader/> :
									null
								:
								(this.props.selected)?
									"Selected":
									<button
										className="btn_grn btn-sm"
										onClick={this.upgradeSubscriptionConfirmation}
										disabled={this.state.patching}
									>
										Select
									</button>
						}
					</td>
				</tr>
		)
	}
}
const mapStateToProps = state => {
	return {
	userId: state.auth.userId,
		companyId: state.auth.companyId,
		apiUrl:state.auth.apiUrl,
		token: state.auth.token
	};
};

export default connect(mapStateToProps)(planPopupRow);

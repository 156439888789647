//Core components import
import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
//Route types import
import LoginRoute from "./loginRoute";
import PrivateRoute from "./privateRoute";
import PrivateComponent from "./privateComponent";

//Pages import
import LandingPage from '../../landing';
import Login from "../../pages/login/login";
import SingUp from "../../pages/signup";
import Dashboard from "../../pages/dashboard/dashboard";
import PrivacyPolicy from "../../pages/privacypolicy/privacypolicy"

import VendorRoute from './vendorRoute';

import SubscriptionDetails from "../../pages/companySetup";
import PaymentMethod from "../../pages/companySetup/paymentMethod/paymentMethod";
import ScanAlerts from "../../pages/companySetup/scanAlertSound/scanAlertSound";

import UserSetup from "../../pages/userPages/userSetupMainPage/userSetupMain"
import AddUser from "../../pages/userPages/userSetupAddUser/userSetupAdd"
import EditUser from "../../pages/userPages/userSetupEditUser/userSetupEdit"
import ViewUser from "../../pages/userPages/userSetupViewUser/userSetupView"


import SwatchInventoryGroup from "../../pages/inventoryGroup/inventoryGroupMainPage/inventoryGroup"
import InventoryGroupAddGroup from "../../pages/inventoryGroup/inventoryGroupAddGroup/inventoryGroupAddGroup"
import InventoryGroupEditGroup from "../../pages/inventoryGroup/inventoryGroupEditGroup/inventoryGroupEditGroup.js"
import ScannedSwatchesInventoryGroup from "../../pages/inventoryGroup/scannedSwatches/scannedSwatches.js"

import Fabrics from "../../pages/fabric";

import OrdersTable from "../vendor/order/order.table";
import OrdersDetail from "../vendor/order/ordersHistory.table";

//Components Import
import Drawer from './../../components/drawer/drawer'
import Navbar from './../../components/navbar/navbar'

import { createBrowserHistory } from "history";
import LoadingScreen from "../loaders/LoadingScreen";
import Configurations from '../../pages/configuration';
import CreateOrder from '../../pages/order/order.create';
import EditOrder from '../../pages/order/order.edit';

class Router extends React.Component {
    constructor() {
        super();
        this.state = {
            toggle: true,
        };
        this.toggleState = this.toggleState.bind(this)
    }

    /**
     * Add event Listner
     */
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));

    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }


    updateDimensions() {
        if (window.location.pathname.indexOf('login') > -1 || window.location.pathname.indexOf('sign-up') > -1) return;
        if (window.innerWidth <= 768) {
            this.setState({ toggle: false })
        } else {
            this.setState({ toggle: true })
        }
    }

    toggleState() {
        this.setState((prev) => ({ toggle: !prev.toggle }))
    }

    handleToggle = (history) => {
        const { isAuthenticate } = this.props
        const { pathname } = history.location;
        var toggle = false;
        if (isAuthenticate) return toggle = true;
        if (pathname !== "/" && pathname.indexOf("sign-up") < 0) return toggle = true;
        return toggle;
    }

    render() {
        const { isAuthenticate, companyType } = this.props;
        const history = createBrowserHistory();

        return (
            <BrowserRouter history={history}>
                <PrivateComponent path={'/'} isAuthenticated={isAuthenticate} component={() => <Navbar toggleStateVal={this.state.toggle} toggleState={this.toggleState} history={history} />} />
                <div id="wrapper" className={(this.state.toggle) ? this.handleToggle(history) ? "toggled" : null : null}>
                    <PrivateComponent path={'/'} isAuthenticated={isAuthenticate} component={Drawer} />
                    <Switch>
                        {/* Landing Page */}
                        {!isAuthenticate && <Route exact path="/" component={() => <LandingPage {...this.props} history={history} />} />}
                        {/* Landing Page End */}
                        <Route path="/sign-up/" component={(props) => <SingUp {...props} />} />
                        <LoginRoute exact
                            path={'/login'}
                            isAuthenticated={isAuthenticate}
                            component={() => <Login history={history} toggleState={this.state.toggle} toggler={this.toggleState} />} />
                        <Route exact
                            path={'/forgot-password'}
                            isAuthenticated={this.props.isAuthenticate}
                            component={() => {
                                window.location.replace('http://swatchmasters.com/admin/forgot-password')
                                return <LoadingScreen />;
                            }}
                        />

                        <Route exact path='/login' component={Login} />
                        <Route exact path='/privacy-policy' component={PrivacyPolicy} />

                        {/*HomePage*/}
                        <PrivateRoute exact path={'/'} isAuthenticated={isAuthenticate} component={Dashboard} />
                        {/*Dashboard Routing*/}
                        <Route exact path='/dashboard' isAuthenticated={isAuthenticate} component={Dashboard} />

                        {/*Company Setup Routing*/}
                        <PrivateRoute exact path='/subscription-details' isAuthenticated={isAuthenticate} component={SubscriptionDetails} />
                        <PrivateRoute exact path='/scan-sounds' isAuthenticated={isAuthenticate} component={ScanAlerts} />

                        {/*Fabrics Routing*/}
                        <PrivateRoute exact path='/fabrics' isAuthenticated={isAuthenticate} component={Fabrics} />
                        <PrivateRoute exact path='/order/edit/:id' isAuthenticated={isAuthenticate} component={EditOrder} />

                        {/*User Setup Routing*/}
                        <PrivateRoute exact path='/user-setup' isAuthenticated={isAuthenticate} component={UserSetup} />
                        <PrivateRoute exact path='/user-setup/add-user' isAuthenticated={isAuthenticate} component={AddUser} />
                        <PrivateRoute exact path='/user-setup/edit-user/:id' isAuthenticated={isAuthenticate} component={EditUser} />
                        <PrivateRoute exact path='/user-setup/view-user/:id' isAuthenticated={isAuthenticate} component={ViewUser} />

                        <PrivateRoute exact path={`/orders/:id`} isAuthenticated={isAuthenticate} component={() => <OrdersTable />} />
                        <PrivateRoute exact path={`/orders_history`} isAuthenticated={isAuthenticate} component={() => <OrdersDetail />} />

                        {companyType && companyType === 'vendor' ?
                            <Route path='/vendor' isAuthenticated={isAuthenticate} component={VendorRoute} />
                            :
                            ManufacturerRoute(isAuthenticate)
                        }
                        <Route path="*" component={() => <Redirect to="/" />} />
                    </Switch>
                </div>
            </BrowserRouter>
        )
    }
}

export default Router;

const ManufacturerRoute = (isAuthenticate) => {
    return (
        <>
            {/*Swatch Inventory Group Routing*/}
            <PrivateRoute exact path='/swatch-inventory-group' isAuthenticated={isAuthenticate} component={SwatchInventoryGroup} />
            <PrivateRoute exact path='/configuration' isAuthenticated={isAuthenticate} component={Configurations} />
            <PrivateRoute exact path='/add-inventory-group' isAuthenticated={isAuthenticate} component={InventoryGroupAddGroup} />
            <PrivateRoute exact path='/edit-inventory-group/:id' isAuthenticated={isAuthenticate} component={InventoryGroupEditGroup} />
            <PrivateRoute exact path='/scanned-swatches/:id' isAuthenticated={isAuthenticate} component={ScannedSwatchesInventoryGroup} />
            <PrivateRoute exact path='/order/create' isAuthenticated={isAuthenticate} component={CreateOrder} />
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import editIcon from "../../../img/edit.png";

import "./style.css"
import Swal from 'sweetalert2';
import { getOrderGroup, getOrders, updateOrder } from '../../../store/services/order';
import { Modal } from 'react-bootstrap';
import BallLoader from '../../loaders/ballBeatLoader';
const OrderHistory = (props) => {
  const company = useSelector(state => state.auth.company);
  const companyId = useSelector(state => state.auth.companyId);
  const token = useSelector(state => state.auth.token);
  const [loading, setLoader] = useState(false);
  const [ORDERS, setORDERS] = useState([]);
  const [groups, setGroups] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({})

  useEffect(() => {
    initiate()
  }, [])

  const initiate = () => {
    setLoader(true)
    const filter = { where: { manufacturerId: companyId }, include: [{ 'order_group': 'vendor' }, 'vendor', 'manufacturer', 'fabrics', 'creator'] }
    const vendorFilter = { where: { vendorId: companyId, status: { ne: "draft" } }, include: [{ 'order_group': 'vendor' }, 'vendor', 'manufacturer', 'fabrics', 'creator'] }
    getOrdersData(company.type === 'vendor' ? vendorFilter : filter)
    getOrderGroup(token, { where: company.type === 'vendor' ? { vendorId: companyId } : { manufacturerId: companyId } })
      .then((response) => {
        if (Array.isArray(response)) setGroups(response)
        setLoader(false)
      })
  }

  const handleFilter = () => {
    const { id, status, verification, startedBy, groupName, vendor } = filters;
    let newOrders = ORDERS.filter(order => {
      if (
        (id ? id === order.id : true) &&
        (status ? status === order.status : true) &&
        (status ? status === order.status : true) &&
        (verification ? verification === order.requireLevel.toLowerCase() : true) &&
        (groupName ? groupName === order.groupId : true) &&
        (startedBy ? order.creator.username.toLowerCase().includes(startedBy) : true) &&
        (vendor ?
          company.type === 'vendor' ?
            order.manufacturer.name.toLowerCase().includes(vendor)
            :
            order.creator.username.toLowerCase().includes(vendor)
          : true)
      ) return order;
    })
    setOrders(newOrders)
  }

  const clearFilter = () => {
    setFilters({})
    setOrders(ORDERS)
  }

  const handleChangeFilter = ({ target }) => {
    const { name, value } = target;
    let newFilter = { ...filters }
    newFilter[name] = value
    setFilters(newFilter)
  }

  const handleSort = (column = undefined, col2 = undefined, date = undefined) => {
    let newORDERs = [...orders]
    newORDERs = newORDERs.sort((a, b) => {
      let date1 = date && (new Date(a[date])).getTime() || 0;
      let date2 = date && (new Date(b[date])).getTime() || 0;
      if (!col2 && !date && a[column] > b[column]) return 1;
      if (col2 && a[column] && b[column] && a[column][col2] > b[column][col2]) return 1;
      if (date && date1 > date2) return 1;
      return -1;
    })
    setOrders(newORDERs);
  }

  const handleQtySort = () => {
    let newORDERs = [...orders]
    newORDERs = newORDERs.sort((a, b) => {
      let qty1 = 0, qty2 = 0;
      a.fabrics.filter(fab => qty1 += fab.quantity)
      b.fabrics.filter(fab => qty2 += fab.quantity)
      if (qty1 > qty2) return 1;
      return -1;
    })
    setOrders(newORDERs);
  }

  const getOrdersData = (filter) => {
    getOrders(filter, token)
      .then(response => {
        if (Array.isArray(response)) {
          setOrders(response)
          setORDERS(response)
        }
      })
  }

  return (
    <div className="orderTrackerContainer">
      <h1>Order History</h1>
      {company && company.type === 'manufacturer' &&
        <div className="row">
          <div className="col-auto">
            <label className="switch float-left mr-2">
              <input type="checkbox" className="default" />
              <span className="slider round"></span>
            </label>
            Send Verification Emails
          </div>
          <br />
          <br />
        </div>
      }
      {/* Filter Row */}
      <div className="row">
        <div className="col-3">
          <div className="input-group form-group fild2">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fa fa-search" /></span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search OrderID"
              name="id"
              value={filters.id || ''}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="input-group form-group fild2">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fa fa-search" /></span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder={`Search ${company.type === 'vendor' ? 'manufacturer' : 'vendor'}`}
              name="vendor"
              value={filters.vendor || ""}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
        <div className="col-3">
          <select className="input-group form-control form-group"
            name="groupName"
            value={filters.groupName || ""}
            onChange={handleChangeFilter}
          >
            <option value="">Filter*</option>
            {Array.isArray(groups) && groups.map((group) => {
              return <option value={group.id} > {group.name} </option>
            })}
          </select>
        </div>
        <div className="col-3">
          <select className="input-group form-control form-group"
            name="status"
            value={filters.status || ""}
            onChange={handleChangeFilter}
          >
            <option value=''>Filter*</option>
            <option value="accepted">Accepted</option>
            <option value="placed">Placed</option>
            <option value="draft">Draft</option>
            <option value="complete">Complete</option>
            <option value="cancelled">Cancelled</option>
            <option value="scanned">Scanned</option>
            <option value="quoted">Quoted</option>
            <option value="invoiced">Invoiced</option>
          </select>
        </div>
        {
          company && company.type === 'manufacturer' &&
          <div className="col-3">
            <select className="input-group form-control form-group"
              name="verification"
              value={filters.verification || ''}
              onChange={handleChangeFilter}
            >
              <option value=''>Filter*</option>
              <option value='none'>None</option>
              <option value='single'>SINGLE</option>
              <option value='full'>FULL</option>
            </select>
          </div>

        }
        <div className="col-3">
          <div className="input-group form-group fild2">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fa fa-search" /></span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search started By"
              name="startedBy"
              value={filters.startedBy || ''}
              onChange={handleChangeFilter}
            />
          </div>
        </div>

        <div className="col-3">
          <button className="btn_grn" onClick={handleFilter}> Filter </button>
        </div>
        <div className="col-3">
          <button className="btn btn-primary" onClick={clearFilter}> Clear filters </button>
        </div>

      </div>

      {/* END FILTER ROW */}
      <div className="tb_conatiner">
        <table className="table tb_top">
          <thead>
            <tr>
              <th className="pointer" onClick={() => handleSort('id')} scope="col">Order ID &nbsp;
                {/* {true ? <i class="fa fa-solid fa-sort-up" /> :
                  <i class="fa fa-solid fa-sort-down" />
                } */}
              </th>
              <th className="pointer" scope="col" onClick={() => handleSort(company.type === 'vendor' ? 'manufacturer' : 'vendor', 'name')} > {company.type === 'vendor' ? 'Manufacturer' : 'Vendor'} </th>
              <th className="pointer" scope="col" onClick={() => handleSort('group', 'name')} >Group Name &nbsp;
                {/* <i class="fa fa-solid fa-sort" /> */}
              </th>
              <th className="pointer" onClick={() => handleSort('status')} scope="col">Order Status &nbsp;
                {/* {true ? <i class="fa fa-solid fa-sort-up" /> :
                  <i class="fa fa-solid fa-sort-down" />
                } */}
              </th>

              {company && company.type === 'manufacturer' &&
                <>
                  <th className="pointer" scope="col" onClick={handleQtySort} >Quantity</th>
                  <th scope="col">Verified</th>
                  <th scope="col">Verification</th>
                  <th scope="col">Release Requirements</th>
                </>
              }

              <th className="pointer" scope="col" onClick={() => handleSort('creator', 'username')} >Started By</th>
              <th className="pointer" scope="col" onClick={() => handleSort(undefined, undefined, 'createdAt')}>Started Date</th>
              <th className="pointer" scope="col" onClick={() => handleSort('modifier', 'username')}>Last Edit by</th>
              <th className="pointer" scope="col" onClick={() => handleSort(undefined, undefined, 'updatedAt')}>Last Edit Date</th>
              <th scope="col">Edit Order</th>
            </tr>
          </thead>
          {/*Rows of pricing data*/}
          <tbody>
            {loading && <tr><td colSpan={13}><BallLoader /></td></tr>}
            {Array.isArray(orders) && orders.map(order => {
              return <HistoryRow index={order.id} initiate={initiate} {...order} companyType={company && company.type || ""} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OrderHistory;

const HistoryRow = (props) => {
  const token = useSelector(state => state.auth.token)
  const [quantity, setQuantity] = useState(0)
  const [verified, setVerified] = useState(0)
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [releaseNotes, setRelaseNotes] = useState('');
  useEffect(() => {
    // if (props.requireLevel === 'full') {
    let qty = 0
    Array.isArray(props.fabrics) && props.fabrics.map(fabric => {
      qty += fabric.quantity;
      if (props.status === 'scanned') {
        setVerified(props.verified)
      }
    })
    setQuantity(qty)
    // }
  }, [props.requireLevel])
  const name = props.companyType === 'vendor' ? (props.manufacturer ? props.manufacturer.name : "N/A") : (
    props.vendor && props.vendor.name || "N/A"
  )

  const handleRelease = async () => {
    if (loader) return;
    if (!releaseNotes) return Swal.fire({ icon: 'error', title: 'Empty Notes', timer: 2000 })
    setLoader(true)
    const response = await updateOrder(props.id, { releaseNotes }, token)
    setLoader(false);
    if (typeof response === 'string') {
      return Swal.fire({ icon: 'error', title: 'Something went wrong!', text: response, timer: 2000 })
    }
    Swal.fire({ icon: 'success', title: 'Successfully released!', timer: 2000 });
    props.initiate()
    setShow(false)
  }
  return (
    <tr key={props.index}>
      <td>{props.index}</td>
      <td>{name}</td>
      <td>{props.group && props.group.name}</td>
      <td>{props.status || "draft"}</td>
      {
        props.companyType === 'manufacturer' &&
        <>
          <td>{quantity || 0}</td>
          <td>{verified || 0}</td>
          <td>{props.requireLevel && props.requireLevel.toUpperCase() || "None"}</td>
          <td>
            {props.releaseNotes ?
              <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); Swal.fire({ title: "Reason for Release of Requirements", text: props.releaseNotes }) }} > Show Release Requirements </button>
              :
              <button className="btn_grn" onClick={() => setShow(true)}> Release Requirements </button>
            }
          </td>
        </>
      }
      <td>{props.creator && props.creator.username || "N/A"}</td>
      <td>{(new Date(props.createdAt)).toLocaleDateString()}</td>
      <td>{(props.modifier && props.modifier.username) || (props.creator && props.creator.username) || "N/A"}</td>
      <td>{(new Date(props.updatedAt)).toLocaleDateString()}</td>
      <td>
        {props.status !== "invoiced" &&
          <Link to={"/order/edit/" + props.id} className="edit_link"><img alt="" src={editIcon} /></Link>
        }
      </td>

      <Modal
        size="md"
        centered
        show={show}
        onClose={() => setShow(false)}
      >
        <Modal.Header>
          <h3>
            Reason for Release of Requirements
          </h3>
        </Modal.Header>
        <Modal.Body>
          <textarea
            cols={50}
            rows={10}
            maxLength={500}
            value={releaseNotes}
            className="input-group form-group fild2"
            onChange={({ target }) => { setRelaseNotes(target.value) }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={() => setShow(false)}>Cancel</button>
          <button className="btn_grn" onClick={handleRelease}>Release Requirements</button>
        </Modal.Footer>
      </Modal>

    </tr>
  )
}
import React from "react";
import Alert from "react-bootstrap/Alert";

export default function successfulAlert (props){
	return(
		<div style={{width: '100%'}}>
			<Alert variant="success" style={{width: '100%', textAlign: 'center'}}>
				{props.message}
			</Alert>
		</div>
	)
}
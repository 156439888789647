import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import CreditCardInput from 'react-credit-card-input';
import { ApiUrl } from '../../../constants';
const CryptoJS = require("crypto-js")
const secretKey = 'swatchSecret136713gayuqweur6231f2y386Swatch';

const CardCheckout = (data) => {
	const [ showForm, setShowForm ] = useState(false);
	const [ cardNumber, setCardNumber ] = useState('');
	const [ expiry, setExpiry ] = useState('');
	const [ cvc, setCvc ] = useState('');
	const [ loader, setLoader ] = useState(false);
	const { props, name, description, amount } = data;
	const { companyId, token } = props;

	const CURRENCY = 'USD';

	const fromDollarToCent = amount => parseInt(amount * 100);

	const successPayment = result => {
		setLoader(false);
		if(result.data && result.data.data && result.data.data.message) {
			alert(result.data.data.message);
		} else {
			alert('Payment Successful');
		}
		// console.log('data', result.data)
	};

	const errorPayment = data => {
		setLoader(false);
		alert('Payment Error');
	};

	const payWithCard = () => {
		setLoader(true);
		setShowForm(false);
		var encryptedString = CryptoJS.AES.encrypt(JSON.stringify(cardNumber), secretKey).toString();
		axios.post(ApiUrl + '/Companies/'+ companyId +'/deductPaymentFromCard',
			{
				cardNumber: encryptedString,
				expiry,
				cvc,
				amount: fromDollarToCent(amount)
			},
			{
				headers: {
					'authorization': token
				}
			})
			.then(successPayment)
			.catch(errorPayment);
	}

	const handleCardNumberChange = (event) => {
		setCardNumber(event.target.value);
	}
	const handleCardExpiryChange = (event) => {
		setExpiry(event.target.value);
	}
	const handleCardCVCChange = (event) => {
		setCvc(event.target.value);
	}
	const handlePay = () => {
		if(amount == 0 || (new Date()).getDate() != 1) {
			const text = amount ? "You cannot pay during month!" : "You don't need to pay!"
			Swal.fire({
				icon: 'error',
				text
			})
			return ;

		} 
		setShowForm(true);
	}
	return (
		<div>
			{!showForm &&
				<>
					{loader ?
						<div className="spinner-border text-primary" role="status">
							<span className="sr-only">Loading...</span>
						</div> :
						<button
							type="btn" className="btn btn-primary"
							data-toggle="modal" data-target="#exampleModal"
							onClick={handlePay}>
							Pay with card
						</button>
					}
				</>
				}

			<div>
				<Modal
					dialogClassName="card-modal"
					show={showForm}
					aria-labelledby="contained-modal-title-vcenter"
					centered
					onHide={()=> {
						setShowForm(false);
					}}
				>
					<Modal.Header closeButton>
						<h5>Enter Card Information</h5>
					</Modal.Header>
					<Modal.Body>
						<CreditCardInput
							cardNumberInputProps={{ value: cardNumber, onChange: handleCardNumberChange }}
							cardExpiryInputProps={{ value: expiry, onChange: handleCardExpiryChange }}
							cardCVCInputProps={{ value: cvc, onChange: handleCardCVCChange }}
							fieldClassName="input"
						/>
					</Modal.Body>
					<Modal.Footer>
						<button type="button" className="btn btn-primary" onClick={()=>{
							payWithCard();
						}}>Confirm to Pay</button>
					</Modal.Footer>
				</Modal>
			</div>
		</div>
	)
}

export default CardCheckout;

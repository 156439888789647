import * as types from '../constants/subscription';

export function addSubscription(payload) {
 return { type: types.ADD_SUBSCRIPTION_REQUEST, payload }
}

export function updateSubscription(id, payload) {
 return { type: types.UPDATE_SUBSCRIPTION_REQUEST, payload, id }
}

export function deleteSubscription(id) {
 return { type: types.DELETE_SUBSCRIPTION_REQUEST, payload: id }
}

export function getSubscription() {
 return { type: types.GET_SUBSCRIPTION_REQUEST }
}
export function getPlans() {
 return { type: types.GET_PLANS_REQUEST }
}
export function setBill(bill) {
 return { type: types.GET_BILL_SUCCESS, payload: bill }
}
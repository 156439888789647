import React, { Component } from 'react'
import ScanAlertItem from './scanAlertItem'
import UploadIcon from '../../../img/upload.png'
import axios from "axios";
import { connect } from "react-redux";
import DeleteIcon from "../../../img/delete.png";
import Spinner from '../../../components/loaders/spinner'
import SuccessAlert from "../../../components/alerts/sucessfulAlert";
import DanderAlert from "../../../components/alerts/dangerAlert";
import Swal from 'sweetalert2';
import { ApiUrl } from '../../../constants';
class scanAlertSound extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sounds: null,
			play: false,

			defaultIdSuccess: null,
			defaultIdFail: null,

			defaultId: null,
			soundType: 'Successful',
			soundTypes: null,

			loading: false,

			loadedFile: false,

			uploading: false,
			soundToUpload: null,

			failedToLoad: false,
			failedToUpload: false,

			success: false,


		};

		this.refreshSounds = this.refreshSounds.bind(this)
		this.handleEvent = this.handleEvent.bind(this)
		this.toggleLoading = this.toggleLoading.bind(this)
		this.showFailedToLoad = this.showFailedToLoad.bind(this)
		this.showFailedToUpload = this.showFailedToUpload.bind(this)
		this.showSuccess = this.showSuccess.bind(this)

		this.getTypes = this.getTypes.bind(this)
	}

	toggleLoading() {
		this.setState(prevState => ({
			loading: !prevState.loading
		}));
	}

	getTypes() {
		const apiUrlSoundTypes = ApiUrl + '/SoundTypes';
		const token = this.props.token
		return new Promise((resolve, reject) => {
			axios.get(apiUrlSoundTypes,
				{
					headers: {
						'Authorization': token
					}
				})
				.then(response => {
					this.setState({
						soundTypes: response.data
					}, () => {
						resolve(true);
					})
				})
				.catch(() => {
					reject(false)
				})
		})
	}

	componentDidMount() {
		this.toggleLoading()
		this.getTypes().then(() => {
			this.refreshSounds().then(() => {
				this.toggleLoading()
			}).catch(() => {
				this.toggleLoading()
				this.showFailedToLoad()
			})
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (this.state.soundType !== prevState.soundType) {
			this.toggleLoading()
			this.refreshSounds().then(() => this.toggleLoading());
		}
		/*switch (this.state.soundType){
				case 'Successful':
					break;
				case 'Inactive':
					break;
				case 'Fabric_not_in_database':
					break;
				case 'Grade_change':
					break;
				case 'Duplicate':
					break;
			}
		if(this.state.soundType==='successSounds'){
			this.setState({
				defaultId: this.state.defaultIdSuccess
			})
		}
		else{
			this.setState({
				defaultId: this.state.defaultIdFail
			})
		}
	if(this.state.defaultIdFail !== prevState.defaultIdFail && this.state.soundType === "failSounds"){
		this.setState({
			defaultId: this.state.defaultIdFail
		})
	}
	if(this.state.defaultIdSuccess !== prevState.defaultIdSuccess && this.state.soundType === "successSounds"){
		this.setState({
			defaultId: this.state.defaultIdSuccess
		})
	}*/

	}

	showFailedToUpload() {
		this.setState({ failedToUpload: true })
		setTimeout(() => { this.setState({ failedToUpload: false }) }, 2000)
	}

	showFailedToLoad() {
		this.setState({ failedToLoad: true })
	}


	showSuccess() {
		this.setState({ success: true })
		setTimeout(() => { this.setState({ success: false }) }, 2000)
	}

	getSoundTypeId(name) {
		if (this.state.soundTypes !== null) {
			for (let i = 0; i < this.state.soundTypes.length; i++) {
				if (name === this.state.soundTypes[i].name) {
					return this.state.soundTypes[i].id;
				}
			}
		}
	}

	setDefault(id) {
		this.setState({ defaultId: id });
	}

	refreshSounds() {
		const s_id = this.getSoundTypeId(this.state.soundType);
		const filter = {
			"where": { "soundTypeId": s_id }
		}
		const apiUrlSound = ApiUrl + '/companies/' + this.props.companyId + '/soundFiles';
		const token = this.props.token
		return new Promise((resolve, reject) => {
			axios.get(apiUrlSound,
				{
					params: {
						filter: filter
					},
					headers: {
						'Authorization': token
					}
				})
				.then(response => {
					this.setState({
						sounds: response.data
					}, () => {
						this.state.sounds.map(val => {
							if (val.default) {
								this.setDefault(val.id)
							}
						})
						resolve(true);
					})

				})
				.catch(() => {
					reject(false)
				})
		})
	}

	handleEvent(event) {
		event.persist();
		const { permissions, user, userId } = this.props;
		if (user && user.id === userId) {
				if (!permissions.length || !this.props.permissions[0]["Add Scan Sounds"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		switch (event.target.id) {
			case "soundSelect":
				this.setState({ soundType: event.target.value });
				break;
			case "loadSound":
				this.setState({
					soundToUpload: event.target.files[0],
					loadedFile: true
				});
				setTimeout(() =>
					this.setState({
						uploading: true,
						loadedFile: true
					}, () => {
						const token = this.props.token
						const soundUploadUrl = ApiUrl + "/SoundFiles/upload"
						const data = new FormData()
						data.append('file', this.state.soundToUpload)
						data.append('name', this.state.soundToUpload.name)
						data.append('soundTypeId', this.getSoundTypeId(this.state.soundType))
						data.append('companyId', this.props.companyId)
						axios.post(soundUploadUrl,
							data, {
							headers: {
								'Authorization': token
							}
						})
							.then(response => {

								// then print response status
								this.refreshSounds().then(
									() => {
										this.showSuccess()
										this.setState({
											loadedFile: false,
											soundToUpload: null,
											soundToUploadName: null,
											uploading: false
										})
									}
								)
									.catch(() => {
										this.setState({ uploading: false })
										this.showFailedToLoad()
									})
							})
							.catch(response => {
								this.setState({ uploading: false })
								this.showFailedToUpload()
							})
					}
					), 1500)
				break;
			case "resetUpload":
				this.setState({
					loadedFile: false,
					soundToUpload: null,
					soundToUploadName: null
				});
				break;
			default:
				break;
		}
	}

	/**
		* Select And Remove File selected for Upload
		* */


	render() {
		return (
			<div id="page-content-wrapper">
				{(this.state.success) ?
					<SuccessAlert message="Sound uploaded successfully" /> :
					null
				}
				{
					(this.state.failedToUpload) ?
						<DanderAlert message="Failed to upload the sound. Please try again" /> :
						null
				}
				<div className="container-fluid">
					<div className="row setup_top2 justify-content-md-center">
						<div className="col-12 col-md-10 col-lg-8">
							<div className="box_payment">
								<div className="heding1">ALERT SOUND TYPE</div>
								<div className="inside">
									<div className="row justify-content-md-center select_area">
										<div className="col-12 col-md-8">
											<form>
												<div className="form-group">
													<div className="row justify-content-center" >
														<div className="col-8 col-sm-8">
															<select
																id='soundSelect'
																name='soundSelect'
																value={this.state.soundType}
																onChange={this.handleEvent}
																className="browser-default custom-select">
																{
																		Array.isArray(this.state.soundTypes) && this.state.soundTypes.map((val) => {
																			return <option value={val.name}>{val.name.replace(/_/g, " ")}</option>
																		})
																}
															</select>
														</div>
													</div>
												</div>
												{(this.state.failedToLoad) ?
													<DanderAlert message="Sounds could not be loaded. Please refresh." /> :
													(this.state.loading || this.state.sounds === null) ?
														<Spinner styleClass="text-primary" /> :
														(this.state.sounds && Array.isArray(this.state.sounds) && this.state.sounds.map((item, index) =>
															<ScanAlertItem key={index}
																item={item}
																type={this.state.soundType}
																refresh={this.refreshSounds}
																default={this.state.defaultId}
																failedLoad={this.showFailedToLoad} />
														))
												}
												<div className="text-center fntSz">
													{(!this.state.loadedFile) ?
														<div>
															<input id='loadSound'
																name='uploadSound'
																type='file'
																accept={'.wav,.mp3'}
																onChange={this.handleEvent}
																style={{
																	width: '0.1px',
																	height: '0.1px',
																	opacity: 0,
																	overflow: 'hidden',
																	position: 'absolute',
																	zIndex: '-1'
																}} />
															<label
																className="uploadBtn"
																htmlFor='loadSound'>
																<img
																	alt=""
																	src={UploadIcon} />
																	Upload
															</label>
														</div> :
														<div>
															<button
																id='uploadSound'
																type="button"
																onClick={this.handleEvent}
																className="uploadBtn">
																{(this.state.uploading) ?
																	<Spinner
																		class="text-primary" /> :
																	<div
																		id="uploadSound">
																		<img
																			alt=""
																			src={UploadIcon} />
																		{this.state.soundToUpload.name}
																	</div>}
															</button>
															<button
																id='resetUpload'
																type='button'
																onClick={this.handleEvent}>
																<img id='resetUpload' alt="" src={DeleteIcon} />
															</button>
														</div>}
													<p>MP3 file types up to 300 KB are allowed</p>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		apiUrl: state.auth.apiUrl,
		userId: state.auth.userId,
		companyId: state.auth.companyId,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user,
	};
};

export default connect(mapStateToProps)(scanAlertSound);

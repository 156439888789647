import { put, select, takeLatest, call, takeEvery } from "redux-saga/effects";
import { selectAuthToken } from "../selectors/auth";
import {
  selectOwner,
  selectOwnerId,
  selectCard,
  selectCompany,
  selectCompanyId,
  selectNoOfPacks,
  selectPlans,
  selectPassword,
  selectLoader
} from "../selectors/signUp";
import * as service from "../services/signUp";
import * as types from "../constants/signUp";
import Swal from "sweetalert2";

function* getCouponsSaga() {
  try {
    const token = yield select(selectAuthToken);
    const coupons = yield call(service.getNonSAASCoupons, token);
    if (coupons) {
      yield put({ type: types.GET_COUPONS_SUCCESS, payload: coupons });
      return;
    }
    yield put({ type: types.GET_COUPONS_FAILURE, payload: "no data found" });
  } catch (error) {
    yield put({ type: types.GET_COUPONS_FAILURE, payload: error.message });
    console.log("ERR ", error.message);
  }
}
function* signUpProceedingSaga() {
  try {
    const owner = yield select(selectOwner);
    const verifyEmail = yield call(
      service.filterUser,
      owner.email,
      undefined,
      owner.username
    );
    if (Array.isArray(verifyEmail) && verifyEmail.length > 0) {
      Swal.fire("Email already in use");
      yield put({ type: types.SET_STEP, payload: 0 });
      return;
    }
    yield put({ type: types.ADD_OWNER_REQUEST, payload: owner });
  } catch (error) {
    Swal.fire("Something went wrong!");
    console.log("err: ", error.message);
  }
}
function* addOwnerSaga(action) {
  try {
    // TODO: get sagas values;
    const isLoading = yield select(selectLoader);

    const getCompany = yield select(selectCompany);
    const getNoOfPacks = yield select(selectNoOfPacks);
    const getCardInfo = yield select(selectCard);
    console.log('isLoading', isLoading)
    if (isLoading) return;
    const password = "$W" + new Date().getTime() + "@T#M@$!@*.35#SKLFG";
    yield put({ type: types.SET_PASSWORD, payload: password });
    yield put({ type: "SET_LOADER", payload: true });
    // TODO: declare separate object for payload;
    const owner = { ...action.payload, password };
    const company = {
      ...getCompany,
      rfDiscount: 0,
      rfHistorical: 0,
      subscribeOn: new Date(),
      signupUsers: 5 + (getNoOfPacks > 0 ? getNoOfPacks * 5 : 0),
    }
    const coupon = {
      name: getCompany.referralCode,
      discount: 50,
      type: "USD",
      couponType: "referral",
      referralDiscount: 5,
      expiryDate: 12
    }
    const cardInfo = {
      ...getCardInfo,
      ccnumber: getCardInfo.cardNumber || "",
      ccexp: getCardInfo.expiry || ""
    }

    const payload = { owner, company, coupon, cardInfo };
    const response = yield call(service.signUp, payload);
    yield put({ type: "SET_LOADER", payload: false });
    if (response === true && typeof response === 'boolean') {
      Swal.fire({
        icon: "success",
        title: "Successfully Registered",
        text: company.isAnnual
          ? "You can collect login credentials from the admins after documentation"
          : "Your login credentials are sent on your email. please change the password.",
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
          '<a href="/login"> <b style="color:#fff"> Login </b> </a>',
        confirmButtonAriaLabel: "Login!",
      }).then(() => {
        window.location.replace("http://swatchmasters.com/login");
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        text: company.isAnnual
          ? "Please verify your form and submit it again."
          : "Please check your payment details and try again or contact admin@swatchmasters.com",
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'Cancel!',
        confirmButtonAriaLabel: "Cancel!",
      }).then(() => {
        window.location.reload();
      });
    }

  } catch (error) {
    yield put({ type: "SET_LOADER", payload: false });
    Swal.fire({
      icon: "error",
      title: "Something went wrong!",
      text: "Please verify your form and submit it again.",
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Cancel!',
      confirmButtonAriaLabel: "Cancel!",
    }).then(() => {
      window.location.reload();
    });
  }
}

// function* addOwnerSaga(action) {
//   try {
//     const password = "$W" + new Date().getTime() + "@T#M@$!@*.35#SKLFG";
//     yield put({ type: types.SET_PASSWORD, payload: password });
//     const owner = yield call(service.registerOwner, {
//       ...action.payload,
//       password,
//     });
//     if (owner && owner.id) {
//       yield put({ type: types.ADD_OWNER_SUCCESS, payload: owner });
//       yield put({ type: types.ADD_COMPANY_REQUEST });
//     }
//   } catch (error) {
//     Swal.fire("Something went wrong");
//     yield put({ type: types.SET_STEP, payload: 0 });
//     yield put({ type: types.ADD_OWNER_FAILURE, payload: error.message });
//     console.log("err: ", error.message);
//   }
// }
function* addCompanySaga() {
  try {
    const ownerId = yield select(selectOwnerId);
    const getCompany = yield select(selectCompany);
    const plans = yield select(selectPlans);
    const getNoOfPacks = yield select(selectNoOfPacks);

    var plan = {};
    var userPlan = {};
    plans.filter((item) => {
      if (item.type === getCompany.type && item.max_users === 5) plan = item;
      if (item.type === "user" && item.max_users === 5) userPlan = item;
    });
    let payload = {
      ...getCompany,
      ownerId,
      signupUsers: 5 + (getNoOfPacks > 0 ? getNoOfPacks * 5 : 0),
      rfDiscount: 0,
      rfHistorical: 0,
      subscribeOn: new Date(),
    };
    const company = yield call(service.registerCompany, payload);
    if (company && company.id) {
      yield put({ type: types.ADD_COMPANY_SUCCESS, payload: company });
      let subscription = {
        companyId: company.id,
        activeUser: 0,
        plan: plan,
        planId: plan.id,
      };
      let userBasePacks = {
        companyId: company.id,
        activeUser: 0,
        plan: userPlan,
        planId: userPlan.id,
      };
      const baseSub = yield call(
        service.addSubscription,
        subscription,
        "signUp!_Process_r=S"
      );

      if (typeof baseSub === "object") {
        if (getNoOfPacks < 1) {
          company.isAnnual
            ? Swal.fire({
              icon: "success",
              title: "Successfully Registered",
              text: "You can get credentials from swatchmasters@gmail.com",
              confirmButtonAriaLabel: "Login",
              confirmButtonText:
                '<a href="/login"> <b style="color:#fff"> Login </b> </a>',
            }).then(() => {
              window.location.replace("http://swatchmasters.com/login");
            })
            : yield put({ type: types.ADD_CARD_INFO_REQUEST });
        }

        for (let index = 0; index < getNoOfPacks; index++) {
          yield put({
            type: types.ADD_SUBSCRIPTION_REQUEST,
            payload: userBasePacks,
            index,
            getNoOfPacks,
          });
        }
        yield put({ type: "SIGNED_UP" });
      }
    }
  } catch (error) {
    yield put({ type: "SIGNED_UP" });
    Swal.fire(error.message);
    yield put({ type: types.ADD_COMPANY_FAILURE, payload: error.message });
    console.log("err: ", error.message);
  }
}
function* addSubScriptionSaga(action) {
  try {
    if (action.index >= action.getNoOfPacks) return;
    const company = yield select(selectCompany);
    const subscription = yield call(
      service.addSubscription,
      action.payload,
      "signUp!_Process_r=S"
    );

    if (subscription && subscription.id) {
      if (action.index === -1 && !company.isAnnual)
        yield put({
          type: types.SEND_EMAIL,
          payload: company,
          sendCredentials: true,
        });
      yield put({
        type: types.ADD_SUBSCRIPTION_SUCCESS,
        payload: subscription,
      });
    }
    if (Number(action.index + 1) === Number(action.getNoOfPacks)) {
      if (company.isAnnual) {
        Swal.fire({
          icon: "success",
          title: "Successfully Registered!",
          text: "You can get your credentials from swatchmasters@gmail.com",
          confirmButtonText:
            '<a href="/login"> <b style="color:#fff"> Login </b> </a>',
          confirmButtonAriaLabel: "Login",
        }).then(() => {
          window.location.replace("http://swatchmasters.com/login");
        });
      } else yield put({ type: types.ADD_CARD_INFO_REQUEST });
    }
  } catch (error) {
    if (action.index + 1 === action.getNoOfPacks) {
      yield put({ type: types.ADD_CARD_INFO_REQUEST });
    }
    yield put({ type: types.ADD_SUBSCRIPTION_FAILURE, payload: error.message });
    console.log("err: ", error.message);
  }
}
function* addCardInfoSaga() {
  try {
    const company = yield select(selectCompany);
    const getCardInfo = yield select(selectCard);
    const getCID = yield select(selectCompanyId);
    const card = {
      ...getCardInfo,
      ccnumber: getCardInfo.cardNumber,
      ccexp: getCardInfo.expiry,
    };
    const cardInfo = yield call(service.registerCardInfo, getCID, card);
    if (typeof cardInfo === "string") {
      yield put({ type: types.DELETE_COMPANY_REQUEST, payload: company });
      yield put({ type: types.ADD_CARD_INFO_FAILURE });
      return;
    }
    if (cardInfo) {
      Swal.fire({
        icon: "success",
        title: "Successfully Registered",
        text: company.isAnnual
          ? "You can collect login credentials from the admins after documentation"
          : "Your login credentials are sent on your email. please change the password.",
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
          '<a href="/login"> <b style="color:#fff"> Login </b> </a>',
        confirmButtonAriaLabel: "Login!",
      }).then(() => {
        window.location.replace("http://swatchmasters.com/login");
      });
      yield put({ type: types.SEND_EMAIL, payload: company });
      yield put({ type: types.ADD_CARD_INFO_SUCCESS });
      yield put({ type: types.TRUNCATE_SIGN_UP });
    }
  } catch (error) {
    const company = yield select(selectCompany);
    Swal.fire(error.message);
    yield put({ type: types.ADD_CARD_INFO_FAILURE, payload: error.message });
    console.log("err: ", error.message);
    yield put({ type: types.DELETE_COMPANY_REQUEST, payload: company });
  }
}
function* sendCredentialsSaga(action) {
  try {
    var getOwner = yield select(selectOwner);
    var getCompany = yield select(selectCompany);
    var getPassword = yield select(selectPassword);
    var user = {
      ...getOwner,
      password: getPassword,
      type: getCompany.type,
      createdAt: new Date(getOwner.createdAt).toLocaleDateString(),
    };
    if (action.sendCredentials) user.sendCredentials = true;
    yield call(service.sendUserCredentials, user);
  } catch (error) {
    console.log("err: ", error.message);
  }
}

function* deleteCompanySaga(action) {
  try {
    const getOwner = yield select(selectOwner);
    const getCompany = yield select(selectCompany);
    const { id, ownerId } = action.payload;
    const owner = yield call(service.deleteOwner, ownerId);
    let newOwner = { ...getOwner };
    delete newOwner.id;
    delete newOwner.createdAt;
    yield put({ type: types.SET_OWNER_INFO, payload: newOwner });
    yield call(service.deleteCoupon, id);
    yield call(service.deleteSubscription, id);
    const company = yield call(service.deleteCompany, id);
    let newCompany = { ...getCompany };
    delete newCompany.id;
    delete newCompany.createdAt;
    yield put({ type: types.SET_COMPANY_INFO, payload: newCompany });
    yield put({ type: types.DELETE_COMPANY_SUCCESS });
    Swal.fire({
      icon: "error",
      title: "Something went wrong!",
      text: "Please check your payment details and try again or contact admin@swatchmasters.com",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(() => {
      window.location.reload();
    });
  } catch (error) {
    console.log("err: ", error.message);
    yield put({ type: types.DELETE_COMPANY_FAILURE, payload: error.message });
    Swal.fire({
      icon: "error",
      title: "Something went wrong!",
      text: "Please check your payment details and try again or contact admin@swatchmasters.com",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(() => {
      window.location.reload();
    });
  }
}

export default function* authWatcher() {
  yield takeLatest(types.GET_COUPONS_REQUEST, getCouponsSaga);
  yield takeLatest(types.PROCEED_TO_SIGN_UP, signUpProceedingSaga);
  yield takeLatest(types.ADD_OWNER_REQUEST, addOwnerSaga);
  yield takeLatest(types.ADD_COMPANY_REQUEST, addCompanySaga);
  yield takeLatest(types.DELETE_COMPANY_REQUEST, deleteCompanySaga);
  yield takeEvery(types.ADD_SUBSCRIPTION_REQUEST, addSubScriptionSaga);
  yield takeLatest(types.ADD_CARD_INFO_REQUEST, addCardInfoSaga);
  yield takeLatest(types.SEND_EMAIL, sendCredentialsSaga);
}

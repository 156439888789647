import React from 'react';
import {Route, Redirect} from 'react-router-dom'

const LoginRoute = ({ component: Component, isAuthenticated:IsAuthenticated, ...rest }) => (
    <Route {...rest} render={(props) => (
        IsAuthenticated === false
            ? <Component {...props} />
            : <Redirect to='/' />
    )} />
)
export default LoginRoute
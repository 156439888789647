import * as types from '../constants/fabric';
const init = {
 fabrics: [],
}

function configurationReducer(state = init, action) {
 switch (action.type) {
  case types.GET_FABRICS_SUCCESS:
   return { ...state, fabrics: action.payload };
  default:
   return state;
 }
}

export default configurationReducer;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap'
import { Alert } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios'

import UpgradeSubscriptionPopup from './upgradeSubscriptionPopup';
import RenewalSubscription from './subscriptionRenewal';
import PaymentMethod from './paymentMethod/paymentMethod';

import { deleteSubscription, getSubscription } from '../../store/actions/subscription';

import deleteIcon from '../../img/delete.png'
import './style.css';
import { ApiUrl, CalculateDays, CalculateMonth } from '../../constants';
import { addCompany } from '../../store/actions/actions';

import UpdateCard from './card.update'
const Subscriptions = (props) => {
  const token = useSelector(state => state.auth.token);
  const apiUrl = ApiUrl;
  const cid = useSelector(state => state.auth.companyId);
  const company = useSelector(state => state.auth.company)
  const subscriptions = useSelector(state => state.subscription.subscriptions);
  const BILL = useSelector(state => state.subscription.bill);
  const permissions = useSelector(state => state.auth.permissions);
  const user = useSelector(state => state.auth.user);
  const userId = useSelector(state => state.auth.userId);
  const role = useSelector(state => state.auth.role);
  const dispatch = useDispatch();

  const [plan, setPlan] = useState(null);
  const [plans, setPlans] = useState(null);
  // const [company, setCompany] = useState(null);
  const [isPopup, setPopup] = useState(false);
  const [isDropping, setDrop] = useState(false);
  const [users, setUsers] = useState([]);
  const [subscribed, setSubscription] = useState([]);
  const [createdMonth, setCreatedMonth] = useState(0);
  const [isAllowed, setAllowed] = useState(true);
  const [canRenew, setRenewal] = useState(false);

  const getSubscriptionPlan = (planId) => {
    return new Promise((resolve, reject) => {
      const apiUrlPlan = apiUrl + '/Plans'
      axios.get(apiUrlPlan,
        {
          headers: {
            'Authorization': token
          }
        })
        .then((response) => {
          setPlans(response.data);
          response.data.map(item => { if (item.id === planId) setPlan(item) })
          resolve(true)
        })
        .catch(err => {
          console.log(err)
          reject(false)
        })
    })
  }

  const refreshCompanyDetails = () => {
    const apiURLCompany = ApiUrl + '/Companies/' + cid;
    return new Promise((resolve, reject) => {
      axios.get(apiURLCompany,
        {
          headers: {
            'Authorization': token
          }
        })
        .then(async (response) => {
          // await setCompany(response.data);
          dispatch(addCompany(response.data))
          // getSubscriptionPlan(response.data.planId);
          resolve(true)
        })
        .catch(e => {
          /**
           * fetcing of user data is failed
           * */
          reject(false)
        })
    })
  }

  const refresh = () => {
    const apiUrlUser = apiUrl + '/Companies/' + cid + '/users'
    return new Promise((resolve, reject) => {
      axios.get(apiUrlUser,
        {
          headers: {
            'Authorization': token
          }
        })
        .then(response => {
          setUsers(response.data);
          resolve(true);
        })
        .catch(e => {
          /**
            * fetcing of user data is failed
            * */
          reject(false)
        })
    })
  }

  const compoundRefresh = () => {
    return new Promise((resolve, reject) => {
      refreshCompanyDetails()
        .then(() => {
          getSubscriptionPlan()
            .then(() => resolve(true))
            .catch(() => reject(false))
        })
        .catch(() => {
          reject(false)
        })
    })
  }

  const togglePopup = () => {
    if (isPopup) compoundRefresh();
    setPopup(!isPopup);
  }

  const getSubscriptionAnniversary = (subscriptionDate) => {
    if (!subscriptionDate) return '-';
    const date = new Date(subscriptionDate);
    date.setFullYear(date.getFullYear() + 1);
    return date.toLocaleDateString();
  }

  const handlePaymentCycle = ({ currentTarget }) => {
    if (company.remainingDays < 28) {
      return Swal.fire({
        icon: "warning",
        text: "Please pay your dues first!"
      })
    }
    const url = apiUrl + "/Companies/" + cid;
    const paylaod = {
      isAnnual: currentTarget.value
    }
    axios.patch(url, paylaod, { headers: { 'Authorization': token } })
      .then((response) => {
        compoundRefresh();
      })
      .catch((err) => { console.log("ERR: ", err.message); })

  }

  const handlePermissions = () => {
    if (role === "user" && user && user.id === userId) {
      if (!permissions && !permissions.length || !permissions[0]["Payment Method"].checked) return setAllowed(false);
    }
  }

  useEffect(() => {
    getReady();
  }, [])
  const getReady = () => {
    handlePermissions();
    refreshCompanyDetails();
    refresh()
      .then(() => {
        getSubscriptionPlan()
      })
    dispatch(getSubscription());
  }

  useEffect(() => {
    handleSubView()
  }, [company.subscribeOn, company.termDate])

  const handleSubView = async () => {
    let createdMonths = await CalculateMonth(new Date(), new Date(company.termDate ? company.termDate : company.createdAt))
    createdMonths = Math.round(createdMonths / 12)
    setCreatedMonth(createdMonths)
    let months = await CalculateMonth(new Date(), new Date(company.subscribeOn))
    let days = await CalculateDays(new Date(), new Date(company.subscribeOn))
    days = 365 - days;
    console.log('days: ', company.subscribeOn)
    if (days < 25 && months >= 12) {
      setRenewal(true)
    }
  }

  useEffect(() => {
    if (subscriptions) {
      let subscription = {
        activeUsers: company?.activeMembers,
        anniversary: getSubscriptionAnniversary(company?.subscribeOn),
        dues: 0,
        max_users: 0,
        nexPayment: company?.isAnnual ? getSubscriptionAnniversary(company?.subscribeOn) || '-' : getNextBillingDate(company?.remainingDays || "-"),
        paymentFrequency: company?.isAnnual ? "Annual" : "Monthly",
        unassigned: company?.maxUsers - company?.activeMembers,
      }
      subscriptions.map(sub => {
        const { max_users, price_per_user } = sub.plan
        subscription.max_users += max_users;
        subscription.dues += max_users * price_per_user;
      })
      setSubscription([subscription]);
    }
  }, [subscriptions, company])

  const getNextBillingDate = (days) => {
    let date = new Date();
    const billingDate = new Date(date.setDate(date.getDate() + days));
    return billingDate.toLocaleDateString();
  }

  const handleDrop = (id) => {
    if (isDropping) return;
    setDrop(true)
    Swal.fire({
      title: 'Are you sure to drop the pack?',
      // text: 'Your payment may be different and you might face some issues',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Drop',
      cancelButtonText: 'No, Quit'
    }).then((result) => {
      if (result.value) {
        if (user && user.id === userId) {
          if (!permissions.length || !permissions[0]["Update Subscription"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
        }
        dispatch(deleteSubscription(id))
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setDrop(false)
      }
    })
  }
  return (
    <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
      {/* <Tabs defaultActiveKey="details" id="uncontrolled-tab-example"> */}
      <Tab eventKey="details" title="Subscription Details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-5">
              <button className="subscribeBTN"
                type="button"
                onClick={togglePopup}
              >
                Upgrade Subscription
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="add_comTable">
                <div className="table-responsive">
                  <table className="table tb_top">
                    <thead>
                      <tr>
                        <th scope="col">Active users</th>
                        <th scope="col">Max allowed Users</th>
                        <th scope="col">Unassigned subscribers</th>
                        <th scope="col">Contract renewal date</th>
                        <th scope="col">Next Payment Date</th>
                        <th scope="col">Next Payment Amount</th>
                        <th scope="col">Payment Frequency</th>
                      </tr>
                    </thead>
                    {/*Rows of pricing data*/}
                    <tbody>
                      {company && subscriptions && subscribed &&
                        subscribed.map((sub, ind) => {
                          return (
                            <tr key={ind}>
                              <td>{sub?.activeUsers || 0}</td>
                              <td>{sub?.max_users || 0}</td>
                              <td>{sub?.unassigned || 0}</td>
                              <td>{sub?.anniversary || '-'}</td>
                              <td> {sub?.nexPayment}  </td>
                              <td>${Number.parseFloat(BILL && BILL.invoice && BILL.invoice.netPayable || sub?.dues * (company.isAnnual ? 12 : 1) || 0).toFixed(2)} </td>
                              <td> {sub?.paymentFrequency} </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>

                  <hr />
                  <h6>Subscribed plans</h6>
                  <table className="table tb_top">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Max allowed Users</th>
                        <th scope="col">Price per user</th>
                        <th scope="col">Price per Month</th>
                        {company && subscriptions && canRenew && Number(createdMonth) === Number(company.term) && Array.isArray(subscribed) && subscribed.length &&
                          subscribed[0].unassigned >= 5 &&
                          <th scope="col">Drop users</th>
                        }
                      </tr>
                    </thead>
                    {/*Rows of pricing data*/}
                    <tbody>
                      {company && subscriptions &&
                        subscriptions.map((sub, ind) => {
                          return (
                            <tr key={ind}>
                              <td>{sub?.plan.name || 0}</td>
                              <td>{sub?.plan.max_users || 0}</td>
                              <td>${sub?.plan.price_per_user || 0} </td>
                              <td>${(sub?.plan.price_per_user * sub?.plan.max_users) || 0} </td>
                              {company && canRenew && Number(createdMonth) === Number(company.term) && subscriptions && Array.isArray(subscribed) && subscribed.length &&
                                (subscribed[0].unassigned >= sub?.plan.max_users ?
                                  <td>
                                    <img className="pointer" alt="" src={deleteIcon} onClick={() => handleDrop(sub.id)} />
                                  </td>
                                  :
                                  <td>N/A</td>)
                              }
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          company?.id &&
          <UpgradeSubscriptionPopup
            displayStatus={isPopup}
            closePopup={togglePopup}
            plans={plans}
            // current={company?.planId}
            refreshComp={compoundRefresh}
            numberOfUsers={users?.length}
            company={company}
          />
        }
      </Tab>
      <Tab eventKey="method" title="Payment Methods">
        {
          !isAllowed ?
            <>
              <Alert variant="danger" style={{ textAlign: 'center' }}>
                You are restricted to view this content.
              </Alert>
            </>
            :
            <PaymentMethod getInitialData={getReady} />
        }
      </Tab>
      {role != "user" &&
        <Tab eventKey="card" title="Credit card">
          <UpdateCard getInitialData={getReady} />
        </Tab>
      }
      {canRenew &&
        <Tab eventKey="renewal" title="Subscription Renewal">
          <RenewalSubscription getInitialData={getReady} />
        </Tab>
      }

    </Tabs>
  )
}

export default Subscriptions;




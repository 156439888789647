import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux";
import userImg from './../index.png'
import UploadIcon from "../../../img/upload.png";
import Spinner from "../../../components/loaders/spinner";
import { ApiUrl, user_permissions } from '../../../constants';

import { Button } from 'react-bootstrap';
import PermissionsModal from '../userSetupEditUser/permissions';
import SuccessAlert from './../../../components/alerts/sucessfulAlert'
import DangerAlert from './../../../components/alerts/dangerAlert'
import DanderAlert from "../../../components/alerts/dangerAlert";
import Swal from "sweetalert2";


import './style.css';
class userSetupAdd extends Component {
	constructor(props) {
		super(props)
		this.state = {
			firstName: "",
			lastName: "",
			csr_email: "",
			email: "",
			phone: "",
			password: null,
			passwordConfirmation: null,
			active: true,
			image: null,
			imageToUpload: null,
			username: null,
			uploading: false,

			success: false,

			passMisMatch: false,
			failedPicture: false,
			failedUser: false,
			redirect: false,
			emailExistsError: false,
			usernameExistsError: false,
			permissions: false,
			user_permissions: user_permissions
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.loadFile = this.loadFile.bind(this);
		this.toggleUploading = this.toggleUploading.bind(this)
		this.showFailedToUploadPicture = this.showFailedToUploadPicture.bind(this)
		this.showFailedToCreateUser = this.showFailedToCreateUser.bind(this)
		this.showMismatchError = this.showMismatchError.bind(this)
		this.showSuccessMessage = this.showSuccessMessage.bind(this)
		this.showEmailExistsError = this.showEmailExistsError.bind(this)
		this.showUsernameExistsError = this.showUsernameExistsError.bind(this)
	}

	toggleUploading() {
		this.setState(prevState => ({
			uploading: !prevState.uploading
		}));
	}

	componentDidMount() {
		this.setState({
			active: true
		})
	}


	handleChange(event) {
		event.persist();
		const { name, value, type, checked } = event.target
		type === "checkbox" ?
			this.setState({ [name]: checked }) :
			this.setState({ [name]: value })
	}

	showSuccessMessage() {
		this.setState({ success: true })
		setTimeout(() => {
			this.setState({ success: false }, () => {
				this.props.history.push('/user-setup')
			})
		}, 2000)
	}

	showMismatchError() {
		this.setState({ passMisMatch: true })
		setTimeout(() => { this.setState({ passMisMatch: false }) }, 2000)
	}

	showFailedToCreateUser() {
		this.setState({ failedPicture: true })
		setTimeout(() => { this.setState({ failedPicture: false }) }, 2000)
	}

	showEmailExistsError() {
		this.setState({ emailExistsError: true })
		setTimeout(() => { this.setState({ emailExistsError: false }) }, 2000)
	}

	showUsernameExistsError() {
		this.setState({ usernameExistsError: true })
		setTimeout(() => { this.setState({ usernameExistsError: false }) }, 2000)
	}

	showFailedToUploadPicture() {
		this.setState({ failedUser: true })
		setTimeout(() => { this.setState({ failedUser: false }) }, 2000)
	}

	//handlessubmit for the user add form. Gets the user from database using the user_id
	//if user exists, then it patches the user params on the origial user.
	// otherwise it makes a post request and creates a new user. Api requests are done using axios.
	handleSubmit(event) {
		// event.preventDefault();
		if (!this.state.email) return Swal.fire({ icon: "error", text: "Email is Required!" })
		if (!this.state.username) return Swal.fire({ icon: "error", text: "Username is Required!" })
		if (this.state.password !== this.state.passwordConfirmation) return Swal.fire({ icon: "error", text: "Password does not match!" })
		const { permissions, role, token, companyId } = this.props;
		if (!companyId) return Swal.fire({ icon: "error", text: "Company is not defined. Please log in Again!" })
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Add User"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}

		if (this.state.passwordConfirmation !== this.state.password) {
			this.showMismatchError()
		}
		else {
			this.toggleUploading()
			const filterUrl = ApiUrl + '/Companies/' + companyId + '/users'
			const filterNext = {
				where: { or: [{ email: this.state.email.toLowerCase() }, { username: this.state.username }] }
			};
			axios.get(filterUrl, {
				params: {
					filter: filterNext
				},
				headers: {
					'content-type': 'application/json',
					'Authorization': token
				},
			}).then((filterResponse) => {
				if (filterResponse.data.length) {
					(filterResponse.data[0].username === this.state.username) ?
						this.showUsernameExistsError() :
						this.showEmailExistsError();
					return this.toggleUploading();
				}
				const params = {
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					email: this.state.email && this.state.email.toLowerCase(),
					mobile: this.state.phone,
					password: this.state.password,
					active: this.state.active,
					username: this.state.username,
					csr_email: this.state.csr_email,
					user_permissions: this.state.user_permissions
				};
				//User Id and auth token grabbed from the browser local storage
				const apiUrlUser = ApiUrl + '/Companies/' + this.props.companyId + '/users'
				axios.post(apiUrlUser,
					params,
					{
						headers: {
							'Authorization': token
						}
					})
					.then(response => {
						//Upload Picture
						const profilePictureUploadUrl = ApiUrl + "/uploads/ProfilePicture"
						const data = new FormData()
						if (this.state.imageToUpload !== null) {
							data.append('file', this.state.imageToUpload)
							data.append('UserId', response.data.id)
							axios.post(profilePictureUploadUrl,
								data,
								{
									headers: {
										'Authorization': token
									}
								})
								.then(() => { // then print response status
									this.setState({
										firstName: "",
										lastName: "",
										email: "",
										phone: "",
										password: "",
										passwordConfirmation: "",
										active: true,
										image: null,
										imageToUpload: null
									}, () => {
										this.toggleUploading()
										this.showSuccessMessage()
									}

									)
								})
								.catch(res => {
									this.showFailedToUploadPicture()
									this.toggleUploading()
								})
						}
						else {
							this.setState({
								firstName: "",
								lastName: "",
								email: "",
								phone: "",
								password: "",
								passwordConfirmation: "",
								active: true,
							}, () => {
								this.toggleUploading()
								this.showSuccessMessage()
							})
						}
					})
					.catch(
						(err) => {
							console.log("ERR WHILE CREATING : ", err)
							this.setState({ failedUser: true })
							// this.showFailedToCreateUser()
							this.toggleUploading()
						}
					)
			}
			)
				.catch(
					() => {
						this.setState({ failedUser: true })
						// this.showFailedToCreateUser()
						this.toggleUploading()
					}
				)
		}
	}
	addUserConfirmation = (event) => {
		event.preventDefault();
		// Swal.fire({
		// 	title: 'PAYMENT INCREASE',
		// 	text: 'You are going to add a new user, your payment will increase based upon the plan you have subscribed!',
		// 	icon: 'info',
		// 	showCancelButton: true,
		// 	confirmButtonText: 'Okay, Add it',
		// 	cancelButtonText: 'No, Quit'
		// }).then((result) => {
		// 	if (result.isConfirmed) {
		// 	} else if (result.dismiss === Swal.DismissReason.cancel) {
		// 		this.props.history.push('/user-setup');
		// 	}
		// })
		this.handleSubmit(event);
	}

	loadFile(event) {
		event.persist();
		this.setState({
			image: URL.createObjectURL(event.target.files[0]),
			imageToUpload: event.target.files[0]
		})
	}

	toggleUserPermissions = (permission) => {
		permission.checked = !permission.checked;
		this.setState({ userPermissionsList: this.state.userPermissionsList }, async () => {
			if (permission.title === "Admin App Access") {
				const { user_permissions } = this.state;
				const newList = await user_permissions && user_permissions.map((permit, ind) => {
					if (ind > 1 && permit.key != "title" && (!permission.checked || permit.key.split(" ")[0] != "DELETE")) {
						return permit.checked = permission.checked;
					}
					return permit;
				})
				this.setState({ userPermissionsList: newList })
			}
		})
	}

	handlePermission = () => {
		this.setState({ permissions: !this.state.permissions });
	}

	render() {
		const { permissions, user_permissions } = this.state;
		return (
			<div id="page-content-wrapper" >
				{(this.state.success) ?
					<SuccessAlert message="User created successfully" /> :
					null
				}
				{(this.state.emailExistsError) ?
					<DangerAlert message="Email already exists" /> :
					null
				}
				{
					(this.state.usernameExistsError) ?
						<DangerAlert message="Username already exists" /> :
						null
				}
				{
					(this.state.passMisMatch) ?
						<DanderAlert message="Passwords do not match" /> :
						null
				}
				{
					(this.state.failedPicture) ?
						<DanderAlert message="Failed to upload the avatar for the user" /> :
						null
				}
				{
					(this.state.failedUser) ?
						<DanderAlert message="Something went wrong! Try again later or with different email/username!" /> :
						null
				}

				<div className="container-fluid">
					<div className="row setup_top2 justify-content-md-center">

						<div className="col-12 col-sm-8">
							<div className="box_payment">
								<div className="heding1">Create New User</div>
								<div className="inside">
									<form className="nw_frm" onSubmit={this.addUserConfirmation}>
										<div className="act_inact text-center">
											<div className="row justify-content-md-center">
												<div className="col col-lg-2">
													Inactive
												</div>
												<div className="row col-4 col-lg-4 justify-content-center">
													<label className="switch ">
														<input
															type="checkbox"
															className="default"
															name="active"
															checked={this.state.active}
															onChange={this.handleChange}
														/>
														<span className="slider round" />
													</label>
												</div>
												<div className="col col-lg-2">
													Active
												</div>
											</div>
										</div>

										<div className="user_in text-center">
											<img alt="" className="rounded-circle"
												style={{ width: '100px', height: '100px', maxWidth: '100px', maxHeight: '100px' }}
												src={(this.state.image === null) ? userImg : this.state.image} />
											<h5>Upload</h5>
											<div className="text-center" >
												<input
													id="uploadProfileButton"
													type="file"
													accept="image/*"
													onChange={this.loadFile}
													style={{
														width: '0.1px',
														height: '0.1px',
														opacity: 0,
														overflow: 'hidden',
														position: 'absolute',
														zIndex: '-1'
													}} />
												<label className="btn_grn" htmlFor="uploadProfileButton">
													<img
														alt=""
														src={UploadIcon}
														style={{ display: 'inLine' }} />
													<h6
														style={{ display: 'inLine', color: 'white' }}>
														{' '} Upload
													</h6>
												</label>
											</div>
										</div>

										<div className="text-center" style={{ margin: "15px" }}>
											<Button onClick={this.handlePermission} >Edit Permissions</Button>
											{permissions &&
												<PermissionsModal
													user_permissions={user_permissions}
													onHide={this.handlePermission}
													toggleUserPermissions={this.toggleUserPermissions}
													onSubmit={this.handlePermission}
												/>
											}
										</div>

										<div className="form-row m_btm_row">
											<div className="col">
												<input
													type="text"
													className="form-control"
													placeholder="Username"
													name="username"
													value={this.state.username}
													required={true}
													onChange={this.handleChange}
												/>
											</div>
											<div className="col">
												<input
													type="email"
													className="form-control"
													placeholder="CSR email"
													name="csr_email"
													value={this.state.csr_email}
													required={true}
													onChange={this.handleChange}
												/>
											</div>
										</div>

										<div className="form-row m_btm_row">

											<div className="col">
												<input
													type="text"
													className="form-control"
													placeholder="First name"
													name="firstName"
													value={this.state.firstName}
													onChange={this.handleChange}
													required={true}
												/>
											</div>
											<div className="col">
												<input
													type="text"
													className="form-control"
													placeholder="Last name"
													name="lastName"
													value={this.state.lastName}
													onChange={this.handleChange}
													required={true}
												/>
											</div>
										</div>

										<div className="form-row m_btm_row">
											<div className="col">
												<input
													type="email"
													className="form-control"
													placeholder="Email"
													name="email"
													// pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$"

													value={this.state.email}
													onChange={this.handleChange}
													required={true}
												/>
											</div>
											<div className="col">
												<input
													className="form-control"
													placeholder="Phone"
													name="phone"
													value={this.state.phone}
													pattern="(\d{3}[\-]\d{3}[\-]\d{4})|(\d{3}\d{3}\d{4})"
													onChange={this.handleChange}
												/>
											</div>
										</div>
										<div className="form-row m_btm_row">
											<div className="col">
												<input
													type="password"
													className="form-control"
													placeholder="Password"
													name="password"
													pattern="(?=^.{8,}$)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
													value={this.state.password}
													onChange={this.handleChange}
													required={true}
												/>
											</div>
											<div className="col">
												<input
													type="password"
													className="form-control"
													placeholder="Confirm Password"
													name="passwordConfirmation"
													value={this.state.passwordConfirmation}
													onChange={this.handleChange}
												/>
											</div>
										</div>
										<div className="text-center">
											<button type="submit" className="user_subBtn">
												{(this.state.uploading) ?
													<Spinner styleClass='text-light' /> :
													'Submit'
												}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		userId: state.auth.userId,
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
		user: state.auth.user,
	};
};
export default connect(mapStateToProps)(userSetupAdd);

import React, { Component, useState } from 'react'
import { NavLink } from 'react-router-dom'
import axios from "axios";
// import TempLogo from "../../img/favIcon.png";
import { connect } from "react-redux";
import { ApiUrl } from '../../constants';

const TempLogo = ApiUrl + "/containers/app/download/logo.jpg"
class drawer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			toggle: false,
			logo: null
		}
		this.toggleState = this.toggleState.bind(this)
	}

	toggleState() {
		this.setState((prev) => ({ toggle: !prev.toggle }))
	}
	componentDidMount() {
		const logoUrl = ApiUrl + '/containers/companylogo/' + this.props.companyId + '/exists'
		axios.get(logoUrl,
			{
				headers: {
					'Authorization': this.props.token
				}
			})
			.then((response) => {
				if (response.data.filename !== "null") {
					this.setState({
						logo: ApiUrl + "/containers/companylogo/download/" + response.data.filename,
					})
				}
				else {
					this.setState({
						logo: TempLogo
					})
				}
			})
			.catch(
				() => {
					this.setState({
						logo: TempLogo
					})
				}
			)
	}


	render() {
		return (
			(this.props.company && this.props.company.type === 'vendor') ? <VendorDrawer {...this.state} />
				:
				<div id="sidebar-wrapper" className="toggled">
					<div className="text-center top_lft">
						<NavLink to={'/'} className="edit_img">
							<img alt="" src={this.state.logo} style={{ maxHeight: '200px', maxWidth: '200px' }} />
						</NavLink>
					</div>
					<div className="sidenav">
						<NavLink to={'/dashboard'}> Dashboard </NavLink>
						<button type="button" className="dropdown-btn" onClick={this.toggleState}>
							Company Setup
							<i className="fa fa-caret-down" />
						</button>
						<div className="dropdown-container" style={{ display: (this.state.toggle) ? 'block' : 'none' }}>
							{/* <NavLink to={'/subscription-details'}>Subscription Details</NavLink> */}
							{/*Link to be implemented*/}
							{/* <NavLink to={'/scan-sounds'}>Scanning Alert Sounds</NavLink> */}
							<a href="/subscription-details">Subscription Details</a>
							<a href="/scan-sounds"> Scanning Alert Sounds</a>
						</div>
						{/* <NavLink to={'/user-setup'}>  */}
						<a href="/user-setup"> User Setup </a>
						<a href="/fabrics"> Fabrics </a>
						<a href="/order/create">  Create Order </a>
						{/* <a href="/orders/:id">  Orders Details </a> */}
						<a href="/orders_history"> Order History </a>
						<a href="/swatch-inventory-group"> Swatch Inventory Group </a>
						<a href="/configuration"> Configurations </a>
						{/* </NavLink> */}
						{/* <NavLink to={'/fabrics'}>Fabrics</NavLink>
						<NavLink to={'/orders/:id'}> Orders Details </NavLink> */}
						{/* <NavLink to={'/orders_history'}> Order History </NavLink> */}
						{/* <NavLink to={'/swatch-inventory-group'}>Swatch Inventory Group</NavLink> */}
						{/* <NavLink to={'/configuration'}>Configurations</NavLink> */}
					</div>
				</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		company: state.auth.company
	};
};
export default connect(mapStateToProps)(drawer);

const VendorDrawer = (props) => {
	const [toggle, setToggle] = useState(false)
	return (
		<div id="sidebar-wrapper" className="toggled">
			<div className="text-center top_lft">
				<NavLink to={'/vendor'} className="edit_img">
					<img alt="" src={props.logo} style={{ maxHeight: '200px', maxWidth: '200px' }} />
				</NavLink>
			</div>
			<div className="sidenav">
				<NavLink to={'/vendor'}> Dashboard </NavLink>
				<button type="button" className="dropdown-btn" onClick={(e) => { e.preventDefault(); setToggle(!toggle) }}>
					Company Setup
					<i className="fa fa-caret-down" />
				</button>
				<div className="dropdown-container" style={{ display: (toggle) ? 'block' : 'none' }}>
					<NavLink to={'/vendor/subscription-details'}>Subscription Details</NavLink>
					{/*Link to be implemented*/}
					<NavLink to={'/vendor/scan-sounds'}>Scanning Alert Sounds</NavLink>
				</div>
				<NavLink to={'/vendor/user-setup'}>User Setup</NavLink>

				{/* <NavLink to={'/vendor/orders/:id'}> Orders Details </NavLink> */}
				<NavLink to={'/vendor/orders_history'}> Order History </NavLink>
				<NavLink to={'/vendor/configurations'}> Payment Options </NavLink>
			</div>
		</div>
	)
}

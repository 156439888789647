import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import Axios from 'axios';
// import { ApiUrl } from '../constants';

import "./css/bootstrap.min.css";
import "./css/style.css";
// import "./js/scrolling-nav";
import Logo from './img/logo.png';
import Dash1 from './img/dash1.png';
import VideoImg from './img/video.png';
import Icon1 from './img/icon1.png';
import Icon2 from './img/icon2.png';
import Icon3 from './img/icon3.png';
import Icon4 from './img/icon4.png';
import Icon5 from './img/icon5.png';
import Icon6 from './img/icon6.png';
import Icon7 from './img/icon7.png';

import FbIcon from './img/fb.png';
import GoogleIcon from './img/google.png';
import TwitterIcon from './img/tw.png';
import { getPlans } from '../store/actions/subscription';
import { ManufacturersFeatures, VendorsFeatures } from '../constants';

const LandingPage = (props) => {
  return (
    <div id="page-top">
      <Nav />
      <Header />
      <Features />
      {/* <Video /> */}
      <HowItWorks />
      <Referrals />
      <Subscriptions {...props} />
      <Footer />
    </div>
  )
}

export default LandingPage;

const Nav = (props) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top hdr_bg_col" id="mainNav">
      <div className="row" style={{ alignItems: "center", width: "100%" }}>
        <div className="col-6 col-sm-6">
          <Link className="navbar-brand js-scroll-trigger" to="/">
            <img src={Logo} style={{ maxWidth: "414px", width: "50%" }} alt="" />
          </Link>
        </div>
        <div className="col-sm-6 toggle-view-div" style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse col-6" id="navbarResponsive">
          <ul className="navbar-nav ml-auto ">
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#home">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#features">Features</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#video">Videos</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#howitwork">How it Works</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#subscription">Subscription Plan</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/sign-up/">Sign Up</a>
            </li>
            <li className="nav-item">
              <Link className="nav-link js-scroll-trigger" to="/login">Login</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

const Header = () => {
  return (
    <header className="bg-primary bnr_area" id="home">
      <div className="blur">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-sm-8 col-12" style={{ display: "flex", justifyContent: "center" }}>
              <div className="" style={{ textAlign: "-webkit-center" }}>
                <h1 className="white bold header_title_1">The Ultimate</h1>
                <h1 className="gold bold header_title_2">Swatch Scanning Solution</h1>
                <p className="white header_description">Scan Swatches with our mobile app and have them available anywhere from your online dashboard</p>
                <a href="/#subscription" className="btn sub_btn1" >subscribe Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

const Features = (props) => {
  return (
    <section id="features" className="feature_sec">
      <div className="container">
        <div className="row" style={{ justifyContent: "center" }}>
          <p style={{ textAlign: "center", width: "60%", fontSize: 'larger' }}>
            SwatchMasters simplifies ordering and inventory management in any
            workflow that uses fabric samples and swatches, including the
            furuniture, upholstery, and flooring industries.
          </p>
        </div>
        <div className="row subscriptionSec">
          <div className="container">
            <h2 className="text-center">Features</h2>
            <div id="carouselExampleIndicators1" className="carousel slide" data-ride="carousel">
              <div className="row justify-content-center">
                <FeaturedCard
                  title={"For Manufacturers"}
                  subHead={"Manufacturer of furuniture, upholstery, flooring, and other products that require swatches or samples."}
                  summary={ManufacturersFeatures}
                  type="manufacturer"
                />
                <FeaturedCard
                  title={"For Vendors"}
                  subHead={"Producers of swatch and simple binders, booklets, and other materials to display available fabrics, colors, and patterns for customizable products."}
                  summary={VendorsFeatures}
                  type="vendor"
                />
              </div>
            </div>



          </div>
        </div>
      </div>
    </section>
  )
}

const FeaturedCard = (props) => {
  return (
    <div className="col-sm-6 col-md-6 ">
      <div className="box_seclol">
        <div className="headin_sec2 text-center">
          <h4>  {props.title || ""}</h4>
        </div>
        <div className="mnth_user">
          <span><strong> {props.subHead} </strong></span>
        </div>
        <div className="pricing_txt">
          {Array.isArray(props.summary) && props.summary.map((info, index) => {
            return (
              <div>
                <h4>{info.title}</h4>
                <ul>
                  {info.bullets.map(bullet => <li>{bullet}</li>)}
                </ul>
              </div>
            )
          })}
        </div>
        <div className="discount text-center">
          <div className="div_mtop">
            <Link to={{ pathname: `/sign-up/`, state: { planType: props.type } }} >
              <button className="sub_btn"  ><span>Register Now</span></button>
            </Link>
          </div>
          <p>
            {/* <span>10% discount</span><br />with this plan */}
          </p>
        </div>
      </div>
    </div>
  )
}

const Video = (props) => {
  return (
    <section id="video" className="videoSec">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-7">
            <img src={VideoImg} alt="" />
          </div>
          <div className="col-12 col-sm-5 py-5">
            <h2>Video</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
              leap into electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const HowItWorks = (props) => {
  return (
    <section id="howitwork" className="bg-light howItWork">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2>HOW IT WORKS</h2>
            <div className="row bg_line">
              <div className="col-sm-3">
                <div className="ring_cur mx-auto">
                  <img src={Icon1} alt="" />
                </div>
                <h6>Choose A<br />Subscription Plan</h6>
              </div>
              <div className="col-sm-3">
                <div className="ring_cur mx-auto">
                  <img src={Icon2} alt="" />
                </div>
                <h6>Setup Your Swatch Inventory<br />Groups & Users</h6>
              </div>
              <div className="col-sm-3">
                <div className="ring_cur mx-auto">
                  <img src={Icon3} alt="" />
                </div>
                <h6>Users Download The <br />APP From The App Store</h6>
              </div>
              <div className="col-sm-3">
                <div className="ring_cur mx-auto">
                  <img src={Icon4} alt="" />
                </div>
                <h6>Users Download The Defined Swatch <br />Inventory Groups To Their Device</h6>
              </div>
              <div className="col-sm-3">
                <div className="ring_cur mx-auto">
                  <img src={Icon5} alt="" />
                </div>
                <h6>Users Scan The<br />Swatch Inventory</h6>
              </div>
              <div className="col-sm-3">
                <div className="ring_cur mx-auto">
                  <img src={Icon6} alt="" />
                </div>
                <h6>Users Upload The Scanned<br />Inventory</h6>
              </div>
              <div className="col-sm-3">
                <div className="ring_cur mx-auto">
                  <img src={Icon7} alt="" />
                </div>
                <h6>Manager Reviews<br />Missing Swatch</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Referrals = (props) => {
  return (
    <section id="referrals" className="bg-light referrals">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2>Referrals</h2>
            <div className="row bg_line1">
              <div className="col-sm-4">
                <div className="ring_cur mx-auto ring_text">
                  {/* <img src={Icon1} alt="" /> */}
                  <h6>Send your unique referral codes to a manufacturer or vendor</h6>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="ring_cur mx-auto ring_text">
                  {/* <img src={Icon2} alt="" /> */}
                  <h6>The company you referred will earn credit for each user subscription</h6>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="ring_cur mx-auto ring_text">
                  {/* <img src={Icon3} alt="" /> */}
                  <h6>On the anniversary of your contract you receive a discount based on the number of active users for each company you referred (up to 75% )</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Subscriptions = (props) => {
  const dispatch = useDispatch();
  const plans = useSelector(state => state.subscription.plans) || [];
  const [basePlans, setBasePlans] = useState([]);
  useEffect(() => {
    dispatch(getPlans());
  }, [])

  useEffect(() => {
    basePlans.length > 0 && setBasePlans([]);
    plans.filter((plan) => {
      let user = false;
      let vendor = false;
      let manufacturer = false;
      // if (plan.type === 'user' && plan.max_users === 5) { if (!user && setBasePlans((prevPlans) => [...prevPlans, plan])) user = true };
      if (plan.type === 'vendor' && plan.max_users === 5) { if (!user && setBasePlans((prevPlans) => [...prevPlans, plan])) vendor = true };
      if (plan.type === 'manufacturer' && plan.max_users === 5) { if (!user && setBasePlans((prevPlans) => [...prevPlans, plan])) manufacturer = true };
    })
  }, [plans])

  return (
    <section id="subscription" className="subscriptionSec">
      <div className="container">
        <h2 className="text-center">SUBSCRIPTION PLANs</h2>
        <div id="" className="carousel slide" data-ride="carousel">
          <div className="row justify-content-center">
            {basePlans.map((plan, index) => {
              return <SubscriptoinCard {...props} {...plan} />
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

const SubscriptoinCard = (props) => {
  return (
    <div className="col-sm-6 col-md-6 ">
      <div className="box_seclol">
        <div className="headin_sec2 text-center">
          <h4> <b>{props.type.toUpperCase() + "'s "}</b> <br />{props.name || "BASIC BUSINESS"}</h4>
        </div>
        <div className="mnth_user">
          <span><strong>$ {Number.parseFloat(props.price_per_user * props.max_users || 15).toFixed(2)}</strong> / Month</span>
        </div>
        <div className="pricing_txt">
          <ul>
            <li>Starting at <b>${props.price_per_user} / user</b>.</li>
            <li>Starting with {props.max_users} <b>Members</b>.</li>
          </ul>
        </div>
        <div className="discount text-center">
          <div className="div_mtop">
            <Link to={{ pathname: `/sign-up/`, state: { planType: props.type } }} >
              <button className="sub_btn"  ><span>Subscribe Now</span></button>
            </Link>
          </div>
          <p>
            {/* <span>10% discount</span><br />with this plan */}
          </p>
        </div>
      </div>
    </div>
  )
}

const Footer = (props) => {
  return (
    <footer className="ftr_sec">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <div className="ftr_logo">
              <a href="#home"><img src={Logo} style={{ maxWidth: "380px" }} alt="" /></a>
            </div>
          </div>
        </div>

        <div className="row ftr_mid_sec py-5">
          <div className="col col-sm-3 social">
            <h3>Social Links</h3>
            <a href="#"><img alt="" src={FbIcon} /></a>
            <a href="#"><img alt="" src={GoogleIcon} /></a>
            <a href="#"><img alt="" src={TwitterIcon} /></a>
          </div>

          <div className="col-12 col-sm-9 contact">
            <h3>Contact Us</h3>
            <div className="row">
              <div className="col-12 col-sm-4">
                <span className="bu1">
                  SwatchMasters<br />
                  535 South Cox Street<br />
                  Suite A<br />
                  Asheboro, NC 27203
                </span>

              </div>
              <div className="col-12 col-sm-4">
                <span className="bu2">info@swatchmasters.com</span>
              </div>
              <div className="col-12 col-sm-4">
                <span className="bu3">(336) 629-6484</span>
              </div>
            </div>
          </div>
        </div>
        <div className="ftrBtm">
          <div className="row py-2">

            <div className="col-auto mr-auto">
              <span>Copyright © <a href="#">SwatchMasters 2019</a></span>
            </div>
            <div className="col-auto mnu_rt">
              <a href="#">Terms & Condiions</a> | <a href="./privacy-policy">Privacy & Policy </a>
            </div>
          </div>
        </div>


      </div>
    </footer>
  )
}
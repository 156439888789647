import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";

import CreditCardInput from "react-credit-card-input";
import Swal from "sweetalert2";

import loginLogo from "./../../img/logo.png";
import Spinner from "../../components/loaders/spinner";

import { ApiUrl, validateEmail } from "../../constants";

import { getPlans } from "../../store/actions/subscription";
import { filterUser, verifyRefCode } from "../../store/services/signUp";
import {
  getCoupons,
  setOwner,
  setCardInfo,
  setCompany,
  setNoOfPacks,
  setNoOfUsers,
  setStep,
  setLoading,
  proceedToSignUp,
} from "../../store/actions/signUp";

import "./style.css";
import { Prompt } from "react-router";

// Parent Functional Component
const SingUp = (props) => {
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.subscription.plans) || [];
  const coupons = useSelector((state) => state.signUp.coupons) || [];
  const settings = useSelector((state) => state.settings.settings) || [];
  const step = useSelector((state) => state.signUp.step || 0) || 0;
  const owner = useSelector((state) => state.signUp.owner) || 0;
  const company = useSelector((state) => state.signUp.company) || 0;
  const cardInfo = useSelector((state) => state.signUp.cardInfo) || 0;
  const noOfUsers = useSelector((state) => state.signUp.noOfUsers) || 0;
  const noOfPacks = useSelector((state) => state.signUp.noOfPacks) || 0;
  const isLoading = useSelector((state) => state.signUp.loading) || false;


  useEffect(() => {
    dispatch(getPlans());
    dispatch(getCoupons());
    setTimeout(() => {
      dispatch(setLoading(false))
    }, 5500);
  }, []);

  const renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <User
            setUser={setUser}
            {...props}
            owner={owner}
            moveBack={moveBack}
          />
        );
      case 1:
        return (
          <Company
            setCompany={setCompanyInfo}
            {...props}
            company={company}
            moveBack={moveBack}
          />
        );
      case 2:
        return (
          <CodeInfo
            setCode={setCode}
            {...props}
            company={company}
            noOfPacks={noOfPacks}
            users={noOfUsers}
            coupons={coupons}
            moveBack={moveBack}
          />
        );
      case 3:
        return (
          <PlanInfo
            plans={plans}
            company={company}
            settings={settings}
            noOfUsers={noOfUsers}
            noOfPacks={noOfPacks}
            setPlan={setPlan}
            coupons={coupons}
            {...props}
            moveBack={moveBack}
          />
        );
      case 4:
        return (
          <TermsOfService
            url={ApiUrl + "/containers/app/download/TOS.pdf"}
            moveToPayments={moveForward}
            {...props}
            owner={owner}
            moveBack={moveBack}
          />
        );
      case 5:
        return (
          <CardInfo
            setCard={setCard}
            {...props}
            cardInfo={cardInfo}
            moveBack={moveBack}
          />
        );
      default:
        return <User setUser={setUser} {...props} moveBack={moveBack} />;
    }
  };

  const moveBack = () => dispatch(setStep(step - 1));
  const moveForward = () => dispatch(setStep(step + 1));

  const setUser = (USER) => {
    dispatch(setOwner(USER));
    dispatch(setStep(step + 1));
  };
  const setCompanyInfo = (COMPANY) => {
    dispatch(setCompany(COMPANY));
    dispatch(setStep(step + 1));
  };
  const setCode = (codes) => {
    let newCompany = {
      ...company,
      term: codes.term,
      couponId: codes.couponId,
      coupon: codes.coupon,
      refCode: codes.refCode,
    };
    dispatch(setNoOfUsers(Number(codes.numberOfUser)));
    dispatch(setCompany(newCompany));
    dispatch(setStep(step + 1));
  };
  const setPlan = (qty) => {
    dispatch(setNoOfPacks(qty));
    dispatch(setStep(step + 1));
  };
  const setCard = (CARD) => {
    dispatch(setCardInfo(CARD));
    // setCCard(CARD);
  };

  return (
    <div>
      {isLoading &&
        <Prompt
          message={(location, action) => {
            if (action === 'POP') {
              console.log("Backing up...")
            }

            return location.pathname.startsWith("/app")
              ? true
              : `Are you sure you want to go to ${location.pathname}?`
          }}
        />
      }
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="login_box">
              <div className="logo_bx">
                <img src={loginLogo} style={{ maxWidth: "414px" }} alt="" />
              </div>
              <div className="frmBx" style={{ width: "100%" }}>
                {renderSteps()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingUp;

const User = ({ setUser, owner }) => {
  const [username, setUserName] = useState((owner && owner.username) || "");
  const [email, setEmail] = useState((owner && owner.email) || "");
  const [isLoading, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) return Swal.fire("Email is requried!");
    if (!validateEmail(email)) return Swal.fire("Email is not valid!");
    if (!username) return Swal.fire("User name is requried!");
    setLoader(true);
    filterUser(email.toLowerCase(), undefined, username).then((response) => {
      setLoader(false);
      if (Array.isArray(response) && response.length) {
        let text =
          response[0].email === email
            ? "Email already in use!"
            : "Username already in use!";
        return Swal.fire({
          icon: "error",
          title: "Duplication!",
          text,
          timer: 3000,
        });
      } else {
        setUser({ username, email: email.toLowerCase() });
      }
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center" }}> Owner Info </h4>
      <hr />
      <div className="input-group form-group fild">
        <div className="input-group-prepend">
          <span className="input-group-text">USER NAME</span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Enter your desired Username"
          name="username"
          value={username}
          onChange={(e) => setUserName(e.target.value)}
        />
      </div>
      <div className="input-group form-group fild">
        <div className="input-group-prepend">
          <span className="input-group-text">USER EMAIL</span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Enter your Email Address"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="form-group">
        <button type="submit" className="nxt_btn">
          <text> {isLoading ? <Spinner /> : "Confirm Owner"} </text>
        </button>
      </div>
    </form>
  );
};

const Company = ({ setCompany, location, moveBack, company }) => {
  const [name, setName] = useState(company.name || "");
  const [type, setType] = useState(
    company.type ||
    (location && location.state && location.state.planId) ||
    "manufacturer"
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name) return Swal.fire("Name is required");
    if (!type) return Swal.fire("Type is required");
    const company = {
      name,
      type,
      active: false,
      isAnnual: false,
      activeMembers: 0,
      isLimited: false,
      subscribeOn: new Date(),
      unassignedSubscriptions: 0,
      referralCode: name.split(" ").join("") + "_REF_" + type,
    };
    setCompany(company);
  };
  return (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center" }}> Company Info</h4>
      <hr />
      <div className="input-group form-group fild">
        <div className="input-group-prepend">
          <span className="input-group-text">COMPANY NAME</span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Company Name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required={true}
        />
      </div>
      <div className="input-group form-group fild">
        <div className="input-group-prepend">
          <span className="input-group-text">Company Type</span>
        </div>
        <select
          className="form-control"
          name="type"
          defaultValue={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          <option value="manufacturer">Manufacturer</option>
          <option value="vendor">Vendor</option>
        </select>
      </div>
      <div className="form-group">
        <button type="submit" className="nxt_btn">
          <text>Confirm Company</text>
        </button>
        <button onClick={moveBack} className="login_btn">
          <text>Back</text>
        </button>
      </div>
    </form>
  );
};

const CodeInfo = ({
  setCode,
  moveBack,
  noOfPacks,
  company,
  users,
  coupons,
}) => {
  const selectedCoupon =
    coupons && coupons.filter((item) => item.id === company.couponId);
  const [couponId, setCouponId] = useState(
    (selectedCoupon && selectedCoupon[0] && selectedCoupon[0].name) || ""
  );
  const [coupon, setCoupon] = useState(company.coupon || {});
  const [refCode, setRef] = useState(company.refCode || "");
  const [numberOfUser, setNumberOfUser] = useState(users || 5);
  const [term, setTerm] = useState(Number(company.term) || 3);

  useEffect(() => {
    if (Number(noOfPacks) > 0) {
      setNumberOfUser(5 + Number(noOfPacks) * 5);
    }
  }, [noOfPacks]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!term) return Swal.fire("Contract Term is required");
    if (!numberOfUser) return Swal.fire("Number of Users is required");
    const next = (isValidRefCode) => {
      const codeInfo = {
        couponId: coupon && coupon.id,
        coupon:
          coupon && coupon.id
            ? {
              ...coupon,
              addedDate: new Date(),
              expiryDate: coupon.couponType === "referral" ? "12" : coupon.expiryDate,
            }
            : {},
        refCode: refCode,
        numberOfUser,
        term,
      };
      setCode(codeInfo);
    };

    verifyRefCode(refCode).then((response) => {
      next(true);
    });
  };

  const handleCoupon = ({ target }) => {
    const currentDate = new Date();
    setCoupon(undefined);
    try {
      coupons.filter((cpn) => {
        const cpnExpiry = (cpn.expiry && new Date(cpn.expiry)) || undefined;
        if (
          (cpn.couponType === "referral" && cpn.name === target.value) ||
          (cpn.couponType === "user" &&
            cpn.name === target.value &&
            cpnExpiry &&
            cpnExpiry.getTime() > currentDate.getTime())
        ) {
          cpn.couponType === "referral" ? setRef(cpn.name) : setRef("");
          setCoupon(cpn);
        }
      });
      setCouponId(target.value);
    } catch (error) {
      console.log("ERR WHILE USING THE CODE : ", error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center" }}> Codes & Discounts</h4>
      <hr />
      <div className="input-group form-group fild">
        <div className="input-group-prepend">
          <span className="input-group-text">No. of users</span>
        </div>
        <input
          type="number"
          min="1"
          className="form-control"
          placeholder="Enter Number of Users"
          name="users"
          onChange={(e) => setNumberOfUser(e.target.value)}
          value={numberOfUser}
        />
      </div>

      <div className="input-group form-group fild">
        <div className="input-group-prepend">
          <span className="input-group-text">Contract Term</span>
        </div>
        <select
          className="form-control"
          name="type"
          onChange={(e) => setTerm(e.target.value)}
          value={term}
        >
          <option value="3">3 years</option>
          <option value="1">1 year</option>
        </select>
      </div>

      <div className="input-group form-group fild">
        <div className="input-group-prepend">
          <span className="input-group-text">COUPON Code</span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Coupon"
          name="couponId"
          onChange={handleCoupon}
          value={couponId}
          style={{ color: coupon && coupon.id ? "green" : "red" }}
        />
      </div>
      <div className="form-group">
        <button type="submit" className="nxt_btn">
          <text>Confirm</text>
        </button>
        <button onClick={moveBack} className="login_btn">
          <text>Back</text>
        </button>
      </div>
    </form>
  );
};

class TermsOfService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: "",
      format: "",
      idAgreed: false,
      numPages: null,
      pageNumber: 1,
    };
  }
  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    this.setState({ src: this.props.url });
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  onLoadError = (error) => {
    console.log("Error while loading document! ", error);
  };

  handleAgreement = () => {
    if (!this.state.isAgreed)
      return Swal.fire("Please Agree to the TOS to continue");
    this.props.moveToPayments();
  };
  render() {
    const { pageNumber, numPages } = this.state;
    return (
      <div className="TOS_MAIN">
        <Document
          file={this.state.src}
          renderMode="svg"
          options={{ workerSrc: "pdf.worker.js" }}
          onLoadError={this.onLoadError}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <br />
        <input
          className="form-controll"
          type="checkbox"
          id="Agreement"
          style={{ marginRight: "10px" }}
          onChange={(e) => {
            this.setState({ isAgreed: e.target.checked });
          }}
          aria-label="Agreement"
          placeholder="I have fully read and agree to the terms of Service"
          label="  I have fully read and agree to the terms of Service"
        />
        <label htmlFor="Agreement">
          I have fully read and agree to the terms of Service
        </label>
        <br />
        <div className="form-group">
          <button onClick={this.handleAgreement} className="nxt_btn">
            <text>Continue</text>
          </button>
          <button onClick={this.props.moveBack} className="login_btn">
            <text>Back</text>
          </button>
        </div>
      </div>
    );
  }
}

const PlanInfo = ({
  setPlan,
  moveBack,
  plans,
  company,
  noOfPacks,
  noOfUsers,
  coupons,
  settings,
}) => {
  const [qty, setQty] = useState(Number(noOfPacks));
  const [plan, setPlans] = useState(undefined);
  const [bulkDiscount, setBulkDiscount] = useState(undefined);
  const [basePack, setBasePack] = useState(undefined);
  const [totalDiscount, setDiscount] = useState(undefined);
  const [maxDisc, setMaxDiscount] = useState(75);
  useEffect(() => {
    Array.isArray(settings) &&
      settings.filter((set) => {
        if (set.name === "MaxDiscount") {
          if (Number(maxDisc) !== Number(set.value.discount))
            setMaxDiscount(Number(set.value.discount));
        }
      });
  }, [settings]);
  useEffect(() => {
    plans &&
      plans.filter((item) => {
        if (item.max_users === 5 && item.type === "user") setPlans(item);
      });
    if (noOfUsers > 5) {
      let nUsers = noOfUsers - 5;
      nUsers = Math.ceil(nUsers / 5);
      let maxNoOfUsers = nUsers * 5 > 0 ? nUsers * 5 + 5 : 5;
      coupons &&
        coupons.filter((item) => {
          if (item.couponType === "auto") {
            if (maxNoOfUsers >= item.minUser) {
              setBulkDiscount(item);
            }
          }
        });
      let users = 5 + Number(noOfPacks) * 5;
      if (users > noOfUsers) setQty(noOfPacks);
      else setQty(nUsers);
    } else {
      setQty(noOfPacks || 0);
      coupons &&
        coupons.filter((item) => {
          if (item.couponType === "auto") {
            if (noOfUsers >= item.minUser) setBulkDiscount(item);
          }
        });
    }
    if (
      Array.isArray(plans) &&
      plans.filter((i) => i.type === company.type && i.max_users === 5).length
    ) {
      setBasePack(
        plans.filter((i) => i.type === company.type && i.max_users === 5)[0]
      );
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPlan(qty);
  };

  const getMonthlyFare = () => {
    let total = 0;
    let dues =
      (plan && plan.price_per_user * 5) * qty +
      ((basePack && basePack.price_per_user * 5) || 0);
    if (company.term === 3) total = 5 + total;
    if (company.couponId && company.coupon)
      total =
        getInPercentage(company.coupon.discount, company.coupon.type, dues) +
        total;
    if (bulkDiscount && bulkDiscount.couponType)
      total =
        getInPercentage(bulkDiscount.discount, bulkDiscount.type, dues) + total;
    // let fare = dues - (dues * (total <= maxDisc ? total : maxDisc)) / 100;
    let fare = dues - (dues * (total <= maxDisc ? total : maxDisc)) / 100;
    return Number.parseFloat(fare).toFixed(2);
    // return dues;
  };

  const getInPercentage = (discount, type, total) => {
    let percent = discount;
    if (type !== "percent") {
      percent = (discount * 100) / total;
    }
    return percent;
  };

  const handleQty = ({ target }) => {
    setQty(target.value);
    coupons &&
      coupons.filter((item) => {
        if (
          item.couponType === "auto" &&
          target.value * 5 + 5 >= item.minUser
        ) {
          setBulkDiscount(item);
          return item;
        }
      }).length < 1 &&
      setBulkDiscount(undefined);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center" }}> Plan Details </h4>
      <p>We made a plan to fit your users. You can change it below.</p>
      <hr />
      <br />
      <table>
        <tr>
          <th>Package</th>
          <th>Qty</th>
          <th>Users</th>
          <th>Price/Month</th>
        </tr>
        <tbody>
          <tr>
            <td>
              {(company && company.type.toUpperCase()) || "Manufacturer"} Base
              Pack
            </td>
            <td>1</td>
            <td>5</td>
            <td>
              $
              {(Array.isArray(plans) &&
                plans
                  .filter((i) => i.type === company.type && i.max_users === 5)
                  .map((i) => Number(i.price_per_user * 5).toFixed(2))) ||
                "250"}
            </td>
          </tr>
          <tr>
            <td>5 user Pack</td>
            <td>
              <input
                type="number"
                name="qty"
                placeholder="Enter Qty"
                className="form-control"
                value={qty}
                min={0}
                onChange={handleQty}
              />
            </td>
            <td>{(plan && Number.parseFloat(plan.max_users * qty)) || 0}</td>
            <td>
              $
              {Number.parseFloat(
                ((plan && plan.price_per_user * plan.max_users) || 0) * qty
              ).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>

      <br />

      <table>
        <tr>
          <th>Discounts</th>
          <th>
            $
            {Number.parseFloat(
              ((plan && plan.price_per_user * 5) || 1) * qty +
              ((basePack && basePack.price_per_user * 5) || 0) -
              getMonthlyFare()
            ).toFixed(2)}
          </th>
        </tr>
        <tbody>
          {company && company.term === 3 && (
            <tr>
              <td>3-year Term Discount:</td>
              <td>(5%)</td>
            </tr>
          )}
          {company && company.couponId && (
            <tr>
              <td>{company.coupon.name}:</td>
              <td>
                ({company.coupon.type !== "percent" && "$"}
                {company.coupon.discount}
                {company.coupon.type === "percent" && "%"})
              </td>
            </tr>
          )}
          {bulkDiscount && bulkDiscount.name && (
            <tr>
              <td>Bulk user discount:</td>
              <td>
                ({bulkDiscount.type !== "percent" && "$"}
                {bulkDiscount.discount}
                {bulkDiscount.type === "percent" && "%"})
              </td>
            </tr>
          )}
          <tr>
            <td>Total Monthly Cost:</td>
            <td> ${getMonthlyFare()} </td>
          </tr>
        </tbody>
      </table>

      <br />
      <div className="form-group">
        <button type="submit" className="nxt_btn">
          <text>Confirm Plan</text>
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            setPlan(qty);
            moveBack();
            moveBack();
          }}
          className="login_btn"
        >
          <text>Back</text>
        </button>
      </div>
    </form>
  );
};

const CardInfo = ({ setCard, moveBack }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.signUp.loading);
  const company = useSelector((state) => state.signUp.company);
  const getCardInfo = useSelector((state) => state.signUp.cardInfo);
  const [isLoading, setLoading] = useState(false);
  const [isErr, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [cardNumber, setCardNumber] = useState(getCardInfo.cardNumber || "");
  const [expiry, setExpiry] = useState(getCardInfo.expiry || "");
  const [cvc, setCvc] = useState(getCardInfo.cvc || "");

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
    isErr && setErr(false);
  };
  const handleCardExpiryChange = (event) => {
    setExpiry(event.target.value);
    isErr && setErr(false);
  };
  const handleCardCVCChange = (event) => {
    setCvc(event.target.value);
    isErr && setErr(false);
  };

  useEffect(() => {
    setLoading(loading)
  }, [loading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isErr) return Swal.fire({ icon: "error", text: errMessage });
    if (!company.isAnnual) {
      if (!cardNumber) return Swal.fire("Card Number is required");
      if (!expiry) return Swal.fire("Card expiry is required");
      if (!cvc) return Swal.fire("cvv is required");
    }
    if (isLoading) return;
    setLoading(true);
    const cardInfo = {
      cardNumber,
      expiry: expiry.split(" ").join(""),
      cvc,
    };
    setCard(cardInfo);
    dispatch(proceedToSignUp());
  };

  const handlePaymentMethod = ({ target }) => {
    var comp = { ...company };
    comp.isAnnual = target.value.toString() === "true" ? true : false;
    dispatch(setCompany(comp));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 style={{ textAlign: "center" }}> Payment Method </h4>
      <hr />
      <br />
      <select
        className="form-control"
        name="isAnnual"
        defaultValue={String(company.isAnnual)}
        onChange={handlePaymentMethod}
      >
        <option value="true">Pay annually with check</option>
        <option value="false">Pay monthly with card</option>
      </select>
      <br />

      {company && company.isAnnual === false && (
        <div className="form-group">
          <CreditCardInput
            cardNumberInputProps={{
              value: cardNumber,
              onChange: handleCardNumberChange,
            }}
            cardExpiryInputProps={{
              value: expiry,
              onChange: handleCardExpiryChange,
            }}
            cardCVCInputProps={{ value: cvc, onChange: handleCardCVCChange }}
            onError={(err) => {
              !isErr && setErr(true);
              setErrMessage(err.error);
            }}
            fieldClassName="input"
          />
        </div>
      )}
      <br />
      <div className="form-group">
        <button type="submit" className="nxt_btn">
          <text>{isLoading && loading ? <Spinner /> : "Sign Up"}</text>
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            moveBack();
          }}
          className="login_btn"
        >
          <text>Back</text>
        </button>
      </div>
    </form>
  );
};

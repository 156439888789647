import { ADD_USER_ID, ADD_COMPANY_ID, ADD_URL, ADD_TOKEN, ADD_COMPANY, ADD_USER, ADD_PERMISSIONS, ADD_ROLE, SET_EMAIL_INFO } from '../constants';
const url = require("../constants").ApiUrl; 

const initialState = {
    apiUrl: null,
    userId: null,
    companyId: null,
    token: null,
    company: null,
    user: null,
    userEmail: '',
    permissions: null,
    role: "",
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case ADD_USER_ID:
            return { ...state, userId: action.payload };
        case ADD_USER:
            return { ...state, user: action.payload };
        case ADD_ROLE:
            return { ...state, role: action.payload };
        case SET_EMAIL_INFO:
            return { ...state, userEmail: action.payload };
        case ADD_PERMISSIONS:
            return { ...state, permissions: [action.payload] };
        case ADD_COMPANY_ID:
            return { ...state, companyId: action.payload };
        case ADD_COMPANY:
            return { ...state, company: action.payload };
        case ADD_URL:
            return { ...state, apiUrl: url };
        case ADD_TOKEN:
            return { ...state, token: action.payload };
        default:
            return state;
    }
}

export default reducer;

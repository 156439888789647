import React, { useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Modal from "react-bootstrap/Modal";
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import Spinner from "../../../components/loaders/spinner";
import { addFabric, getFabric } from '../../../store/services/fabric';
import { createOrderFabric, getOrderFabrics } from '../../../store/services/order';

// fabricId = id AND fabricID = code
const tempFabrics = { quantity: 0, fabricId: "", fabricID: "", code: "", pattern_name: "", color: "" }
const AddFabricModal = (props) => {
 const token = useSelector(state => state.auth.token)
 const companyId = useSelector(state => state.auth.companyId)

 const [loading, setLoader] = useState(false);
 const [submitClick, setSubmitClick] = useState(false);
 const [isFabricFound, setFound] = useState(true);
 const [orderFabrics, setFabrics] = useState(tempFabrics)

 const handleFabric = () => {
  if (orderFabrics.fabricID) {
   setLoader(true)
   setFound(true)
   let filter = { where: { companyId: companyId, code: orderFabrics.fabricID } }
   getFabric({ filter }, token)
    .then(response => {
     setLoader(false)
     if (Array.isArray(response) && response.length) {
      let fabric = { ...orderFabrics }
      fabric.fabricId = response[0].id
      fabric.fabricID = response[0].code
      fabric.code = response[0].code
      fabric.pattern_name = response[0].pattern_name
      fabric.color = response[0].color
      setFabrics(fabric)
     } else {
      let fabric = { ...orderFabrics }
      fabric.fabricId = ""
      fabric.code = ""
      fabric.pattern_name = ""
      fabric.color = ""
      setFabrics(fabric)
     }
     setFound(false)
    })
  }
 }

 const handleChange = ({ target }) => {
  let fabrics = { ...orderFabrics };
  fabrics[target.name] = target.value;
  setFabrics(fabrics)
 }

 const handleSubmit = async () => {
  if (submitClick) return;
  setSubmitClick(true)
  let fabric = {
   quantity: orderFabrics.quantity,
   orderId: props.groupId,
   fabricId: orderFabrics.fabricId,
  }
  if (orderFabrics.quantity < 1) { setSubmitClick(false); return Swal.fire({ icon: 'warning', title: 'Quantity is required', timer: 1500 }) }
  if (!orderFabrics.fabricId) {
   if ((!orderFabrics.code) || (!orderFabrics.fabricID) || (!orderFabrics.pattern_name) || (!orderFabrics.color)) { setSubmitClick(false); return Swal.fire({ icon: 'warning', title: 'Add a fabric', timer: 1500 }) }
   const payload = {
    code: orderFabrics.code,
    pattern_name: orderFabrics.pattern_name,
    color: orderFabrics.color,
    active: true,
    companyId
   }
   addFabric(payload, token)
    .then(response => {
     fabric.fabricId = response.id
     createFabric(fabric)
    })
  } else {
   createFabric(fabric)
  }

 }

 const createFabric = (payload) => {
  createOrderFabric(payload, token)
   .then(response => {
    setSubmitClick(false)
    if (typeof response === 'object') {
     Swal.fire({ icon: 'success', title: 'Added Successfully', timer: 1500 })
     props.getOrders();
     props.close()
    }
   })
 }



 return (
  <Modal
   className="container-fluid"
   show={props.modal}
   onHide={props.close}
   centered
   size="xl"
   dialogClassName="modal_width"
  >
   <Modal.Header>
    <h3>Add Fabric Details</h3>
   </Modal.Header>

   <Modal.Body>
    <form className="container addFabricModal" onSubmit={(e) => e.preventDefault()}>

     <div className="row">
      <div className="col-3">
       Quantity:
      </div>
      <div className="col-9">
       <input
        name="quantity"
        value={orderFabrics.quantity || 0}
        onChange={handleChange}
        placeholder="[Whole number or 'Available Fabric']"
       />
      </div>
     </div>

     <div className="row">
      <div className="col-3">
       Fabric Id:
      </div>
      <div className="col-9">
       {!loading ?
        <input
         name="fabricID"
         value={orderFabrics.fabricID}
         placeholder="Enter fabric Id to search"
         onBlur={handleFabric}
         onChange={handleChange}
        />
        :
        <Spinner styleClass="text-primary" />
       }
      </div>
     </div>
     {!isFabricFound &&
      <>
       <div className="row">
        <div className="col-3">
         Barcode:
        </div>
        <div className="col-9">
         <input
          name="code"
          disabled={orderFabrics.fabricId ? true : false}
          value={orderFabrics.code || ""}
          onChange={handleChange}
          placeholder=""
         />
        </div>
       </div>

       <div className="row">
        <div className="col-3">
         Pattern Name:
        </div>
        <div className="col-9">
         <input
          name="pattern_name"
          disabled={orderFabrics.fabricId ? true : false}
          value={orderFabrics.pattern_name || ""}
          onChange={handleChange}
          placeholder=""
         />
        </div>
       </div>

       <div className="row">
        <div className="col-3">
         Color Name:
        </div>
        <div className="col-9">
         <input
          name="color"
          disabled={orderFabrics.fabricId ? true : false}
          value={orderFabrics.color || ""}
          onChange={handleChange}
          placeholder=""
         />
        </div>
       </div>
      </>
     }


    </form>
   </Modal.Body>

   <Modal.Footer>
    <div className="btn_Wrapper">
     <button className="btn_grn" onClick={handleSubmit}>
      {
       submitClick ?
        <Spinner styleClass="text-primary" />
        :
        "Submit"
      }
     </button>
     <button className="btn_red" onClick={props.close}>Cancel</button>
    </div>
   </Modal.Footer>

  </Modal>
 )
}

export default AddFabricModal;
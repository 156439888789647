import axios from "axios";
import { ApiUrl } from "../../constants";
const url = ApiUrl + "/Swatches/"

export const inactiveSwatches = (fabricId, token) => {
 return axios.post(url + "inactive/" + fabricId,{} ,{ headers: { 'authorization': token } })
  .then((response) => { return response.data })
  .catch(err => { return err.message })
}

export const deleteSwatches = (fabricId, token) => {
 return axios.delete(url + "delete/" + fabricId,{ headers: { 'authorization': token } })
  .then((response) => { return response.data })
  .catch(err => { return err.message })
}
import * as types from '../constants/signUp';

export function setStep(step) {
 return { type: types.SET_STEP, payload: step }
}
export function setLoading(loading) {
 return { type: "SET_LOADER", payload: loading }
}
export function setOwner(owner) {
 return { type: types.SET_OWNER_INFO, payload: owner }
}
export function setCompany(company) {
 return { type: types.SET_COMPANY_INFO, payload: company }
}
export function setNoOfUsers(users) {
 return { type: types.SET_NO_OF_USERS, payload: users }
}
export function setNoOfPacks(packs) {
 return { type: types.SET_NO_OF_PACKS, payload: packs }
}
export function setCardInfo(card) {
 return { type: types.SET_CARD_INFO, payload: card }
}
export function getCoupons() {
 return { type: types.GET_COUPONS_REQUEST }
}

export function proceedToSignUp() {
 return { type: types.PROCEED_TO_SIGN_UP }
}

export function flushSignUP() {
 return { type: types.TRUNCATE_SIGN_UP }
}
import React, { Component } from 'react'
import PaypalButton from './paypalButton'
import { connect } from "react-redux";
import { } from 'dotenv/config';


import PaymentOptions from './options'
import PaymentOptionItem from './paymentOptions'

// import Checkout from './checkoutStripe';
import CardCheckout from './cardCheckout';
import { getBill } from '../../../store/services/subscription';
import { setBill } from '../../../store/actions/subscription';

//grabs the paypal id from environment variables.
const CLIENT = {
	sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID,
	production: 'xxxXXX',
};

const ENV = process.env.NODE_ENV === 'production'
	? 'production'
	: 'sandbox';

class paymentMethod extends Component {
	constructor(props) {
		super(props)

		//constructor for setting the state of payment options radio buttons
		this.state = {

			data: PaymentOptions,
			currentMethod: 'Visa',
			totalAmount: 0
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);

	}

	componentDidMount() {
		//sends a get request for billable amount
		getBill(this.props.companyId, this.props.token)
			.then(response => {
				this.props.setBill(response);
				this.setState({ totalAmount: response.invoice.netPayable });
			})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	handleStateChange(type) {
		// this.setState({ currentMethod: type })
	}

	//change function for altering values on click.
	handleChange(event) {
		event.persist();
		const { name, value, type, checked } = event.target
		type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
	}


	//renders the html elements for radio buttons. Using names, values and onChange to
	//toggle event handling.
	render() {
		const onSuccess = (payment) => {
			this.setState({ totalAmount: 0 })
		};

		const onError = (error) => console.log('Erroneous payment OR failed to load script!', error);

		const onCancel = (data) => console.log('Cancelled payment!', data)

		return (
			<div id="page-content-wrapper">
				<div className="container-fluid">
					<div
						className="row setup_top2 justify-content-md-center"
						style={{ display: 'flex', justifyContent: 'center' }}
					>
						<div className="col-12 col-sm-8">
							<div className="box_payment">
								<div className="heding1">PAYMENT METHOD</div>
								<div className="inside">
									<div
										className="row"
										style={{ display: 'flex', justifyContent: 'space-around' }}
									>
										{this.state.data && Array.isArray(this.state.data) && this.state.data.map((item, ind) =>
											<PaymentOptionItem
												key={item.id + ind}
												item={item}
												currentMethod={this.state.currentMethod}
												onChange={this.handleStateChange}
											/>)
										}
									</div>
									<div className="total_amt">
										<h3>Total Amount</h3>
										<h4>$ {this.state.totalAmount}</h4>

										{(this.state.currentMethod === 'Visa') ?
											<CardCheckout
												props={this.props}
												name={'Swatch Masters'}
												description={'Item that you sold'}
												amount={this.state.totalAmount}
												onSuccess={onSuccess}
											/> :
											<PaypalButton
												client={CLIENT}
												env={ENV}
												commit={true}
												currency={'USD'}
												total={this.state.totalAmount}
												onSuccess={onSuccess}
												onError={onError}
												onCancel={onCancel}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		companyId: state.auth.companyId,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setBill: (bill) => { dispatch(setBill(bill)) }
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(paymentMethod);

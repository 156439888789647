import React, { Component } from 'react';
import BallbeatLoader from '../../components/loaders/ballBeatLoader';
import uploadImage from "../../img/upload.png";
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2';

import { editFabric, getFabric, uploadFabricPhoto } from '../../store/services/fabric';
import { getFabrics } from '../../store/actions/fabric';

class EditFabric extends Component {
	constructor(props) {
		super(props)
		this.state = {
			id: '',
			code: '',
			alias: '',
			patternName: '',
			color: '',
			grade: '',
			active: true,
			discontinued: false,
			saving: false,
			error: false,
			isEdited: false,
			isFile: false,
			file: undefined,
			errMsg: '',
		}
	}

	componentDidMount() {
		this.setState({
			id: this.props.fabric.id,
			code: this.props.fabric.code,
			alias: this.props.fabric.alias,
			patternName: this.props.fabric.pattern_name,
			color: this.props.fabric.color,
			grade: this.props.fabric.grade,
			active: this.props.fabric.active,
			photo: this.props.fabric.photo,
			discontinued: this.props.fabric.discontinued,
		})
	}

	handleSave = async (event) => {
		this.props.getFabrics()
		event.preventDefault();
		const { code, alias, patternName, color, grade, discontinued, active } = this.state
		if (!code || !alias || !patternName || !color || !grade) return Swal.fire({ icon: 'error', title: 'All fields are required!', timer: 2000 })
		const { token, companyId } = this.props;
		this.setState({
			error: false,
			saving: true,
		})

		const fabricFilter = { where: { companyId: companyId, code: code } }
		if (this.props.fabric.code !== this.state.code) {
			var fabric = await getFabric(fabricFilter, token);
			if (typeof fabric === 'string' || fabric.length < 1) return Swal.fire({ icon: 'error', title: 'Code already exists!', timer: 2000 })
		}

		const fabricPayload = {
			code,
			pattern_name: patternName,
			alias,
			color,
			grade,
			photo: this.props.fabric.photo,
			active,
			discontinued
		}

		editFabric(this.props.fabric.id, fabricPayload, token)
			.then((response) => {
				if (typeof response === 'object') {
					Swal.fire({ icon: 'success', title: 'Sunccessfully Updated', timer: 2000 })
					return this.props.getFabrics()
				}
				Swal.fire({ icon: 'error', title: 'Something went wrong', timer: 2000 })
			})


	}
	//handleChange function for all inputs
	handleChange = (event) => {
		event.persist();
		this.setState({ [event.target.name]: event.target.value, isEdited: true })
	}

	handleCheck = (event) => {
		event.persist();
		this.setState({ [event.target.name]: event.target.checked, isEdited: true })
	}

	handleFile = ({ target }) => {
		this.setState({
			isFile: true,
			file: target.files[0]
		})
	}

	handleUpload = (e) => {
		e.preventDefault();
		this.setState({
			errMsg: '',
			error: false,
			saving: true,
		})

		let formData = new FormData();
		formData.append('file', this.state.file)

		uploadFabricPhoto(this.props.fabric.id, formData, this.props.token)
			.then((response) => {
				console.log('response ==> ', response)
				if (typeof response === 'object') {
					if (response.statusCode === 304) {
						return this.setState({
							error: true,
							saving: false,
							errMsg: response.message,
						})
					}
					if (response.Location) {
						this.props.getFabrics();
						Swal.fire({icon: 'success', title: 'Uploaded successfully!', timer: 2000})
						return this.setState({ photo: response.Location, file: undefined, isFile: false, saving: false })
					}
				}
				this.setState({
					error: true,
					errMsg: "Soemthing went wrong",
					saving: false,
				})

			})

	}

	render() {
		return (
			<Modal
				className="container-fluid"
				show={this.props.editModal}
				onHide={this.props.close}
				centered
				dialogClassName="modal_width"
			>
				<Modal.Header
					closeButton>
					<h5 className="">Edit Fabric</h5>
				</Modal.Header>
				<Modal.Body>
					<div className="addgrp_sec addgrp_sec_block">
						<div className="addgrp_sec2 no-shadow">
							<form className="col-12" onSubmit={this.handleSave}>
								<div className="form-row">
									<div className="col-xl-4 col-6">
										<div className="row">
											<label className="col-xl-auto col-auto label-text font-weight-bold">Fabric Scan Id:</label>
											<div className="col-xl-6 col-6 input-popup">
												<input type="text" required value={this.state.code} name="code" onChange={this.handleChange} className="form-control col-12" />
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-12" >
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold no-padding">Fabric Alias:</label>
											<div className="col-xl-8 col-8 input-popup">
												<input type="text" value={this.state.alias} name="alias" onChange={this.handleChange} className="form-control col-12" />
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-12" >
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold">Pattern name:</label>
											<div className="col-xl-8 col-8 input-popup">
												<input required type="text" value={this.state.patternName} name="patternName" onChange={this.handleChange} className="form-control col-12" />
											</div>
										</div>
									</div>

								</div>
								<div className="form-row">
									<div className="col-xl-2 col-6" >
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold">Color:</label>
											<div className="col-xl-8 col-8 input-popup">
												<input required type="text" value={this.state.color} name="color" onChange={this.handleChange} className="form-control col-12" />
											</div>
										</div>
									</div>

									<div className="col-xl-3 col-12">
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold">Grade:</label>
											<div className="col-xl-7 col-8 input-popup">
												<input type="text" required value={this.state.grade} name="grade" onChange={this.handleChange} className="form-control col-12" />
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-12">
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold">Discontinued:</label>
											<div className="col-xl-7 col-8 input-popup">
												<label className="switch float-left mt-2" style={{ color: 'white' }}>
													<input type="checkbox" name='discontinued' onChange={this.handleCheck} checked={this.state.discontinued} className="default" />
													<span className="slider round" />
												</label>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-12">
										<div className="row justify-content-center">
											<label className="col-xl-auto col-4 label-text font-weight-bold">Status:</label>
											<div className="col-xl-8 col-8  input-popup">
												<label className="switch float-left mt-2" style={{ color: 'white' }}>
													<input type="checkbox" name='active' onChange={this.handleCheck} checked={this.state.active} className="default" />
													<span className="slider round" />
												</label>
											</div>
										</div>
									</div>

									<div className="col-xl-12 col-12">
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold">photo:</label>

											{this.state.isFile ?
												<div className="col-xl-8 col-8">
													<img style={{ width: "100px" }} src={URL.createObjectURL(this.state.file)} ></img>
													&nbsp;&nbsp;&nbsp; <button className="btn_grn" onClick={this.handleUpload} >Upload</button>
												</div>
												:
												<div className="col-xl-8 col-8  input-popup">
													<input
														onChange={this.handleFile}
														type='file'
														id="uploadFabricPhoto"
														accept="image/*"
														style={{
															width: '0.1px',
															height: '0.1px',
															opacity: 0,
															overflow: 'hidden',
															position: 'absolute',
															zIndex: '-1'
														}}
													/>
													<label className="btn_grn"
														htmlFor="uploadFabricPhoto">
														<img
															alt=""
															src={uploadImage}
															style={{ display: 'inLine' }} />
														<h6
															style={{
																display: 'inLine',
																color: 'white'
															}}>
															{' '} Upload Fabric Photo
														</h6>
													</label>
												</div>
											}

										</div>
									</div>
								</div>
								<div className="btn_box" >
									<div className="float-right btn_grp">
										<div className="addgroup1">
											<button type="button" disabled={this.state.saving} className="btn_red" onClick={this.props.close}>Cancel</button>

											{
												this.state.saving ?
													<div style={{ display: "inline-block", paddingLeft: "10px" }}>
														<BallbeatLoader styleclassName='text-primary' />
													</div>
													:
													this.state.isEdited && <button type="submit" className="btn_grn">Save</button>
											}

										</div>

									</div>
									{
										this.state.error ?
											<Alert key='1' variant='danger'>
												{this.state.errMsg}
											</Alert>
											:
											null
									}
								</div>

							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		)
	}
}
const mapStateToProps = state => {
	return {
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getFabrics: () => dispatch(getFabrics())
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(EditFabric)

export const SET_STEP = "SET_STEP";
export const SET_CARD_INFO = "SET_CARD_INFO";
export const SET_SINED_INFO = "SET_SINED_INFO";
export const SET_OWNER_INFO = "SET_OWNER_INFO";
export const SET_NO_OF_PACKS = "SET_NO_OF_PACKS";
export const SET_NO_OF_USERS = "SET_NO_OF_USERS";
export const SET_COMPANY_INFO = "SET_COMPANY_INFO";
export const SET_SUBSCRIPTIONS_INFO = "SET_SUBSCRIPTIONS_INFO";
export const SET_PASSWORD = "SET_PASSWORD";
export const SEND_EMAIL = "SEND_EMAIL";

export const PROCEED_TO_SIGN_UP = "PROCEED_TO_SIGN_UP";

export const GET_COUPONS_REQUEST = "GET_COUPONS_REQUEST";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAILURE = "GET_COUPONS_FAILURE";

export const ADD_OWNER_REQUEST = "ADD_OWNER_REQUEST";
export const ADD_OWNER_SUCCESS = "ADD_OWNER_SUCCESS";
export const ADD_OWNER_FAILURE = "ADD_OWNER_FAILURE";

export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const ADD_SUBSCRIPTION_REQUEST = "ADD_SUBSCRIPTION_REQUEST";
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS";
export const ADD_SUBSCRIPTION_FAILURE = "ADD_SUBSCRIPTION_FAILURE";

export const ADD_CARD_INFO_REQUEST = "ADD_CARD_INFO_REQUEST";
export const ADD_CARD_INFO_SUCCESS = "ADD_CARD_INFO_SUCCESS";
export const ADD_CARD_INFO_FAILURE = "ADD_CARD_INFO_FAILURE";


export const TRUNCATE_SIGN_UP = "TRUNCATE_SIGN_UP";
import paypayImg from "../../../img/paypal.png"
import creditCardImg from "../../../img/credit-card.png"

const options = [
	// {
	// 	itemId: "PayPal",
	// 	name: 'PayPal',
	// 	imgSrc: paypayImg,
	// 	cssClass:'paypal_bx'
	// },
	{
		itemId: "Visa",
		name: 'Credit Card',
		imgSrc: creditCardImg,
		cssClass: 'creditBx'
	}
]

export default options;

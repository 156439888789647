import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Modal from "react-bootstrap/Modal";
import BallbeatLoader from "../../../components/loaders/ballBeatLoader";
import Swal from 'sweetalert2';
import { ApiUrl } from '../../../constants';
import { addFabric, getFabric, editFabric } from '../../../store/services/fabric.js';

class editSwatchDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			id: '',
			code: '',
			groupName: '',
			fabricName: '',
			patternName: '',
			color: '',
			grade: '',
			isEdited: false,
			active: false,
			gradeNotice: false,
			saving: false,
			error: false,
			errMsg: '',
			updateNotice: false,
			initialGrade: ''
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.getInitialValues = this.getInitialValues.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
	}
	componentDidMount() {
		this.getInitialValues();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props !== prevProps)
			this.getInitialValues()
		if (this.state.grade !== prevState.grade)
			this.setState({ gradeNotice: (this.state.initialGrade !== this.state.grade) });
	}

	getInitialValues = () => {
		this.setState({
			code: this.props.details.code,
			fabricName: this.props.details.fabricName,
			patternName: this.props.details.patternName,
			color: this.props.details.color,
			grade: this.props.details.grade,
			gradeNotice: this.props.details.gradeNotice,
			active: this.props.details.activeStatus,
			id: this.props.details.id,
			initialGrade: this.props.details.grade
		})
	}

	handleSave = async (event) => {
		event.preventDefault();
		const { permissions, role, companyId, token } = this.props;
		const { code, fabricName, patternName, color, grade, gradeNotice, active } = this.state
		if (role == "user") {
			if (!permissions.length || !this.props.permissions[0]["Edit Swatch"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
		}
		this.setState({
			error: false,
			saving: true,
		})


		const fabricFilter = { filter: { where: { companyId: companyId, code: code } } }
		const fabricPayload = {
			code,
			alias: fabricName,
			pattern_name: patternName,
			color,
			grade,
			photo: '',
			companyId,
			discontinued: false
		}
		var fabric = await getFabric(fabricFilter, token)
		if (typeof fabric === 'string' || fabric.length < 1) {
			fabric = await addFabric(fabricPayload, token)
		} else {
			if (Array.isArray(fabric) && fabric.length > 0) {
				fabric = fabric[0];
				delete fabricPayload.discontinued;
				await editFabric(fabric.id, fabricPayload, token)
			}
		}

		const swatch_data = {
			grade_notice: gradeNotice,
			active: active,
			fabricId: typeof fabric === 'object' ? fabric.id : '',
			scanned: null,
		}

		axios.patch(ApiUrl + "/Swatches/" + this.props.details.id, swatch_data,
			{
				headers: {
					'Authorization': this.props.token
				}
			})
			.then(res => {
				this.props.handleUpdateGroup();
				this.props.close().then(() => {
					this.props.updateSwatches().then(() => {
						this.setState({
							error: false,
							saving: false,
						})
					}).catch(() => {
						this.setState({
							error: true,
							saving: false,
							errMsg: "Changes saved but unable to refresh Swatches"
						}, () => {
							setTimeout(() => { this.setState({ error: false }) }, 2000)
						})
					})
				}).catch(() => {
					this.setState({
						error: true,
						saving: false,
						errMsg: "Changes saved but unable to refresh Swatches"
					}, () => {
						setTimeout(() => { this.setState({ error: false }) }, 2000)
					})
				})
			})
			.catch(err => {
				console.log(err);
				this.setState({
					error: true,
					saving: false,
					errMsg: "Changes not saved. Please check your internet connection"
				}, () => {
					setTimeout(() => { this.setState({ error: false }) }, 2000)
				})
				return null;
			})
		this.props.getSwatches();
	}
	//handleChange function for all inputs
	handleChange(event) {
		event.persist();
		this.setState({ [event.target.name]: event.target.value, isEdited: true })
	}

	handleCheck(event) {
		event.persist();
		this.setState({ [event.target.name]: event.target.checked, isEdited: true })
	}

	render() {
		return (
			<Modal
				className="container-fluid"
				show={this.props.popupState}
				onHide={this.props.close}
				centered
				size="xl"
				dialogClassName="modal_width"
			>
				<Modal.Header
					closeButton>
					<h5 className="">Edit Swatch</h5>
				</Modal.Header>
				<Modal.Body>
					<div className="addgrp_sec addgrp_sec_block">
						<div className="addgrp_sec2 no-shadow">
							<form className="col-12" onSubmit={this.handleSave}>
								<div className="form-row">
									<div className="col-xl-4 col-6">
										<div className="row">
											<label className="col-xl-auto col-auto label-text font-weight-bold">Fabric Scan ID:</label>
											<div className="col-xl-6 col-6 input-popup">
												<input type="text" required value={this.state.code} name="code" onChange={this.handleChange} className="form-control col-12" />
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-12" >
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold no-padding">Fabric Alias:</label>
											<div className="col-xl-8 col-8 input-popup">
												<input type="text" value={this.state.fabricName} name="fabricName" onChange={this.handleChange} className="form-control col-12" />
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-12" >
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold">Pattern name:</label>
											<div className="col-xl-8 col-8 input-popup">
												<input required type="text" value={this.state.patternName} name="patternName" onChange={this.handleChange} className="form-control col-12" />
											</div>
										</div>
									</div>
								</div>
								<div className="form-row">
									<div className="col-xl-2 col-6" >
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold">Color:</label>
											<div className="col-xl-8 col-8 input-popup">
												<input required type="text" value={this.state.color} name="color" onChange={this.handleChange} className="form-control col-12" />
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-12">
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold">Grade:</label>
											<div className="col-xl-7 col-8 input-popup">
												<input type="text" required value={this.state.grade} name="grade" onChange={this.handleChange} className="form-control col-12" />
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-12">
										<div className="row">
											<label className="col-xl-auto col-4 label-text font-weight-bold">Grade Notice:</label>
											<div className="col-xl-7 col-8 input-popup">
												<label className="switch float-left mt-2" style={{ color: 'white' }}>
													<input type="checkbox" name='gradeNotice' onChange={this.handleCheck} checked={this.state.gradeNotice === true} className="default" />
													<span className="slider round" />
												</label>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-12">
										<div className="row justify-content-center">
											<label className="col-xl-auto col-4 label-text font-weight-bold">Status:</label>
											<div className="col-xl-8 col-8  input-popup">
												<label className="switch float-left mt-2" style={{ color: 'white' }}>
													<input type="checkbox" name='active' onChange={this.handleCheck} checked={this.state.active} className="default" />
													<span className="slider round" />
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="btn_box" >
									<div className="float-right btn_grp">
										<div className="addgroup1">
											<button type="button" disabled={this.state.saving} className="btn_red" onClick={this.props.close}>Cancel</button>
											{
												this.state.saving ?
													<div style={{ display: "inline-block", paddingLeft: "10px" }}>
														<BallbeatLoader styleclassName='text-primary' />
													</div>
													:
													(
														this.state.isEdited &&
														<button type="submit" className="btn_grn">Save</button>
													)
											}
										</div>


									</div>
									{
										this.state.error ?
											<Alert key='1' variant='danger'>
												{this.state.errMsg}
											</Alert>
											:
											null
									}
								</div>

							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		)
	}
}
const mapStateToProps = state => {
	return {
		companyId: state.auth.companyId,
		apiUrl: state.auth.apiUrl,
		token: state.auth.token,
		permissions: state.auth.permissions,
		role: state.auth.role,
	};
};
export default connect(mapStateToProps)(editSwatchDetails)

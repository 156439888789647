import React, { Component } from 'react';
import { addCompanyId, addToken, addURL, addUserId, addPermissions } from "../../store/actions/actions";
import { connect } from "react-redux";
import logoutIcon from "../../img/logout.png";
import { Link } from "react-router-dom";
import Logo from './../../img/favIcon.png'

class myNavbar extends Component {

	constructor(props) {
		super(props)
		this.logout = this.logout.bind(this)
	}

	logout() {
		if (this.props.toggleStateVal) {
			this.props.toggleState()
		}
		this.props.addToken(null)
		this.props.addUserId(null)
		this.props.addCompanyId(null)
		this.props.addPermissions(null)
		localStorage.clear()


	}

	render() {
		return (
			<nav id="navbar_id" className="navbar navbar-expand navbar-dark naviget">

				<Link id="navbar-logo" className="navbar-brand btn btn-link" style={{ cursor: "pointer" }} to={this.props.company && this.props.company.type === "vendor" ? 'vendor' : '/'}>
					<img
						alt="Logo"
						className="rounded-circle"
						style={{ width: '40px', height: '40px', maxWidth: '40px', maxHeight: '40px', cursor: 'pointer' }}
						src={Logo}
					/>
				</Link>

				<button id="menu-toggle" className="navbar-brand btn btn-link" onClick={this.props.toggleState}>
					<span className="navbar-toggler-icon"
						style={{ width: '30px', height: '30px', maxWidth: '30px', maxHeight: '30px', marginTop: '10px' }}
					/>
				</button>

				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample02"
					aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon" />
				</button>

				<div style={{ position: 'absolute', right: 0, display: 'flex', flexDirection: 'column', margin: '0px 15px 0px 0px'}}>
					<Link to={'/'}
						className="delete_link"
						onClick={this.logout}
						style={{ margin: 'auto'}}>
						<img alt="logout icon" src={logoutIcon} />
					</Link>
					<Link to="/" onClick={this.logout} style={{ color: 'white', fontWeight: 'bold' }}>SIGN OUT</Link>
				</div>
			</nav>
		)
	}
}

const mapStateToProps = state => {
	return {
		apiUrl: state.auth.apiUrl,
		userId: state.auth.userId,
		companyId: state.auth.companyId,
		company: state.auth.company,
		token: state.auth.token

	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addURL: () => dispatch(addURL()),
		addToken: id => dispatch(addToken(id)),
		addUserId: id => dispatch(addUserId(id)),
		addCompanyId: id => dispatch(addCompanyId(id)),
		addPermissions: id => dispatch(addPermissions(id)),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(myNavbar);
import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import delImage from '../../../img/delete.png';
import editImage from './../../../img/edit.png';
import codeImage from './../../../img/code.png';
import Spinner from './../../../components/loaders/spinner.js';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { getScannedGroups } from '../../../store/services/inventory';
import { ApiUrl } from '../../../constants';

class Inventory_Group_Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inventoryType: "N/A",
      checkBox: false,
      reRend: false,
      display: true,
      fetchingType: true,
      typeErr: false,
      downloadStatus: false,
      isDeleting: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangeDownloadStatus = this.handleChangeDownloadStatus.bind(this);
  }
  componentDidMount() {
    this.setType(this.props.item.id)
    this.setState({
      downloadStatus: this.props.item.download_available
    })
    //set default value of checkbox
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.item.download_available !== this.state.downloadStatus) {
      this.setState({downloadStatus: newProps.item.download_available})
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if ((this.props.checkedGroupId.indexOf(this.props.item.id) !== parseInt("-1")) && ((this.state.checkBox === false))) {
      this.setState({
        checkBox: true
      }, () => {//console.log("state Changed to true")
      })
    }
    else if ((this.props.checkedGroupId.indexOf(this.props.item.id) === parseInt("-1")) && ((this.state.checkBox === true))) {
      this.setState({
        checkBox: false
      }, () => {//console.log("state Changed to true")
      })
    }
    if (prevProps.filterType !== this.props.filterType && this.state.inventoryType !== "N/A") {
      if (this.props.filterType === "") {
        this.setState({
          display: true
        })
      }
      else if (this.state.inventoryType.toLowerCase().includes(this.props.filterType.toLowerCase())) {
        this.setState({ display: true })
      }
      else if (this.props.item.name.toLowerCase().includes(this.props.filterType.toLowerCase())) {
        this.setState({ display: true })
      }
      else {
        this.setState({
          display: false
        })
      }
    }
  }
  //deletes current inventory group
  handleDelete() {
    const { permissions, user, userId } = this.props;
    if (user && user.id === userId) {
      if (!permissions.length || !this.props.permissions[0]["Delete Group"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
    }

    this.setState({
      isDeleting: true
    })
    getScannedGroups(this.props.item.id, this.props.token)
      .then(response => {
        if (Array.isArray(response) && response.length > 0) {
          this.setState({
            isDeleting: false
          })
          return Swal.fire({ icon: "warning", title: "Can't delete", text: "The group contains records" });
        } else {
          this.props.handleDeleteRow(this.props.item.id).then(() => {
            this.setState({
              isDeleting: false
            })
          })
            .catch(() => {
              this.setState({
                isDeleting: false
              })
            })
        }
      })

  }
  //handle change for check box
  handleChange(event) {
    if (this.state.checkBox === false) {
      this.props.handleChecks(this.props.item.id);
    }
    else {
      this.props.handleUnChecks(this.props.item.id);
    }
    this.setState((prevState) => ({
      checkBox: !prevState.checkBox
    }))


  }
  handleChangeDownloadStatus = (event) => {
    event.persist();
    const { permissions, role } = this.props;
    if (role == "user") {
      if (!permissions.length || !this.props.permissions[0]["Edit Group"].checked) return Swal.fire({ icon: "error", text: "You are restricted by the admin!" })
    }
    const group_data = {
      download_available: event.target.checked,
    };

    axios.patch(ApiUrl + `/InventoryGroups/` + this.props.item.id, group_data,
      {
        headers: {
          'Authorization': this.props.token
        }
      })
      .then(res => {
        this.props.updateGroups();
        this.setState({
          downloadStatus: res.data.download_available,
        })
      })
      .catch(err => {
        return null;
      })
  }
  //Type of inventory group is set using id of group
  setType = (tokenUrl) => {
    this.setState({
      fetchingType: true,
      typeErr: false,
    })
    const urlForToken = ApiUrl + "/InventoryGroups/" + tokenUrl + "/inventoryType"
    axios
      .get(urlForToken,
        {
          headers: {
            'Authorization': this.props.token
          }
        })
      .then(response => {
        this.setState({
          inventoryType: response.data.name,
          fetchingType: false,
        })
      })
      .catch(err => {
        console.log(err);
        this.setState({
          fetchingType: false,
          typeErr: true,
        })
        return null;
      })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.defaultState) {
      this.setState({ toggle: true })
    }

  }
  render() {
    if (this.state.display === true) {
      return (
        this.state.isDeleting || this.state.fetchingType ?
          <tr style={{ height: '100%', alignContent: 'center' }}>
            <td colSpan={10}>
              <Spinner styleClass="text-primary" />
            </td>
          </tr>
          :
          <tr>
            <td>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" name='checkBox' checked={this.state.checkBox} onChange={this.handleChange} className="custom-control-input" id={this.props.item.id} />
                <label className="custom-control-label" htmlFor={this.props.item.id}></label>
              </div>
            </td>
            <td>
              {
                //Name of Inventory Group
                this.props.item.name
              }
            </td>
            <td>
              {
                this.state.typeErr ?
                  <p style={{ color: 'red' }}>type not available</p>
                  :
                  this.state.inventoryType
              }
            </td>
            <td>
              {
                //Version
                this.props.item.version
              }
            </td>
            <td>
              {
                (new Date(this.props.item.createdAt)).toLocaleDateString()
              }
            </td>
            <td>
              {
                (new Date(this.props.item.updatedAt)).toLocaleDateString()
              }
            </td>
            <td style={{ display: 'flex', justifyContent: 'center' }}>
              <label className="switch ">
                <input type="checkbox" name='downloadStatus' checked={this.state.downloadStatus} onChange={this.handleChangeDownloadStatus} className="default" />
                <span className="slider round" />
              </label>
            </td>
            <td>
              <Link to={`/edit-inventory-group/${this.props.item.id}`} className="edit_link"><img alt="" src={editImage} /></Link>
            </td>
            <td>
              <button className="delete_link btn btn-link" onClick={this.handleDelete}><img alt="" src={delImage} /></button>
            </td>
            <td>
              <Link to={`/scanned-swatches/${this.props.item.id}`} className="delete_link" ><img alt="" src={codeImage} /></Link>
            </td>
          </tr>
      )
    }
    else {
      return null
    }
  }
}
const mapStateToProps = state => {
  return {
    companyId: state.auth.companyId,
    apiUrl: state.auth.apiUrl,
    token: state.auth.token,
    permissions: state.auth.permissions,
    role: state.auth.role,
    user: state.auth.user,
    userId: state.auth.userId
  };
};

export default connect(mapStateToProps)(Inventory_Group_Row)

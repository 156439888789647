export const selectOwner = (state) => {
 return state.signUp.owner;
}
export const selectPassword = (state) => {
 return state.signUp.password;
}
export const selectOwnerId = (state) => {
 return state.signUp.owner.id;
}
export const selectCompany = (state) => {
 return state.signUp.company;
}
export const selectCompanyId = (state) => {
 return state.signUp.company.id;
}
export const selectCard = (state) => {
 return state.signUp.cardInfo;
}
export const selectLoader = (state) => {
 return state.signUp.loading;
}
export const selectNoOfPacks = (state) => {
 return state.signUp.noOfPacks;
}
export const selectPlans = (state) => {
 return state.subscription.plans.filter((plan) => plan.max_users === 5);
}